(function() {
  'use strict';

  var app = angular.module('inlineEdit');

  app.controller('inlineWidgetChipSelectBase', [
    '$scope',
    '$controller',
    '$element',
    '$attrs',
    '$rootScope',
    'taskService',
    'accountService',
    'commonService',
    '$timeout',
    function($scope, $controller, $element, $attrs, $rootScope, taskService, accountService, commonService, $timeout) {
      $scope.emptyVal = [];
      $scope.allowMultiple = true;
      $scope.scope = $scope;
      var label = 'title';

      // Watch for model clears and make sure they are reflected visually.
      $scope.$watch("modelVal", function(newVal, oldVal, scope) {
        if (newVal != oldVal && commonService.empty(newVal)) {
          scope.selectedValue[scope.modelKey] = [];
          scope.acBlur(scope.modelKey);
        }
      });

      // Non-member flag needs to enabled as a Feature.
      // Also want to check the context for this field.
      if ($scope.context == 'taskModal' && $scope.taskId != false) {
        $scope.nonMemberNotice = true;
      }

      $scope.populateValue = function(options, valueKey, key) {
        if (typeof valueKey != 'object') {
          valueKey = [valueKey];
        }
        valueKey = valueKey.map(String);

        let value = commonService.getObjectByIds(options, valueKey, key);
        if (commonService.empty(value)) {
          value = valueKey;
        }
        return value;
      };

      $scope.chip_querySearch = function(query, list) {
        var results = query ? list.filter(createFilterFor(query)) : list.filter(createFilterFor(''));

        return results;
      };

      $scope.transformChip = function (chip) {
        return chip;
      };

      /**
       * Create filter functionfor a query string
       */
      function createFilterFor(query) {
        var lowercaseQuery = angular.lowercase(query);
        // Search in UID and Name of the list for matched result.
        return function filterFn(value) {
          if (value.hasOwnProperty('_lowercase')) {
            return (value['_lowercase'].indexOf(lowercaseQuery) === 0);
          }
          return false;
        };
      }

      // Generate a list of items with specific keyworks.
      function loadOptions(itemList) {
        return itemList.map(function(item) {
          let label = item.display_name || item.title || item.name || item.label;
          item['_lowercase'] = angular.lowercase(label);
          return item;
        });
      }

      $scope.chip_readonly = false;
      $scope.chip_removable = true;

      // Apply chip multi select based on passed field.
      // selected The list of previous selected options.
      // options The list of data that user can search and select.
      $scope.applyChipSelect = function(mapping) {
        let objectList = mapping.list;
        let key = 'id';
        let label = 'title';
        if (mapping.hasOwnProperty('key')) {
          key = mapping.key;
        }
        if (mapping.hasOwnProperty('label')) {
          label = mapping.label;
        }

        // Start Of Chip Select
        // Set the list of selected and available options.
        let options = loadOptions($scope.account[objectList]);


        // Add non-member notice to option list as needed.
        if ($scope.nonMemberNotice) {
          $scope.updateOptions(options);
        }
        // Search by user key work on the list of items.
        return options;
      };

      $scope.updateOptions = function(options) {
        // Load the project for this node into scope.
        if ($scope.node.project) {
          $scope.project = accountService.getObjectById('projects', $scope.node.project);
        }

        // Highlight users not in this project for user-type fields.
        if ($scope.inputType == 'user') {
          // Compare the project members with the account members and flag all
          // options that aren't in the current project.
          angular.forEach(options, function(mem_option) {
            if (mem_option.uid) {
              mem_option.outsider = ($scope.project.members.indexOf(mem_option.uid) == -1);
            }
            else {
              mem_option.outsider = false;
            }
          });
        }

        // For search chips (users, tags).
        // TODO: this doesn't support removing chips on the fly.
        if ($scope.inputType == 'text') {
          // Inititalize if not already present (for early load).
          let options = $scope.selectedValue[$scope.modelKey] || [];

          // Merge new chips with field/node values.
          if (Array.isArray($scope.node[$scope.fieldName])) {
          // if ($scope.node[$scope.fieldName]) {
            $scope.node[$scope.fieldName].forEach((id) => {
              let item = commonService.getObjectById($scope.options, id, $scope.selectKey);

              // See if value id maps to a chip.
              let existing = false;
              if (options.length) {
                existing = commonService.getObjectById(options, id, $scope.selectKey);
              }

              // Add new value only if it's not a duplicate.
              if (!existing && item) {
                options.push(item);
              }
            });
            $scope.selectedValue[$scope.modelKey] = options;
          }
        }

      };

      // Allow opening the project edit modal from this field.
      $scope.editProject = function() {
        $rootScope.openProjectModal($scope.project.id, $scope.project.type);
      };

      $scope.hasOutsiders = function() {
        let outsiderCount = 0;
        if ($scope.selectedValue && $scope.modelKey && $scope.selectedValue.hasOwnProperty($scope.modelKey)) {

          angular.forEach($scope.selectedValue[$scope.modelKey], function(item) {
            if (item.outsider) {
              outsiderCount++;
            }
          });
        }
        return outsiderCount;
      }

      // Provide handling for focus on material input-container labels.
      $scope.states = {};
      $scope.states[$scope.modelKey] = {};
      $scope.acFocus = function (field) {
        $scope.states[field] = $scope.states[field] || {};
        $scope.states[field].focus = true;
      };

      // Defaults to a blank state. This is called again /after/ chip removal.
      $scope.acBlur = function (field) {
        if ($scope.states[field]) {
          $scope.states[field].focus = false;
          $scope.states[field].filled = false;
          if ($scope.selectedValue[field] && $scope.selectedValue[field].length >= 1) {
            $scope.states[field].filled = true;
          }
        }
      };

      angular.extend(this, $controller('inlineWidgetCtrl', { $scope: $scope, $element: $element, $attrs: $attrs }));

      let taskUpdateListener = $rootScope.$on('taskPostUpdate', function(event, task, updates, hash) {
        if ($scope.taskId == task.nid) {
          $timeout(() => {
            $scope.updateOptions();
          })
        }
      });
      $rootScope.addListener(taskUpdateListener, $scope);
    }
  ]);

  app.directive('inlineWidgetChipSelect', [
    '$compile',
    function($compile) {
      return {
        restrict: 'E',
        scope: {
          taskId: '=taskId',
          cid: '=?',
          fieldName: '@',
          label: '@',
          defaultValue: '@',
          onChange: '&?',
          modelVal: '=?',
          modelKey: '=?',
          inputType: '@',
          immediate: '=?',
          context: '@?'
        },
        link: function(scope, element) {
          var panelTemplate = {
            templateUrl: 'app/src/components/inline-edit/widgets/chips/templates/chipselect.html'
          };
          if ((scope.fieldName) == 'field_label') {
            panelTemplate = {
              templateUrl: 'app/src/components/inline-edit/widgets/chips/templates/chiptagging.html'
            };
          }

          element.html(panelTemplate.template);
          $compile(element.contents())(scope);
        },
        controller: 'inlineWidgetChipSelectBase'
      };
    }
  ]);

  app.directive('inlineWidgetMatChipSelect', [
    '$compile',
    function($compile) {
      return {
        restrict: 'E',
        scope: {
          taskId: '=taskId',
          cid: '=?',
          fieldName: '@',
          label: '@',
          defaultValue: '@',
          onChange: '&?',
          modelVal: '=?',
          modelKey: '=?',
          inputType: '@',
          immediate: '=?',
          context: '@?'
        },
        link: function(scope, element) {
          var panelTemplate = {
            templateUrl: 'app/src/components/inline-edit/widgets/chips/templates/chipselect-mat.html'
          };
          if ((scope.fieldName) == 'field_label' || scope.inputType == 'tag') {
            panelTemplate = {
              templateUrl: 'app/src/components/inline-edit/widgets/chips/templates/chiptagging-mat.html'
            };
          }

          element.html(panelTemplate.template);
          $compile(element.contents())(scope);
        },
        controller: 'inlineWidgetChipSelectBase'
      };
    }
  ]);

  app.directive('inlineWidgetChipSelectFlat', [
    '$compile',
    function($compile) {
      return {
        restrict: 'E',
        scope: {
          taskId: '=taskId',
          cid: '=?',
          fieldName: '@',
          label: '@',
          defaultValue: '@',
          onChange: '&?',
          modelVal: '=?',
          modelKey: '=?',
          inputType: '@',
          immediate: '=?',
          context: '@?'
        },
        templateUrl:'app/src/components/inline-edit/widgets/chips/templates/chipselect-flat.html',
        controller: 'inlineWidgetChipSelectBase'
      };
    }
  ]);
  app.directive('inlineWidgetMatChipSelectFlat', [
    '$compile',
    function($compile) {
      return {
        restrict: 'E',
        scope: {
          taskId: '=taskId',
          cid: '=?',
          fieldName: '@',
          label: '@',
          defaultValue: '@',
          onChange: '&?',
          modelVal: '=?',
          modelKey: '=?',
          inputType: '@',
          immediate: '=?',
          context: '@?'
        },
        templateUrl:'app/src/components/inline-edit/widgets/chips/templates/chipselect-flat-mat.html',
        controller: 'inlineWidgetChipSelectBase'
      };
    }
  ]);


})();
