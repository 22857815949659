(function () {
  'use strict';
  var app = angular.module('todolist', ['angular-drupal', 'ngMaterial', 'as.sortable', 'ngMessages', 'misc']);

  app.directive('todolistForm', function () {
    return {
      scope: {
        node: '=',
        field: '=',
      },
      templateUrl: 'app/src/todo/todolist-form.html',
      controller: [
        '$scope',
        'listLocalService',
        'refreshService',
        'commonService',
        'RestResource',
        '$timeout',
        function ($scope, listLocalService, refreshService, commonService, RestResource, $timeout) {
          $scope.todoListShow = false;
          $scope.base = AppConfig.basePath;
          var $modal = $scope.$parent.$parent;

          $scope.init = function () {
            $scope.newTodoList = {
              name: ""
            };
          };

          $scope.init();

          $scope.inputFocus = function () {
            $scope.todoItemShow = true;
          };
          $scope.inputBlur = function () {
            $scope.todoItemShow = false;
          };
          $scope.inputClear = function () {
            $scope.newTodoList.name = '';
            $scope.inputBlur();
          };
          $scope.buttonsShow = function () {
            return $scope.newTodoList.name || $scope.todoItemShow;
          };


          $scope.addTodoList = function () {
            if ($scope.newTodoList.name) {
              var newList = Object.assign({}, $scope.newTodoList);
              var listName = newList.name;
              newList.index = $scope.field.length;

              var hash = commonService.sdbmHash(JSON.stringify(newList));
              newList.hash = hash;

              listLocalService.addChanges('todolists', 'created', newList, 'id', $modal);

              // TODO: Should this use inputClear() instead?
              $scope.newTodoList.name = "";
              $(':focus').blur();
              var request = {
                title: listName,
                __attach: {
                  entity_id:  $scope.node.nid
                }
              };

              $timeout(function () {
                RestResource.eckEntityCreate('todo', 'todolist', request).then(function (res) {
                  // TODO: Should use the actual returned result that has been rendered
                  // serverside.
                  newList.parent_title = newList.name;
                  newList.id = res.id;
                  newList.parent_id = newList.id;
                  newList.todo_items = [];
                  newList.field_entity_state = '';

                  listLocalService.addChanges('todolists', 'created', newList, 'id', $modal);

                  if (res.id && res.id[0]) {
                    // Instantiate the field before attempting to add a value.
                    if (!$scope.field) {
                      $scope.field = [];
                    }
                  }

                  // Pass to parent scope.
                  $scope.field.push(res.id);

                  $scope.$parent.activeList = res.id;

                  // TODO: This finds the last Add Todo form (the newest) and focuses
                  // it. Because todolists aren't immediately added to scope, and
                  // there isn't an immediate way to determine when it has been, then
                  // we can't reliably select the new field.
                  //$('.todolist-wrap').last().find('.add-todo-field input').focus();

                  // Refresh the app with new data.
                  refreshService.appRefresh();

                });
              });
            }
          };
        }
      ]
    };
  });
})();
