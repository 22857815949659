(function () {
  'use strict';

  angular.module('activityview', [
      'ngRoute',
      'angular-drupal',
      'md.data.table',
      'message-service'
    ])

    .config(['$routeProvider', function ($routeProvider) {
      $routeProvider.when('/:accountId/projects/:projectId/activity/:taskId?', {
        templateUrl: 'app/src/activity/activityview.html',
        controller: 'ActivityViewCtrl',
        activetab: 'activity',
        reloadOnSearch: false
      });
    }])

    .controller('ActivityViewCtrl', [
      '$scope',
      '$rootScope',
      'routingService',
      'featureService',
      function ($scope, $rootScope, routingService, featureService) {

        // Have rootScope destroy all the listeners for this view.
        $scope.$on('$destroy', function () {
          $rootScope.destroyListeners($scope);
        });

        // Initialize the view.
        routingService.viewInit();

        // If the activity page isn't enabled, then redirect to the dashboard.
        featureService.featureEnabled('activity_log_activity_page').then(function(enabled) {
          if (!enabled) {
            routingService.changeDisplay('dashboard');
          }
        });

      }
    ]);
})();
