(function () {
  'use strict';

  var app = angular.module('filterpane');

  /**
   * This is an extension of the mdDateRangePicker directive to facilitate
   * this directive to properly respond when a template is set from an
   * outside source.
   */
  app.directive('mdDateRangePicker', function() {
    return {
      require: 'mdDateRangePicker',
      link: function(scope, element, attributes, mdDateRangePickerCtrl) {
        // Grab the actual scope of the date picker element.
        var innerScope = element.isolateScope();

        // Add a listener for when filters are updated.
        scope.$root.$on('filterUpdate', function() {
          scope.reApplyTemplate();
        });

        // This function is almost a direct copy of part of the init() function
        // from mdDateRangePickerCtrl. It handles template initialization.
        scope.reApplyTemplate = function() {
          if (innerScope.selectedTemplate) {
            switch (innerScope.selectedTemplate) {
              case 'TD':
                innerScope.handleClickSelectToday();
                break;
              case 'YD':
                innerScope.handleClickSelectYesterday();
                break;
              case 'TW':
                innerScope.handleClickSelectThisWeek();
                break;
              case 'LW':
                innerScope.handleClickSelectLastWeek();
                break;
              case 'TM':
                innerScope.handleClickSelectThisMonth();
                break;
              case 'LM':
                innerScope.handleClickSelectLastMonth();
                break;
              case 'TY':
                innerScope.handleClickSelectThisYear();
                break;
              case 'LY':
                innerScope.handleClickSelectLastYear();
                break;
              case 'BT':
                handleClickSelectBeforeToday();
                break;
            }
            innerScope.updateActiveDate();
          }
        }

        // Modeled after mdDateRangePicker, sets no start date, ends today.
        function handleClickSelectBeforeToday() {
          var d = new Date(), d1 = new Date(d.getFullYear(), d.getMonth(), d.getDate());

          innerScope.dateStart = null;
          innerScope.dateEnd = d1;
          innerScope.selectedTemplate = 'BT';
          innerScope.selectedTemplateName = 'Scheduled before today';
        }

      }
    };
  });
})();
