(function() {
  'use strict';

  var app = angular.module('entitylist-service', ['angular-drupal', 'account', 'rest']);

  app.factory('entityListService', [
    'commonService',
    'filterService',
    'RestResource',
    '$rootScope',
    'listLocalService',
    'searchService',
    '$route',
    '$timeout',
    'featureService',
    function(commonService, filterService, RestResource, $rootScope, listLocalService, searchService, $route, $timeout, featureService, ) {

      function EntityService(list, type, key, entityName = '', eventName = '') {
        this.initted = false;
        this.requestId = 1;
        this.responseId = 1;
        this.digest = false;
        this.hiding = {};
        this.list = list;
        this.type = type;
        this.key = key;
        this.eventName = eventName;
        this.vals = {};
        this.updateMapping = false;

        this.entityName = (entityName) ? entityName : this.type + 's';
      };

      // Initialize the rootScope storage of the values for this entity list.
      EntityService.prototype = {
        // Called after contstuction by Entity Lists instances.
        serviceInit: function() {
          this.vals = {}
          this.initLists();
        },

        reinit: function() {
          // Don't try to refresh an un-initted tasklist, and tasks aren't shown
          // on the dashboard.
          if (this.isInitted() && $route.current.activetab != 'dashboard') {
            // Filter updates should trigger a whole new load of tasks.
            this.clearHidingList();
            this.refresh('init');
          }
        },

        // Called during serviceInit() and this.refresh('init') to nuke these.
        initLists: function() {
          this.vals.entities = [];
          this.vals.entityList = [];
        },

        // Update the entity list with an array of changes.
        digestEntities: function(changes) {
          if (changes.length > 0) {
            this.processNewValues(this.getListIdentifier(), changes);
          }
        },

        // Check if the initial load is complete.
        isInitted: function() {
          return this.initted;
        },

        // Emit an event indicating that the list is loaded.
        loadComplete: function(loadType) {
          if (this.eventName.length > 0) {
            $rootScope.$emit(this.eventName, loadType, this.vals);
          }
        },

        // Get custom filters for this user and account.
        getCustomFilters: function() {
          var filters = [];
          if (this.vals.user.field_data && this.vals.user.field_data.filters) {
            filters = this.vals.user.field_data.filters;
          }
          if (this.vals.entity.field_data && this.vals.entity.field_data.filters) {
            filters = filters.concat(this.vals.entity.field_data.filters);
          }
          return filters;
        },

        // Trigger an 'init' refresh. Just an alias.
        init: function() {
          this.refresh('init');
        },

        // Get the core content of the request.
        getRequestBase: function() {
          return this.getCurrentRequest();
        },

        // Builds the request before loading the entity list.
        buildRequest: function(type, options = {}) {
          // Get the base contents of the request before preparing it to go off.
          var request = this.getRequestBase();
          var hash = false;

          // Get searches on init/loadmore.
          if (type != 'update') {
            let searches;
            // Note: while the loadmore event is technically supported here,
            // it has searches filled in so it can alter limits, so skips this.
            if (commonService.empty(options)) {
              searches = searchService.getSearches();
            }
            else {
              searches = searchService.getSearchByOptions(options);
            }

            request.requests = searches;
          }

          // Only fire on update/loadmore.
          if (type != 'init') {
            var entities = this.getCurrentEntities();

            // Get the identifier for the list of keys.
            let listName = this.getListIdentifier(true);
            request[listName] = Object.keys(entities);

            // Ensure that no hashes go out.
            // cleanList loops over an array, checks if item.nid isNan, and
            // removes the item from the list.
            request[listName] = listLocalService.cleanList(request[listName]);
          }
          // Only clear the list if this is a new 'init' search.
          else if (!this.vals.loading || this.vals.loading != this.getCurrentHash()) {
            this.initLists();
          }

          if (this.vals) {
            // Update last fetch on update.
            if (type == 'update') {
              if (this.requestId != this.responseId) {

                // If we're bailing on this entity update, then the main search
                // request should force trigger an update.
                this.digest = true;
                return false;
              }
              request.last_fetch = this.vals.lastFetch;
            }
            this.vals.loading = this.getCurrentHash();
            hash = this.vals.loading;
          }

          // Send a requestId to only consume the response if it's still relevant.
          this.requestId++;

          request.request_id = this.requestId;

          return request;
        },

        // Returns a plural version of the appropriate key that is used when
        // referencing lists of the items.
        getListIdentifier: function(abbreviated = false) {
          let key = 'id';
          if (abbreviated) {
            key = this.key;
          }
          else {
            key = this.type;
          }

          // TODO: I hate this.... :)
          return key + 's';
        },

        // Callback to process the results of the request.
        processResults: function(result) {
          let list = this.entityName;
          this.processNewValues(list, result[list], result.deleted);
        },

        // Callback to handle the aftermath of the request, including
        // bailing if this is an old request, keeping track of the responses,
        // and preparing the results to be merged with the existing values.
        afterRequest: function(request, result, type) {
          this.responseId++;
          let hash = this.vals.loading;

          // Bail on irrelevant requests.
          // loadmore requests should still save the tasks even if this request
          // is "old."
          if (result.request_id != this.requestId && type != 'loadmore') {
            // Only bail if this request isn't applicable to the current search.
            if (!hash || hash != this.vals.loading) {
              return;
            }
          }

          // Get the listname for the list of keys.
          let listName = this.getListIdentifier(true);

          this.vals.sent_list = (request[listName]) ? request[listName] : [];

          this.vals.loading = false;
          this.vals.lastFetch = result.request_time;

          // The server sends this data back then update it.
          if (!commonService.empty(result.count)) {
            this.vals.count = result.count;
          }
          if (result.hasOwnProperty('facets')) {
            this.vals.facets = result.facets;
          }
          if (!commonService.empty(result.aggregations)) {
            this.vals.aggregations = result.aggregations;
          }

          // Set a few things to response values on init requests.
          if (type == 'init') {
            this.initted = true;
            // Keep track of when the last time an init search was performed.
            this.vals.lastInit = result.request_time

            this.vals.subcount = false;
            if (result.count_returned && result.count_returned < result.count) {
              this.vals.subcount = result.count_returned;
            }
            // New fetch? Build this list again in processNewValues().
            this.vals.entityList = [];
          }

          // List of all tasks is stored globally.
          this.processResults(result);

          // On non-init requests with current values, update subcount.
          if (type != 'init' && this.vals.entityList.length) {
            this.vals.subcount = this.vals.entityList.length;
          }

          this.loadComplete(type);

          // If this has been flagged for digest, then do it!
          if (this.digest) {
            $rootScope.$emit('appDigest');
            this.digest = false;
          }
        },

        // Actuallly perform the request to D8 to fetch new results.
        performRequest: function(request, type) {
          let self = this;
          // This is the POST body, sent to Drupal as JSON.
          searchService.taskSearch(request, type).then(function(result) {
            self.afterRequest(request, result, type);
          });
        },

        refresh: function(type = 'update', options = {}) {
          let request = this.buildRequest(type, options);
          if (request) {
            this.performRequest(request, type);
          }
        },

        /**
         * Returns the values portion of this service.
         */
        getEntityList: function() {
          return this.vals;
        },

        /**
         * Get entities based on provided parameters.
         */
        getEntities: function(requests = {}, forceNew = false) {
          let self = this,
              refresh = false;
          return new Promise(function(resolve, reject) {
            // Clear the list if new results are being forced.
            if (forceNew || !self.isInitted()) {
              // Clear out the local entityList array.
              let list = self.getListIdentifier();
              listLocalService.clearList(list, 'created');

              self.refresh('init', requests);
              refresh = true;
            }
            // If the list empty, trigger an update request.
            else if (commonService.empty(self.vals.entities)) {
              let hash = self.getCurrentHash();
              // Kick off a refresh if there's not a request out for the same thing.
              if (hash != self.vals.loading) {
                self.refresh('update', requests);
              }
              refresh = true;
            }

            // When sending a request, listen for the response.
            if (refresh) {
              let eventListener = $rootScope.$on(self.eventName, function() {
                eventListener();
                resolve(self.vals.entities);
              });
            }
            else {
              resolve(self.vals.entities);
            }
          });
        },

        // Returns true if there are currently entities loaded.
        hasCurrentEntities: function() {
          let entities = this.getCurrentEntities();
          return entities.length > 0;
        },

        // Retrieves all the current entities. Respects changes in the local
        // changelist.
        getCurrentEntities: function() {
          if (this.vals && this.vals.hasOwnProperty('lastFetch')) {
            // Get the list of current entities, respecting changes in the
            // local changelist.
            let list = this.getListIdentifier();
            this.digestChanges(list);
            return this.vals.entities;
          }
          else {
            return [];
          }
        },

        // Get the current list of entity IDs.
        getCurrentEntityList: function(allowOld = false) {
          // If there are entities, return an array of the ids.
          if (this.vals && this.vals.hasOwnProperty('entities') && (allowOld || this.vals.hasOwnProperty('lastFetch'))) {

            // Get's the list of full entities, before converting them to keys.
            // This is done to allow respecting the values in the changelist.
            let entities = this.getCurrentEntities();
            return Object.keys(entities);
          }
          else {
            return [];
          }
        },

        // Returns all of the facet values for the current set.
        getFacets: function() {
          let facets = false;

          // Check to ensure there are actually facets to return.
          if (this.vals && this.vals.hasOwnProperty('facets')) {
            facets = this.vals.facets;
          }

          return facets;
        },

        // Increment the total task counter.
        incrementCount: function(amount = 1) {
          if (this.hasOwnProperty('vals') && this.vals.hasOwnProperty('count')) {
            this.vals.count += amount;
          }
        },

        // Performs a lookup of all the active filters, and prepares them to be
        // sent off to Drupal.
        getCurrentRequest: function() {

          var request = {
            'filters': {}
          };

          var sorting = filterService.getSearchSorting();
          if (sorting) {
            request.sorting = sorting;
          }
          var filtering = filterService.getFiltering();
          if (filtering.selection) {

            var currentFilters = filtering.selection;
            var excludeVals = ['taskId', 'projectId', 'editProject', 'selectedRange'];
            var dateFilters = ['start', 'end'];

            angular.forEach(currentFilters, function(filterVal, filterName) {

              if (excludeVals.indexOf(filterName) == -1) {

                if (filterName == 'project') {
                  if (!commonService.empty(filterVal[0])) {
                    request.filters.project = filterVal;
                  }
                }
                // Dates require a bit of extra processing.
                else if (dateFilters.indexOf(filterName) != -1) {
                  // Dates are stored as strings, are only objects when generated.
                  if (typeof filterVal != 'object') {
                    filterVal = new Date(filterVal);
                  }
                  // Convert to timestamp.
                  if (filterVal) {
                    request.filters[filterName] = Math.round(filterVal.getTime() / 1000);
                  }
                }
                else {
                  // Only include non-empty values in request.
                  if (filterName == 'task_custom_value') {
                    if (typeof filterVal == 'object') {
                      angular.forEach(filterVal, function(fieldVal, fieldId) {
                        // Skip sending empty values.
                        if (commonService.empty(fieldVal)) {
                          return;
                        }
                        // Account for ranges as filled/empty.
                        else if (typeof fieldVal == 'object') {
                          if (fieldVal.hasOwnProperty('min') && fieldVal.hasOwnProperty('max')) {
                            if (!fieldVal.min && !fieldVal.max) {
                              return;
                            }
                          }
                        }

                        // Ensure this is always set.
                        request.filters[filterName] = request.filters[filterName] || {};
                        request.filters[filterName][fieldId] = fieldVal;
                      });
                    }
                  }
                  // Skip sending empty values.
                  else if (!commonService.empty(filterVal)) {
                    request.filters[filterName] = filterVal;
                  }
                }
              }
            });
          }

          let aggregations = searchService.getAggregations()
          if (aggregations) {
            request.aggregations = aggregations;
          }

          return request;
        },

        /**
         * Removes an entity from this.vals.entityList.
         */
        removeEntity: function(id, immediately = true) {
          if (this.vals.entities && this.vals.entities.hasOwnProperty(id)) {
            if (immediately) {
              // Broadcast that the task should be hidden.
              $rootScope.$emit('hideTask', id, this.vals.entities[id]);

              // Remove from the list (can no longer be fetched locally).
              delete this.vals.entities[id];

              // When removing an entity, decrment both counts.
              this.vals.subcount--;
              this.vals.count--;

              return true;
            }
            // If the entity isn't to be hidden immediately, then queue it.
            else {
              // Grab the entity and flag it as about to be hidden.
              var entity = this.getEntityById(id);
              entity.hiding = true;

              // Stop if the entity is already in the hiding queue.
              if (!this.hiding.hasOwnProperty(id)) {
                // Create a timeout and save the promise, so that the hide
                // op can be cancelled.
                let self = this;
                this.hiding[id] = $timeout(function() {
                  if (entity) {
                    entity.hiding = false;
                  }

                  // Remove this entity from the hiding queue. If this entity
                  // is returned by a search after removal, it shouldn't be
                  // displayed as hiding.
                  delete self.hiding[id];

                  // Re-call this function without the delay parameter set to
                  // actually remove the entity.
                  self.removeEntity(id);
                }, 3000);
              }
            }
          }
          else {
            return false;
          }
        },

        /**
         * Cancels the hide callback and removes the hiding flag from the entity.
         */
        cancelHide: function(id) {
          if (this.hiding.hasOwnProperty(id)) {

            // Cancel the timeout to remove the entity.
            $timeout.cancel(this.hiding[id]);

            // Remove this entity ID fro mthe hiding queue.
            delete this.hiding[id];
          }

          // Remove the hiding flag for display purposes.
          var entity = this.getEntityById(id);
          if (entity) {
            entity.hiding = false;
          }
        },

        /**
         * Clear all items from the hiding list.
         */
        clearHidingList: function() {
          if (Object.keys(this.hiding).length > 0) {
            let self = this;
            angular.forEach(this.hiding, function(value) {
              self.cancelHide(value);
            });
          }
        },

        /**
         * Returns an entity Object.
         */
        getEntityById: function(id, key = 'id') {
          if (this.key) {
            key = this.key;
          }
          // Force entities to numbers if the expected id can't be found.
          if (commonService.empty(this.vals.entities[id])) {
            id = Number(id);
          }

          if (typeof this.vals.entities[id] != 'undefined') {
            return this.vals.entities[id];
          }
        },

        // Returns an entity Object, ensure it's ready.
        // @TODO: Need error handling in case the task can't be loaded.
        fetchEntityById: function(id, key) {
          let self = this;
          return new Promise(function (resolve, reject) {
            let entity = self.getEntityById(id, key);
            if (!commonService.empty(entity)) {
              resolve(entity);
            }
            else {
              let eventListener = $rootScope.$on(self.eventName, function() {
                entity = self.getEntityById(id, key);
                if (!commonService.empty(entity)) {
                  eventListener();
                  resolve(entity);
                }
              });
            }
          });
        },

        /**
         * Returns an Array filed with entity objects.
         */
        getEntitiesByIds: function(ids, key = 'id') {
          if (this.key) {
            key = this.key;
          }

          var list = [];

          for (var index in ids) {
            var id = ids[index];
            var obj = this.getEntityById(id, key);

            if (typeof obj == 'object') {
              list.push(obj);
            }
          }

          return list;
        },

        // Allows the new values to be reflected in the current object.
        // Allows for a subset to be merged in, taking precendence, without
        // deleting values which were not sent.
        processNewValues: function(objectList, valueList, deleted = []) {
          let self = this;

          var valueIds = Object.keys(valueList);

          if (commonService.empty(this.vals.sent_list)) {
            this.vals.sent_list = [];
          }

          // Create a unique list of all ids.
          this.vals.entityList = this.vals.sent_list.concat(valueIds.filter(function (item) {
            return self.vals.sent_list.indexOf(item) < 0;
          }));

          if (!this.vals.entities || Object.keys(this.vals.entities).length < 1) {
            this.vals.entities = {};
          }

          // Process any items that should be deleted from the list.
          if (!commonService.empty(deleted)) {
            angular.forEach(this.vals.entities, function(item, id) {
              if (deleted.indexOf(id) != -1 || deleted.indexOf(Number(id)) != -1) {
                delete self.vals.entities[id];
              }
            });
          }

          // Process the list of values themselves.
          angular.forEach(valueList, function(value, id) {
            let current = self.vals.entities[id];
            if (current) {
              let current_changed = new Date(current.changed);
              let new_changed = new Date(value.changed);
              if (current_changed.getMilliseconds() > 0 && new_changed.getMilliseconds() == 0) {
                new_changed.setMilliseconds(999);
              }

              // Is the "new" version older than the local version? Bail.
              if (current_changed >= new_changed) {
                return;
              }
            }

            // Verify that the object list is the list that is being used by
            // this entity.
            let list = self.getListIdentifier();
            if (objectList == list) {
              value = self.updateEntityVals(id, value, false, false);
            }
            if (value) {
              self.vals.entities[id] = value;
            }
          });

          // As we're processing new values, diff it with the stored list of
          // recent changes. This will ensure that recent deletions and additions
          // that haven't been indexed yet will display properly.
          this.digestChanges(objectList);
        },
        digestChanges: function(objectList = false) {
          if (!objectList) {
            objectList = this.getListIdentifier();
          }
          listLocalService.getChangeList(objectList, this.vals.entities, false, this.key);
        },

        /**
         * This method calls `processNewValues` which does the the work of locally updating entity values.
         *
         * @param {int} id | Entity id, sometimes know as nid, sometimes known as entityId
         * @param {object} vals | Object which holds the updated values, (as well as nid)
         * @param {bool} set | When true, 'processNewValues` is called, which does the real work of updating local entity values.
         * @param {bool} update | When true, update the local changed string.
         */
        updateEntityVals: function(id, vals, set = true, update = true) {
          let entity = false;
          // If the entity doesn't have an id yet, then don't try to update it.
          if (id !== 0) {
            entity = this.getEntityById(id, this.key);

            if (typeof entity == 'undefined') {
              entity = {};
            }

            // If "updated" vals are older than current, bail.
            // This series of if statements could be consolidated but is
            // simpler to read when left with the progression.
            if (!commonService.empty(entity)) {
              // If entity object isn't empty, check there are changed dates.
              if (vals.changed && entity.changed) {
                // If both have dates, convert them to JS/Moment milli-epochs.
                vals.changed = (typeof vals.changed == 'number') ? vals.changed * 1000 : vals.changed;
                entity.changed = (typeof entity.changed == 'number') ? entity.changed * 1000 : entity.changed;

                // Compare the dates and see if the value update is too old to
                // apply (thus ignore it).
                if (moment(vals.changed).isBefore(entity.changed)) {
                  return;
                }
              }
            }
            // Else continue.

            let hidden = false;
            let self = this;
            let hideFiltered = featureService.featureStatus('automatically_hide_filtered_tasks');

            angular.forEach(vals, function(value, key) {
              // Actually update the task values, by either callback or directly.
              if (self.updateMapping && self.updateMapping.hasOwnProperty(key)) {
                self.updateMapping[key](entity, key, value);
              }
              else {
                entity[key] = value;
              }

              if (hideFiltered) {
                // As the task value is being updated, check to see if it
                // no longer passes the main filter critera.
                if (typeof value != 'undefined') {
                  if (self.valueFiltered(key, value, vals)) {
                    // If the task doesn't pass filtering queue it to be removed.
                    hidden = true;
                    self.removeEntity(id, false);
                  }
                }
              }
            });

            // @TODO: Finalize
            if (hideFiltered) {
              // If the task shouldn't be hidden then make sure it isn't in the
              // queue. This is necessary in case a user changes a value and then
              // changes it back.
              if (!hidden) {
                self.cancelHide(id);
              }
            }

            // Update the changed date if available.
            if (update && entity.changed) {
              let date;
              // If this is an integer on an update call, this is an entity
              // save that is faking. Don't set a faked date after the save,
              // set a date 500ms before save so that the next load from search
              // emits a new update event.
              if (Number.isInteger(entity.changed)) {
                date = new Date(entity.changed * 1000 - 500);
              }
              else {
                date = new Date();
              }

              // Convert the changed date to a string and remove the zero offset
              // that is appended by toISOString().
              entity.changed = date.toISOString().slice(0, -1);
              this.vals.lastChanged = date.getTime() / 1000;
            }

            if (set) {
              let list = {};
              list[id] = entity;
              this.processNewValues(this.getListIdentifier(), list);
            }
            return entity;
          }
        },

        /**
         * Determine whether the value is exluded from the current filter
         */
        valueFiltered: function(key, value, fullVals) {
          // Grab the default values. Default to not hiding the entity.
          var hide = false;
          var filtering = filterService.getFiltering();

          // Archivals get special handling.
          if (key == 'field_entity_state' && value == 'archived') {
            // Alter the key to match the filter.
            key = 'archived';

            // The only way this entity isn't about to be hidden is if the
            // "Show Archived" filter is set. In which case default to the
            // boolean filter handling below.
            hide = true;
          }
          // So do titles.
          else if (key != 'title') {
            // If this isn't an object, then convert it into an array.
            if (!(typeof value == 'object')) {
              value = [value];
            }
            // Projects are sometimes passed as a project object that need to
            // be converted to an array of ids.
            else if (key == 'project' && !(value instanceof Array)) {
              value = [value.id];
            }
          }

          // Make sure filtering is actually set for this key.
          if (filtering.selection) {
            if (filtering.selection.hasOwnProperty(key)
              && filtering.selection[key]) {

              // If the filter is empty, don't proceed.
              if (filtering.selection[key].length > 0) {

                // Since the filter for this field has a value, assume that the
                // entity should be hidden unless proven otherwise.
                hide = true;
                if (value.length > 0) {

                  // Special handling for titles.
                  if (key == 'title') {
                    hide = (value.search(new RegExp(filtering.selection[key], 'i')) == -1);
                    if (hide && fullVals.hasOwnProperty('body')) {
                      if (fullVals.body) {
                        hide = (fullVals.body.search(new RegExp(filtering.selection[key], 'i')) == -1);
                      }
                    }
                  }
                  else {
                    // Check each value of the entity field against the
                    // corresponding filter.
                    angular.forEach(value, function(item, index) {

                      // If the value is found, then don't hide the entity.
                      // Once it's been determined that the entity shouldn't be
                      // hidden, don't keep checking.
                      if (hide) {

                        // Resolve type differences between filters and values.
                        if (typeof item == 'number') {
                          item = item.toString();
                        }

                        // If the filter value exists then don't hide the task.
                        if (filtering.selection[key].indexOf(item) !== -1) {
                          hide = false;
                        }
                      }
                    });
                  }
                }
                // Special case for task owner.
                else if (key == 'field_task_owner') {
                  // The filter value for unassigned is uid = '0'.
                  // Field values for task field_task_owner = [] shouldn't hide.
                  if (filtering.selection[key].indexOf('0') != -1) {
                    hide = false;
                  }
                }
              }
              // If the filter is a boolean then just do a straight of comparision.
              else if (typeof filtering.selection[key] == 'boolean') {
                hide = !(filtering.selection[key]);
              }
            }
          }

          // Return whether the entity should be hidden or not.
          return hide;
        },

        /**
         * Hashes the filter data we send to drupal.
         * Returns a unique number
         */
        getCurrentHash: function() {
          /**
           * retuns a unique 11 digit number.
           * We generate this number by turning the 'currentRequest' into a string,
           * and putting that string through a hash function.
           *
           * Note, this has NOTHING to do with a request object.
           */
          var request = this.getCurrentRequest();
          var hash = commonService.sdbmHash(JSON.stringify(request));
          return hash;
        }

      };

      return (EntityService);
    }
  ]);
})();
