(function () {
  'use strict';

  var app = angular.module('labelpanel', ['angular-drupal', 'user', 'angular.filter', 'colorbox', 'misc']);

  app.directive('labelpanelTrigger', function () {
    return {
      scope: {
        label: '=',
        chip: '=',
        onUpdate: "=?",
        bundle: '=?',
        parentScope: '=?'
      },
      restrict: 'A',
      replace: true,
      template: function (tElement, tAttrs) {
        var id = 'label-panel';
        if (tAttrs.chip) {
          id += '-edit';
        }
        return '<div id="' + id + '" class="panel-new-label" ng-click="addLabel($event)" ng-transclude></div>';
      },
      transclude: true,
      controller: [
        '$scope',
        '$mdPanel',
        function ($scope, $mdPanel) {
          if (typeof $scope.bundle === 'undefined') {
            $scope.bundle = 'label';
          }

          $scope.addLabel = function (ev) {
            var position = $mdPanel.newPanelPosition()
              .absolute()
              .top(ev.clientY + 20 + "px")
              .left(ev.clientX - 110 + "px");

            var config = {
              attachTo: angular.element(document.body),
              controller: 'LabelPanel',
              templateUrl: 'app/src/components/labelpanel/labelpanel.html',
              panelClass: 'label-add-wrapper',
              position: position,
              openFrom: ev,
              clickOutsideToClose: true,
              focusOnOpen: true,
              locals: {
                label: $scope.label,
                chip: $scope.chip,
                bundle: $scope.bundle,
                onUpdate: $scope.onUpdate,
                parentScope: $scope.parentScope
              },
              zIndex: 82,
              width: 200
            };

            $mdPanel.open(config);
          };

        }
      ]
    };
  });

  app.controller('LabelPanel', [
    '$scope',
    'mdPanelRef',
    'RestResource',
    'accountService',
    'refreshService',
    '$timeout',
    'label',
    'chip',
    'bundle',
    'onUpdate',
    'parentScope',
    'commonService',
    function ($scope, mdPanelRef, RestResource, accountService, refreshService, $timeout, label, chip, bundle, onUpdate, parentScope, commonService) {
      $scope.select = {};

      if (chip) {
        $scope.select.color = chip.field_label_color;
        $scope.name = commonService.decodeHtml(chip.title);
        $scope.id = chip.id;
      }
      else {
        $scope.select.color = false;
      }

      $scope.saveLabel = function () {
        var request = {
          title: $scope.name,
          field_label_color: $scope.select.color
        };

        // Update an existing label.
        if ($scope.id) {
          $timeout(function () {
            // Add new item to chip list immediately.
            chip.field_label_color = $scope.select.color;
            chip.title = $scope.name;
            // Force an update.
            $scope.$parent.$apply();
          });

          request.id = $scope.id;
          RestResource.eckEntityUpdate('tag', bundle, request.id, request).then(function (res) {
            // Blank out the form.
            $scope.name = '';
            // Close the panel.
            mdPanelRef.close();
            // Refresh everything.
            refreshService.appRefresh();
          });
        }
        // Instantiate a new label with the info provided.
        else {
          accountService.getAccount().then(function(account) {
            var accountId = account.entity.id;
            request.__attach = {
              "entity_id": accountId
            };
            RestResource.eckEntityCreate('tag', bundle, request).then(function (response) {
              // Blank out the form.
              $scope.name = '';
              // Close the panel.
              mdPanelRef.close();

              // Add new item to chip list immediately.
              var newLabel = request;
              newLabel.id = response.id;
              newLabel._lowercase = newLabel.title.toLowerCase();

              var chip = {};
              angular.forEach(newLabel, function (val, index) {
                chip[index] = val;
              });

              // Add the value to the field model.
              label.push(chip);

              // If there's an an options list and update function call it.
              if (typeof parentScope != 'undefined'
                 && typeof parentScope.options != 'undefined'
                 && typeof parentScope.updateOptions == 'function') {
                // Add it to the top for clarity.
                parentScope.options.unshift(chip);
                // Actually display the update.
                parentScope.updateOptions();
              }

              account.tags.push(chip);
              refreshService.appRefresh();

              // If there's an update function provided, call it.
              // This tells the field to actually save the value.
              if (typeof onUpdate == 'function') {
                onUpdate();
              }
            });
          });
        }
      };
    }
  ]);
})();
