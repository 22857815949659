(function () {
  'use strict';

  let repeatable = angular.module('repeatable-service', []);

  repeatable.service('repeatableService', [
    'accountService',
    'RestResource',
    'taskService',
    function (accountService, RestResource, taskService) {
      // For now, a default trigger of setting status completed.
      this.DEFAULT_TRIGGER_ID = '2';

      this.getRepeatText = function(task, repeatable = false) {
        let day, repeatableString, period_text;
        // Pull from task if not set.
        repeatable = (repeatable !== false) ? repeatable : task.repeatable;

        if (repeatable) {
          let quantity = repeatable.field_quantity;
          let period = repeatable.field_period;
          let offset = repeatable.field_offset;

          // Get day info.
          if (period == 'week') {
            day = moment().day(offset).format('dddd');
          }
          else if (period == 'month') {
            day = moment().date(offset).format('Do');
            day = ' the ' + day;
          }

          if (quantity == '1') {
            if (period == 'day') {
              period_text = 'daily';
            }
            else {
              period_text = period + 'ly';
            }
          }
          else {
            period_text = quantity + ' ' + period + 's';
          }

          repeatableString = period_text;

          if (day) {
            repeatableString += ' on ' + day;
          }
        }
        else {
          repeatableString = 'Make Repeatable';
        }
        return repeatableString;
      };

      // Accepts scope values and either creates or updates a repeatable.
      this.upsertRepeatable = function(values, repeatable) {
        let request = {
          field_quantity: values.quantity,
          field_period: values.period,
          field_offset: values.offset
        };

        // Update an existing repeatable.
        if (repeatable.id) {
          request.id = repeatable.id;
          // Returns a Promise that can be .then()ed.
          return RestResource.eckEntityUpdate('repeatable', 'repeatable', request.id, request);
        }
        else {
          // Lock the trigger in at the completed status one.
          repeatable.field_trigger = this.DEFAULT_TRIGGER_ID;
          // Returns a Promise that can be .then()ed.
          return RestResource.eckEntityCreate('repeatable', 'repeatable', request);
        }
      };

      // Remove a repeatable from a task.
      this.removeRepeatable = function(repeatableId, nid) {
        // Update the task reference.
        taskService.fieldUpdate('field_repeatable', nid, null);
        // Delete the repeatable object itself.
        RestResource.eckEntityDelete('repeatable', 'repeatable', repeatableId);
      };

      // Set the repeatable object for a task
      this.setRepeatable = function(repeatableId, nid) {
        taskService.fieldUpdate('field_repeatable', nid, repeatableId);
      };

      this.getPeriodList = function() {
        return [
          {'value': 'day', 'name': 'Day'},
          {'value': 'week', 'name': 'Week'},
          {'value': 'month', 'name': 'Month'},
          {'value': 'quarter', 'name': 'Quarter'},
          {'value': 'year', 'name': 'Year'},
        ]
      };

      this.getDayList = function() {
        return [
          {'value': '0', 'name': 'Sunday'},
          {'value': '1', 'name': 'Monday'},
          {'value': '2', 'name': 'Tuesday'},
          {'value': '3', 'name': 'Wednesday'},
          {'value': '4', 'name': 'Thursday'},
          {'value': '5', 'name': 'Friday'},
          {'value': '6', 'name': 'Saturday'},
        ]
      };


      // Generate a list of days of the month.
      this.getDayNumList = function() {
        let dayNumList = [];
        for (let i = 1; i <= 31; i++) {
          dayNumList.push({'value': i, 'name': i});
        }
        return dayNumList;
      };

    }
  ]);
})();