(function () {
  'use strict';

  var app = angular.module('labelmanage', ['angular-drupal', 'user', 'angular.filter', 'labelpanel', 'colorbox', 'xeditable']);

  app.directive('labelManage', function() {
    return {
      scope: {},
      controller: [
        '$scope',
        '$rootScope',
        'RestResource',
        'accountService',
        '$mdDialog',
        'commonService',
        function ($scope, $rootScope, RestResource, accountService, $mdDialog, commonService) {

          accountService.getAccount().then(function(account) {
            $scope.account = account;
            $scope.labels = $scope.account.tags;
          });

          $scope.cleanTag = function(tag, xEFtitle) {
            tag.title = commonService.decodeHtml(tag.title);
            xEFtitle.$show();
          };

          // Perform the request.
          $scope.tagUpdate = function(tag) {
            var request = {
              id: tag.id,
              title: tag.title,
              field_label_color: tag.field_label_color
            };
            RestResource.eckEntityUpdate('tag', 'label', request.id, request).then(function (res) {
            });
          };

          // Delete the tag.
          $scope.deleteTag = function(tag) {
            var confirm = $mdDialog.confirm()
              .multiple(true)
              .title('Are you sure you want to delete this tag?')
              .textContent("This will also delete from all tasks that reference it.")
              .ariaLabel('Delete tag')
              .ok('Delete Tag')
              .cancel('Cancel');

            // @TODO: This needs to be checked with multiple tabs interacting
            // with the same localstorage.
            $mdDialog.show(confirm).then(function () {
              // Update the tag itself, so that child components can filter
              // it out.
              tag.field_entity_state = 'deleted';

              // Emit an event so that child elements can respond.
              $rootScope.$emit('labelUpdated');

              // Remove the label immediately from the account.
              let deleteIndex = $scope.account.tags.findIndex(function (item) {
                return item.id == tag.id;
              });

              // Remove the tag from the account.
              if (deleteIndex != -1) {
                $scope.account.tags.splice(deleteIndex, 1);
              }

              // The delete that tag.
              RestResource.eckEntityDelete('tag', 'label', tag.id);
            });
          };
        }
      ],
      templateUrl: 'app/src/components/labelmanage/labelmanage.html'};
    });
})();
