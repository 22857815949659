(function () {
  'use strict';

  var app = angular.module('project', [
    'ngRoute',
    'angular-drupal',
    'misc',
    'rest',
    'task',
    'user',
    'angular.filter',
    'checklist-model',
    'colorbox'
  ]);

  app.config(['$mdDialogProvider', function ($mdDialogProvider) {
    $mdDialogProvider.addPreset('newProjectModal', {
      options: function () {
        return {
          controller: 'ProjectDialogController',
          templateUrl: 'app/src/project/project-new.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          escapeToClose: true
        };
      }
    });
    $mdDialogProvider.addPreset('editProjectModal', {
      options: function () {
        return {
          controller: 'ProjectDialogController',
          templateUrl: 'app/src/project/project-edit.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          escapeToClose: true
        };
      }
    });
  }]);

  app.directive('projectTrigger', function () {
    return {
      transclude: true,
      scope: {
        pid: '=',
        projectType: '=?',
        projectList: '=?',
        state: '=?',
        folder: '=?',
      },
      controller: [
        '$scope',
        '$rootScope',
        function ($scope, $rootScope) {
          $scope.projectModal = function (ev) {
            $rootScope.openProjectModal($scope.pid, $scope.projectType, $scope.projectList, $scope.state, $scope.folder);
          };
        }
      ],
      templateUrl: 'app/src/project/project-trigger.html'
    };
  });

  app.controller('ProjectDialogController', [
    '$scope',
    '$rootScope',
    '$mdDialog',
    'drupal',
    'pid',
    'projectType',
    'projectList',
    'state',
    'folderId',
    'RestResource',
    'accountService',
    'refreshService',
    'commonService',
    '$route',
    'listLocalService',
    'featureService',
    '$filter',
    'userDataService',
    function ($scope, $rootScope, $mdDialog, drupal, pid, projectType, projectList, state, folderId, RestResource, accountService, refreshService, commonService, $route, listLocalService, featureService, $filter, userDataService) {
      let project;
      $scope.pid = pid;
      $scope.projectType = projectType;
      $scope.projectList = projectList;
      $scope.dialogReady = false;
      $scope.activeTemplate = false;
      $scope.state = state;
      $scope.folderId = folderId;
      $scope.titleInvalid = false;
      $scope.userGroupEndpoint = AppConfig.externalEndpointUrl + '/user/me/group';
      $scope.uid = null;
      $scope.settings = {
        name: ''
      };
      $scope.selectedRange = {
        showTemplate: true,
        fullscreen: false
      };
      $scope.ariaLabel = 'Loading';
      $scope.select = {
        color: false
      };
      $scope.projectTemplateForm = {};
      $scope.project = {
        members: []
      };
      $scope.members = [];
      $scope.root = $rootScope.root;

      $scope.init = function () {
        accountService.getAccount().then(function(account) {
          $scope.uid = String(drupal.currentUser().id());
          $scope.account = account;
          $scope.isStaff = false;
          if (typeof $scope.account.user != 'undefined') {
            $scope.isStaff = ($scope.account.user.roles.indexOf('staff') !== -1);
          }
          $scope.members = $scope.account.members;

          // Add the first option as the "Unassigned" user.
          let uncategorized = accountService.getUnassignedFolder();
          $scope.folders = [uncategorized].concat($scope.account.folders);


          let templateDefaults = commonService.getDateRangeDefaults();
          $scope.selectedRange.customTemplates = templateDefaults.customTemplates;
          $scope.disabledTemplates = templateDefaults.disabledTemplates;

          $scope.settings.field_date_auto_adjust = true;

          if (pid == 'new') {
            $scope.disabled = [$scope.uid];
            userDataService.setDashboardSetting('filterFolders-' + $scope.folderId, false);
          }
          else {
            $scope.baseProject = accountService.getObjectById('projects', pid);
            project = $scope.baseProject;
          }

          RestResource.loadTemplateList('project').then(function (result) {
            $scope.templates = result;
            if (typeof $route.current.params.launchProject != 'undefined') {
              var remoteId = $route.current.params.launchProject;

              if (result) {
                for (var i = 0; i < result.length; i++) {
                  if (typeof result[i].field_remote_id != 'undefined') {
                    if (result[i].field_remote_id == remoteId) {
                      $scope.selectTemplate(result[i]);
                      break;
                    }
                  }
                }
              }
            }
            $scope.$apply();
          });

          // Check whether project owners are enabled.
          $scope.projectOwnerFeature = featureService.featureStatus('project_modal_add_project_owner_field');

          // Set the folder based on what was provided in the project trigger.
          let folder;
          if ($scope.folderId) {
            folder = accountService.getProjectFolderById($scope.folderId);
          }
          else if (project && project.field_project_folder) {
            folder = accountService.getProjectFolderById(project.field_project_folder);
          }
          else {
            folder = accountService.getProjectFolderById('no_folder');
          }
          // Now a folder object.
          $scope.settings.field_project_folder = folder;

          if (pid != 'new') {
            $scope.ariaLabel = 'Edit Project';
            var request = {
              type: 'project_settings',
              entity_id: pid
            };

            if (project) {
              // If the Project Owner feature is enabled, set the owner.
              if ($scope.projectOwnerFeature) {
                let owner = accountService.getAccountMemberById(project.field_owner);
                $scope.settings.field_owner = owner
              }

              if (project.hasOwnProperty('field_date_auto_adjust')) {
                let autoAdust = project.field_date_auto_adjust;
                if (autoAdust != '1' && autoAdust !== null) {
                  $scope.settings.field_date_auto_adjust = false;
                }
              }
              // Set the start and end dates against selectedRange.
              if (project.start || project.end) {
                $scope.selectedRange.dateStart = project.start ? moment(project.start).startOf('day').toDate() : false;
                $scope.selectedRange.dateEnd = project.end ? moment(project.end).endOf('day').toDate() : false;
              }
            }

            // Get the project and list of members.
            RestResource.loadAccountInfoData(request).then(function (res) {
              $scope.project.members = res.members;
              if (res.project.field_project_color[0]) {
                $scope.select.color = res.project.field_project_color[0].value;
              }
              $scope.settings.name = res.project.label[0].value;
              $scope.dialogReady = true;

              // If the Project Owner feature is enabled, create the options list.
              if ($scope.projectOwnerFeature) {
                $scope.updateMemberOptions();
              }

              $scope.$apply();
            });
          }
          else {
            $scope.ariaLabel = 'Create New Project';
            $scope.dialogReady = true;
            // Default the current user.
            $scope.project.members = [$scope.uid];

            // If the Project Owner feature is enabled, create the options list.
            if ($scope.projectOwnerFeature) {
              $scope.updateMemberOptions();
            }
          }

          // Set the default value to unassigned if it's not set.
          if (!$scope.settings.field_project_folder) {
            $scope.settings.field_project_folder = uncategorized;
          }

        });
      };

      $scope.isIndeterminate = function() {
        return ($scope.project.members.length !== 0 &&
            $scope.project.members.length !== $scope.members.length);
      };

      $scope.isChecked = function() {
        return $scope.project.members.length === $scope.members.length;
      };

      $scope.toggleAll = function() {
        if ($scope.project.members.length === $scope.members.length) {
          // Unselect all.
          $scope.project.members = (pid == 'new') ? [$scope.uid] : [];
        }
        else if ($scope.project.members.length === 0 || $scope.project.members.length > 0) {
          // Select all (by uid).
          $scope.project.members = $scope.members.map(function(member) {
            return member.uid;
          });
        }
      };

      // Function to update list of possible project owners.
      $scope.updateMemberOptions = function() {
        let ownerOptions = [];
        $scope.memberOptions = [];

        // Only current list of members can be added as a member.
        $scope.members.forEach(function(item) {
          if ($scope.project.members.indexOf(item.uid) >= 0) {
            ownerOptions.push(item);
          }
        });

        // Add the first option as the "Unassigned" user.
        let unassigned = accountService.getUnassignedUser();
        ownerOptions = [unassigned].concat(ownerOptions);

        // Assign the updated list.
        $scope.memberOptions = ownerOptions;

        // Set the default value to unassigned if it's not set.
        if (!$scope.settings.field_owner) {
          $scope.settings.field_owner = unassigned;
        }
      };

      $scope.dateClear = function () {
        $scope.selectedRange = {
          showTemplate: false,
          fullscreen: false
        };
      };

      $scope.inArray = function (item, array) {
        return (-1 !== array.indexOf(item));
      };
      $scope.cancel = function () {
        $mdDialog.cancel();
      };

      $scope.selectTemplate = function (template = false) {
        $scope.activeTemplate = template;
        if (template) {
          $scope.settings.name = template.title;
        }
        else {
          $scope.settings.name = '';
        }
        $scope.project.listShow = false;
      };

      $scope.saveProject = function () {
        if ($scope.settings.name.length === 0) {
          $scope.titleInvalid = true;
          return;
        }
        else {
          $scope.titleInvalid = false;
        }

        $scope.state.submitting = true;

        var project = {
          label: $scope.settings.name,
          field_project_color: $scope.select.color,
          field_date_auto_adjust: $scope.settings.field_date_auto_adjust,
          field_owner: $scope.settings.field_owner.uid,
          field_project_folder: $scope.settings.field_project_folder.id
        };

        var request = Object.assign({
          __attach: {}
        }, project);

        // Only account projects can have members.
        if ($scope.projectType != 'personal') {
          request.__attach.members = $scope.project.members;
          project.members = $scope.project.members;

          // Add the owner field if the owner feature is enabled.
          if ($scope.projectOwnerFeature) {
            project.field_owner = ($scope.settings.field_owner.uid) ? $scope.settings.field_owner.uid : [];
          }
        }

        if (!$scope.settings.field_date_auto_adjust) {
          project.start = $scope.selectedRange.dateStart;
          project.end = $scope.selectedRange.dateEnd;
          var format = 'YYYY-MM-DD\THH:mm:ss';

          request.field_date = {
            value: moment($scope.selectedRange.dateStart).startOf('day').format(format),
            end_value: moment($scope.selectedRange.dateEnd).endOf('day').format(format)
          };
        }

        project.title = project.label;

        var afterResponse = function (res) {
          // Blank out the form.
          $scope.settings.name = '';
          // Close the panel.
          $scope.state.submitting = false;

          refreshService.appRefresh();

          $rootScope.$emit('projectUpdate', $scope.pid);
        };

        var processProject = function (result, request, hash, cloning = false) {
          // Process the created project to make sure that it's in a format that
          // is consistent with projects returned via other methods.
          request.id = result.id[0].value.toString();
          request.type = $scope.projectType;
          request.title = request.label;
          $scope.pid = request.id;

          if (hash) {
            request.hash = hash;
          }

          request.cloning = cloning;

          // Make sure the project has a lowercase title for filtering handling.
          accountService.addLowercase([request], 'title');

          // Add this project to the project changes list so that duplicates
          // aren't shown.
          listLocalService.addChanges('projects', 'created', request);
          // $rootScope.addProjectChanges('created', request);
        };

        // If this is an update.
        if (pid != 'new') {
          if ($scope.projectList && $scope.projectList.projects) {
            for (let i = 0; i < $scope.projectList.projects.length; i++) {
              if ($scope.projectList.projects[i].id == pid) {
                angular.forEach(project, function (value, key) {
                  $scope.projectList.projects[i][key] = value;
                });
              }
            }
          }

          project.id = pid;
          accountService.getAccount().then(function(account) {

            let projects = account.projects;

            for (let i = 0; i < projects.length; i++) {
              if (projects[i].id == project.id) {
                angular.forEach(project, function (value, key) {
                  projects[i][key] = value;
                });
              }
            }

            RestResource.eckEntityUpdate('group', 'project', pid, request).then(afterResponse);
          });
        }
        // Project is new.
        else {
          // Create a hash of the project, so that we can detect we have a unique
          // identifier before the project is actually created.
          var hash = commonService.sdbmHash(JSON.stringify(project));
          project.hash = hash;

          // Add the creation animation for the project.
          project.cloning = true;

          listLocalService.addChanges('projects', 'created', project);

          // Add the project to the display immediately.
          if ($scope.projectList) {
            // Make sure the list is initialized.
            if (!$scope.projectList.projects) {
              $scope.projectList.projects = [];
            }

            $scope.projectList.projects.push(project);
          }

          // Ensure that account projects get attached to the account.
          if ($scope.projectType != 'personal') {
            request.__attach.entity_id = $scope.account.entity.id;
          }

          // If this isn't a template clone, then simply create the project.
          if (!$scope.activeTemplate) {
            RestResource.eckEntityCreate('group', 'project', request).then(function (res) {
              processProject(res, request, hash);
              afterResponse();
            });
          }
          else {

            // Perform a replication operation on the template, which will actually
            // create a project off of the template's source project.
            RestResource.eckEntityReplicate('template', 'project', $scope.activeTemplate.id, request).then(function (res) {
              processProject(res, request, hash, true);

              // Perform a long-poll of the project to assist in wrapping up the
              // replication, and to ensure we know as soon as it's done.
              var data = {
                '__processing': true
              };
              RestResource.eckEntityGet('group', 'project', res.id[0].value, data).then(function (project) {
                afterResponse();
              });
            });
          }
        }
        $mdDialog.cancel();
      };

      if ($scope.isStaff) {
        $scope.projectTemplateForm.visible = false;
      }
      $scope.projectTemplateForm.show = function () {
        $scope.projectTemplateForm.visible = true;
      };
      $scope.projectTemplateForm.hide = function () {
        this.visible = false;
      };
    }
  ]);

  app.directive('projectTemplateForm', function () {
    return {
      controller: [
        '$scope',
        'RestResource',
        'accountService',
        '$filter',
        function ($scope, RestResource, accountService, $filter) {
          var $modal = $scope.$parent;
          $scope.template_items = {};

          $scope.init = function () {
            RestResource.loadTemplateList('project', true).then(function (result) {
              accountService.addLowercase(result, 'title');

              for (let i = 0; i < result.length; i++) {
                let item = result[i];
                if (item.field_base_project == $modal.pid) {
                  $scope.template = item;
                  break;
                }
              }

              $scope.template_items = result;
              $scope.$digest();
            });
          };
          $scope.save = function () {
            $modal.projectTemplateForm.hide();

            var request = {
              "entity_type": "template",
              "entity_bundle": "project",
              "entity_id": $scope.template.id,
              "fields": {
                field_base_project: $modal.pid
              }
            };
            RestResource.entityFieldUpdate([request]);
          };

          $scope.querySearch = function (query, list) {
            var results = query ? $filter('filter')(list, query) : list.filter(createFilterFor(''));
            return results;
          };

          function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(value) {
              return (value._lowercase.indexOf(lowercaseQuery) === 0);
            };
          }

        }
      ],
      templateUrl: 'app/src/project/project-template-form.html'
    };
  });
})();
