(function () {
  'use strict';

  var app = angular.module('outsider', ['angular-drupal']);

  app.directive('outsider',
    function () {
      return {
        scope: {
          member: '=',
          uid: '=',
          project: '=',
          tooltip: '=',
          task: '=?'
        },
        controller: [
          '$scope',
          'accountService',
          '$rootScope',
          function($scope, accountService, $rootScope) {
          $scope.init = function() {
            // Hide by default.
            $scope.outsider = false;

            // If member is provided, use it's value.
            // Doing this first means uid/project ID will override if provided.
            // TODO: Remove this option as it causes old results to show.
            if ($scope.member) {
              $scope.outsider = $scope.member.outsider;
            }

            // Fetch full project object from project ID.
            if ($scope.project) {
              $scope.proj = accountService.getObjectById('projects', $scope.project);
            }

            // If there's an ID (not Unassigned).
            if ($scope.uid && $scope.proj && $scope.uid != 'Unassigned') {
              // Compare that ID to project members.
              $scope.outsider = ($scope.proj.members.indexOf($scope.uid) == -1);
            }
          };
          $scope.init();

          // Allow for processing updates to task (such as changing it's
          // project) to update status of outsiders.
          let taskUpdateListener = $rootScope.$on('taskUpdate', function(event, task, updates, hash) {
            if (task.nid && task.nid == $scope.task) {
              $scope.init();
            }
          });
          $rootScope.addListener(taskUpdateListener, $scope);

          // Allow for processing updates to projects (such as changing it's
          // members) to update status of outsiders.
          let projectUpdateListener = $rootScope.$on('projectUpdate', function(event, project) {
            if ($scope.project == project) {
              $scope.init();
            }
          });
          $rootScope.addListener(projectUpdateListener, $scope);
        }],
        templateUrl: 'app/src/components/outsider/outsider.html'
      };
    });
})();
