(function () {
  'use strict';


  angular.module('filter-service', [])
    .factory('filterService', [
      '$location',
      '$route',
      'commonService',
      '$rootScope',
      function ($location, $route, commonService, $rootScope) {
        var filtering = {};

        // Get the currently active filters from the URL.
        var functions = {
          getFiltering: function () {
            return filtering;
          },
          getFilters: function () {
            var filters;
            var query = $location.search();
            if (query) {

              if (typeof filtering == 'undefined') {
                filtering = {};
              }

              // Build the filters from what is found in the URL.
              filters = functions.buildFilters(query);
              if (typeof filters == 'undefined') {
                filters = {};
              }

              filtering.selection = filters;
            }
            return filters;
          },

          getSearchSorting: function() {
            let sorting = this.getSorting();
            let searchSorting = [];
            angular.forEach(sorting, function(filter) {
              searchSorting.push(filter);
            });
            return searchSorting;
          },

          resetSorting: function(type = false) {
             // Define the default sort parameters.
            let sorting = {
              primary: {
                field: 'field_weight',
                direction: 'asc',
              },
              secondary: {
                field: 'created',
                direction: 'desc',
              },
            };
            if (type && this.sorting.hasOwnProperty(type)) {
              this.sorting[type] = sorting[type];
            }
            else {
              this.sorting = sorting;
            }
          },

          clearSorting: function(type) {
            if (this.sorting.hasOwnProperty[type]) {
              this.sorting[type] = {};
            }
          },

          // Returns the sorting criteria.
          getSorting: function() {
            if (!this.hasOwnProperty('sorting')) {
              this.resetSorting();
            }

            return this.sorting;
          },

          setSort: function(field, direction = null, priority = 'primary') {
            let self = this;
            let sorting = this.getSorting();


            // TODO: This behaves a little funky. It assumes that whatever sort
            // type being updated already exists. (If there is no secondary then
            // a secondary sort can't be added.) It also doesn't have any
            // handling for when the secondary sort is being made the primary.
            // E.g. Given Primary A, Secondary B. setSort(B, asc, primary)
            // will result in a sort of Primary B: asc. (Secondary get's removed
            // and primary is replaced with B.)
            // Check all of the current set sorts against the parameters.
            angular.forEach(sorting, function(sort, type) {
              // If this field is already sorted, then update or clear it.
              if (sort.field == field) {

                // Update the filter in place if it's the correct priority.
                if (type == priority) {
                  // If no direction was provided, then just flip the sort.
                  if (!direction) {
                    self.flipSort(field, priority);
                  }
                  // Otherwise, set the sort to the provided direction.
                  else {
                    sort.direction = direction;
                  }
                }
                // Otherwise, remove the sort.
                else {
                  self.clearSorting(type);
                }
              }
              // When the correct priority is provided, then set the filter.
              else if (type == priority) {
                sorting[type] = {
                  field: field,
                  direction: direction,
                };
              }
            });
          },

          flipSort: function(field, type) {
            if (this.sorting[type].field == field) {
              if (this.sorting[type].direction == 'asc') {
                this.sorting[type].direction = 'desc';
              }
              else {
                this.sorting[type].direction = 'asc';
              }
            }
          },

          // Builds the filter object from the query string in the URL.
          buildFilters: function(query) {
            let filters = {};

            if (!commonService.empty(query)) {
              let valPieces = [];
              let dateFilters = ['start', 'end', 'dateStart', 'dateEnd'];
              let excludedParam = functions.getExcluded();
              let filterVal, filterName, filterParts;

              angular.forEach(query, function (value, key) {
                // Matches if the value is wrapped in '[' and ']'.
                // Used to indicate value is an array.
                if (typeof value == 'string' && value.match(/^\[(.+)\]$/)) {
                  // If the value is an array, parse it into an array.
                  valPieces = value.match(/^\[(.+)\]$/);
                  filterVal = valPieces[0].replace(/[\[\]]/g, '').split(',');
                }
                // Otherwise use the flat value as is.
                else {
                  filterVal = value;
                }

                // This regex matches strings in the format of val1[val2][val3]
                // and returns each value as an element of an array.
                // Supports 1-3 vals.
                filterParts = key.match(/(\w*[^\[\]])\[?(\w*)\]?(?:\[(\w*)\]?)?/).filter((filterPart, index) => {
                  // Grabs only pieces of they key and not the full match or
                  // metadata from match().
                  if (Number.isInteger(index) && index > 0) {
                    return filterPart;
                  }
                });

                // Builds an empty nested value bottom up.
                filterParts.reverse().forEach((filterName, index) => {
                  // Skip over excluded parameters.
                  if (excludedParam.indexOf(filterName) !== -1) {
                    return;
                  }

                  // If this field is a date field, then convert it to a date.
                  if (dateFilters.indexOf(filterName) != -1) {
                    filterVal = new Date(filterVal);
                  }

                  // Note this will nest the values, building the whole object.
                  filterVal = {
                    [filterName]: filterVal
                  };
                });

                // Merge the new object into the primary filter object.
                angular.merge(filters, filterVal);
              });
            }
            return filters;
          },

          // Cleans filters in preparation for adding them to URL.
          cleanFilters: function (filters, depth = 0) {
            var cleanFilters = {};
            var tempVal;
            var excludedParam = functions.getExcluded();

            angular.forEach(filters, function (val, key) {
              if (excludedParam.indexOf(key) == -1) {
                tempVal = null;

                if (!commonService.empty(val) || val instanceof Date) {

                  // Convert dates to a string.
                  if (typeof val == 'object' && val instanceof Date) {
                    val = val.toDateString();
                  }
                }

                // Recursively clean objects.
                if (typeof val == 'object' && Array.isArray(val) === false && !commonService.empty(val)) {
                  // Allow different handling on lower level items.
                  tempVal = functions.cleanFilters(filters[key], depth + 1);

                  // Handling for subvalues.
                  if (!commonService.empty(tempVal)) {
                    angular.forEach(tempVal, function (subVal, subKey) {

                      // Handling for top level filters
                      if (depth == 0) {
                        cleanFilters[key + subKey] = subVal;
                      }
                      else {
                        cleanFilters['[' + key + ']' + subKey] = subVal;
                      }
                    });
                  }
                }
                // Convert array's to a stringified version.
                else if (Array.isArray(val) && val.length > 0) {

                  var arrayVals = [];

                  for (let i = 0; i < val.length; i++) {
                    if (!commonService.empty(val[i])) {
                      arrayVals.push(val[i]);
                    }
                  }
                  if (!commonService.empty(arrayVals)) {
                    val = '[' + arrayVals.join(',') + ']';

                    if (depth == 0) {
                      cleanFilters[key] = val;
                    }
                    else {
                      cleanFilters['[' + key + ']'] = val;
                    }
                  }
                }
                // Default handling for all other values.
                else {

                  if (commonService.empty(val)) {
                    val = null;
                  }

                  if (depth == 0) {
                    cleanFilters[key] = val;
                  }
                  else {
                    cleanFilters['[' + key + ']'] = val;
                  }
                }
              }
            });

            return cleanFilters;
          },
          setFilterItem: function (key, value) {
            if (filtering.selection) {
              filtering.selection[key] = value;
            }
          },
          setFilters: function () {
            // The cleaning operation on filters
            let filters = functions.cleanFilters(filtering.selection);

            // Set all of the filters as URL query params.
            angular.forEach(filters, function (val, key) {
              $location.search(key, val);
            });

            $rootScope.$emit('filterUpdate');
          },
          getExcluded: function () {
            return ['task', 'editProject', 'length'];
          }
        };
        return functions;
      }
    ]);
})();
