(function() {
  'use strict';

  var app = angular.module('inlineEdit');

  app.controller('inlineWidgetNumberBase', [
    '$scope',
    '$controller',
    '$element',
    '$attrs',
    function($scope, $controller, $element, $attrs) {
      $scope.preProcessValue = function(value) {
        if (value || (value === 0 || value === '0')) {
          return Number(value);
        }
        else {
          return '';
        }
      };

      angular.extend(this, $controller('inlineWidgetCtrl', { $scope: $scope, $element: $element, $attrs: $attrs }));
    }
  ]);

  app.controller('inlineWidgetNumberRangeBase', [
    '$scope',
    '$controller',
    '$element',
    '$attrs',
    function($scope, $controller, $element, $attrs) {
      if (!$scope.defaultValue) {
        $scope.defaultValue = {
          min: '',
          max: ''
        };
      }
      $scope.preProcessValue = function(value) {
        if (value && value.hasOwnProperty('min')) {
          value.min = $scope.convertToNumber(value.min);
        }
        if (value && value.hasOwnProperty('max')) {
          value.max = $scope.convertToNumber(value.max);
        }
        return value;
      }
      $scope.convertToNumber = function(value) {

        if (value || (value === 0 || value === '0')) {
          return Number(value);
        }
        else {
          return null;
        }
      }

      angular.extend(this, $controller('inlineWidgetCtrl', { $scope: $scope, $element: $element, $attrs: $attrs }));
    }
  ]);

  app.directive('inlineWidgetNumber', function() {
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&?',
        modelVal: '=?',
        modelKey: '=?',
        inputType: '@',
        immediate: '=?',
        context: '@?'
      },
      templateUrl: 'app/src/components/inline-edit/widgets/number/templates/number.html',
      controller: 'inlineWidgetNumberBase'
    };
  });

  app.directive('inlineWidgetMatNumber', function() {
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&?',
        modelVal: '=?',
        modelKey: '=?',
        inputType: '@',
        immediate: '=?',
        context: '@?'
      },
      templateUrl: 'app/src/components/inline-edit/widgets/number/templates/number-mat.html',
      controller: 'inlineWidgetNumberBase'
    };
  })


  app.directive('inlineWidgetNumberRange', function() {
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&?',
        modelVal: '=?',
        modelKey: '=?',
        inputType: '@',
        immediate: '=?',
        context: '@?'
      },
      templateUrl: 'app/src/components/inline-edit/widgets/number/templates/number-range.html',
      controller: 'inlineWidgetNumberRangeBase'
    };
  });

  app.directive('inlineWidgetMatNumberRange', function() {
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&?',
        modelVal: '=?',
        modelKey: '=?',
        inputType: '@',
        immediate: '=?',
        context: '@?'
      },
      templateUrl: 'app/src/components/inline-edit/widgets/number/templates/number-range-mat.html',
      controller: 'inlineWidgetNumberRangeBase'
    };
  });

  // Add this directive to a number input to apply pretty formatting.
  app.directive('formatNumber', [
    '$filter',
    function ($filter) {
    return {
      require: '?ngModel',
      link: function (scope, elem, attrs, ctrl) {
        // Wait for initialization.
        if (!ctrl) return;

        // Generate filtered value based on input type.
        ctrl.filterize = function() {
          if (scope.inputType == 'money') {
            return $filter('currency')(ctrl.$modelValue, '$', 0);
          }
          else {
            return $filter('number')(ctrl.$modelValue);
          }
        };

        // Add a parser to convert user input back to a number.
        ctrl.$parsers.push(function(value) {
          return value.replace(/[^\d|\-+|\.+]/g, '');
        });

        // Add a formatter to convert machine input to a currency display.
        ctrl.$formatters.push(function (value) {
          // Zero isn't empty, but other falsy values are.
          if (ctrl.$modelValue || ctrl.$modelValue === 0){
            return ctrl.filterize();
          }
        });

        // On blur (by user) set the value to a currency style string.
        elem.bind('blur', function(event) {
          if (ctrl.$modelValue || ctrl.$modelValue == "0") {
            elem.val(ctrl.filterize());
          }
        });
      }
    };
  }]);
})();
