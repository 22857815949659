(function () {
  'use strict';

  angular.module('routing', [])
    .service('routingService', [
      '$rootScope',
      'taskService',
      'commonService',
      'filterService',
      '$location',
      '$route',
      '$routeParams',
      function ($rootScope, taskService, commonService, filterService, $location, $route, $routeParams) {


        // Called by each view to perform general tasks as they are being initialized.
        this.viewInit = function () {
          // Check for the task URL param to see if the task modal needs to be opened.
          var taskActive = taskService.openTaskModal();
          if (!taskActive) {
            $rootScope.openProjectModal();
          }
          this.checkRouteParams();
        };

        // Sets the project filter value from the URL parameters.
        this.checkRouteParams = function () {
          var filters = filterService.getFiltering().selection;
        };

        this.changeDisplay = function (display, projectId) {
          let accountId = $routeParams.accountId;
          let path = '/' + accountId + '/projects/all/' + display;

          // If a projectId is passed, set only that project as visible.
          if (projectId && !isNaN(projectId)) {
            filterService.setFilterItem('project', [projectId]);
          }

          $location.path(path);
          filterService.setFilters();

        };

        // TODO: Move to filterService?
        // REPLACES app.js $scope.updateFiltering
        this.updateFiltering = function (projectId) {
          var filtering = filterService.getFiltering();
          var filterValue = 'all';

          // Attempt to grab the projectID if one wasn't provided.
          if (!projectId) {
            if ($route.hasOwnProperty('current')) {

              // Get the project ID from the current route.
              projectId = $route.current.params.projectId;
              // If the project ID isn't defined and isn't 0, then use it.
              if (typeof projectId != 'undefined' && projectId !== 0) {
                filterValue = projectId;
              }
            }
          }
          else {
            filterValue = projectId;
          }

          // Make sure that the filtering object exists.
          if (filtering) {
            // If the filtervalue isn't set to all and there is a project ID.
            if (filterValue != 'all' && typeof projectId != 'undefined') {
              filtering.selection.project = [projectId];
            }
            // Only reset the filter value one or none projects are set.
            else if (filtering.selection.hasOwnProperty('project')
              && filtering.selection.project.length < 1) {
              filtering.selection.project = [];
            }
          }

          // Set the values, and return the project path.
          filterService.setFilters();
          return filterValue;
        };

        // Returns classname if path is active.
        this.getClass = function (path) {
          return this.displayIsActive(path) ? '' : 'active';
        };

        // Checks a string or array of strings to see if any are active.
        this.displayIsActive = function (path) {
          var paths = Array.isArray(path) ? path : [path],
              active = false;
          // Checks all paths and considers active true if any match.
          paths.forEach(function(p) {
            if (typeof $route.current !== 'undefined' &&
              $route.current.activetab == p) {
              active = true;
            }
          });
          return active;
        };

      }
    ]);
})();
