(function () {
  'use strict';

  var app = angular.module('filterpane', ['angular-drupal', 'user', 'misc', 'userchip', 'ngMaterialDateRangePicker']);

  app.directive('filterpane', function () {
    return {
      scope: {},
      controller: [
        '$scope',
        '$rootScope',
        'RestResource',
        'drupal',
        'accountService',
        'refreshService',
        'commonService',
        'routingService',
        'filterService',
        '$timeout',
        '$route',
        'taskListService',
        'listLocalService',
        'userDataService',
        'customFieldService',
        '$mdDialog',
        '$window',
        function ($scope, $rootScope, RestResource, drupal, accountService, refreshService, commonService, routingService, filterService, $timeout, $route, taskListService, listLocalService, userDataService, customFieldService, $mdDialog, $window) {
          $scope.filtering = filterService.getFiltering();
          $scope.account = $rootScope.account;
          $scope.facetAccount = {};
          $scope.tasklist = taskListService.getTaskList();
          $scope.sel = $scope.filtering.selection;
          if (!$scope.sel.hasOwnProperty('task_custom_value')) {
            $scope.sel.task_custom_value = {};
          }
          if (!Array.isArray($scope.sel.project)) {
            $scope.sel.project = [];
          }
          $scope.filterAddFormShow = false;
          $scope.accountLoaded = false;
          $scope.dateSelectShow = true;
          $scope.withAttachmentsShow = true;
          $scope.newFilterName = '';
          $scope.editFilterId = false;
          $scope.version = AppConfig.version;
          $scope.build = AppConfig.build;

          $scope.searchTerms = {
            field_assigned: '',
            field_list: '',
            field_priority: '',
            project: ''
          };

          $scope.base = AppConfig.basePath;
          $scope.models = $scope.filtering.models || {};
          $scope.states = $scope.filtering.states || {
            'field_label': {},
            'field_list': {},
            'field_priority': {},
            'field_task_owner': {},
            'project': {},
            'task_custom_value': {}
          };
          $scope.keys = {
            'tags': 'field_label',
            'statuses': 'field_list',
            'priorities': 'field_priority',
            'projects': 'project',
            'members': 'field_task_owner',
            'task_custom_value': 'task_custom_value',
          };
          $scope.fieldLookups = {
            'field_label': 'tags',
            'field_list': 'statuses',
            'field_priority': 'priorities',
            'field_task_owner': 'members',
            'project': 'projects',
            'task_custom_value': 'task_custom_value',
          };

          // Set all the routes that provide their own date filtering.
          $scope.dateDependents = [
            'calendar',
            // 'timeline',
            // 'swimlane',
          ];
          $scope.resetDate = [
            'calendar',
            'timeline',
            'swimlane',
          ];

          $scope.resetProjects = function() {
            $scope.models.project = [];
            $scope.sel.project = [];
          };

          $scope.isAllProjects = function() {
            if ($scope.sel.project.length == 0) {
              return true;
            }
            else {
              return false;
            }
          };

          $scope.models.selectedRange = {
            showTemplate: false,
            fullscreen: false
          };

          // Instantiate project groups.
          $scope.project_groups = {};
          accountService.getAccount().then(function(account) {
            $scope.updateProjects();
          });

          // Updates the filtering settings and project groups list to account
          // for changes sent from server.
          $scope.updateProjects = function() {
            $scope.project_groups = $scope.account.project_groups;
            $scope.filterSettings = userDataService.getFilterSettings();
          };

          // Update facet lists at several points
          $scope.updateFacets = function() {
            // Facets are only updated on an 'init' task search. If nothing
            // has changed, then don't re-calculate facet values.
            if ($scope.tasklist && $scope.tasklist.hasOwnProperty('lastInit')) {
              if ($scope.facetInit && $scope.facetInit >= $scope.tasklist.lastInit) {
                return;
              }
            }

            // Clone a subset of account information to use for facets.
            for (let key in $scope.keys) {
              $scope.facetAccount[key] = angular.copy($scope.account[key]);
            }

            // Add facet count information and remove facets with 0 counts.
            if ($scope.tasklist && !commonService.empty($scope.tasklist.facets)) {

              $scope.facetInit = $scope.tasklist.lastInit;
              angular.forEach($scope.facetAccount, function (filterVal, filterName) {
                // Skip empty lists.
                if (typeof filterVal !== 'object') {
                  return;
                }

                // Get the field name for this filter.
                let fieldName = $scope.keys[filterName];
                angular.forEach(filterVal, (option, index) => {

                  // Get the facets for this field.
                  let facet = $scope.tasklist.facets[fieldName];
                  let key = 'id';

                  // If the filter has a uid but no id, then the uid is the id.
                  if (option.hasOwnProperty('uid') && !option.hasOwnProperty('id')) {
                    key = 'uid';
                  }

                  // Display the count for each facet otherwise.
                  if (typeof facet == 'object' && facet.hasOwnProperty(option[key])) {
                    $scope.facetAccount[filterName][index].count = facet[option[key]];
                  }
                });

                // Iterate over all of the options to determine if they will
                // return results.
                for (let i = 0; i < filterVal.length; i++) {

                  let option = filterVal[i];
                  let hasCount = option.hasOwnProperty('count');
                  // Remove facets if they won't return results:
                  // - is in facet list but doesn't have results
                  // - not filtered currently (since facets support OR).
                  if (!hasCount || (hasCount && option.count < 1)) {

                    // Grab the field selection for the current field name.
                    let fieldSel = $scope.sel[fieldName];

                    // If the value is an array, then determine if it is empty.
                    let arrayEmpty = false;
                    if (Array.isArray(fieldSel) && fieldSel.length < 1) {
                      arrayEmpty = true;
                    }

                    // Determine if the field is being filtered for currently.
                    if (typeof $scope.sel[fieldName] == 'undefined' || arrayEmpty) {
                      // Remove the filter from the facet options.
                      $scope.facetAccount[filterName].splice(i, 1);

                      // Decrement the index, since the array of options is now
                      // one item shorter than it used to be.
                      i--;
                    }
                  }
                }
              });

              // No result count for the absence of a value. Add Unassigned member
              // manually.
              $scope.updateMembersList($scope.facetAccount.members);

              $scope.updateStatusList($scope.facetAccount.statuses);
              $scope.updateSearchTerms();
            }
          };

          // TODO: This is a poor attempt at forcing autocomplete options to
          // refresh while the autocomplete options are open. The below works,
          // but causes a visual jitter. Ideally we would only update facet vals
          // when something changes rather than on every app refresh.
          $scope.updateSearchTerms = function() {
            angular.forEach($scope.searchTerms, function(value, name) {
              if (name && $scope.searchTerms[name]) {
                // TODO: This is a janky way to trigger an update in autocomplete.
                // Causes autocomplete to think that the user typed a search term.
                // We should evaluate just removing autocomplete as it's not
                // adding much value at this point.
                let prev = $scope.searchTerms[name];
                $scope.searchTerms[name] = ' ';
                $timeout(function() {
                  $scope.searchTerms[name] = prev;
                });
              }
            });
          };

          // Process the filter selection and make sure the corresponding model
          // is populated.
          $scope.syncModels = function(fieldName, filter, init = false) {
            let accountKey = $scope.fieldLookups[fieldName];
            if (accountKey) {
              let lookupKey = (accountKey == 'members') ? 'uid' : 'id';
              if (fieldName == 'task_custom_value') {
                angular.forEach(filter, function (val, key) {
                  if (Array.isArray(val)) {
                    $scope.models[fieldName][key] = [];
                    angular.forEach(val, function (item) {
                      $scope.models[fieldName][key].push(item);
                    });
                  }
                  else {
                    $scope.models[fieldName][key] = val;
                  }
                });
              }
              else {
                $scope.models[fieldName] = accountService.getObjectByIds(accountKey, filter, lookupKey);
              }
              if (fieldName == 'field_task_owner') {
                // If the unassigned user is part of the filter, then forcibly
                // add it to the model. This is necessary since the unassigned
                // user is more of a psudo-filter value.
                if (typeof $scope.sel.field_task_owner != 'undefined' &&
                  $scope.sel.field_task_owner &&
                  $scope.sel.field_task_owner.indexOf('0') != '-1') {
                  $scope.updateMembersList($scope.models[fieldName]);
                }
              }
            }

            // Set up focus/blur states based on new values.
            if ($scope.states[fieldName] && $scope.sel[fieldName] && $scope.sel[fieldName].length) {
              // On init just put it in as it may not be defined yet.
              if (typeof $scope.states[fieldName].filled !== 'undefined' || init) {
                $scope.states[fieldName].filled = true;
              }
            }

            $scope.initDateRange($scope.sel);

            // Broadcast the new field data to child fields.
            $scope.$broadcast('filterFieldUpdate', $scope.models.task_custom_value);
          };

          // Adds the unassigned user to the members list.
          $scope.updateMembersList = function(members) {
            // @TODO: Should this check if the unassigned user is in the list?
            let unassigned = accountService.getUnassignedUser();
            unassigned.uid = '0';
            members.push(unassigned);
          };

          // Load the status name overrides from the account.
          $scope.updateStatusList = function(statuses) {
            accountService.loadTitleOverrides(statuses);
          };

          // Force the model to stay up to date with route changes.
          $rootScope.$on('routeProcessed', function () {
            var sel = filterService.getFiltering().selection;
            if ($scope.account) {
              $scope.syncModels('project', sel.project);
            }
            else {
              $rootScope.$on('accountLoaded', function() {
                $scope.syncModels('project', sel.project);
              });
            }
          });

          // Copy all of the dateRange filters over to the model.
          $scope.initDateRange = function(filter, reinit = true) {
            if (filter.start || filter.end) {
              if (reinit) {
                // This is necessary because md-date-range doesn't update this.
                // This more basic than theirs, but gets the job done.
                let from = moment(filter.start).format('MMM D');
                let to = moment(filter.end).format('MMM D');
                let templateName = from;
                if (from != to) {
                  templateName += ' - ' + to;
                }

                $scope.models.selectedRange = {
                  selectedTemplateName: templateName,
                  showTemplate: true,
                  selectedTemplate: null,
                  dateStart: moment(filter.start).toDate(),
                  dateEnd: moment(filter.end).toDate()
                };
              }
            }
          }
          $scope.initDateRange($scope.sel);

          $scope.models.field_label = $scope.models.field_label || [];
          $scope.models.field_list = $scope.models.field_list || [];
          $scope.models.project = $scope.models.project || [];
          $scope.models.field_priority = $scope.models.field_priority || [];
          $scope.models.field_task_owner = $scope.models.field_task_owner || [];
          $scope.models.task_custom_value = $scope.models.task_custom_value || {};

          let templateDefaults = commonService.getDateRangeDefaults();
          $scope.customTemplates = templateDefaults.customTemplates;
          $scope.disabledTemplates = templateDefaults.disabledTemplates;

          $scope.filterSettings = userDataService.getFilterSettings(true);

          // Instantiate filterSettings and customFields once present.
          $scope.initCustomFields = function() {
            $scope.customFields = $scope.account.custom_fields;

            if (!commonService.empty($scope.customFields)) {
              let nonArrayTypes = ['money', 'plain_text', 'phone_number', 'email_address', 'url', 'number'];
              angular.forEach($scope.customFields, function (field, key) {
                if ($scope.sel.task_custom_value[field.id]) {
                  $scope.models.task_custom_value[field.id] = $scope.sel.task_custom_value[field.id];
                }
                // If it's needing a default.
                else if (commonService.empty($scope.models.task_custom_value[field.id])) {
                  // If it's an array type
                  if (nonArrayTypes.indexOf(field.field_type) === -1) {
                    $scope.models.task_custom_value[field.id] = [];
                  }
                  else {
                    $scope.models.task_custom_value[field.id] = "";
                  }
                }
              });
            }
          };

          // TODO: Move to Routing Service?
          $scope.onRouteChange = function (event, newRoute, prevRoute) {
            if ($route.current && $scope.dateDependents.indexOf($route.current.activetab) != -1) {
              $scope.dateSelectShow = false;
              if ($scope.account && $scope.account.filters) {
                let dateFilters = [
                  'filterOverdue',
                  'filterDueToday',
                  'filterDueThisWeek',
                ];
                angular.forEach($scope.account.filters, function (filter) {
                  if (typeof filter.filter == 'string' &&
                    dateFilters.indexOf(filter.filter) >= 0) {
                    filter.hidden = true;
                  }
                });
              }
            }
            // Hide force-selected attachment field on files page.
            else if ($route.current && $route.current.activetab == 'files') {
              $scope.withAttachmentsShow = false;
            }
            else if ($route.current && $route.current.activetab != 'dashboard') {
              if ($scope.account && $scope.account.filters) {
                angular.forEach($scope.account.filters, function (filter) {
                  filter.hidden = false;
                });
              }
              $scope.dateSelectShow = true;
              $scope.withAttachmentsShow = true;
            }

            if (typeof prevRoute != 'undefined' && typeof prevRoute.$$route != 'undefined') {
              if ($scope.resetDate.indexOf(prevRoute.$$route.activetab) != -1 ) {
                $scope.resetDateRange();
              }
              else if (prevRoute.$$route.activetab == 'files') {
                $scope.sel.field_attachment = false;
              }
            }

            routingService.checkRouteParams();
          };
          $rootScope.$on('$routeChangeSuccess', $scope.onRouteChange);

          var filterListener = $rootScope.$on('accountLoaded', function () {
            if (!$scope.accountLoaded) {
              $scope.accountLoaded = true;
              $scope.account = $rootScope.account;
              $scope.initCustomFields();

              // Treat saved selection as a preset filter to apply it.
              if ($scope.sel) {
                $scope.applyFilter($scope.sel, true);
              }
              $scope.updateFacets();
            }

            $scope.updateProjects();
            $scope.fetchFilters();
          });

          $scope.fetchFilters = function () {
            $scope.searches = $scope.account.searches;
            listLocalService.getChangeList('searches', $scope.searches, false, 'id', $scope);

            // Re-sort the searches in case any new ones have been added.
            $scope.searches.sort(accountService.alphaSortByTitle);

            // Exclude deleted here. Ideally would be done by accountService.
            $scope.searches = $scope.searches.filter(filter => filter.field_entity_state != 'deleted');
          };

          // Allow other directives/etc. to reset filters.
          // Note that selfEmitting is not currently used.
          $rootScope.$on('resetFilters', function (event, selfEmitting) {
            if (!selfEmitting) {
              $scope.reset();
            }
          });

          // Allow other directives/etc. to reset filters.
          $rootScope.$on('taskListLoaded', function () {
            if ($scope.account) {
              $scope.updateFacets();
            }
          });

          // Delay consumption of filtration to reduce load.
          var timeoutPromise;
          var delayInMs = 200;
          $scope.$watch("sel", function(newVal, oldVal, scope) {
            // Skip non-operations.
            if (newVal == oldVal) {
              return;
            }

            // Does nothing, if timeout already done.
            $timeout.cancel(timeoutPromise);
            // Set timeout.
            timeoutPromise = $timeout(function () {
              // Redirect from Dashboard on updates to filterpane.
              if ($route.current && $route.current.activetab == 'dashboard') {
                routingService.changeDisplay('board');
              }

              // Filter service handles all other implications of filter update.
              filterService.setFilters();
            }, delayInMs);
          }, true);

          $scope.processFilterTemplate = function (filter) {
            var newFilter;
            if (typeof $scope[filter] === 'function') {
              newFilter = $scope[filter]();
            }
            else {
              newFilter = angular.copy(filter);
            }

            $scope.initDateRange(newFilter);

            if (typeof newFilter === 'object') {
              $scope.applyFilter(newFilter);
            }
            if ($route.current && $route.current.activetab == 'dashboard') {
              routingService.changeDisplay('board');
            }
          };


          // Accepts a new filter array and pipes it into filtration system.
          $scope.applyFilter = function (newFilter, init = false) {
            // On init $scope.sel itself is being applied, so don't reset it.
            if (!init) {
              $scope.reset(true);
            }
            for (var fieldName in newFilter) {
              // Maintain pointers for date fields.
              if (fieldName === 'selectedRange') {
                angular.forEach(newFilter[fieldName], function (val, key) {
                  if (val) {
                    if (key == 'dateStart' || key == 'dateEnd') {
                      if (typeof val == 'string') {
                        val = Date.parse(val);
                      }
                    }
                    $scope.models[fieldName][key] = val;
                  }
                });

                $scope.onSelect();
              }
              // Apply custom field values differently since they're nested.
              else if (fieldName === 'task_custom_value') {
                $scope.sel[fieldName] = $scope.sel[fieldName] || {};
                angular.forEach(newFilter[fieldName], function (val, key) {
                  if (val) {
                    $scope.sel[fieldName][key] = val;
                  }
                });
              }
              // All other fields can be replaced.
              else {
                $scope.sel[fieldName] = newFilter[fieldName];
              }

              // Tell the models about the changes.
              $scope.syncModels(fieldName, newFilter[fieldName], init);
            }
          };

          $scope.acFocus = function (field) {
            $scope.states[field].focus = true;
          };
          // Defaults to a blank state. This is called again /after/ chip removal.
          $scope.acBlur = function (field) {
            if ($scope.states[field]) {
              $scope.states[field].focus = false;
              $scope.states[field].filled = false;
              if ($scope.models[field].length >= 1) {
                $scope.states[field].filled = true;
              }
            }
          };
          $scope.onArchive = function () {
            $scope.sel.archived = !$scope.sel.archived;
          };

          $scope.close = function () {
            $scope.filtering.open = false;
          };
          $scope.reset = function (skipUpdate = false) {
            // Maintain object (and pointer), but delete all children.
            let fieldName;
            for (fieldName in $scope.sel) {
              switch (fieldName) {
                case 'project':
                  // Don't reset project if currently "within" a project.
                  if ($scope.filtering.showProjectFilter) {
                    $scope.models.project = [];
                    $scope.sel.project = [];
                  }
                  break;
                case 'start':
                case 'end':
                  // If the date filter isn't shown, then don't clear it.
                  if ($scope.dateSelectShow) {
                    $scope.sel[fieldName] = null;
                  }
                  break;
                case 'task_custom_value':
                  // Clear out al the custom field values individually to
                  // maintain some of the references.
                  angular.forEach($scope.sel[fieldName], function(value, id) {
                    if (Array.isArray($scope.sel[fieldName][id])) {
                      $scope.sel[fieldName][id].length = 0;
                    }
                    else if (typeof $scope.sel[fieldName][id] == 'object') {
                      angular.forEach($scope.sel[fieldName][id], function(subVal, key) {
                        delete value[key];
                        $scope.models[fieldName][id][key] = null;
                      });
                    }
                    else {
                      $scope.sel[fieldName][id] = null;
                    }
                  });
                  break;
                default:
                  $scope.sel[fieldName] = null;
                  break;
              }

              // Add new reset information to the scope models.
              $scope.syncModels(fieldName, $scope.sel[fieldName]);
            }

            // Clear all the states.
            for (fieldName in $scope.states) {
              $scope.states[fieldName].focus = $scope.states[fieldName].filled = false;
            }

            $scope.models.selectedRange.selectedTemplate = null;
            $scope.models.selectedRange.selectedTemplateName = null;

            // If the date filter isn't shown, then don't clear it.
            if ($scope.dateSelectShow) {
              $scope.models.selectedRange.dateStart = null;
              $scope.models.selectedRange.dateEnd = null;
            }

            $scope.models.field_label = [];
            $scope.models.field_list = [];
            $scope.models.field_priority = [];
            $scope.models.field_task_owner = [];

            if (!skipUpdate) {
              $rootScope.$emit('filterUpdate');
            }
          };

          $scope.resetDateRange = function () {
            $scope.sel.start = null;
            $scope.sel.end = null;
            $scope.models.selectedRange.selectedTemplate = null;
            $scope.models.selectedRange.selectedTemplateName = null;
            $scope.models.selectedRange.dateStart = null;
            $scope.models.selectedRange.dateEnd = null;

            filterService.setFilterItem('start', null);
            filterService.setFilterItem('end', null);
          };

          // Add filter form functions.
          $scope.toggleFilterAddForm = function () {
            if ($scope.filterAddFormShow) {
              $scope.hideFilterAddForm();
            }
            else {
              $scope.showFilterAddForm();
            }
          };
          $scope.showFilterAddForm = function () {
            $scope.filterAddFormShow = true;
            angular.element(this.element).find('input').focus();
          };
          $scope.hideFilterAddForm = function () {
            // Blank out the form.
            $scope.newFilterName = '';
            $scope.editFilterId = false;
            $scope.filterAddFormShow = false;
          };

          function afterRequest() {
            refreshService.appRefresh();
          }

          $scope.saveFilterAddForm = function () {
            var title = $scope.newFilterName;
            var custom_filter = $scope.sel;

            // TODO: Determine if this is an edit operation.
            // Attach this entity to the Account.
            var request = {
              title: title,
              // TODO: not working...
              field_filters: JSON.stringify(custom_filter),
              "__attach": {
                "entity_id": $scope.account.entity.id
              }
            };

            // Check if this is an edit or a create operation.
            if ($scope.editFilterId === false) {
              let hash = commonService.sdbmHash(JSON.stringify(request));
              $scope.hideFilterAddForm();

              request.hash = hash;

              // Add the search to the changelist.
              listLocalService.addChanges('searches', 'created', request, 'id', $scope);
              $scope.fetchFilters();
              refreshService.appRefresh();

              // Send it off, hide the form on completion.
              RestResource.eckEntityCreate('search', 'search', request).then(function (res) {
                delete request.__attach;
                request.id = res.id[0].value;
                listLocalService.addChanges('searches', 'created', request, 'id', $scope);
                $scope.fetchFilters();
                afterRequest();
              });
            }
            else {
              request.id = $scope.editFilterId;
              $scope.hideFilterAddForm();
              // Send it off, hide the form on completion.
              RestResource.eckEntityUpdate('search', 'search', request.id, request).then(afterRequest);
            }

          };
          // To edit, process the filter, and autofill for resave.
          $scope.filterEdit = function (filter) {
            $scope.processFilterTemplate(filter.field_filters);
            $scope.newFilterName = filter.title;
            $scope.editFilterId = filter.id;
            $scope.filterAddFormShow = true;
          };

          // To delete, splice the filter from the filters list (after confirm).
          $scope.filterDelete = function (filter) {
            var confirm = $mdDialog.confirm()
              .multiple(true)
              .title('Are you sure you want to delete this filter?')
              .textContent("This will also delete it for everyone else in your account as well.")
              .ariaLabel('Delete filter')
              .ok('Delete filter')
              .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
              var request = {
                "__attach": {
                  "entity_id": $scope.account.entity.id
                }
              };
              listLocalService.addChanges('searches', 'deleted', filter, 'id', $scope);
              $scope.fetchFilters();

              RestResource.eckEntityDelete('search', 'search', filter.id).then(function (res) {
                refreshService.appRefresh(true);
              });
            });
          };

          // Genearte a url to the task-export resource for the given filter in the
          // provided format.
          $scope.generateSearchUrl = function (filter, extension = 'csv', format = 'csv', machine = '') {
            let basePath = drupal.restPath() + 'rest/task-export/';
            // Convert non-alphanumeric to underscores in URLs/filenames.
            let baseFileName = filter.title.replace(/[\W_]+/g, "_");
            let fileName = baseFileName + '.' + extension;
            let query = '?_format=' + format + '&uuid=' + filter.uuid;

            if (machine) {
              query += '&machine=' + machine;
            }

            return basePath + fileName + query;
          };

          // Generate and return a URL as an href for the menu item.
          $scope.filterCsvExport = function (filter) {
            if (filter.uuid && filter.title) {
              $window.open($scope.generateSearchUrl(filter, 'csv', 'csv'), '_blank');
            }
          };
          // Generate and return a URL as an href for the menu item.
          $scope.filterMachineCsvExport = function (filter) {
            if (filter.uuid && filter.title) {
              $window.open($scope.generateSearchUrl(filter, 'csv', 'csv', true), '_blank');
            }
          };

          // Generate a URL and copy it to the clipboard.
          $scope.filterIcalExport = function (filter) {
            let url = $scope.generateSearchUrl(filter, 'ics', 'ical');
            commonService.copyToClipboard(url);
          };

          $scope.getChangePublicityLabel = function (filter) {
            if (!commonService.empty(filter.field_private == 1)) {
              return 'Make Export Link Public';
            }
            else {
              return 'Make Export Link Private';
            }
          };

          // Callback to flip the privacy of a search.
          $scope.changePublicity = function (filter) {
            let newPublicity = 1;
            let dialogText = {};

            // Change the dialog based whether this is public or private.
            if (!commonService.empty(filter.field_private == 1)) {
              newPublicity = 0;
              dialogText = {
                title: 'Are you sure you want to make this search public?',
                description: 'This will allow anyone with Calendar or CSV export URLs to access the results.',
                label: 'Make Public'
              };
            }
            else {
              newPublicity = 1;
              dialogText = {
                title: 'Are you sure you want to make this search private?',
                description: 'This will prevent anyone with Calendar or CSV export URLs from accessing the results. Syncing with external calendar applications will not work with private searches.',
                label: 'Make Private'
              };
            }

            let confirm = $mdDialog.confirm()
              .multiple(true)
              .title(dialogText.title)
              .textContent(dialogText.description)
              .ariaLabel(dialogText.label)
              .ok(dialogText.label)
              .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {

              filter.field_private = newPublicity;

              var request = {
                "entity_type": "search",
                "entity_bundle": "search",
                "entity_id": filter.id,
                "fields": {
                  'field_private': filter.field_private
                }
              };
              RestResource.entityFieldUpdate([request]);
            });
          };

          // Set up the popmenu
          $scope.filterItemMenu = {
            duplicate: {
              title: 'Edit',
              callback: $scope.filterEdit
            },
            deleteSearch: {
              title: 'Delete',
              callback: $scope.filterDelete
            },
            export: {
              title: 'Export...',
              callback: angular.noop,
              nest: {
                csvExport: {
                  title: 'Export As CSV',
                  callback: $scope.filterCsvExport
                },
                icalExport: {
                  title: 'Calendar Export',
                  callback: $scope.filterIcalExport
                },
                changePublicity: {
                  title: 'Change Publicity',
                  titleCallback: $scope.getChangePublicityLabel,
                  callback: $scope.changePublicity
                },
                machineCsvExport: {
                  title: 'Export As Detailed CSV',
                  callback: $scope.filterMachineCsvExport
                }
              }
            }
          };

          $scope.onSelect = function () {
            // Ignore the selectedRange date values when on the calendar.
            if (!$route.current || $route.current.activetab != 'calendar') {

              // Make sure the models and the stored values are synced up.
              if ($scope.models.selectedRange.dateStart) {
                $scope.sel.start = $scope.models.selectedRange.dateStart;
              }
              if ($scope.models.selectedRange.dateEnd) {
                $scope.sel.end = $scope.models.selectedRange.dateEnd;
              }
            }
            return true;
          };

          // Updates search query (based on custom-field's value).
          // This callback fires when a change to the field happens.
          $scope.onCustomFieldChange = (fieldName, nid, value, cid, scope) => {
            let tempVal;
            let arrayType = (Array.isArray(scope.modelVal)) ? true : false;
            if (typeof value == 'object') {
              if (!Array.isArray(value)) {
                value = [value];
              }

              if (arrayType) {
                tempVal = [];
              }

              if (Array.isArray(scope.modelVal)) {
                scope.modelVal.length = 0;
              }
              angular.forEach(value, function(val) {

                let key = false;
                if (val && typeof val == 'object') {
                  if (val.hasOwnProperty('id')) {
                    key = 'id';
                  }
                  else if (val.hasOwnProperty('uid')) {
                    key = 'uid';
                  }
                }

                if (key) {
                  if (arrayType) {
                    tempVal.push(val[key]);

                    if (scope.modelVal.indexOf(val[key]) === -1) {
                      scope.modelVal.push(val[key])
                    }
                  }
                  else {
                    tempVal = val[key];
                  }
                }
                // If there isn't a key, then this value should be a single value.
                else {
                  tempVal = val;
                }
                
              });
              value = tempVal;
            }

            // Note this nukes the reference.
            $scope.sel.task_custom_value[cid] = value;
          };

          $scope.addChip = function (list, chip, key = 'id') {
            $scope.sel[list] = $scope.sel[list] || [];
            $scope.sel[list].push(chip[key]);
          };
          $scope.removeChip = function (list, model, chip, key = 'id') {
            model.splice(model.indexOf(chip[key]), 1);
            $scope.acBlur(list);
          };

          $scope.transformChip = function (chip) {
            return chip;
          };

          $scope.querySearch = function (query, list) {
            var results = query ? list.filter(createFilterFor(query)) : list.filter(createFilterFor(''));
            return results;
          };

          // This expands/collapses the task filter box.
          $scope.toggleCustomFilters = function () {
            // Toggle the custom filter setting.
            userDataService.setFilterSetting('customFilters');
          };

          // This expands/collapses the project filter box.
          $scope.toggleProjectFilters = function (folderId) {
            if (!folderId) {
              // Toggle the project filter setting.
              userDataService.setFilterSetting('projectFilters');
            }
            else {
              // Toggle individual folders in sidebar.
              userDataService.setFilterSetting('filterFolders-' + folderId);
            }
          };

          $scope.isValidField = function (item) {
            return item && item.id;
          };

          // Related call to this function is commented out, so this won't be needed anymore.
          // Expands/Collapses the custom field filter-list.
          $scope.toggleCustomFieldFilters = function () {
            userDataService.setFilterSetting('customFieldFilters');
          };

          // Checks if value is iterable with ng-repeat.
          $scope.isEmpty = function (value) {
            if (value) {
              if (typeof value === 'object') {
                for (const property in value) {
                  if (value.hasOwnProperty(property)) {
                    return false;
                  }
                }
              }
              else if (Array.isArray(value)) {
                return value.length <= 0;
              }
            }
            return true;
          };

          // Add the item to the list, or remove it if it exists.
          $scope.toggle = function(item, list) {
            var idx = list.indexOf(item);
            if (idx > -1) {
              list.splice(idx, 1);
            }
            else {
              list.push(item);
            }
          };

          // See if project is in the list or not.
          $scope.exists = function (item, list) {
            return list.indexOf(item) > -1;
          };

          // Determine if all projects in a folder are currently selected.
          // If not "indeterminate" (some, not all), returns false.
          $scope.isIndeterminate = function(group) {
            let projects = group.projects.map(project => project.id);
            return ($scope.sel.project.length !== 0 && !$scope.isChecked(group) &&
                projects.some(pid => $scope.sel.project.includes(pid)));
          };

          // Determine if all projects in a folder are currently selected.
          // If all are checked, returns true.
          $scope.isChecked = function(group) {
            let projects = group.projects.map(project => project.id);
            return projects.every(pid => $scope.sel.project.includes(pid));
          };

          // Either removes all or adds all items in folder to filtered list.
          $scope.toggleAll = function(group) {
            // If all are added, remove all.
            if ($scope.isChecked(group)) {
              group.projects.forEach(function(project) {
                // Find and remove each project.
                let index = $scope.sel.project.indexOf(project.id);
                if (index !== -1) {
                  $scope.sel.project.splice(index, 1);
                }
              });
            }
            // If less than all of the items are added, select them all.
            else {
              group.projects.forEach(function(project) {
                // Prevent items from being double added.
                if ($scope.sel.project.indexOf(project.id) === -1) {
                  $scope.sel.project.push(project.id);
                }
              });
            }
          };

          /**
           * Filter function for a query string.
           */
          function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);

            return function filterFn(value) {
              return (value._lowercase.indexOf(lowercaseQuery) === 0);
            };
          }

          /**
           * Return the statuses that aren't complete.
           */
          function getActiveStatuses() {
            // Get the status ID of the completed state.
            let completeId = accountService.getDefaultValue('completed_list');

            // Make sure the completeId matches the type of the statuses.
            if (typeof completeId == 'number') {
              completeId = completeId.toString();
            }

            // Get an array of all status IDs from the account.
            let statuses = $scope.account.statuses.map(status => status.id);

            // Get the index where the completed status is.
            let completeIndex = statuses.indexOf(completeId);

            // Remove the complete status from the status list.
            if (completeIndex >= 0) {
              statuses.splice(completeIndex, 1);
            }

            return statuses;
          }

          /**
           * Programatic date filter function for past due tasks.
           */
          $scope.filterOverdue = function () {
            var filters = {};
            filters.selectedRange = {
              selectedTemplate: "BT",
              selectedTemplateName: 'Scheduled before today',
              dateStart: moment().utc().subtract(1, 'year').toDate(),
              dateEnd: moment().subtract(1, 'day').endOf('day').toDate()
            };

            // Restrict the statuses to incomplete tasks.
            filters.field_list = getActiveStatuses();

            return filters;
          };

          /**
           * Programatic date filter function for past due tasks.
           */
          $scope.filterDueToday = function () {
            var filters = {};
            filters.selectedRange = {
              selectedTemplate: "TD",
              selectedTemplateName: 'Scheduled today',
              dateStart: moment().startOf('day').toDate(),
              dateEnd: moment().endOf('day').toDate()
            };
            return filters;
          };

          /**
           * Programatic date filter function for past due tasks.
           */
          $scope.filterDueThisWeek = function () {
            var filters = {};
            filters.selectedRange = {
              selectedTemplate: "TW",
              selectedTemplateName: 'This Week',
              dateStart: moment().startOf('week').toDate(),
              dateEnd: moment().endOf('week').toDate()
            };
            return filters;
          };

          /**
           * Programatic filter function for tasks owned by the current user.
           */
          $scope.filterOwnedByMe = function () {
            var filters = {};
            filters.field_task_owner = [$scope.account.user.uid];
            return filters;
          };
        }
      ],
      templateUrl: 'app/src/components/filters/filterpane.html'
    };
  });
})();
