(function () {
  'use strict';

  var app = angular.module('feature', []);

  app.service('featureService', [
    'accountService',
    function (accountService) {
      var self = this;

      // Return whether the provided feature is enabled once account is loaded.
      this.featureEnabled = function (featureName) {
        return new Promise(function (resolve, reject) {
          accountService.getAccount().then(function(account) {
            // Get the actual status of the feature.
            resolve(self.featureStatus(featureName));
          });
        });
      };

      // Non-promise version of featureEnabled for when the account is loaded.
      this.featureStatus = function(featureName) {
        let enabled = false;

        // Grab the account.
        let account = accountService.getAccountValue();

        // Make sure the account is loaded and has the features list.
        if (account && account.hasOwnProperty('features')) {

          // If the feature name is in the list, it's enabled.
          enabled = (account.features.indexOf(featureName) != -1);
        }

        return enabled;
      }
    }
  ]);
})();
