(function () {
  'use strict';

  require('./src/rest/rest.js');
  require('./src/user/user.js');
  require('./src/account/account.js');
  require('./src/account/account-settings.js');
  require('./src/activity/activityview.js');
  require('./src/components/accountswitch/accountswitch.js');
  require('./src/components/subhead/subhead.js');
  require('./src/dashboard/dashboard.js');
  require('./src/project/project.js');
  require('./src/board/board.js');
  require('./src/task/task.js');
  require('./src/task/taskmini.js');
  require('./src/task/tasktrigger.js');
  require('./src/task/taskform.js');
  require('./src/task/taskdetails.js');
  require('./src/task/taskwidget.js');
  require('./src/task/taskschedule.js');
  require('./src/task/taskmisc.js');
  require('./src/task/TaskService.js');
  require('./src/task/TaskListService.js');
  require('./src/todo/todo.js');
  require('./src/todo/todolist.js');
  require('./src/comment/comment.js');
  require('./src/tableview/tableview.js');
  require('./src/tableview/tablerow.js');
  require('./src/tableview/table.js');
  require('./src/fileview/fileview.js');
  require('./src/listlocal/listlocal.js');
  require('./src/entitylist/EntityListService.js');
  require('./src/calendar/calendar.js');
  require('./src/charts/swimlane.js');
  require('./src/charts/timeline.js');
  require('./src/charts/ChartService.js');
  require('./src/file/file.js');
  require('./src/resource/resource.js');
  require('./src/attachments/attachments.js');
  require('./src/components/addusers/addusers.js');
  require('./src/components/aggregation/aggregation-viewer.js');
  require('./src/components/usermenu/usermenu.js');
  require('./src/components/gridmenu/gridmenu.js');
  require('./src/components/filters/filterpane.js');
  require('./src/components/filters/filterpaneDatePicker.js');
  require('./src/components/filters/filtertrigger.js');
  require('./src/components/filters/FilterService.js');
  require('./src/components/labelpanel/labelpanel.js');
  require('./src/components/layout/LayoutService.js');
  require('./src/components/labelmanage/labelmanage.js');
  require('./src/components/message/message-list.js');
  require('./src/components/message/message.js');
  require('./src/components/message/MessageListService.js');
  require('./src/components/message/EntityMessageService.js');
  require('./src/components/misc/misc.js');
  require('./src/components/multiedit/multieditService.js');
  require('./src/components/multiedit/multiedit-dialog.js');
  require('./src/components/multiedit/multiedit-banner.js');
  require('./src/components/multiedit/multiedit-checkbox.js');
  require('./src/components/popmenu/popmenu.js');
  require('./src/components/outsider/outsider.js');
  require('./src/components/memberpic/memberpic.js');
  require('./src/components/errorhandler/errorHandlerService.js');
  require('./src/components/routing/RoutingService.js');
  require('./src/components/search/searchService.js');
  require('./src/components/tracking/tracking.js');
  require('./src/components/tracking/TrackingService.js');
  require('./src/components/common/common.js');
  require('./src/components/colorbox/colorbox.js');
  require('./src/components/colorbox/colorbox-panel.js');
  require('./src/components/events/RefreshService.js');
  require('./src/components/feature/FeatureService.js');
  require('./src/components/userchip/userchip.js');
  require('./src/components/userdata/UserDataService.js');
  require('./src/components/projectstats/projectstats.js');
  require('./src/components/folders/folders-form.js');
  require('./src/components/repeatable/repeatable.js');
  require('./src/components/repeatable/repeatable-panel.js');
  require('./src/components/repeatable/repeatableService.js');

  require('./src/components/customfield/customfield.js');
  require('./src/components/customfield/custom-field-list.directive.js');
  require('./src/components/customfield/CustomFieldService.js');

  require('./src/components/edit-panel/edit-panel.js');
  require('./src/components/edit-panel/edit-panel-trigger.js');
  require('./src/components/inline-edit/inline-edit.js');
  require('./src/components/inline-edit/custom-fields-widget.js');

  require('./src/components/inline-edit/widgets/boolean/inline-edit.widget.boolean.js');
  require('./src/components/inline-edit/widgets/chips/inline-edit.widget.chips.js');
  require('./src/components/inline-edit/widgets/date/inline-edit.widget.date.js');
  require('./src/components/inline-edit/widgets/input/inline-edit.widget.input.js');
  require('./src/components/inline-edit/widgets/number/inline-edit.widget.number.js');
  require('./src/components/inline-edit/widgets/select/inline-edit.widget.select.js');
  require('./src/components/inline-edit/widgets/select/inline-edit.widget.select-flat.js');


  angular.module('dm', [
      'ngRoute',
      'ngMaterial',
      'ngResource',
      'ngCookies',
      'angular-drupal',
      'ngMaterialDateRangePicker',
      'dashboard',
      'comment',
      'timeline',
      'swimlane',
      'rest',
      'board',
      'task',
      'task-service',
      'tasklist-service',
      'custom-field-service',
      'customfield',
      'todo',
      'todolist',
      'addusers',
      'usermenu',
      'gridmenu',
      'filterpane',
      'filtertrigger',
      'filter-service',
      'labelpanel',
      'activityview',
      'tableview',
      'fileview',
      'layout',
      'listlocal',
      'calendar',
      'account',
      'account-settings',
      'account-switch',
      'project',
      'subhead',
      'tracking',
      'userdata',
      'search',
      'routing',
      'error-handler',
      'refresh-service',
      'feature',
      'common',
      'multiedit',
      'multiedit-service',
      'message-service',
      'messages',
      'entitylist-service',
      'editPanel', 'inlineEdit', 'customFieldWidget',
      'repeatable',
      'repeatable-panel',
      'repeatable-service',
    ])
    // Run the Drupal connection right away, since everything depends on it.
    .run([
      'drupal',
      '$rootScope',
      'errorHandlerService', '$templateCache',
      function (drupal, $rootScope, errorHandlerService, $templateCache) {
        $rootScope.connectPromise = drupal.connect();
        $rootScope.connectPromise.then(function () {
          $rootScope.$emit('drupalConnected');
        }, function () {
          errorHandlerService.appLogin();
        });

        // var url = 'app/src/task/selectinline/priority.html';
        // $templateCache.put(url, require(url));
      }
    ])
    .controller('AppCtrl', [
      '$scope',
      '$location',
      '$interval',
      '$rootScope',
      '$mdSidenav',
      '$mdDialog',
      'drupal',
      'accountService',
      'routingService',
      'commonService',
      'filterService',
      'userDataService',
      'errorHandlerService',
      '$route',
      'refreshService',
      '$window',
      '$timeout',
      function ($scope, $location, $interval, $rootScope, $mdSidenav, $mdDialog, drupal, accountService, routingService, commonService, filterService, userDataService, errorHandlerService, $route, refreshService, $window, $timeout) {

        $rootScope.opens = 0;
        $scope.trackOn = AppConfig.trackOn;
        $scope.toggleLeft = buildToggler('left');
        $scope.drupalConnected = drupal.connected;
        $rootScope.$on('drupalConnected', function () {
          $scope.drupalConnected = drupal.connected;
          accountService.init(true);
          accountService.getAccount().then(function (account) {
            $scope.account = $rootScope.account = account;
            $scope.settings = userDataService.getFilterSettings();
            $scope.activeAccount = $rootScope.account.activeAccount;
            $scope.showAccountSwitch = ($rootScope.account.accounts.length > 1);

            // Fallback ready here, show after 1 second.
            $timeout(() => {
              $scope.showPage();
            }, 1000);
          });
        });
        $scope.appReady = false;
        $scope.hideSplash = false;

        $scope.externalPath = AppConfig.externalEndpointUrl;
        $rootScope.root = {};
        $rootScope.root.pageReady = true;
        $rootScope.root.isMobile = false;
        $rootScope.defaultDateFormat = 'MMM D, YYYY [at] h:mm a';


        $rootScope.$on('viewReady', function () {
          $scope.showPage();
        });

        $scope.showPage = function() {
          // Begin show animation
          $scope.appReady = true;

          // After 200ms, hide the div completely.
          $timeout(() => {
            $scope.hideSplash = true;
          }, 200);
        };



        // Initalize the filters from the URL.
        filterService.getFilters();

        // And set those filters against scope.
        $scope.filtering = filterService.getFiltering();

        $rootScope.addListener = function (callback, scope = false) {
          if (scope === false) {
            scope = $rootScope;
          }
          if (typeof scope.listeners == 'undefined') {
            scope.listeners = [];
          }
          scope.listeners.push(callback);
        };
        $rootScope.destroyListeners = function (scope = false) {
          if (scope === false) {
            scope = $rootScope;
          }

          if (typeof scope.listeners != 'undefined' && scope.listeners.length > 0) {
            for (let i = 0; i < scope.listeners.length; i++) {
              scope.listeners[i]();
            }
            scope.listeners = [];
          }
        };

        $rootScope.openProjectModal = function (id = false, type = 'account', list = false, state = {}, folder = false) {
          if (!id && typeof $route.current.params.editProject !== 'undefined') {
            id = $route.current.params.editProject;
          } else if (id) {
            $route.current.params.editProject = id;
          }

          if (id) {
            $location.search('editProject', id);

            var options = {
              locals: {
                pid: id,
                projectType: type,
                projectList: list,
                state: state,
                folderId: folder
              },
              onRemoving: function () {
                $location.search('editProject', null);
                $location.search('launchProject', null);
              }
            };

            if (id == 'new') {
              $mdDialog.show(
                $mdDialog.newProjectModal(options)
              );
            } else {
              $mdDialog.show(
                $mdDialog.editProjectModal(options)
              );
            }
            return true;
          }
          return false;
        };

        // "Mobile" is defined as a screensize under 768, for now.
        $rootScope.updateMobile = function () {
          $timeout(function() {
            $rootScope.root.isMobile = ($window.innerWidth <= 768);
          });
        };
        // Update if mobile mode should be triggered: inititally and on resize.
        $rootScope.updateMobile();
        angular.element($window).bind('resize', function() {
          $rootScope.updateMobile();
        });

        // Run a full app refresh every so often, if enabled.
        if (AppConfig.appRefreshInterval) {
          var refresher = $interval(function () {
            refreshService.appRefresh();
          }.bind($scope), AppConfig.appRefreshInterval);
        }

        $scope.changeDisplay = routingService.changeDisplay;
        $scope.getClass = commonService.getClass;

        function buildToggler(navID) {
          return function () {
            $mdSidenav(navID).toggle();
          };
        }
      }
    ]).config(function() {
      angular.lowercase = angular.$$lowercase;  
    })
    .config(function ($mdThemingProvider) {
      $mdThemingProvider.disableTheming();
    })
    .config(['$locationProvider', '$routeProvider', function ($locationProvider, $routeProvider) {
      $locationProvider.hashPrefix('!');

      $routeProvider.otherwise({
        redirectTo: '/dashboard'
      });
    }]);
})();
