(function () {
  'use strict';

  var app = angular.module('message-service', [
    'angular-drupal'
  ]);

  app.service('messageListService', [
    '$rootScope',
    'RestResource',
    'entityListService',
    'searchService',
    function ($rootScope, RestResource, entityListService, searchService) {

      // Initialize the entityList service and overwrite any functions needed.
      let entityService = new entityListService('messageList', 'message', 'mid', '', 'messageListLoaded');

      // Override the entityListService getRequestBase function to provide the
      // correct default request for this message list type.
      entityService.getRequestBase = function(update) {
        let taskSearchRequest = this.getCurrentRequest();
        // Override sorting to be based on task changes to get latest tasks.
        // This only really matters if there are too many tasks to load.
        taskSearchRequest.sorting = [
          {
            field: 'changed',
            direction: 'desc',
          }
        ];
        return {
          task_search: taskSearchRequest,
        };
      };

      // Override the entityListService processResults function to ignore
      // deleted messages, since messages can't be deleted.
      // entityService.processResults = function(result) {
      //   self.processNewValues(this.getListIdentifier(), result.messages);
      // };

      // Override the entityListService request function to point to the
      // messages endpoint.
      entityService.performRequest = function(request, update) {
        searchService.messageSearch(request, update).then(function(result) {
          entityService.afterRequest(request, result, update);
          entityService.vals.entityList = result.message_list;
        });
      };

      // // Load all messages pertaining to this project.
      // this.loadProjectMessages = function(pid) {
      //   let filters = {
      //     project: [pid]
      //   };
      //   this.loadMessages(filters);
      // }

      // Mgerge entityService with the tasklist service.
      // self = angular.merge(this, entityService);

      // On filter update, trigger a new search.
      $rootScope.$on('filterUpdate', function(event, args) {
        entityService.reinit();
      });

      // Initialize the service with the values for this instance.
      entityService.serviceInit();

      // Provide a wrapper around digestEntities.
      entityService.digestMessages = function(changes) {
        return entityService.digestEntities(changes);
      };

      // Override to maintain order of ids passed from backend.
      entityService.getCurrentMessageList = function(allowOld = false) {
          // Require entityList to have been returned by backend.
          if (this.vals && this.vals.hasOwnProperty('entityList') && (allowOld || this.vals.hasOwnProperty('lastFetch'))) {
            return this.vals.entityList;
          }
          else {
            return [];
          }
        };

      // Set up all the entity-type specific function aliases for easier use.
      entityService.getMessagesByIds = entityService.getEntitiesByIds;
      entityService.getMessageById = entityService.getEntityById;
      entityService.getMessageList = entityService.getEntityList;
      entityService.removeMessage = entityService.removeEntity;
      // entityService.addToCurrentMessageList = entityService.addtoCurrentList;
      entityService.getMessages = entityService.getEntities;

      return entityService;
    }
  ]);
})();
