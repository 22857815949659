(function () {
  'use strict';

  var app = angular.module('popmenu', ['angular-drupal']);

  app.directive('popmenu',
    function () {
      return {
        scope: {
          menu: '=',
          menuName: '=',
          updateCallback: '=',
          item: '='
        },
        controller: [
          '$scope',
          function ($scope) {

            $scope.menuName = $scope.menuName ? $scope.menuName : 'Menu';

            // Menu callaback distributor.
            $scope.menuHandler = function (index, nestedMenu = false) {
              let menu = nestedMenu || $scope.menu;

              menu[index].callback($scope.item);
              $scope.updateMenu(index, $scope.item);
            };

            $scope.updateMenu = function (index, item) {
              if (typeof $scope.updateCallback == 'function') {
                $scope.updateCallback(index, item);
              }
            };

            $scope.getTitle = function (menuItem) {
              if (menuItem.hasOwnProperty('titleCallback')) {
                return menuItem.titleCallback($scope.item);
              }
              else {
                return menuItem.title;
              }
            };
          }
        ],
        templateUrl: 'app/src/components/popmenu/popmenu.html'
      };
    });
})();
