(function () {
  'use strict';

  var app = angular.module('colorbox');

  app.directive('colorboxTrigger', function () {
    return {
      scope: {
        entity: '=',
        callback: '&?',
        field: '='
      },
      // restrict: 'A',
      // replace: true,
      template: function (tElement, tAttrs) {
        var id = 'colorbox-panel';
        if (tAttrs.chip) {
          id += '-edit';
        }
        return '<div id="' + id + '" class="panel-colorbox" ng-click="openBox($event)" ng-transclude></div>';
      },
      transclude: true,
      controller: [
        '$scope',
        '$mdPanel',
        function ($scope, $mdPanel) {
          $scope.openBox = function (ev) {
            var position = $mdPanel.newPanelPosition()
              .absolute()
              .top(ev.clientY + "px")
              .left(ev.clientX - 45 + "px");

            var config = {
              attachTo: angular.element(document.body),
              controller: 'colorboxPanelCtrl',
              templateUrl: 'app/src/components/colorbox/colorbox.html',
              panelClass: 'colorbox-wrapper',
              position: position,
              openFrom: ev,
              clickOutsideToClose: true,
              focusOnOpen: true,
              locals: {
                entity: $scope.entity,
                callback: $scope.callback,
                field: $scope.field
              },
              zIndex: 82,
              width: 200
            };

            $mdPanel.open(config);
          };
        }
      ]
    };
  });

  app.controller('colorboxPanelCtrl', [
    '$scope',
    '$controller',
    'mdPanelRef',
    'entity',
    'callback',
    'field',
    function ($scope, $controller, mdPanelRef, entity, callback, field) {
      // Assign all the params against scope for when we pass this back to
      // colorboxCtrl.
      $scope.entity = entity;
      $scope.callback = callback;
      $scope.field = field;
      $scope.panel = true;

      // Extend the base class.
      angular.extend(this, $controller('colorboxCtrl', {$scope: $scope}));

      // Override the submit callback to close the panel afterwards.
      $scope.submit = function(entity) {
        $scope.callback(entity);
        mdPanelRef.close();
      }
  }]);
})();
