(function () {
  'use strict';

  var app = angular.module('subhead', ['angular-drupal', 'misc']);

  app.directive('subhead', function () {
    return {
      scope: {},
      controller: [
        '$scope',
        '$rootScope',
        'accountService',
        'commonService',
        'filterService',
        'routingService',
        '$route',
        'taskListService',
        'listLocalService',
        'multiEditService',
        'featureService',
        'layoutService',
        function ($scope, $rootScope, accountService, commonService, filterService, routingService, $route, taskListService, listLocalService, multiEditService, featureService, layoutService) {

          const ROUTE_PROJECT_ALL = 'all';
          const FILTER_NAME_START = 'start';
          const FILTER_NAME_END = 'end';

          if (multiEditService.isMultiEditEnabled()) {
            $scope.multiEdit = true;
          }
          else {
            $scope.multiEdit = false;
          }

          $scope.filtering = filterService.getFiltering();
          $scope.filtering.showProjectFilter = true;
          $scope.sel = $scope.filtering.selection;
          $scope.layoutSelector = {id:0};
          $scope.lastLayout = false;
          $scope.projects = $scope.baseProjects = [{
            id: 0,
            title: ' Multiple Projects'
          }];
          $scope.tasklist = {
            count: -1
          };

          $scope.sort = filterService.getSorting().primary;
          $scope.sortOptions = {
            field_weight: 'Order',
            title: 'Title',
            project: 'Project',
            field_list: 'Status',
            field_priority: 'Priority',
            field_task_owner: 'Owner',
            field_date: 'Scheduled',
            changed: 'Last Updated',
          };

          $scope.project = $scope.project || $scope.baseProjects[0];
          $scope.isAdmin = false;
          $scope.loaded = false;

          // Only show the menu if there are items in it.
          $scope.showProjectMenu = function() {
            return (Object.keys($scope.projectMenu).length > 0);
          };

          function editProject(project) {
            var projectList = {
              projects: $scope.projects
            };
            $rootScope.openProjectModal(project.id, project.type, projectList);
          }

          $scope.toggleMultiEdit = function () {
            if (multiEditService.isMultiEditEnabled()) {
              multiEditService.unsetMultiEdit();
              multiEditService.clearTaskIDs();
            }
            else {
              multiEditService.setMultiEdit();
            }
          };

          $scope.projectMenu = {};

          $scope.updateProjectMenu = function () {
            // There's enough variance here, just start over each time.
            $scope.projectMenu = {};
            // Only allow edit if viewing exactly one project.
            if ($scope.project.id) {
              $scope.projectMenu.edit = {
                title: 'Edit Project',
                callback: editProject
              };
            }
            // Allow bulk editing on table and board.
            if (routingService.displayIsActive(['board', 'table'])) {
              $scope.projectMenu.multiedit = {
                title: (multiEditService.isMultiEditEnabled()) ? 'Cancel Multiple Edit' : 'Edit Multiple Tasks',
                callback: $scope.toggleMultiEdit
              };
            }
          };
          $scope.updateProjectMenu();

          $scope.subheadClass = function () {
            if (!$scope.hide && $scope.project.hasOwnProperty('field_project_color')) {
              return 'color-' + $scope.project.field_project_color;
            }
          };

          // TODO: this only fires on load.
          $scope.pageTitle = 'Project Dashboard';

          $scope.hide = false;
          if ($route.current && $route.current.$$route.controller === 'DashboardCtrl') {
            $scope.hide = true;
            $scope.pageTitle = 'Project Dashboard';
          }

          $scope.getClass = function (path) {
            return routingService.getClass(path);
          };

          $scope.isActive = function (path) {
            return routingService.displayIsActive([path]);
          };

          $scope.changeDisplay = function (display) {
            return routingService.changeDisplay(display);
          };

          $rootScope.$on('alteredMultiEditFlag', function () {
            $scope.updateProjectMenu();
          });

          $rootScope.$on('filterUpdate', function () {
            $scope.searching = true;
            $scope.skipDate = ($route.current && $route.current.activetab == 'calendar');
            $scope.applied = 0;
            let dateApplied = false;

            angular.forEach($scope.sel, function (filterVal, filterName) {
              if (!commonService.empty(filterVal)) {
                // Exclude start and end.
                if (filterName === FILTER_NAME_START || filterName === FILTER_NAME_END) {
                  if (filterName == FILTER_NAME_START && !$scope.skipDate) {
                    // Don't count both start and end of date range as filters.
                    if (!dateApplied) {
                      $scope.applied++;
                      dateApplied = true;
                    }
                  }
                  return;
                }
                // Exclude scheduled range unless it's filled in.
                else if (filterName == 'selectedRange') {
                  if (!filterVal.dateEnd || typeof filterVal.dateEnd == 'undefined' || dateApplied) {
                    return;
                  }
                  else {
                    // Prevent the start filter from applying as well.
                    dateApplied = true;
                  }
                }
                else if (filterName == 'task_custom_value') {
                  angular.forEach(filterVal, function (fieldVal, fieldId) {
                    if (commonService.empty(fieldVal)) {
                      return;
                    }
                    // Account for ranges as filled/empty.
                    else if (typeof fieldVal == 'object') {
                      if (fieldVal.hasOwnProperty('min') && fieldVal.hasOwnProperty('max')) {
                        if (!fieldVal.min && !fieldVal.max) {
                          return;
                        }
                      }
                    }
                    $scope.applied++;
                  });

                  // `task_custom_value`` object itself doesn't constitute a field.
                  $scope.applied--;
                }
                // Exclude empty arrays.
                else if ((Array.isArray(filterVal) && filterVal.length < 1)) {
                  return;
                }

                $scope.applied++;
              }

              if (filterName == 'project') {
                // Default to multiple projects.
                let projectId = 0;
                if (filterVal.length == 1) {
                  projectId = filterVal[0];
                }

                // Set select list in subhead to correct value (failure safe).
                $scope.setProjectSelectById(projectId);
              }
            });
          });

          // Sets the select list to an object value given an ID.
          // Supports '0' as a way to say mulitple.
          // Safe in that it won't error out for bad values (e.g., from URL).
          $scope.setProjectSelectById = function(projectId) {
            let newProject = $scope.projects.filter((project) => {
              if (typeof project != 'undefined') {
                return project.id == projectId;
              }
              return false;
            });

            if (typeof newProject[0] === 'object') {
              $scope.project = newProject[0];
            }

            // Process changes to the menu to show the correct info.
            $scope.updateProjectMenu();
          };

          $rootScope.$on('taskListLoaded', function () {
            // Hide the searching animation.
            // This drops the bar when any task list loads complete, so not all
            // are necessarily complete. Task search does provide a tool to
            // bail on old data though, so most should be caught.
            $scope.searching = false;

            // Process the task list.
            $scope.tasklist = taskListService.getTaskList();
          });


          $scope.resetFilters = function () {
            $rootScope.$emit('resetFilters');
          };

          // Called after the directive can actually init (has account info).
          $scope.onAccountLoaded = function () {

            // While this is more responsive (as soon as a project is created
            // it will show in the subhead), that also means that it is possible
            // for a project without an ID can be added to the list. This means
            // that you couldn't actually view the project. It also causes the
            // issue where multiple projects show in the subhead because no
            // id is considered equivalent to id = 0 which is the All Projects
            // ID.
            // var projectsList = listLocalService.getChangeList('projects', $scope.account.projects);
            var projectsList = $scope.account.projects;

            $scope.projects = $scope.baseProjects.concat(projectsList);

            // Strip out any groups that don't have an ID (or are empty).
            $scope.projects.filter((group) => {
              !commonService.empty(group) && !commonService.empty(group.id)
            });

            // Set the status of all subhead features.
            $scope.activityEnabled = featureService.featureStatus('activity_log_activity_page');
            $scope.timelineEnabled = featureService.featureStatus('timeline_page_display');
            $scope.swimlaneEnabled = featureService.featureStatus('swimlane_page_display');
            $scope.globalSortEnabled = featureService.featureStatus('allow_global_sort');
            $scope.layoutSwitchingEnabled = featureService.featureStatus('layout_switching_interface');

            $scope.memberUser = accountService.getAccountMember();
            $scope.isAdmin = false;
            if (typeof $scope.memberUser != 'undefined') {
              $scope.isAdmin = $scope.memberUser.group_roles == 'account-admin';
            }

            if (!$scope.loaded) {
              $scope.onRouteChange();
            }
          };

          $scope.refresh = function () {
            $rootScope.$emit('filterUpdate');
          };

          $scope.updateSort = function() {
            let dir = $scope.sort.direction.toLowerCase();
            $scope.sort.direction = dir == 'desc' ? 'asc' : 'desc';
            $scope.refresh();
          };

          // Attach to layoutUpdate to update the current value of the layout.
          $rootScope.$on('layoutUpdate', function() {
            $scope.layoutUpdate();
          });

          // Set up the layouts selection list.
          $scope.layoutUpdate = function() {
            layoutService.getLayouts().then(function(layouts) {
              $scope.layouts = layouts;
              let activeId = layoutService.getActiveLayoutId();
              $scope.layoutSelector = {id: activeId};
            });
          };
          $scope.layoutUpdate();

          // Do the initial load of the account, then set up a listener.
          accountService.getAccount().then(function(account) {
            $scope.account = account;
            $rootScope.$on('accountLoaded', $scope.onAccountLoaded);
          });

          $scope.onRouteChange = function () {
            $scope.loaded = true;
            $scope.searching = true;
            let displayController = $route.current.$$route.controller;

            $scope.hide = (displayController === 'DashboardCtrl');
            $scope.disableDisplayControls = false;
            if (displayController == 'ActivityViewCtrl' || displayController == 'CalendarCtrl') {
              $scope.disableDisplayControls = true;
            }

            // This routeProject stuff in somewhat legacy. Project filtering
            // has been moved to a URL search rather than Route param.
            // Leaving here mostly for old URLs.
            let routeProject = $route.current.params.projectId;
            let routeProjectId = (routeProject === ROUTE_PROJECT_ALL) ? 0 : routeProject;

            if (routeProject === ROUTE_PROJECT_ALL) {
              if (!commonService.empty($scope.sel.project) && $scope.sel.project.length < 2) {
                routeProjectId = $scope.sel.project[0];
              }
            }

            // Set select list in subhead to correct value (failure safe).
            $scope.setProjectSelectById(routeProjectId);

            // Send page changes to AppCues.
            if (AppConfig.trackOn) {
              if ($scope.hide) {
                Appcues.track("Viewed Dashboard");
              }
              else {
                Appcues.track("Changed Project View", {
                  project: routeProjectId
                });
              }
            }
          };

          $rootScope.$on('$routeChangeSuccess', function() {
            // This is triggered on the initial page load. Don't continue if the
            // subhead hasn't finished loading.
            if ($scope.loaded) {
              $scope.onRouteChange();
            }
          });

          $scope.updateProject = function() {
            if ($scope.project.id !== 0) {
              $scope.sel.project = [$scope.project.id];
            }
            else {
              if ($scope.sel.project.length == 1) {
                $scope.sel.project = [];
              }
            }
          };

          // On change event for layout select list, update the layout.
          $scope.updateLayout = function() {
            let newLayout = $scope.layoutSelector;

            // Ensure a layout change before kicking off a layout refresh.
            if ($scope.lastLayout !== false && newLayout.id != $scope.lastLayout) {
              layoutService.setLayout(newLayout.id);
            }
            // If this is just an init for the layout, don't double fire.
            $scope.lastLayout = newLayout.id;
          };
        }
      ],
      templateUrl: 'app/src/components/subhead/subhead.html'
    };
  });
})();
