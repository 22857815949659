(function () {
  'use strict';

  var app = angular.module('task');

  app.directive('taskDetails', function () {
    return {
      scope: {
        nid: '='
      },
      controller: function ($scope, $rootScope, accountService, taskListService) {


        $scope.base = AppConfig.basePath;

        $scope.getAttachments = function () {
          let task = $scope.task;
          $scope.attachments = task.field_attachment ? task.field_attachment.length : 0;
        };

        $scope.getLabels = function () {
          let task = $scope.task;
          $scope.labels = accountService.getObjectByIds('tags', task.field_label);
          $scope.labels = $scope.labels.filter(label => label.field_entity_state != 'deleted');
        };

        $scope.getTodoCounts = function () {
          let task = $scope.task;
          if (!task.hasOwnProperty('todo_count_total') || !task.hasOwnProperty('todo_count_completed')) {
            if (task.hasOwnProperty('field_todo_counts')) {
              task.todo_count_total = task.field_todo_counts.total;
              task.todo_count_completed = task.field_todo_counts.complete;
            }
          }
          $scope.todo_items = {
            total: task.todo_count_total ? task.todo_count_total : 0,
            complete: task.todo_count_completed ? task.todo_count_completed : 0,
          };
        };

        $scope.init = function () {
          $scope.task = taskListService.getTaskById($scope.nid);
          $scope.getAttachments();
          $scope.getLabels();
          $scope.getTodoCounts();
        };

        let taskUpdateListener = $rootScope.$on('taskPostUpdate', function(event, task, updates, hash) {
          if (task.nid == $scope.nid) {
            $scope.init();
          }
        });
        $rootScope.addListener(taskUpdateListener, $scope);

        $scope.init();
      },
      templateUrl: 'app/src/task/task-details.html'
    };
  });
})();
