(function () {
  'use strict';

  angular.module('userdata', [])
    .service('userDataService', [
      '$rootScope',
      'accountService',
      'commonService',
      'RestResource',
      'featureService',
      function ($rootScope, accountService, commonService, RestResource, featureService) {
        this.initted = false;
        this.defaultLayouts = {
          'GanttChartCtrl': 0,
          'TimelineChartCtrl': 0,
          'SwimlaneChartCtrl': 0,
          'TableviewCtrl': 0,
          'FileviewCtrl': 0,
          'ActivityViewCtrl': 0
        };
        this.saving = false;
        this.data = {};

        var self = this;

        this.init = function() {
          // To initialize userdata, account must also be initialized.
          return accountService.getAccount().then(function(account) {
            self.user = account.user;
            self.data = self.user.field_data;

            self.initted = true;
          });
        };

        // Reinitialize should only init after primary init.
        // @TODO: This is probably useless because user and data aren't copies.
        this.reinit = function() {
          if (this.initted) {
            this.data = {};
            this.init();
          }
        };

        // Return the full userdata object.
        this.getData = function() {
          return self.data;
        };

        // Fetch the specified setting and default to the provided value.
        this.getSetting = function(settingName, defaultVal = {}) {
          // If no settingName was provided, then only use the defaultVal.
          if (settingName) {
            let data = this.getData();

            if (data && !data.hasOwnProperty(settingName)) {
              data[settingName] = defaultVal;
            }
            return data[settingName];
          }
          else {
            return defaultVal;
          }
        };

        // Get layouts from the current user data.
        this.getLayoutSettings = function() {
          // Provide defaults for the layout system.
          let defaultValue = self.defaultLayouts;

          // Assume that layout switching is off and only defaults are used.
          let setting = false;

          // If layout switching isn't enabled, then the values saved against
          // the user should be ignored and the defaults should be used.
          if (featureService.featureStatus('layout_switching_interface')) {

            // Provide different defaults for layout switching;
            setting = 'layout';

            // @TODODATA: Theoretically this default is used to prevent
            // the defaults from getting saved against the user. Disable for now.
            //  1) Is this necessary?
            //  2) Does this work?
            // defaultValue = {};
          }

          return this.getSetting(setting, defaultValue);
        };

        // The main callback to provide the layout ID for the current display.
        this.getLayoutId = function() {

          // Get all the layout settings for this user.
          let layouts = this.getLayoutSettings();

          // Get the current controller.
          let controller = commonService.getActiveController();

          // If there isn't a set layout for this controller, then use the
          // default.
          if (!layouts.hasOwnProperty(controller)) {
            layouts[controller] = -1;
          }
          return layouts[controller];
        };

        // Main setter function to update layouts for the user.
        this.setLayoutId = function(layoutId) {

          // Start with a fresh copy of the layouts.
          let layouts = this.getLayoutSettings();

          // Get the current controller that should be updated.
          let controller = commonService.getActiveController();

          // And set the value.
          layouts[controller] = layoutId;

          // Only continue to save the layout to the user's data if the
          // user is given control over which layout they see.
          if (featureService.featureStatus('layout_switching_interface')) {
            self.saveUserData();
          }
        };

        // Get collapse settings from the current user data.
        this.getCollapseSettings = function() {
          // Provide defaults for collapsed lists.
          let defaultValue = {};

          return this.getSetting('collapseLists', defaultValue);
        };

        // The main callback to provide the collapsed status for a list.
        this.getCollapseStatus = function(listId) {

          // Get all the layout settings for this user.
          let collapseSettings = this.getCollapseSettings();

          // If there isn't a setting for this list, then use the default.
          if (!collapseSettings.hasOwnProperty(listId)) {
            collapseSettings[listId] = false;
          }
          return collapseSettings[listId];
        };

        /**
         * Main setter function to update list collapse statuses for the user.
         * value = -1 is the value to toggle the status.
         */
        this.setCollapseStatus = function(listId, value = -1) {
          let status;

          // Get all of the collapse settings.
          let collapseSettings = this.getCollapseSettings();

          // Set or flip the collapse setting.
          commonService.setOrFlip(collapseSettings, listId, value);

          // And save.
          self.saveUserData();
        };

        // Get filter settings from the current user data.
        this.getFilterSettings = function(getDefault = false) {
          // Provide defaults for filter settings.
          let defaultValue = {
            projectFilters: true,
            customFilters: true,
            customFieldFilters: true,
            filteringClosed: false
          };

          return (getDefault) ? defaultValue : this.getSetting('filters', defaultValue);
        };

        /**
         * Main setter function to update filter setting values for the user.
         * value = -1 is the value to toggle the status.
         */
        this.setFilterSetting = function(name, value = -1) {
          let status;
          // Get all of the filter settings.
          let filterSettings = this.getFilterSettings();

          // Set or flip the filter pane setting.
          commonService.setOrFlip(filterSettings, name, value);

          // And save.
          self.saveUserData();
        };

        // Get collapse settings from the current user data.
        this.getDashboardSettings = function(getDefault = false) {
          // Provide defaults for collapsed lists.
          let defaultValue = {
            expanded: false,
            showArchived: 'none',
            sorting: {
              mode: 'id',
              dir: 'asc',
            }
          };

          return (getDefault) ? defaultValue : this.getSetting('dashboard', defaultValue);
        };

        /**
         * Main setter function to update dashboard settings for the user.
         * value = -1 is the value to toggle the status.
         */
        this.setDashboardSetting = function(name, value = -1) {
          let status;
          // Get all of the collapse settings.
          let dashboardSettings = this.getDashboardSettings();

          // Set or flip the filter pane setting.
          commonService.setOrFlip(dashboardSettings, name, value);

          // And save.
          self.saveUserData();
        };

        // Save the userdata object and send it to the server.
        this.saveUserData = function () {
          // Don't try to do a double save as that throws an error.
          if (!this.saving) {
            // Indicate that a save is taking place.
            this.saving = true;

            // Build the request.
            var request = {
              field_data: JSON.stringify(this.data)
            };

            // Define the after request.
            var afterResponse = function (res) {
              // The request is done, no longer saving.
              self.saving = false;

              // Check if a duplicate save was attempted.
              if (self.saveSkipped) {

                // If it was, then reset the duplicate save marker and resave.
                self.saveSkipped = false;
                self.saveUserData();
              }
              // refreshService.appRefresh(true);
            };

            // Actually perform the update request.
            RestResource.eckEntityUpdate('user', 'user', this.user.uid, request).then(afterResponse);
          }
          // Indicate that a second save was attempted.
          else {
            this.saveSkipped = true;
          }
        };

        // Kick off initial setup.
        this.init();
      }
    ]);
})();
