(function () {
  'use strict';

  var app = angular.module('task', [
    'angular-drupal',
    'ngRoute',
    'ngMaterial',
    'xeditable',
    'ckeditor',
    'misc',
    'outsider',
    'popmenu',
    'attachments',
    'resource',
    'labelpanel',
  ]);

  app.config(['$mdDialogProvider', function ($mdDialogProvider) {
    $mdDialogProvider.addPreset('openTaskModal', {
      options: function () {
        return {
          controller: 'TaskDialogController',
          templateUrl: 'app/src/task/task.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          escapeToClose: false
        };
      }
    });
  }]);

  app.controller('TaskDialogController', [
    '$scope',
    '$rootScope',
    '$mdDialog',
    'node_id',
    'RestResource',
    'taskService',
    'taskListService',
    'accountService',
    'entityMessageService',
    'refreshService',
    'trackingService',
    'commonService',
    'featureService',
    '$filter',
    function ($scope, $rootScope, $mdDialog, node_id, RestResource, taskService, taskListService, accountService, entityMessageService, refreshService, trackingService, commonService, featureService, $filter) {

      $scope.self = $scope;
      $scope.nid = node_id;
      if($scope.nid == 'false')
      $scope.nid = false;
      $scope.nodeReady = false;
      $scope.activeList = false;
      $scope.accountLoaded = false;
      $scope.readyCount = 0;
      $scope.expectedReadys = 2;
      $scope.opened = {};
      $scope.ariaLabel = 'Loading';
      $scope.activeTab = 'comments';
      $scope.root = $rootScope.root;
      $scope.node = {};
      $scope.resourceForm = {};
      $scope.resourceForm.visible = false;

      $scope.tabs = {
        'comments': { label: 'Comments' }
      };

      featureService.featureEnabled('activity_log_task_activity').then(function (enabled) {
        if (enabled) {
          $scope.tabs.activity = { label: 'Activity' };
        }
      });

      $scope.changeTab = function (tab) {
        if (tab !== $scope.activeTab) {
          $scope.activeTab = tab;
        }
      };

      $scope.selectedRange = {
        showTemplate: true,
        fullscreen: false
      };

      let templateDefaults = commonService.getDateRangeDefaults();
      $scope.selectedRange.customTemplates = templateDefaults.customTemplates;
      $scope.disabledTemplates = templateDefaults.disabledTemplates;

      $scope.$on('$destroy', function () {
        $rootScope.destroyListeners($scope);
      });

      $scope.ready = function () {
        $scope.readyCount++;
        if ($scope.readyCount >= $scope.expectedReadys) {
          $scope.nodeReady = true;

          // Set up the binding to close the dialog an clicking outside it.
          angular.element(document).find(".md-dialog-container").on('click', function(event) { 
            // If the click was the container itself (not within the modal).
            if (event.target.className == "md-dialog-container ng-scope") {
              // Try to hide the modal.
              $scope.hide();
            }
          });
        }
      };

      $scope.replicateTask = function () {
        $scope.hide();

        taskService.replicateTask($scope.node);
      };

      $scope.archiveTask = function () {
        taskService.archiveTask($scope.node, $scope.hide);
      };

      $scope.deleteTask = function () {
        taskService.deleteTask($scope.node, $scope.hide);
      };

      $scope.showAccessDenied = function() {
        $scope.hide();
        $mdDialog.show(
          $mdDialog.alert()
            .parent(angular.element(document.querySelector('#popupContainer')))
            .clickOutsideToClose(true)
            .title("You don't have access to that task")
            .textContent("If you think this in error please contact someone who is on the project so they can add you, or contact us so we can help.")
            .ariaLabel('Access Denied')
            .ok('Okay')
        );

      };

      // Display the description CKEditor form.
      $scope.showDescriptionForm = function (event) {
        // Prevents editability when clicking on a link.
        if (event.originalEvent.srcElement.localName == 'a') {
          return;
        }
        $scope.xEFbody.$show();
      };

      $scope.fieldUpdate = function (field) {
        let value = $scope.node[field];
        taskService.fieldUpdate(field, $scope.node.nid, value);
      };

      $scope.taskMenu = {
        duplicate: {
          title: 'Duplicate',
          callback: $scope.replicateTask
        }
      };
      $scope.updateTaskMenu = function () {
        if ($scope.node) {
          // @todo: allow showing archived tasks first. :)
          $scope.taskMenu.archive = {
            title: (!taskService.archivedStatus($scope.node)) ? 'Archive' : 'Unarchive',
            callback: $scope.archiveTask
          };
          $scope.taskMenu.delete = {
            title: (!taskService.deletedStatus($scope.node)) ? 'Delete' : 'Undo Deletion',
            callback: $scope.deleteTask
          };
        }
      };

      $scope.querySearch = function (query, list) {
        var results = query ? $filter('filter')(list, query) : list.filter(createFilterFor(''));
        return results;
      };

      /**
       * Create filter function for a query string
       */
      function createFilterFor(query) {
        var lowercaseQuery = angular.lowercase(query);

        return function filterFn(value) {
          if (value._lowercase) {
            return (value._lowercase.indexOf(lowercaseQuery) === 0);
          }
        };
      }

      $scope.resourceForm.show = function () {
        $scope.resourceForm.visible = true;
      };
      $scope.resourceForm.hide = function () {
        this.visible = false;
      };
      $scope.resourceForm.save = function () {
        $scope.resourceForm.hide();
        $scope.fieldUpdate('field_dm_resource');
        $scope.resourceLoading = true;
        taskService.resourceLoad($scope);
      };
      $scope.resourceForm.remove = function () {
        $scope.node.field_dm_resource = [];
        $scope.resourceForm.save();
      };

      $scope.taskInit = function () {
        $scope.node = taskListService.getTaskById(node_id);
        if ($scope.node) {
          $scope.setProject();
          $scope.$digest();
        }

        let x = taskService.loadTask(node_id, true).then(function(node) {
          $scope.ready();
          $scope.node = node;
          $scope.node.loaded = true;
          $scope.updateTaskMenu();

          $scope.setProject();

          // Display resources block if present.
          if (node.field_dm_resource) {
            $scope.resourceId = node.field_dm_resource;
          }

          // Send page changes to AppCues.
          trackingService.eventTaskOpened($scope.node);

          // Staff users can add/edit/remove resources on tasks.
          if ($scope.isStaff) {
            $scope.taskMenu.modifyResource = {
              title: ((!!node.field_dm_resource) ? 'Edit' : 'Add') + ' Resource',
              callback: $scope.resourceForm.show
            };
          }
          $scope.$digest()
        }).catch(function(error) {
          // Show access denied messages when the task can't be loaded.
          if (error.message == '403') {
            $scope.showAccessDenied();
          }
        });
      };

      $scope.setProject = function() {
        // Grab project to display the color.
        let projectId = $scope.node.project;
        if (typeof $scope.node.project == 'object' && $scope.node.project.hasOwnProperty('id')) {
          projectId = $scope.node.project.id;
        }
        $scope.projectObj = accountService.getObjectById('projects', projectId);
      };

      // Resolve callback for mdDialog. Ensures the modal should be closed,
      // closes the modal if so. Used for X button and clicking outside modal.
      $scope.hide = function () {
        // Simple, if the desription field is visible, prevent close.
        if (!$scope.xEFbody.$visible) {
          $mdDialog.hide();
        }
        else {
          let confirm = $mdDialog.confirm()
            .multiple(true)
            .title('Are you sure you want to close the task?')
            .textContent("Closing now will discard any changes to the task description.")
            .ariaLabel('Close Task?')
            .ok('Close Anyway')
            .cancel('Go Back');

          $mdDialog.show(confirm).then(function () {
            $mdDialog.hide();
          });
        }
      };

      // Initialize the account or listen for the account to be loaded.
      $scope.initAccount = function () {
        // Attempt to load the account
        accountService.getAccount().then(function (account) {

          // Skip this if the account has already been loaded.
          if (!$scope.accountLoaded) {

            // Initialize the account and set the accountLoaded flag.
            $scope.account = account
            $scope.accountLoaded = true;
            $scope.isStaff = ($scope.account.user.roles.indexOf('staff') !== -1);
            // Provide an array of id and value to make easy access to value.
            $scope.custom_fields = [];
            $scope.custom_fields = account.custom_fields;
            $scope.taskInit();
          }
        });
      };

      $scope.initAccount();

      let taskUpdateListener = $rootScope.$on('taskPostUpdate', function(event, task, updates, hash) {
        $scope.node = task;
        $scope.setProject();
      });
      $rootScope.addListener(taskUpdateListener, $scope);

      entityMessageService.setEntity('task', $scope.nid);
      $scope.messageService = entityMessageService;

      // Watch selectedRange model separately from mdDateRangeSelect since it
      // calls the update callback like 4x as much as needs to.
      let dateRangeListener = $scope.$watch('selectedRange', $scope.onSelect, true);
      $rootScope.addListener(dateRangeListener, $scope);
    }
  ]);
})();
