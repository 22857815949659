(function () {
  'use strict';

  var app = angular.module('filtertrigger', []);

  app.directive('filterpaneTrigger', function () {
    return {
      scope: {},
      controller: [
        '$scope',
        'drupal',
        '$rootScope',
        'filterService',
        'userDataService',
        function ($scope, drupal, $rootScope, filterService, userDataService) {
          // Hide until connected.
          $scope.ready = false;
          $scope.filtering = filterService.getFiltering();

          $scope.base = AppConfig.basePath;
          if (AppConfig.cdnUrl) {
            $scope.base = AppConfig.cdnUrl + $scope.base;
          }

          $scope.settings = userDataService.getFilterSettings(true);

          $scope.toggleFilters = function () {
            userDataService.setFilterSetting('filteringClosed');
            $scope.updateToolTip();
            $rootScope.$emit('filteringToggled');
          };

          $scope.updateToolTip = function () {
            var closed = $scope.settings.filteringClosed;
            $scope.toolTipText = closed ? 'Expand Sidebar' : 'Collapse Sidebar';
          };

          // Wait for a connection to Drupal.
          $rootScope.$on('drupalConnected', function () {
            // If the connection wasn't successful, there won't be anything to show.
            if (drupal.connected) {
              $scope.ready = true;
              $scope.$apply();
              $scope.updateToolTip();
            }
          });
        }
      ],
      templateUrl: 'app/src/components/filters/filterpane-trigger.html'
    };
  });
})();
