'use strict';

var app = angular.module('account-switch', [
  'ngRoute',
  'angular-drupal',
  'misc',
  'rest'
]);

app.config(['$mdDialogProvider', function($mdDialogProvider) {
  $mdDialogProvider.addPreset('switchAccountModal', {
    options: function() {
      return {
        controller: 'AccountSwitchDialogController',
        templateUrl: 'app/src/components/accountswitch/account-switcher.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        escapeToClose: true
      };
    }
  });
}]);

app.controller('AccountSwitchDialogController', ['$scope', '$rootScope', '$mdDialog', 'drupal', 'RestResource', 'accountService', 'errorHandlerService', '$window', function($scope, $rootScope, $mdDialog, drupal, RestResource, accountService, errorHandlerService, $window) {
  $scope.ariaLabel = 'Change Accounts';

  $scope.dialogReady = false;
  $scope.current = accountService.getActiveAccount();
  if ($rootScope.account && $rootScope.account.accounts) {
    $scope.accounts = $rootScope.account.accounts;
    $scope.dialogReady = true;
  }
  else {
    $rootScope.$on('accountLoaded', function() {
      $scope.accounts = $rootScope.account.accounts;
      $scope.dialogReady = true;
    });
  }

  $scope.cancel = function() {
    $mdDialog.cancel();
  };

  $scope.switchAccount = function(account) {
    if (account.field_active != '0') {
      $window.open('/#!/' + account.id + '/dashboard', '_blank');
      $scope.cancel();
    }
    else {
      errorHandlerService.appLogin(account.id);
    }
  };
}]);
