(function() {
  'use strict';

  var app = angular.module('inlineEdit');

  app.controller('inlineWidgetSelectBase', [
    '$scope',
    '$controller',
    '$element',
    '$attrs',
    'accountService',
    'featureService',
    '$rootScope',
    '$timeout',
    function($scope, $controller, $element, $attrs, accountService, featureService, $rootScope, $timeout) {
      $scope.placeholder = 'Select...';
      $scope.nonMemberNotice = false;
      $scope.project = {};

      // Watch for model updates and make sure they are reflected visually.
      $scope.$watch("modelVal", function(newVal, oldVal, scope) {
        if (newVal != oldVal && Array.isArray(scope.modelVal)) {
          scope.selectedValue[scope.modelKey] = scope.options[scope.modelVal[0]];
        }
      });

      // Non-member flag needs to enabled as a Feature.
      // Also want to check the context for this field.
      if ($scope.context == 'taskModal' && $scope.taskId != false) {
        $scope.nonMemberNotice = true;
      }

      $scope.makeSingleValue = function() {
        if (Array.isArray($scope.selectedValue[$scope.modelKey])) {
          $scope.selectedValue[$scope.modelKey] = $scope.selectedValue[$scope.modelKey].shift();
        }
      }

      $scope.memberSelectOptions = function(mapping) {
        $scope.placeholder = 'Unassigned';

        // Set up the options.
        var unassigned = [accountService.getUnassignedUser()];
        var options = unassigned.concat($scope.account.members);
        // From here out it's all for user fields (for now).

        $scope.updateOptions = function() {
          // Load the project for this node into scope.
          if ($scope.node.project) {
            $scope.project = accountService.getObjectById('projects', $scope.node.project);
          }

          // Highlight users not in this project for user-type fields.
          if ($scope.fieldName == 'field_task_owner' || $scope.inputType == 'user') {
            // Compare the project members with the account members and flag all
            // options that aren't in the current project.
            angular.forEach(options, function(mem_option) {
              // Don't consider the "unassigned" user an outsider (uid = null).
              if (mem_option.uid) {
                mem_option.outsider = ($scope.project.members.indexOf(mem_option.uid) == -1);
              }
              else {
                mem_option.outsider = false;
              }
            });
          }
        };

        // Add non-member notice to option list as needed.
        if ($scope.nonMemberNotice) {
          $scope.updateOptions();
        }
        return options;
      };

      $scope.priorityClasses = function(item) {
        let classes = ['icon'];
        if (item.hasOwnProperty('_lowercase')) {
          classes.push('icon-priority-' + item._lowercase);
        }
        return classes;
      };

      // Allow opening the project edit modal from this field.
      $scope.editProject = function() {
        $rootScope.openProjectModal($scope.project.id, $scope.project.type);
      };

      angular.extend(this, $controller('inlineWidgetCtrl', { $scope: $scope, $element: $element, $attrs: $attrs }));

      // Since this is a single value type of field display, process array data
      // (such as from URL) and make it visible.
      $scope.makeSingleValue();
    }
  ]);

  app.directive('inlineWidgetSelect', function($compile) {
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&?',
        inputType: '@',
        modelVal: '=?',
        modelKey: '=?',
        immediate: '=?',
        context: '@?'
      },
      link: function(scope, element) {
        // This structure is used because there's a gulp plugin that inlines
        // template urls. When this get's compiled, panelTemplate.templateUrl
        // will be replaced with panelTemplate.template where template is the
        // actual HTML from the template file. This template inlining plugin
        // requires that the templateUrl be defined as a string.

        var panelTemplate = {
          templateUrl: 'app/src/components/inline-edit/widgets/select/templates/select.html'
        };

        if ((scope.fieldName) == 'field_task_owner' || scope.inputType == 'user') {
          panelTemplate = {
            templateUrl: 'app/src/components/inline-edit/widgets/select/templates/select-user.html'
          };
        }
        else if (scope.inputType == 'tag') {
          panelTemplate = {
            templateUrl: 'app/src/components/inline-edit/widgets/select/templates/select-tag.html'
          };
        }
        element.html(panelTemplate.template);
        $compile(element.contents())(scope);
      },
      controller: 'inlineWidgetSelectBase',
    };
  });

  app.directive('inlineWidgetMatSelect', function($compile) {
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&?',
        inputType: '@',
        modelVal: '=?',
        modelKey: '=?',
        immediate: '=?',
        context: '@?'
      },
      link: function(scope, element) {
        // This structure is used because there's a gulp plugin that inlines
        // template urls. When this get's compiled, panelTemplate.templateUrl
        // will be replaced with panelTemplate.template where template is the
        // actual HTML from the template file. This template inlining plugin
        // requires that the templateUrl be defined as a string.

        var panelTemplate = {
          templateUrl: 'app/src/components/inline-edit/widgets/select/templates/select-mat.html'
        };

        if ((scope.fieldName) == 'field_task_owner' || scope.inputType == 'user') {
          panelTemplate = {
            templateUrl: 'app/src/components/inline-edit/widgets/select/templates/select-user-mat.html'
          };
        }
        else if (scope.inputType == 'tag') {
          panelTemplate = {
            templateUrl: 'app/src/components/inline-edit/widgets/select/templates/select-tag-mat.html'
          };
        }
        element.html(panelTemplate.template);
        $compile(element.contents())(scope);
      },
      controller: 'inlineWidgetSelectBase',
    };
  });

})();
