(function () {
  'use strict';

  var app = angular.module('listlocal', [
    'angular-drupal'
  ]);

  app.service('listLocalService', [
    '$rootScope',
    function ($rootScope) {

      // Mapping of whether the list is a list of additional entities, or a list
      // removed entities. "true" means the items in the list are in addition to
      // the provided list of entities. "false" means the items should be
      // removed or hidden.
      var listDirectionMapping = {
        'created': true,
        'loaded': true,
        'deleted': false
      };

      // Mapping that defines whether an item should be removed from other
      // lists when an item is added to another.
      var deletionMapping = {
        'deleted': ['created', 'loaded']
      };

      var functions = {
        // returns the boolean representation of the list direction.
        getOpDirection: function(op) {
          if (listDirectionMapping.hasOwnProperty(op)) {
            return listDirectionMapping[op];
          }
          else {
            return true;
          }
        },

        // Prepare the scope changes var.
        prepScopeVar: function (objectList, scope = false) {
          if (!scope) {
            // NOTE: For task searches, only rootScope is used to force update
            // the tasklist. If created tasks are tracked against a different scope,
            // they will need to also track that change against root scope. Other-
            // wise, changes to the task won't be reflected until the change-record
            // expires.
            scope = $rootScope;
          }

          // If the changeLists property isn't set, then set it.
          if (!scope.hasOwnProperty('changeLists') || typeof scope.changeLists == 'undefined') {
            scope.changeLists = {};
          }

          // And make sure that the actual item list exists too.
          if (!scope.changeLists.hasOwnProperty(objectList) || typeof scope.changeLists[objectList] == 'undefined') {
            scope.changeLists[objectList] = {
              'deleted': {},
              'created': {},
              'loaded': {}
            };
          }

          return scope.changeLists[objectList];
        },

        // Returns the list of items for the specified type.
        getChanges: function (objectList, type, scope = false) {
          var changeList = this.prepScopeVar(objectList, scope);

          let returnList = (functions.getOpDirection(type)) ? {} : [];

          if (typeof changeList[type] == 'undefined') {
            changeList[type] = {};
          }
          else if (Object.keys(changeList[type]).length > 0) {

            // Loop through all of the item changes to ensure they're still valid.
            let now = new Date();
            angular.forEach(changeList[type], function (vals, id) {

              // After 60 seconds, auto-expire item changes. If the system
              // hasn't synced up the new record after 60 seconds, then something
              // probably went wrong.
              if (now - vals.timestamp < 60 * 1000) {

                if (!functions.getOpDirection(type)) {
                  returnList.push(id);
                }
                else if (functions.getOpDirection(type)) {
                  returnList[id] = vals;
                }
              }
              else {
                delete changeList[type][id];
              }
            });
          }

          return returnList;
        },
        // Add an item to the changes list.
        addChanges: function (objectList, op, item, initKey = 'id', scope = false) {
          var changeList = this.prepScopeVar(objectList, scope);

          // Set a timestamp so that we know how long ago this change was made.
          item.timestamp = new Date();

          // Indicates that a new item was added to the changeList.
          let addItem = true;

          // Indicates that an old item was removed from the ChangeList.
          let remove = false;

          // The key being used to reference the task.
          let key = initKey;

          if (typeof changeList[op] == 'undefined') {
            changeList[op] = {};
          }

          // When deleting an entity, ensure that it isn't in the created list.
          // (Otherwise the item will get added back.)
          if (deletionMapping.hasOwnProperty(op)) {
            angular.forEach(deletionMapping[op], function(removeList) {

              if (changeList.hasOwnProperty(removeList)) {
                if (changeList[removeList].hasOwnProperty(item[key])) {
                  delete changeList[removeList][item[key]];
                }
              }
            });
          }

          // If the item has a key, then it's a fully fledged entity.
          if (item.hasOwnProperty(key)) {
            // If the changelist has the matching hash, then this item can
            // beremoved from the changeList
            if (changeList[op].hasOwnProperty(item.hash)) {
              remove = true;
            }
          }
          // Since this item doesn't have a key, check if it has a hash instead.
          else if (item.hasOwnProperty('hash')) {
            // If it does, then use the hash as the new key.
            key = 'hash';
          }
          // If there's no hash and no key, then don't add this to the list as
          // it could never be removed again.
          else {
            addItem = false;
          }

          // Remove this item from the changeList, since it's a full entity.
          if (remove) {
            delete changeList[op][item.hash];
          }

          // No updates took place, just add it.
          if (addItem) {
            changeList[op][item[key]] = item;
          }

          // AddItem means that something (fake or real) was added to listlocal.
          // Remove means that the faked version was removed.
          // If both are true then the faked version was replaced with the real.
          let info = {
            'addItem': addItem,
            'remove': remove
          };

          $rootScope.$emit('localItemUpdate', objectList, op, item, initKey, info);
        },

        // Returns a display appropriate list of items. Excluding recently deleted
        // and including recently created items even if they aren't in the
        // normal search results.
        getChangeList: function (objectList, items, type = false, key = 'id', scope = false, ascending = true) {
          this.prepScopeVar(objectList, scope);

          var lists = {
            add: {},
            remove: {}
          };
          angular.forEach(listDirectionMapping, function(direction, listName) {
            let directionName = (direction) ? 'add' : 'remove';
            lists[directionName][listName] = functions.getChanges(objectList, listName, scope);
          });
          // // Get the lists of created and deleted items.
          // let deleted = this.getChanges(objectList, 'deleted', scope);
          // let created = this.getChanges(objectList, 'created', scope);

          // Handle if the item list was orinially empty.
          if (typeof items == 'undefined') {
            items = [];
          }

          // Array's and objects are handled differently.
          // TODO: Abstract this to be less repetitive.
          if (items instanceof Array) {

            // Create an id map and a hash map to compare against.
            let itemMap = items.map(entity => entity[key]);
            let hashMap = items.map(entity => entity.hash);

            // Check each item id against the deleted list to remove them.
            angular.forEach(lists.remove, function(removeList) {
              let deleteIndex = -1;
              for (let i = 0; i < removeList.length; i++) {
                deleteIndex = itemMap.indexOf(removeList[i]);
                if (deleteIndex != -1) {
                  items.splice(deleteIndex, 1);
                }
              }
            });
            // Then check the hash map against the created list to see if any new
            // items are in the list to ensure duplicates aren't displayed.
            angular.forEach(lists.add, function(addList) {
              angular.forEach(addList, function (item, id) {
                if ((type === false || item.type == type) && typeof items[id] == 'undefined') {
                  let hashIndex = hashMap.indexOf(item.hash);

                  // If the hash was found, then sync it up.
                  if (hashIndex != -1) {
                    addList[id] = items[hashIndex];
                  }
                  // Otherwise add it to the list.
                  else if (itemMap.indexOf(id) == -1) {
                    if (ascending) {
                      items.push(item);
                    }
                    else {
                      items.unshift(item);
                    }
                  }
                }
              });
            });
          }
          else {
            angular.forEach(lists.remove, function(removeList) {
              // Delete any items that are in the deleted list.
              for (let i = 0; i < removeList.length; i++) {
                if (items.hasOwnProperty(removeList[i])) {
                  delete items[removeList[i]];
                }
              }
            });

            angular.forEach(lists.add, function(addList) {
              // Add all items from the created list, so long as they match the
              // type specified and aren't already in the listlist of items.
              angular.forEach(addList, function (item, id) {
                if (items.hasOwnProperty(item.hash)) {
                  delete items[item.hash];
                }
                if ((type === false || item.type == type) && typeof items[id] == 'undefined') {
                  items[id] = item;
                }
              });
            });
          }
          return items;
        },
        clearList: function (objectList, op, scope = false) {
          let changeLists = this.prepScopeVar(objectList, scope);

          changeLists[op] = {};
        },
        cleanList: function (items, scope = false) {
          if (typeof items != 'undefined' && items.length > 0) {
            angular.forEach(items, function (nid, index) {
              if (isNaN(nid)) {
                items.splice(index, 1);
              }
            });
          }
          return items;
        }
      };

      return functions;
    }
  ]);

})();
