(function() {
  'use strict';

  var app = angular.module('inlineEdit');

  app.controller('inlineWidgetBooleanBase', [
    '$scope',
    '$controller',
    '$element',
    '$attrs',
    function($scope, $controller, $element, $attrs) {
      $scope.closeOnUpdate = false;
      $scope.allowMultiple = false;
      $scope.preProcessValue = function(value) {
        value = Number(value);
        if (value) {
          return true;
        }
        else {
          return false;
        }
      }
      angular.extend(this, $controller('inlineWidgetCtrl', { $scope: $scope, $element: $element, $attrs: $attrs }));
    }
  ]);

  app.directive('inlineWidgetBoolean', function() {
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&?',
        modelVal: '=?',
        modelKey: '=?',
        inputType: '@',
        immediate: '=?',
        context: '@?'
      },
      templateUrl: 'app/src/components/inline-edit/widgets/boolean/templates/boolean.html',
      controller: 'inlineWidgetBooleanBase'
    };
  });

  app.directive('inlineWidgetMatBoolean', function() {
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&?',
        modelVal: '=?',
        modelKey: '=?',
        inputType: '@',
        immediate: '=?',
        context: '@?'
      },
      templateUrl: 'app/src/components/inline-edit/widgets/boolean/templates/boolean-mat.html',
      controller: 'inlineWidgetBooleanBase'
    };
  });

})();
