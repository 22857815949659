(function () {
  'use strict';

  let multiedit = angular.module('multiedit-service', []);

  multiedit.service('multiEditService', [
    '$rootScope',
    '$mdDialog',
    'accountService',
    'RestResource',
    'taskService',
    'taskListService',
    'listLocalService',
    'refreshService',
    function ($rootScope, $mdDialog, accountService, RestResource, taskService, taskListService, listLocalService, refreshService) {

      this.MULTI_EDIT_FLAG = 'multiEdit';
      this.MULTI_EDIT_TASK_LIST = 'multiEditTaskList';
      var self = this;
      self.vals = {
        editing: false,
        taskList: []
      };

      if ($rootScope.account && $rootScope.account.activeAccount) {
        this.initStorage();
      }
      else {
        var accountListener = $rootScope.$on('accountLoaded', function () {
          self.initStorage();
          accountListener();
        });
      }

      $rootScope.$on('taskListLoaded', function () {
        let tasklist = taskListService.getCurrentTaskList();

        let filteredArray = self.vals.taskList.filter(function(n) {
          return tasklist.indexOf(n + "") !== -1;
        });
        self.vals.taskList = filteredArray;

        $rootScope.$broadcast(self.ALTERED_MULTI_EDIT_TASK_LIST);
      });

      this.initStorage = function () {
        self.activeAccount = $rootScope.account.activeAccount;

        $rootScope.$broadcast(this.ALTERED_MULTI_EDIT_FLAG);
        $rootScope.$broadcast(this.ALTERED_MULTI_EDIT_TASK_LIST);
      };

      this.ALTERED_MULTI_EDIT_FLAG = 'alteredMultiEditFlag';
      this.ALTERED_MULTI_EDIT_TASK_LIST = 'alteredMultiEditTaskList';


      this.setMultiEdit = function () {
        self.vals.editing = true;
        $rootScope.$broadcast(this.ALTERED_MULTI_EDIT_FLAG);
      };

      this.unsetMultiEdit = function () {
        self.vals.editing = false;
        this.clearTaskIDs();
        $rootScope.$broadcast(this.ALTERED_MULTI_EDIT_FLAG);
      };

      this.isMultiEditEnabled = function () {
        return (self.vals.editing === true);
      };

      this.getTaskIDs = function () {
        let task_list = self.vals.taskList;
        if (task_list === null) {
          task_list = [];
        }
        return task_list;
      };

      this.clearTaskIDs = function () {
        self.vals.taskList = [];
        $rootScope.$broadcast(this.ALTERED_MULTI_EDIT_TASK_LIST);
      };

      this.addTaskID = function (id) {
        let task_list = self.vals.taskList;
        if (task_list === null) {
          task_list = [];
        }
        id = parseInt(id, 10);
        // Do not add a taskID already in the list
        let index = task_list.indexOf(id);
        if (index === -1) {
          task_list.push(id);
        }
        $rootScope.$broadcast(this.ALTERED_MULTI_EDIT_TASK_LIST);
      };

      this.addAllTaskIDs = function () {
        let tasklist = taskListService.getCurrentTaskList();
        for (let index = 0; index < tasklist.length; index++) {
           this.addTaskID(tasklist[index]);
        }
        $rootScope.$broadcast(this.ALTERED_MULTI_EDIT_TASK_LIST);
      };

      this.removeTaskID = function (id) {
        let task_list = self.vals.taskList;
        if (task_list === null) {
          task_list = [];
        }
        let index = task_list.indexOf(id);
        if (index > -1) {
          task_list.splice(index, 1);
        }

        $rootScope.$broadcast(this.ALTERED_MULTI_EDIT_TASK_LIST);
      };

      this.isInTaskList = function (id) {
        let task_list = self.vals.taskList;
        if (task_list === null) {
          task_list = [];
        }
        let index = task_list.indexOf(id);
        if (index > -1) {
          return true;
        }
        else {
          return false;
        }
      };

      this.openMultiEditModal = function () {
        $mdDialog.show(
          $mdDialog.MultiEditDialog()
        );
      };

      this.prepFieldVals = function(fieldValues) {
        if (typeof fieldValues !== 'object' || !(fieldValues instanceof Array)) {
          fieldValues = [fieldValues];
        }
        return fieldValues;
      }

      this.genTaskFieldData = function (fieldName, fieldValues) {
        fieldValues = this.prepFieldVals(fieldValues);
        let data = taskService.generateTaskFieldUpdateValues(fieldName, fieldValues);
        return data;
      };

      this.genLocalTaskFieldData = function (fieldName, fieldValues) {
        fieldValues = this.prepFieldVals(fieldValues);
        let data = taskService.generateLocalTaskFieldUpdateValue(fieldName, fieldValues);
        return data;
      };

      this.updateRemoteTasks = function (data) {
        return RestResource.entityFieldUpdate(data).then(function(response) {
          refreshService.appRefresh();
        });
      };

      this.updateLocalTasks = function (data) {
        data.forEach(function (task) {
          taskListService.updateTaskVals(task.nid, task);
        });
      };

      this.deleteTasks = function (taskList) {
        let request = [];
        taskList.forEach(function (taskId) {
          let task = taskListService.getTaskById(taskId);

          // Emit a taskUpdate event for each task being deleted.
          taskService.emitTaskUpdate(task, {}, 'deleted');

          // Forcibly remove the task from the tasklist.
          // TODO: Should this pass the immediate parameter?
          taskListService.removeTask(taskId);
          let data = {
            entity_type: 'node',
            entity_bundle: 'task',
            entity_id: taskId,
            fields: {
              field_entity_state: [{value: 'deleted'}]
            },
            action: 'delete'
          };
          request.push(data);
        });
        this.updateRemoteTasks(request);
      };

      this.getAccountMembers = function () {
        return accountService.getAccount().then(function(account) {
          return account.members;
        });
      };

      this.getAccountPriorities = function () {
        return accountService.getAccount().then(function(account) {
          return account.priorities;
        });
      };

      this.getAccountStatuses = function () {
        return accountService.getAccount().then(function(account) {
          return account.statuses;
        });
      };

      this.getAccountTags = function () {
        return accountService.getAccount().then(function(account) {
          // Reset tags ids to ints (vs strings) for merging with values that
          // are loaded from ElasticSearch.
          // Note: this does actually update the values in the account object
          // which should be interchangable at all other levels.
          let tags = [];
          angular.forEach(account.tags, function(tag) {
            tag.id = Number(tag.id);
            tags.push(tag);
          });

          return tags;
        });
      };

      this.getAccountProjects = function () {
        return accountService.getAccount().then(function(account) {
          return account.projects;
        });
      };
      this.getCustomFields = function () {
        let account = accountService.getAccountValue();
        return account.custom_fields;
      };
    }
  ]);
})();
