(function () {
  'use strict';

  var app = angular.module('resource', ['angular-drupal', 'user', 'hm.readmore', 'account']);

  app.directive('resourceCard', function () {
    return {
      scope: {
        resourceId: '='
      },
      controller: [
        '$scope',
        '$sce',
        'taskService',
        function ($scope, $sce, taskService) {

          taskService.resourceLoad($scope).then(function (resource) {

            $scope.description = $sce.trustAsHtml(resource.field_description);
            $scope.download_url = resource.field_file_url.uri;
            $scope.file_type = resource.field_file_type;
            $scope.external_url = resource.field_external_url.uri;
            $scope.resource_image = resource.field_image;

            $scope.readmore = {
              "character_limit": 140,
              "more_text": "Read More",
              "less_text": "Read Less",
              "link_class": "readmore-link",
              "dots_class": "readmore-dots"
            };

            $scope.resource = resource;
            $scope.$apply();
          });
        }
      ],
      templateUrl: 'app/src/resource/resource.html'
    };
  });

  app.directive('resourceForm', function () {
    return {
      scope: {
        resource: '='
      },
      controller: [
        '$scope',
        'RestResource',
        'accountService',
        function ($scope, RestResource, accountService) {
          var $modal = $scope.$parent;
          $scope.resource_items = {};

          $scope.init = function () {
            RestResource.loadResources().then(function (result) {
              accountService.addLowercase(result, 'title');

              $scope.resource_items = result;
              $scope.$digest();
            });
          };
          $scope.save = function () {
            $modal.node.field_dm_resource = $scope.resource.id;
            $modal.resourceForm.save();
          };

        }
      ],
      templateUrl: 'app/src/resource/resource-form.html'
    };
  });
})();