(function () {
  'use strict';

  angular.module('search', ['rest'])
    .service('searchService', [
      '$rootScope',
      'commonService',
      'accountService',
      'RestResource',
      'customFieldService',
      '$timeout',
      function ($rootScope, commonService, accountService, RestResource, customFieldService, $timeout) {
        this.searches = [];
        this.defaultSearch = false;
        this.taskGroups = {};
        this.defaultOffset = 0;
        this.defaultLimit = 10;
        this.defaultScroll = 10;
        var self = this;

        // Returns a list of searches that should be used for performing the
        // search.
        this.getSearches = function() {
          return this.searches;
        };

        // Resets searches and initializes default searches.
        this.setDefaultSearch = function(initial) {
          this.resetSearches();
          let search = {
            offset: 0,
            limit: initial
          };
          this.defaultSearch = true;
          this.searches.push(search);
        }

        // Set the default limit on new searches.
        this.setDefaultLimit = function(limit) {
          this.defaultLimit = limit;
        };

        // Returns the seaches array and resets custom searches.
        this.getDefaultSearch = function(initial = 15) {
          // Check if non-default searches have been initialized.
          if (!this.defaultSearch) {
            // If they have, then reset searches with the default search.
            this.setDefaultSearch(initial);
          }

          // Return the default search only.
          return this.searches[0];
        };

        // Reset the searches array.
        this.resetSearches = function() {
          this.searches = [];
        };

        // Adds a new search for a layout cell and adds it to the searches.
        this.createNewSearch = function(layoutItem) {
          // Flag that non-default searches are now in effect.
          this.defaultSearch = false;

          let searches = this.searches;

          // Create a new search object.
          let search = {
            id: layoutItem.id,
            field: layoutItem.field,
            key: layoutItem.key,
            offset: layoutItem.offset || this.defaultOffset,
            limit: layoutItem.limit || this.defaultLimit,
            aggregations: this.getAggregations,
            filters: {}
          };

          // Define the specific filter for this search.
          // @TODO: Assumes only one filter criteria and totally ignores
          // the concept of grouping.
          search.filters[layoutItem.field] = [layoutItem.id];

          // And add the search to the list of searches.
          searches.push(search);
        };

        // Returns a list of all task id arrays keyed by primary search id.
        this.getTaskGroups = function() {
          return this.taskGroups;
        };

        // Iterates over al lists and re-sets the tasks for all groups.
        this.updateTaskGroups = function(lists) {
          angular.forEach(lists, function(list) {
            this.taskGroups[list.key] = list.tasks;
          }, this);
        };


        // Gets the list of task ids by primary search id.
        this.getTaskGroupById = function(id) {
          if (!this.taskGroups.hasOwnProperty(id)) {
            // Instantiate the list if necessary.
            this.taskGroups[id] = [];
          }
          return this.taskGroups[id];
        };

        // Returns the search request object given a primary field/id pair.
        this.getSearchById = function(field, id) {
          let key = field + ":" + id;
          return this.getSearchByKey(key);
        };

        // Returns the search request object given the key.
        this.getSearchByKey = function(key) {
          let search = commonService.getObjectById(this.searches, key, 'key');
          if (!commonService.empty(search)) {
            return search;
          }
          else {
            return [];
          }
        };

        this.getSearchByOptions = function(options = {}) {
          let search = {};
          // As long as there are options, start adjusting based on them.
          if (!commonService.empty(options)) {
            if (!commonService.empty(options.key) ) {
              search = this.getSearchByKey(options.key);
            }
            else if (!commonService.empty(options.field) && !commonService.empty(options.id)) {
              search = this.getSearchById(options.field, options.id);
            }
            // Failsafe.
            if (search == []) {
              search = this.getDefaultSearch();
            }

            let scroll = options.scroll || this.defaultScroll;
            search.limit += scroll
          }

          return [search];
        };

        this.getAggregations = function() {
          let aggregations = {};
          let account = accountService.getAccountValue();
          if (account) {
            let customFields = account.custom_fields;
            if (customFields) {
              aggregations.task_custom_value = {};
              angular.forEach(customFields, function(field) {
                if (customFieldService.fieldIsAggregatable(field)) {
                  aggregations.task_custom_value[field.id] = field.bundle;
                }
              });
            }
          }
          return aggregations;
        };

        // Performs the actual search, given a full request object.
        this.taskSearch = function(request, type) {
          return new Promise(function (resolve, reject) {
            RestResource.loadTaskList(request, type).then(function(result) {
              // Save task groupings for later.
              if (result.hasOwnProperty('task_groups') && !commonService.empty(result.task_groups)) {
                Object.assign(self.taskGroups, result.task_groups);
              }
              resolve(result);
            });
          });
        };

        this.messageSearch = function(request, type) {
          return new Promise(function (resolve, reject) {
            RestResource.loadMessages(request).then(function(result) {
              resolve(result);
            });
          });
        }

      }
    ]);
})();
