(function () {
  'use strict';

  var app = angular.module('messages', ['message-service']);

  app.directive('messageList', function () {
    return {
      scope: {
        type: '=?',
        eid: '=?',
        limit: '=?'
      },
      controller: [
        '$scope',
        '$rootScope',
        '$timeout',
        'messageListService',
        'entityMessageService',
        'taskListService',
        'layoutService',
        'searchService',
        'commonService',
        function ($scope, $rootScope, $timeout, messageListService, entityMessageService, taskListService, layoutService, searchService, commonService) {
          $scope.pageReady = false;
          $scope.loadReady = true;

          if (!$scope.limit) {
            $scope.limit = 20;
          }
          searchService.setDefaultLimit($scope.limit);

          $scope.listService = messageListService;
          if ($scope.type && $scope.eid) {
            $scope.listService = entityMessageService;
          }

          // Load the messages and, once complete, flag the message list as ready.
          $scope.loadMessages = function() {
            // Update activity grouping lists (one for now) to hide the loader.
            $scope.lists.forEach(function(val, index) {
              val.showLoader = false;
            });

            // Load the full message id list.
            $timeout(function() {
              $scope.messages  = $scope.listService.getCurrentMessageList();
              $scope.pageReady = true;
            });
          }

          // Before messages can be loaded, make sure the account and tasklist
          // have been loaded.
          $scope.init = function() {
            if ($scope.loadReady) {
              $scope.loadReady = false;

              // This is a fake list that would ideally be loaded from the layout
              // service. Faked here because the message service doesn't support
              // layouts.
              // @TODO: Use the layout service to get these lists.
              $scope.lists = [{
                'field': '',
                'id': '',
                'key': '0',
                'title': 'No Grouping',
                'limit': 50,
              }];
              $scope.listService.getMessages().then(function() {
                $scope.loadReady = true;
                $scope.loadMessages();
              });
            }
          }

          // Initialize the message list.
          $scope.init();

            // List of situations where a scroll should be avoided.
          $scope.preventScroll = function(list, ignoreGroup) {
            let prevent = false;

            let messageList = $scope.listService.getEntityList();
            // With multiple lists, it doesn't make sense to scroll as it's very
            // difficult to get to the next list.
            if (!ignoreGroup) {
              if (!$scope.lists || $scope.lists.length != 1) {
                prevent = true;
              }
            }
            else if (!$scope.lists || $scope.lists.length == 1) {
              prevent = true;
            }

            // If everything has already been shown, don't keep trying to load more.
            // If there are 0 tasks, that indicates a load is in progress.
            // Or if the list column already contains the full number of tasks.
            if (!messageList || !messageList.entityList || messageList.entityList.length < 1 || messageList.count <= messageList.entityList.length) {
              prevent = true;
            }
            // If tasklist isn't ready, or if dragging or updating is in progress.
            if (!$scope.listService.isInitted() || $scope.pauseScroll) {
              prevent = true;
            }

            // If the list has all the items (allowed) stop trying to scroll.
            if (messageList.entityList.length >= messageList.entityList.count) {
              prevent = true;
            }

            return prevent;
          };

          // Trigger a load for more tasks on scroll events.
          $scope.infiniScroll = function(list) {
            if (!$scope.preventScroll()) {

              // Show loader  until there's a response.
              list.showLoader = true;

              let options = {
                key: list.key
              };
              $scope.listService.refresh('loadmore', options);
            }
          };

          // Add a listener for when messages are loaded to re-digest them.
          // @TODO: Ideally this would work in tandem with the layoutService.
          let messageListener = $rootScope.$on($scope.listService.eventName, $scope.loadMessages);
          $rootScope.addListener(messageListener, $scope);

          // Any time an app refresh/digest or filter update occurs, attempt
          let filterListener = $rootScope.$on('filterUpdate', function () {
            // If an app refresh happens before the messages have finished
            // loading, don't do anything.
            if ($scope.pageReady) {
              $scope.init();
            }
          });
          $rootScope.addListener(filterListener, $scope);

          // When scope is destroyed, also destroy all its listeners.
          $scope.$on('$destroy', function () {
            $rootScope.destroyListeners($scope);
          });

        }
      ],
      templateUrl: 'app/src/components/message/message-list.html'
    };
  });

})();
