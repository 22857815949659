(function () {
  'use strict';
  var app = angular.module('todo', ['angular-drupal', 'ngMaterial', 'as.sortable', 'ngMessages', 'misc', 'xeditable']);

  app.directive('todos', function () {
    return {
      scope: {
        eid: '=',
        node: '='
      },
      link: function (scope, element, attrs) {
        // Check if this list is a newly created list.
        // The top level task item is what contains that reference.
        if (scope.eid == scope.$parent.$parent.$parent.activeList) {
          // Focus the todo item creation form on the new list.
          $(element[0].lastChild).find('input').focus();

          // And unset the active list identifier.
          scope.$parent.$parent.$parent.activeList = false;
        }
      },
      controller: 'TodoCtrl',
      templateUrl: 'app/src/todo/todo.html'
    };
  });

  app.controller(
    'TodoCtrl', [
      '$scope',
      'listLocalService',
      '$rootScope',
      'RestResource',
      'taskListService',
      'refreshService',
      'commonService',
      '$mdDialog',
      '$timeout',
      function ($scope, listLocalService, $rootScope, RestResource, taskListService, refreshService, commonService, $mdDialog, $timeout) {
        var $modal = $scope.$parent.$parent;
        $scope.todoItemShow = false;
        $scope.editingList = false;
        $scope.editingItem = false;
        $scope.loaded = false;
        $scope.xEFlistName;
        $scope.inputFocus = function () {
          $scope.todoItemShow = true;
        };
        $scope.inputBlur = function () {
          $scope.todoItemShow = false;
        };
        $scope.inputClear = function () {
          $scope.newTodo.name = '';
          $scope.inputBlur();
        };

        // Only show active checklists.
        $scope.showTodoList = function() {
          let hiddenStates = ['deleted', 'archived', 'trashed'];
          return (!hiddenStates.includes($scope.todolist.field_entity_state));
        };

        $scope.editStart = function (type) {
          if (type == 'todolist') {
            $scope.editingList = true;
          }
          else if (type == 'todo_item') {
            $scope.editingItem = true;
          }
        };
        $scope.editEnd = function (type) {
          if (type == 'todolist') {
            $scope.editingList = false;
          }
          else if (type == 'todo_item') {
            $scope.editingItem = false;
          }
        };
        $scope.buttonsShow = function () {
          return $scope.newTodo.name || $scope.todoItemShow;
        };

        $scope.addListener = function (callback) {
          $rootScope.addListener(callback, $scope);
          $rootScope.addListener(callback, $scope.$parent.$parent);
        };

        $scope.replicateTodoList = function () {
          RestResource.eckEntityReplicate('todo', 'todolist', $scope.eid).then(function (cloned) {
            let task = $scope.node;
            let todolists = task.field_todolist;
            todolists.push(cloned.id);
            taskListService.updateTaskVals(task.nid, task);
            var data = {
              '__processing': true
            };
            RestResource.eckEntityGet('todo', 'todolist', cloned.id, data).then(function (todo) {
              $rootScope.$emit('appRefresh');
            });
          });
        };
        $scope.deleteTodoList = function () {

          // Create the confirmation dialoge.
          var confirm = $mdDialog.confirm()
            .multiple(true)
            .title('Are you sure you want to delete this checklist?')
            .textContent("This will also delete all subitems and prevent it from being used as a template.")
            .ariaLabel('Delete checklist')
            .ok('Delete Checklist')
            .cancel('Cancel');

          // And actually show it.
          $mdDialog.show(confirm).then(function () {
            // Grab the node and todolist.
            let task = $scope.node;
            let todolists = task.field_todolist;

            // And attempt to remove the todolist from the node.
            // TODO: With todo using field_entity_state this might be extra.
            let removeIndex = todolists.indexOf($scope.eid);
            if (removeIndex !== -1) {
              todolists.splice(removeIndex, 1);
            }

            // Loop through all of the todo items in the list and adjust
            // the node todo counts.
            angular.forEach($scope.todolist.todo_items, function (value) {
              task.todo_count_total--;
              if (value.complete) {
                task.todo_count_completed--;
              }
            });

            taskListService.updateTaskVals(task.nid, task);
            let tid = $scope.eid;
            $scope.eid = false;
            RestResource.eckEntityDelete('todo', 'todolist', tid).then(function () {
              // $rootScope.$emit('appRefresh');
              refreshService.appRefresh(true);
            });
          });
        };

        $scope.todoMenu = {
          duplicate: {
            title: 'Duplicate',
            callback: $scope.replicateTodoList
          },
          deleteTodo: {
            title: 'Delete',
            callback: $scope.deleteTodoList
          }
        };

        $scope.init = function () {
          if (!$scope.loaded) {
            $scope.loaded = true;
            // $scope.todolist = {};
            // $scope.todolist.items = [];
            // $scope.todolist.id = $scope.eid;
            if ($scope.eid) {
              $scope.newTodo = {
                name: ""
              };
              $scope.newTodo.form = {};
              // $scope.loadTodoList();
              $scope.loadTodos();
            }
          }
        };

        $scope.loadTodos = function () {
          if ($scope.eid && $scope.node.task_details) {
            var node = $scope.node;
            var todolists = {};
            if (node.task_details.todolists && Object.keys(node.task_details.todolists).length > 0) {
              todolists = node.task_details.todolists;
            }

            // Get local changes to todolists for this task.
            listLocalService.getChangeList('todolists', todolists, false, 'id', $modal.$parent.$parent);

            // Make sure there are actually todolists to process.
            if (Object.keys(todolists).length > 0) {

              // Get the full todolist object from the node.
              if (todolists.hasOwnProperty($scope.eid)) {
                let todolist = todolists[$scope.eid];

                // Check for any local changes within the todolist itself.
                listLocalService.getChangeList('todos', todolist.todo_items, false, 'id', $modal.$parent.$parent);

                // Only proceed if there are actually items in the list.
                if (todolist.todo_items.length > 0) {
                  // $scope.todolist.items = [];

                  // Place cards in their corresponding lists.
                  for (var i = 0; i < todolist.todo_items.length; i++) {
                    var todo = todolist.todo_items[i];

                    // Strings don't compare properly, so convert to a number.
                    if (typeof todo.field_todo_state == 'string') {
                      todo.field_todo_state = Number(todo.field_todo_state);
                    }

                    // Set the complte property to a bool based on the value.
                    todo.complete = (todo.field_todo_state === 1) ? false : true;
                  }
                }

                // Don't update the title if the list is currently being edited.
                if (todolist.editing != true) {
                  todolist.title = todolist.parent_title;
                }
                todolist.id = todolist.parent_id;

                $scope.todolist = todolist;
              }
            }
            $modal.ready();
          }
        };

        $scope.init();
        var listenerHandler = function () {
          $timeout(function () {
            if ($scope.editingItem === false) {
              $scope.loadTodos();
            }
          });
        };
        let refreshListener = $rootScope.$on('appRefresh', listenerHandler);
        let digestListener = $rootScope.$on('appDigest', listenerHandler);
        $scope.addListener(refreshListener);
        $scope.addListener(digestListener);

        // Add an individual destruction listener to ensure that deleted todolists
        // aren't loaded on refreshes.
        $scope.$on('$destroy', function () {
          $rootScope.destroyListeners($scope);
        });

        // TODO: This doesn't properly properly store the new todo item against the
        // list. This results in subsequent changes to the list to drop the todo item
        // from the list (server side).
        $scope.addTodo = function (type) {
          if ($scope.newTodo.name && !isNaN($scope.eid)) {
            var newTodo = {
              title: $scope.newTodo.name,
              field_todo_state: 1,
              complete: false
            };

            var hash = commonService.sdbmHash(JSON.stringify(newTodo));
            newTodo.hash = hash;

            listLocalService.addChanges('todolists', 'created', newTodo, 'id', $modal);

            $scope.todolist.todo_items.push(newTodo);

            if ($scope.node && $scope.node.hasOwnProperty('todo_count_total')) {
              let count = $scope.node.todo_count_total;
              count = Number(count);
              count++;
              $scope.node.todo_count_total = count;
            }

            // Blank out the form.
            $scope.newTodo.name = "";

            var request = {
              title: newTodo.title,
              field_todo_state: 1, // Open
              __attach: {
                entity_id: $scope.eid
              }
            };

            RestResource.eckEntityCreate('todo', type, request).then(function (res) {
              newTodo.id = res.id;
              listLocalService.addChanges('todolists', 'created', newTodo, 'id', $modal);
              refreshService.appRefresh(true);
            });
          }
        };

        $scope.deleteTodo = function (item) {
          var index = $scope.todolist.todo_items.indexOf(item);
          $scope.todolist.todo_items.splice(index, 1);

          var request = {
            id: item.id
          };
          RestResource.eckEntityDelete('todo', 'todo_item', item.id, request).then(function (res) {
            refreshService.appRefresh(true);
          });
        };
        $scope.updateTodoStatus = function (item) {
          // Invert the state since state and complete are inversely related.
          var state = (item.complete) ? 0 : 1;

          if ($scope.node && $scope.node.hasOwnProperty('todo_count_completed')) {
            let count = $scope.node.todo_count_completed;
            count = Number(count);
            if (item.complete) {
              count++;
            }
            else {
              count--;
            }
            $scope.node.todo_count_completed = count;
          }

          item.field_todo_state = state;

          var request = {
            "entity_type": "todo",
            "entity_bundle": 'todo_item',
            "entity_id": item.id,
            "fields": {
              "field_todo_state": state,
            }
          };
          refreshService.appRefresh(true);
          RestResource.entityFieldUpdate([request]);

        };

        var buildUpdateRequest = function (list) {
          var referenceList = [];
          list.todolist.todo_items.forEach(function (todo, index) {
            var referenceItem = {};
            referenceItem.target_id = todo.id;
            referenceList.push(referenceItem);
          });

          // Return an object to be saved.
          return {
            "entity_type": "todo",
            "entity_bundle": "todolist",
            "entity_id": list.eid,
            "fields": {
              "field_todo_items": referenceList,
            }
          };
        };

        // Both order and list changes require the same processing.
        var processOrderChange = function (event) {
          // Grab the old and new lists (same in the case of reorders).
          var old_list = event.source.sortableScope.$parent;
          var new_list = event.dest.sortableScope.$parent;

          // Perform the updates on all todolists that have changed.
          var request = [];
          request.push(buildUpdateRequest(new_list));

          if (old_list.eid != new_list.eid) {
            request.push(buildUpdateRequest(old_list));
          }

          // Process all updates in one request.
          if (request.length > 0) {
            RestResource.entityFieldUpdate(request);
          }
        };

        $scope.todoSortable = {
          containment: '#field_todolist',
          containerPositioning: 'relative',
          orderChanged: processOrderChange,
          itemMoved: processOrderChange,
          longTouch: true
        };
      }
    ]);

  // Edit in place for todos.
  app.directive('editInPlace', function () {
    return {
      restrict: 'E',
      scope: {
        // value: '=',
        entity: '=',
        bundle: '='
      },
      template: '<span class="todoName" ng-click="edit()" ng-bind="entity.title"></span><input class="todoField" ng-model="entity.title" ng-enter="save()" ng-blur="save()"></input>',
      controller: 'InPlaceEditController'
    };
  });

  // Controller for todo item inline editing.
  app.controller('InPlaceEditController', ['$scope', '$element', 'RestResource', 'taskListService', function ($scope, $element, RestResource, taskListService) {
    // Shortcut to the input.
    var inputElement = angular.element($element.children()[1]);
    if (!$scope.entity) {
      $scope.entity = {};
    }
    $scope.entity.editing = false;
    $scope.entity.saving = false;

    // Initially, state is display only.
    // $scope.editing = false;
    $element.addClass('edit-in-place');

    // ngClick handler to activate edit-in-place.
    $scope.edit = function () {
      // Flag this todo as being edited.
      $scope.entity.editing = true;

      $scope.$parent.editStart($scope.bundle);
      // Display is just controlled through CSS.
      $element.addClass('active');
      // Focus on the input element.
      inputElement.focus();
    };

    // Push result up to server (blur or enter).
    $scope.save = function () {
      let todo = $scope.entity;

      // Not editing anymore.
      todo.editing = false;

      // When a save is triggered by pressing enter, it causes a save and a blur
      // which also causes a save. The first save goes through, the second
      // fails. This prevents two saves from occurring at the same time.
      if (!todo.saving) {

        // Indicate that a save is in process.
        todo.saving = true;

        // Todolists should be set against the node now, as they are regularly
        // refreshed from the node.
        if ($scope.bundle == 'todolist') {
          let node = $scope.$parent.node;
          node.task_details.todolists[todo.id].parent_title = todo.title;
          taskListService.updateTaskVals(node.nid, node);
        }

        $scope.$parent.editEnd($scope.bundle);
        $element.removeClass('active');

        RestResource.entityFieldUpdate([{
          "entity_type": "todo",
          "entity_bundle": $scope.bundle,
          "entity_id": todo.id,
          "fields": {
            "title": todo.title,
          }
        }]).then(function() {
          // The save is complete. Unlock it.
          todo.saving = false;
        });
      }
    };
  }]);
})();
