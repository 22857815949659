(function () {
  'use strict';

  angular.module('usermenu', ['angular-drupal', 'user'])
    .directive('usermenu', function () {
      return {
        scope: {},
        controller: [
          '$scope',
          'drupal',
          '$rootScope',
          '$window',
          'userService',
          'accountService',
          'commonService',
          '$mdDialog',
          'tracking',
          function ($scope, drupal, $rootScope, $window, userService, accountService, commonService, $mdDialog, tracking) {
            // Hide until connected.
            $scope.ready = false;
            $scope.isAdmin = false;
            $scope.showAccountSwitch = false;
            $scope.activeAccount = 0;
            $scope.currentAccount = '';
            $scope.root = $rootScope.root;

            $rootScope.$on('accountLoaded', function() {
              $scope.activeAccount = $rootScope.account.activeAccount;
              $scope.showAccountSwitch = ($rootScope.account.accounts.length > 1);
              let account = $rootScope.account.entity;
              if (account && account.hasOwnProperty('title')) {
                $scope.currentAccount = account.title;
              }
            });

            $scope.openMenu = function ($mdMenu, ev) {
              $mdMenu.open(ev);
            };

            $scope.logout = function () {
              commonService.clearAppStorage();
              userService.logout();
            };

            $scope.forceRefresh = function () {
              commonService.clearAppStorage();
              tracking.logEvent('warn', 'Force Refresh');
              $window.location.reload(true);
            };

            $scope.accountSettings = function () {
              $mdDialog.show(
                $mdDialog.accountSettingsModal()
              );
            };

            $scope.switchAccount = function() {
              $mdDialog.show(
                $mdDialog.switchAccountModal()
              );
            };

            $scope.init = function () {
              $scope.user = accountService.getAccountMember();
              var user = $scope.user;

              if (typeof user != 'undefined') {
                $scope.path = AppConfig.externalEndpointUrl;
                $scope.uid = user.uid;
                $scope.name = user.display_name;
                $scope.ready = true;

                // Is this user an admin?
                $scope.memberUser = accountService.getAccountMember();
                $scope.isAdmin = false;
                if (typeof $scope.memberUser != 'undefined') {
                  $scope.isAdmin = $scope.memberUser.group_roles == 'account-admin';
                }
              }
            };

            accountService.getAccount().then(function(account) {
              $scope.init();
            });
          }
        ],
        templateUrl: 'app/src/components/usermenu/usermenu.html'
      };
    });

})();
