(function () {
  'use strict';

  angular.module('table', [])

  .controller('tableCtrl', [
      '$scope',
      '$rootScope',
      '$routeParams',
      'filterService',
      'taskListService',
      'layoutService',
      'searchService',
      '$timeout',
      'multiEditService',
      'routingService',
      function ($scope, $rootScope, $routeParams, filterService, taskListService, layoutService, searchService, $timeout, multiEditService, routingService) {
        $scope.root.pageReady = false;
        $scope.noProject = false;
        let filtering = filterService.getFiltering();

        routingService.viewInit();

        // @TODO: Sorting should probably be app wide. Not just a variable
        // slapped in.
        $scope.query = {
          order: 'title',
        };

        // No grouping here.
        // layoutService.setLayout(0);
        // Show 20 rows, since they're not as tall as cards.
        searchService.setDefaultLimit(20);

        // Just keeps track of project status.
        $scope.setupColumns = function() {
          if (typeof filtering.selection.project === 'undefined' || filtering.selection.project.length != 1) {
            $scope.noProject = true;
          }
          else {
            $scope.noProject = false;
          }

          $scope.tableClass = {
            'all-projects': $scope.noProject,
            'project-active': !$scope.noProject
          };
        }

        $scope.showAggregationRow = function() {
          let tasklist = taskListService.getTaskList();
          let showAgg = false;
          $scope.aggregations = tasklist.aggregations;

          // Only show the row if there are number type rows, as that's the
          // only aggregation currently supported for display.
          if ($scope.aggregations && $scope.aggregations.hasOwnProperty('task_custom_value')) {
            angular.forEach($scope.aggregations.task_custom_value, function(item) {
              if (item.hasOwnProperty('task_custom_value.number')) {
                showAgg = true;
              }
            });
          }

          return showAgg;
        };

        // List of situations where a scroll should be avoided.
        $scope.preventScroll = function(list, ignoreGroup) {
          let prevent = false;

          // With multiple lists, it doesn't make sense to scroll as it's very
          // difficult to get to the next list.
          if (!ignoreGroup) {
            if (!$scope.lists || $scope.lists.length != 1) {
              prevent = true;
            }
          }
          else if (!$scope.lists || $scope.lists.length == 1) {
            prevent = true;
          }
          // If everything has already been shown, don't keep trying to load more.
          // If there are 0 tasks, that indicates a load is in progress.
          // Or if the list column already contains the full number of tasks.
          if (!list || list.tasks.length < 1 || list.count <= list.tasks.length) {
            prevent = true;
          }
          // If tasklist isn't ready, or if dragging or updating is in progress.
          if (!taskListService.isInitted() || $scope.pauseScroll) {
            prevent = true;
          }

          return prevent;
        };

        // Trigger a load for more tasks on scroll events.
        $scope.infiniScroll = function(list) {
          // Show loader for up to 5 seconds.
          list.showLoader = true;
          $timeout(function() {
            list.showLoader = false;
          }, 5000);

          let options = {
            key: list.key
          };
          taskListService.refresh('loadmore', options);
        };

        if (!$scope.hasOwnProperty('init')) {

          // Initial setup of lists once loaded from account.
          $scope.init = function () {
            layoutService.loadLayout().then(function(lists) {
              // On initial load this generally won't include tasks in the lists.
              // $scope.processTasks(lists);
              $scope.lists = lists;

              $scope.setupColumns();
            });
          };
        }

        $scope.pageReady = function () {
          $rootScope.root.pageReady = true;
          $rootScope.$emit('viewReady');
        };

        // Check with the multiEditService to see if checkboxes should display.
        $scope.isCheckboxVisible = function() {
          return multiEditService.isMultiEditEnabled();
        };

        // Check with the multiEditService to see if some items are selected.
        $scope.isIndeterminate = function() {
          let numChecked = multiEditService.getTaskIDs().length,
              numTasks = taskListService.getCurrentTaskList().length;
          return (numChecked > 0 && numChecked < numTasks);
        };

        // Check with the multiEditService to see if ALL items are selected.
        $scope.isChecked = function() {
          let numChecked = multiEditService.getTaskIDs().length,
              numTasks = taskListService.getCurrentTaskList().length;
          return numChecked == numTasks;
        };

        // Select or clear all items in multiEditService.
        $scope.toggleAll = function() {
          if ($scope.isChecked()) {
            multiEditService.clearTaskIDs();
          }
          else {
            multiEditService.addAllTaskIDs();
          }
        };

        // Helper function to provide a unique fieldname for sorting purposes.
        $scope.getSortableName = function(col) {
          let fieldName = null
          if (col.sortable) {
            fieldName = col.fieldName;
            if (col.cid) {
              fieldName += '.' + col.cid;
            }
          }

          return fieldName;
        };

        // Callback for when a sort is updated.
        $scope.sortUpdate = function(field) {

          // Default direction is acending.
          let direction = 'asc';

          // The table sort tool prepends a '-' for inverse sort.
          if (field.indexOf('-') === 0) {
            // Set the direction to descending.
            direction = 'desc';

            // And strip out the '-' so it doesn't mess up the server search.
            field = field.substr(1);
          }

          // Set the new sort against the filterService.
          filterService.setSort(field, direction);

          // @TODO: This really should be either: A) a direct re-search function
          // or B) a sort-specific event emission.

          // Emit a filterupdate so that new results are fetched.
          $rootScope.$emit('filterUpdate');
        };
        
        $scope.processTasks = function(lists) {
          if (lists) {
            $scope.lists = lists;
          }

          $timeout(function() {
            $scope.pageReady();

          });
        }

        // Updates to the layout go through the layout service.
        let layoutListener = $rootScope.$on('layoutUpdate', function (event, lists) {
          $scope.pauseScroll = false;
          // This adds tasks and/or task updates to the board.
          $scope.processTasks(lists);
        });
        $rootScope.addListener(layoutListener, $scope);

        // Implement any layout changes from app digest.
        let digestListener = $rootScope.$on('appDigest', function (event, args) {
          $scope.processTasks();
        });
        $rootScope.addListener(digestListener, $scope);

        // Implement any column changes from filter service.
        let filterListener = $rootScope.$on('filterUpdate', function (event, args) {
          $scope.setupColumns();
        });
        $rootScope.addListener(filterListener, $scope);

        $scope.$on('$destroy', function () {
          $rootScope.destroyListeners($scope);
        });
      }
    ]);

})();
