(function() {
'use strict';

angular.module('swimlane', ['angular-drupal', 'task', 'ngRoute', 'chart-service'])

.config(['$routeProvider', function ($routeProvider) {
  $routeProvider.when('/:accountId/projects/:projectId/swimlane/:taskId?', {
    templateUrl: 'app/src/charts/swimlane.html',
    controller: 'SwimlaneChartCtrl',
    activetab: 'swimlane',
    reloadOnSearch: false
  });
}])

.controller('SwimlaneChartCtrl', [
  '$scope',
  '$rootScope',
  'accountService',
  'layoutService',
  'searchService',
  'taskListService',
  'taskService',
  'filterService',
  'chartService',
  '$timeout',
  function($scope, $rootScope, accountService, layoutService, searchService, taskListService, taskService, filterService, chartService, $timeout) {
    $scope.initted = false;
    $scope.chart = false;

    searchService.setDefaultLimit(400);

    // Initial setup of lists once loaded from account.
    $scope.init = function () {
      // Sort tasks by newest first.
      filterService.setSort('field_date', 'asc');


      // Get the overall filtering object so that custom date ranges can be
      // set for this view.
      $scope.filtering = filterService.getFiltering();
      let start = null;
      let end = null;
      if ($scope.filtering.hasOwnProperty('selection')) {

        // If there isn't already a date filter set, then override it with the
        // default date range.
        // @TODO: Should existing date filters be ignored here?
        let sel = $scope.filtering.selection;
        if (!sel.start && !sel.end) {
          sel.start = moment().utc().subtract(6, 'month').toDate();
          sel.end = moment().utc().add(6, 'months').toDate();
        }
        start = sel.start;
        end = sel.end;
      }

      // Wait for the layout to be loaded before initializing the chart.
      layoutService.loadLayout().then(function(lists) {

        // Create a new instance of the chart service.
        $scope.chartService = new chartService(start, end, 'categories', $scope, 'swimlane-view');

        // Perform all of the basic initialization for the chart.
        $scope.chartService.initChart();

        // Finally, load all of the tasks and insert them into the chart.
        $scope.chartService.loadData(lists, 'field_task_owner');
        $scope.initted = true;

        $timeout(function() {
          $scope.readyClass = 'data-ready';
        }, 1000,() => {
          $rootScope.$emit('viewReady');
        });
      });

    };

    // Kick off the actual init.
    $scope.init();
    // Updates to the layout go through the layout service.
    var layoutListener = $rootScope.$on('layoutUpdate', function (event, lists) {

      // Don't try to refresh if the chart hasn't initted or is dragging.
      if ($scope.initted && !$scope.chartService.dragging) {

        // This adds tasks and/or task updates to the chart.
        $scope.chartService.loadData(lists, 'field_task_owner');
      }
    });

    // Add scope listeners to be destroyed once the display is changed.
    $rootScope.addListener(layoutListener, $scope);
    $scope.$on('$destroy', function () {
      $rootScope.destroyListeners($scope);
    });
  }
]);

})();
