(function () {
  'use strict';
  angular.module('customfield')
    .directive('customField', () => ({
      scope: {},
      controller: [
        '$scope',
        '$rootScope',
        'commonService',
        'accountService',
        'customFieldService',
        'listLocalService',
        function ($scope, $rootScope, commonService,
          accountService,
          customFieldService, listLocalService
        ) {
          $scope.customFieldForm = function () {
            customFieldService.OpenCustomFieldForm();
          };

          accountService.getAccount().then((account) => {
            $scope.account = account;
            $scope.fields = $scope.account.custom_fields;

            // initial load of custom field.
            $scope.loadCustomField();
          });

          $scope.loadCustomField = function () {

            // Get local changes to custom fields.
            listLocalService.getChangeList('custom_field', $scope.fields, false, 'id');
          };

          $scope.getFieldLabel = function(fieldType) {
            let label = fieldType;
            let mapping = customFieldService.getFieldTypeMapping(fieldType);

            if (mapping && mapping.label) {
              label = mapping.label;
            }
            return label;
          };

          // TaskService fires this on just about every update to a task.

          // Implement any layout changes from app digest.
          const digestListener = $rootScope.$on('customFieldCreate', () => {
            $scope.loadCustomField();
          });

          $rootScope.addListener(digestListener, $scope);
          $scope.$on('$destroy', () => {
            $rootScope.destroyListeners($scope);
          });

          $scope.deleteField = function (id, bundle) {
            customFieldService.deleteCustomField(id, bundle, $scope);
          };

          $scope.editField = function (id, field) {
            field.id = id;
            // Open the Field form on edit mode.
            customFieldService.OpenCustomFieldForm(field);
          };
        },
      ],
      templateUrl: 'app/src/components/customfield/customfield.html',
    }));
}());
