(function () {
  'use strict';

  var app = angular.module('attachments', ['angular-drupal', 'user', 'file', 'ngFileUpload']);

  app.directive('attachmentList', function () {
    return {
      scope: {
        node: '='
      },
      controller: [
        '$scope',
        '$rootScope',
        '$timeout',
        '$interval',
        '$document',
        '$location',
        'accountService',
        'refreshService',
        'taskService',
        'taskListService',
        'fileService',
        'listLocalService',
        'RestResource',
        function ($scope, $rootScope, $timeout, $interval, $document, $location, accountService, refreshService, taskService, taskListService, fileService, listLocalService, RestResource) {
          $scope.deletePending = false;
          $scope.editAttachment = false;
          $scope.zipping = false;

          if ($scope.node) {
            var $modal = $scope.$parent.$parent;
            $scope.nid = $scope.node.nid;

            taskService.loadAttachments($modal, $scope.node);
            $scope.attachments = $modal.attachments;

            // TODO: Add allowances for appDigest.
            let refreshListener = $rootScope.$on('appRefresh', function (event, args) {
              taskService.loadAttachments($modal, $scope.node);
              $scope.attachments = $modal.attachments;
            });

            $rootScope.addListener(refreshListener, $scope.$parent);

            // Switch a file into edit mode.
            $scope.editFile = function(attachment) {
              attachment.editing = true;
              $scope.editAttachment = attachment;
              $scope.originalName = attachment.filename;
            };

            // Actually change the filename of a file.
            $scope.fileRename = function(fieldName, nid, value, cid) {
              let attachment = $scope.editAttachment;
              // Should not happen, since this is faked as well.
              // Just in case though, don't brick the task if not present.
              let extension = attachment.extension || 'txt';
              // Add extension back on prior to saving.
              let newName = value + '.' + extension;

              // Make sure the value actually changed.
              if ($scope.originalName !== newName) {
                attachment.filename = newName;
                let request = {
                  filename: newName,
                  __attach: {
                    field_id: 'field_attachment'
                  }
                };

                // Perform the file rename request.
                fileService.fileEntityUpdate(attachment.fid, request);
              }

              // No longer editing.
              attachment.editing = false;
              $scope.editAttachment = false;
            };

            // Callback to fetch a file from the server and download it for the
            // user automatically.
            $scope.download = function(attachment) {

              let uri = attachment.uri;

              // Check if the current protocol can be found in the uri.
              // Forces an upgrade from http, but not a downgrade from https.
              if (uri.indexOf($location.protocol()) === -1) {
                uri = uri.replace('http://', 'https://');
              }

              fileService.downloadFile(uri, attachment.filename);
            }

            $scope.afterZip = function() {
              // Wait a second before removing the throbber to account for
              // the time it takes to actually save the file.
              $timeout(function(){
                $scope.zipping = false;
              }, 1000);
            };

            $scope.downloadArchive = function() {
              $scope.zipping = true;
              fileService.taskGetArchive($scope.nid).then(function(url) {
                let filename = $scope.node.title + '-files.zip';
                fileService.downloadFile(url, filename, $scope.afterZip);
              });
            }

            // Add an event listener to the inline edit for the attachment field.
            let inlineListner = $scope.$on('inlineOnClose', function() {
              $scope.editAttachment.editing = false;
              $scope.editAttachment = false;
            });
            $rootScope.addListener(inlineListner, $scope.$parent);


            // TODO: Through rapid deletion, it's possible to get out of sync and
            // make an attempt to delete an attachment that doesn't exist.
            $scope.deleteFile = function (item) {

              // Set flags on the item and globally to indicate that a deletion
              // is occurring.
              item.pending = true;
              $scope.deletePending = true;

              let data = {
                __attach: {
                  field_id: 'field_attachment'
                }
              };

              // Send it off to the API and then refresh the front end.
              fileService.fileEntityDelete(item.fid, data).then(function (res) {
                // Instantly show the deletion.
                var index = $scope.attachments.indexOf(item);
                $scope.attachments.splice(index, 1);

                // Add the comment to the changelist.
                listLocalService.addChanges('attachments', 'deleted', item, 'fid', $modal);

                $timeout(function () {
                  let task = taskListService.getTaskById($scope.nid);
                  task.field_attachment.splice(index, 1);

                  taskListService.updateTaskVals($scope.nid, task);
                  refreshService.appRefresh();
                });

                // Reset the flag to allow deleting more files.
                $scope.deletePending = false;

                refreshService.appRefresh(true);
              });
            };

            // Define the operations for the files popmenu.
            $scope.fileOps = {
              zipFiles: {
                title: 'Download All Files',
                callback: $scope.downloadArchive
              }
            };
          }
        }
      ],
      templateUrl: 'app/src/attachments/attachments-list.html'
    };
  });

  app.directive('attachmentForm', function () {
    return {
      scope: {
        nid: '='
      },
      controller: ['$scope', 'drupal', '$rootScope', '$document', 'RestResource', 'accountService', 'refreshService', 'taskService', 'Upload', '$timeout', '$interval', 'fileService', 'taskListService', 'listLocalService', 'commonService', function ($scope, drupal, $rootScope, $document, RestResource, accountService, refreshService, taskService, Upload, $timeout, $interval, fileService, taskListService, listLocalService, commonService) {
        $scope.isUploading = false;
        $scope.uploadProgress = 0;
        $scope.progressBar = 0;


        // Main uploader. Note that this is not done with a special Upload
        // function, but base64DataUrl is used.
        $scope.upload = function (files) {
          if (files && files.length && typeof files[0] !== 'undefined') {
            Upload.base64DataUrl(files).then(function (result) {
              $scope.uploadRequest(files, result);
            });
          }
        };

        $scope.uploadRequest = function (files, result, afterRequest = false) {
          var request = {
            __attach: {
              entity_type: 'node',
              entity_bundle: 'task',
              entity_id: $scope.nid,
              field: 'field_attachment'
            },
            files: []
          };
          for (var i = 0; i < result.length; i++) {
            var fileObj = files[i];
            var file = result[i];
            // Add all files to the request.
            request.files.push({
              file_data: file,
              file_name: fileObj.name
            });
          }
          if (request.files.length) {
            $scope.startUpload();
            $timeout(function () {
              var task = taskListService.getTaskById($scope.nid);

              if (task.field_attachment.length < 1) {
                task.field_attachment = [];
              }
              for (let i = 0; i < request.files.length; i++) {
                task.field_attachment.push(i);
              }

              taskListService.updateTaskVals($scope.nid, task);


              refreshService.appRefresh();
            });

            // Send this off using custom file service.
            fileService.fileEntityCreate(request, $scope.progressTracker).then(function (res) {
              if (typeof res != 'undefined' && res.length > 0) {
                for (let i = 0; i < res.length; i++) {

                  // For attachments, we wait to display the file until the upload
                  // is complete. So we don't create a hash prior to the request.
                  // However thelistLocal logic assumes that a hash is present. A
                  // unique value is needed to ensure that it doesn't match with
                  // 'undefined' and plays nicely with multiple uploads.
                  res[i].hash = commonService.sdbmHash(JSON.stringify(res[i]));

                  // bareName and extension are not returned by create requests.
                  let filename = res[i].filename;
                  let parts = filename.split('.');
                  let extension = parts.pop();
                  let bareName = parts.join('.');
                  // No extension? Create one in case this is edited.
                  if (bareName.length < 1) {
                    bareName = extension;
                    extension = 'txt';
                  }
                  res[i].bareName = bareName;
                  res[i].extension = extension;

                  // Add the comment to the changelist.
                  listLocalService.addChanges('attachments', 'created', res[i], 'fid', $scope.$parent);

                  // TODO: This still isn't super responsive. The file takes several
                  // seconds to actually show up.
                  $scope.$parent.attachments.push(res[i]);
                }

                if (afterRequest) {
                  afterRequest(res);
                }
              }

              $scope.endUpload();

              refreshService.appRefresh();
            });
          }
        };

        // Kick off the progress bar handler.
        $scope.startUpload = function() {
          $scope.isUploading = true;
          $scope.uploadProgress = 0;
          $scope.progressBar = 1;
          $scope.uploader = $interval($scope.calcProgressBar, 100);
        }

        // Finish the progressbar calculation.
        $scope.endUpload = function() {
          $scope.isUploading = false;
          $interval.cancel($scope.uploader);
          $scope.progressBar = 100;
        }

        // Handles the actual progress bar calculation.
        $scope.calcProgressBar = function() {
          // The progress bar is split into 3 sections.
          // 0-20% is the processing that occurs before the actual upload starts.
          // 20-90% is the upload progress (where 90% means 100% uploaded).
          // 90-100% accounts for server processing time and request round trip.
          // 100% means the upload is done and has been added to the UI.

          // Very beginning as the file upload is being initialized.
          if (!$scope.uploadProgress && $scope.progressBar <= 20) {
            // Manually increment the progressbar, but max out at 20.
            $scope.progressBar++;
          }
          // The actual upload process.
          else if ($scope.uploadProgress && $scope.uploadProgress < 100) {
            // Calculate what percent on the progress bar corresponds to
            // the upload progres.
            let tempUpload = ($scope.uploadProgress * 0.7) + 20;

            // If the upload progress is further than what is shown then skip ahead.
            if (tempUpload > $scope.progressBar) {
              $scope.progressBar = tempUpload;
            }
            // Otherwise, then gradually increase the progress bar so that it
            // feels like something is happening.
            else if ($scope.progressBar <= 90) {
              // Since the uploadProgress is slower than what is being shown,
              // slow down the progressBar proportionally to how far beind the
              // upload is.
              $scope.progressBar += ($scope.uploadProgress / $scope.progressBar) / 5;
            }
          }
          // Once the upload has completed (100%), then tick the last 20% until
          // the final response comes back from the server.
          else if ($scope.uploadProgress && $scope.progressBar < 99) {
            $scope.progressBar++;
          }
        }

        // Event handler to keep track of the current upload percentage.
        $scope.progressTracker = function(percent) {
          $scope.uploadProgress = percent;
        }

        $scope.$watch('files', function () {
          $scope.upload($scope.files);
        });

        // Listen for the custom JS event from the imageUpload plugin (custom).
        $document.on('ckeFileUploaded', function(e) {
          // Get the fileTools loader from the details section of the event.
          let uploadEvent = e.originalEvent;
          let loader = uploadEvent.detail.loader;
          let editor = loader.editor;

          // Grab the file object and the base64 file data.
          let file = loader.file;
          let fileData = loader.data;

          let afterRequest = function(res) {
            // Filetools expects the URL to be set directly against the loader.
            if (res && res[0]) {
              loader.url = res[0].uri;
            }
            // Prevent an error caused by responseData not being set.
            loader.responseData = {};

            // Update the status to indicate that the upload is complete.
            loader.changeStatus('uploaded');

            // Update editor data to ensure it is passed to $scope model and
            // thus in request.
            let data = editor.getData();
            editor.setData(data);
          }

          // Perform an upload! Include the afterRequest function to pass back
          // to CKE.
          $scope.uploadRequest([file], [fileData], afterRequest);
        });

        $scope.triggerFileSelect = function () {
          $timeout(function () {
            angular.element('#fileUpload').trigger('click');
          });
        };

        $scope.filePatterns = [
          '.3dm',
          '.3ds',
          '.3g2',
          '.3gp',
          '.7z',
          '.accdb',
          '.ai',
          '.aif',
          '.asf',
          '.avi',
          '.bin',
          '.bmp',
          '.cbr',
          '.csr',
          '.css',
          '.csv',
          '.cue',
          '.dat',
          '.db',
          '.dbf',
          '.dds',
          '.deb',
          '.dmg',
          '.doc',
          '.docx',
          '.dwg',
          '.dxf',
          '.eps',
          '.flv',
          '.fnt',
          '.fon',
          '.ged',
          '.gif',
          '.gpx',
          '.gz',
          '.hqx',
          '.htm',
          '.html',
          '.iff',
          '.indd',
          '.iso',
          '.jpeg',
          '.jpg',
          '.key',
          '.keychain',
          '.kml',
          '.kmz',
          '.log',
          '.m3u',
          '.m4a',
          '.m4v',
          '.max',
          '.mdb',
          '.mdf',
          '.mid',
          '.mim',
          '.mov',
          '.mp3',
          '.mp4',
          '.mpa',
          '.mpg',
          '.msg',
          '.obj',
          '.odt',
          '.otf',
          '.pages',
          '.pct',
          '.pdb',
          '.pdf',
          '.pkg',
          '.png',
          '.pps',
          '.ppt',
          '.pptx',
          '.ps',
          '.psd',
          '.pspimage',
          '.rar',
          '.rm',
          '.rpm',
          '.rtf',
          '.sdf',
          '.sitx',
          '.sql',
          '.srt',
          '.svg',
          '.swf',
          '.tar',
          '.tar.gz',
          '.tax2016',
          '.tax2017',
          '.tex',
          '.tga',
          '.thm',
          '.tif',
          '.tiff',
          '.toast',
          '.ttf',
          '.txt',
          '.uue',
          '.vcd',
          '.vcf',
          '.vob',
          '.wav',
          '.wma',
          '.wmv',
          '.wpd',
          '.wps',
          '.xhtml',
          '.xlr',
          '.xls',
          '.xlsx',
          '.xlsm',
          '.xml',
          '.yuv',
          '.zip',
          '.zipx'
        ].join();

      }],
      templateUrl: 'app/src/attachments/attachments-form.html'
    };
  });
})();
