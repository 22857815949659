(function () {
  'use strict';

  var app = angular.module('account', [
    'angular-drupal'
  ]);

  app.service('accountService', [
    'drupal',
    'RestResource',
    'errorHandlerService',
    'commonService',
    'refreshService',
    '$rootScope',
    '$mdDialog',
    '$mdToast',
    '$window',
    '$routeParams',
    '$route',
    function (drupal, RestResource, errorHandlerService, commonService, refreshService, $rootScope, $mdDialog, $mdToast, $window, $routeParams, $route) {
      var self = this;
      self.loadCount = 1;
      self.loaded = 0;
      self.memberErrorOpen = false;
      self.vals = false;

      var openToast;

      var functions = {
        addLowercase: function (result, key) {
          key = (key) ? key : 'title';
          for (var i in result) {
            if (result[i][key]) {
              result[i]._lowercase = result[i][key].toLowerCase();
            }
          }
        },
        loaded: function () {
          return self.loaded == self.loadCount;
        },
        isLoaded: function () {
          if (functions.loaded()) {
            $rootScope.$emit('accountLoaded');
          }
        },
        getCustomFilters: function () {
          var filters = {};
          if (self.vals.entity.data && self.vals.entity.data.filters) {
            filters = Object.assign(self.vals.entity.data.filters, filters);
          }
          return filters;
        },
        init: function (storageInit = false) {
          if (storageInit) {
            functions.initStorage();
          }
          // If there's a lastFetch present already, this was loaded from cache.
          if (self.vals.lastFetch) {
            let date = new Date();
            // Get the current time in seconds.
            let time = date.getTime() / 1000;

            // If it's been less than 12 hours since last refresh, tell the system
            // the app is good to go prior to refresh.
            if (self.vals.lastFetch > time - (60 * 60 * 12)) {
              self.loaded++;
              functions.isLoaded();
            }
            else {
              self.vals.lastFetch = 0;
            }
          }

          // Always kick off a refresh on init.
          functions.refresh();
        },
        refresh: function () {
          self.loadCount = 1;
          self.loaded = 0;

          // Force update self.vals with rootScope to make sure they stay in sync.
          self.vals = $rootScope.account;

          var lastFetch = self.vals.lastFetch ? self.vals.lastFetch : 0;
          var includeArchived = false;
          RestResource.loadAccountInfo(lastFetch, includeArchived).then(function (result) {
            if (result === false) {
              errorHandlerService.appLogin();
              return;
            }

            // Store the request time for future request diffing.
            self.vals.lastFetch = result.request_time;

            self.vals.activeAccount = result.active_account;
            $rootScope.$emit('accountActive');

            if (!self.loadTime) {
              self.loadTime = result.request_time;
            }

            angular.forEach(result, function (value, index) {
              if (typeof value !== 'undefined') {
                if (index == 'account_members') {
                  functions.addLowercase(value, 'name');
                }
                else if (index == 'projects' && value.hasOwnProperty('all')) {
                  functions.addLowercase(value.all);
                }
                // else if (index == 'layout') {
                //   functions.addLowercase(value);
                //   let settings = result.account_settings;
                //   if (settings.hasOwnProperty('statusNames')) {
                //     for (let i = 0; i < value.length; i++) {
                //       let item = value[i];
                //       if (settings.statusNames.hasOwnProperty(item.id) &&
                //         settings.statusNames[item.id].length > 0) {
                //         item.title = settings.statusNames[item.id];
                //       }
                //     }
                //   }
                // }
                else {
                  functions.addLowercase(value);
                }
              }
            });

            angular.forEach(result.account_members, function (user) {
              if (user.field_profile_image) {
                // Double slash is a protocol agnostic absolute URL.
                if (user.field_profile_image.indexOf('//') == -1) {
                  // TODO: migrate images.
                  // let imageBase = drupal.settings.cdnUrl || drupal.settings.sitePath;
                  let imageBase =  drupal.settings.sitePath;
                  let urlParts = [imageBase, user.field_profile_image];
                  user.field_profile_image = urlParts.join('');
                }
              }
              if (commonService.empty(user.field_first_name) ||
                commonService.empty(user.field_last_name)) {
                user.display_name = user.name;
              }
              else {
                user.display_name = user.field_first_name + ' ' + user.field_last_name;
              }
              if (user.display_name) {
                user.abbrev = user.display_name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g).slice(0, 2).join('').toUpperCase();
              }
            });

            functions.processNewValues('members', result.account_members, true);
            functions.processNewValues('user', result.user);
            functions.filterNewValues('tags', result.account_tags, true);
            functions.processNewValues('colored_vals', result.account_colored_values);
            functions.processNewValues('custom_fields', result.account_fields);
            functions.processNewValues('priorities', result.account_priorities);
            functions.processNewValues('statuses', result.account_statuses);
            functions.filterNewValues('folders', result.account_folders);
            functions.processNewValues('layouts', result.layouts);
            functions.processNewValues('projects', result.projects);
            functions.processNewValues('project_groups', result.project_groups);
            functions.processNewValues('entity', result.account);
            functions.processNewValues('settings', result.account_settings);
            functions.processNewValues('accounts', result.accounts_list);
            functions.processNewValues('searches', result.account_searches, true);
            functions.processNewValues('filters', result.filters);
            functions.processNewValues('defaults', result.info);

            // If the active account isn't active, then force the user to
            // upgrade or change accounts.
            let activeAccount = functions.getActiveAccount();
            angular.forEach(self.vals.accounts, function(account) {
              if (account.id == activeAccount) {
                if (account.field_active == 0) {
                  $mdDialog.show(
                    $mdDialog.switchAccountModal()
                  );
                  // Redirect to D7 and depend on D7 handling for activation.
                  // errorHandlerService.appLogin();
                }
              }
            });

            if (result.enabled_features) {
              self.vals.features = result.enabled_features
            }

            // Parse JSON fields.
            if (self.vals.entity.field_data && typeof self.vals.entity.field_data == "string") {
              self.vals.entity.field_data = JSON.parse(self.vals.entity.field_data);
            }
            else if (self.vals.entity.field_data == 'undefined') {
              self.vals.entity.field_data = {};
            }
            if (self.vals.user.field_data && typeof self.vals.user.field_data == "string") {
              self.vals.user.field_data = JSON.parse(self.vals.user.field_data);
            }
            else if (typeof self.vals.user.field_data == 'undefined' || !self.vals.user.field_data) {
              self.vals.user.field_data = {};
            }

            // Pull together list of custom fitlers based on JSON output.
            self.vals.custom_filters = functions.getCustomFilters();

            self.loaded++;
            functions.isLoaded();

            if (self.loadTime < result.refresh) {
              // If there's not already a toast, or it completed already.
              if (!openToast || openToast.$$state.status) {
                var toast = $mdToast.simple()
                  .textContent('An update is available, please refresh.')
                  .action('Refresh')
                  .highlightAction(true)
                  .position('bottom right')
                  .hideDelay(0);

                openToast = $mdToast.show(toast).then(function (response) {
                  if (response == 'ok') {
                    functions.forceRefresh();
                  }
                });
              }
            }
          },
          // Process account failures.
          function (res) {
            // If the user can't access account info with a 403 code, it's
            // almost certainly because they're not logged in anymore, so
            // tell them to log in again (and attempt to do it for them).
            if (res.message === "403") {
              // Only show one at a time (not a new one every app refresh.)
              if (!self.memberErrorOpen) {
                self.memberErrorOpen = true;
                let confirm = $mdDialog.confirm()
                  .multiple(false)
                  .title('You Need to Login Again!')
                  .textContent("You've been logged out. Click Refresh to log back into the app")
                  .ariaLabel('Refresh App')
                  .ok('Refresh');

                $mdDialog.show(confirm).then(function () {
                  self.memberErrorOpen = false;
                  $mdDialog.hide();
                  errorHandlerService.appLogin();
                });
              }
            }
            // For all others, say something more generic, just in a toast.
            else {
              let toast = $mdToast.simple()
                .textContent('There was an error communicating with the server. If the problem continues, please refresh.')
                .action('Refresh')
                .highlightAction(true)
                .position('bottom right')
                .hideDelay(10000);
              if (openToast && openToast.$$state.status) {
                $mdToast.hide(false).then(function () {
                  openToast = $mdToast.show(toast).then(function (response) {
                    if (response == 'ok') {
                      functions.forceRefresh();
                    }
                  });
                });
              }
              else {
                openToast = $mdToast.show(toast).then(function (response) {
                  if (response == 'ok') {
                    functions.forceRefresh();
                  }
                });
              }
            }
          });
        },
        getAccount: function () {
          return new Promise(function(resolve, reject) {
            if (functions.loaded()) {
              resolve(self.vals);
            }
            else {
              let accountListener = $rootScope.$on('accountLoaded', function() {
                accountListener();
                resolve(self.vals);
              });
            }
          });
        },
        getAccountValue: function() {
          return self.vals;
        },
        getAccountData: function (field) {
          var value = false;
          if (self.vals.entity && self.vals.entity.data) {
            if (field) {
              if (self.vals.entity.data[field]) {
                value = self.vals.entity.data[field];
              }
            }
            else {
              value = self.vals.entity.data;
            }
          }
          return value;
        },
        getUnassignedFolder: function () {
          return {
            id: null,
            title: "Uncategorized",
          };
        },
        getUnassignedUser: function () {
          return {
            uid: null,
            name: "Unassigned",
            _lowercase: "unassigned",
            display_name: "Unassigned",
            field_profile_image: "",
            abbrev: "U"
          };
        },
        setObjectById: function (objectList, id, key, object) {
          let add = true;
          for (var index in self.vals[objectList]) {
            var obj = self.vals[objectList][index];
            if (obj[key] == id) {
              // Make sure Angular identifies this as the same object. This prevents
              // any flashing or temporary duplication while Angular things out.
              if (self.vals[objectList][index].hasOwnProperty('$$hashKey')) {
                object.$$hashKey = self.vals[objectList][index].$$hashKey;
              }

              self.vals[objectList][index] = object;
              // If the value is modified, make sure it doesn't added too.
              add = false;
              break;
            }
          }
          // If the key doesn't already exist, then it should be added to the list.
          if (add) {
            self.vals[objectList].push(object);
          }
        },
        getObjectByIndex: function(objectList, index){
          return self.vals[objectList][index];
        },
        getObjectById: function (objectList, id, key = 'id') {
          key = (key) ? key : 'id';
          for (var index in self.vals[objectList]) {
            var obj = self.vals[objectList][index];
            if (obj[key] == id) {
              return obj;
            }
          }
        },
        getObjectByIds: function (objectList, ids, key = 'id') {
          key = (key) ? key : 'id';
          var list = [];

          for (var index in ids) {
            var id = ids[index];
            var obj = this.getObjectById(objectList, id, key);

            if (typeof obj == 'object') {
              list.push(obj);
            }
          }
          return list;
        },
        deleteObjectById: function (objectList, id, key = 'id') {
          let deleteIndex;
          // Delete from current self values if it matches the given id.
          deleteIndex = self.vals[objectList].findIndex(function (item) {
            return item[key] == id;
          });
          if (deleteIndex != -1) {
            self.vals[objectList].splice(deleteIndex, 1);
          }
        },
        joinByProp: function (items, prop, separator) {
          prop = (prop) ? prop : 'title';
          separator = (separator) ? separator : ', ';
          var list = [];

          for (var index in items) {
            var str = items[index][prop];
            if (str) {
              list.push(str);
            }
          }

          return list.join(separator);
        },
        stringToIdList: function (idString) {
          var ret = [],
            arr = idString.split(',');

          for (var index in arr) {
            var item = arr[index];
            if (typeof item == 'string') {
              item = item.trim();
              if (item !== "") {
                ret.push(item);
              }
            }
          }
          return ret;
        },
        // Sets overrides on layouts based on account settings.
        loadTitleOverrides: function (layout, objectlist = 'statuses') {
          // @TODO: Currently assumes that the layout uses field_list.
          let overrideList = functions.getOverrideMapping(objectlist)

          // Maintain current objects so that references are not rebuilt.
          if (overrideList
            && typeof self.vals.settings != 'undefined'
            && self.vals.settings.hasOwnProperty(overrideList)) {
            let overrides = self.vals.settings[overrideList];

            // Check all the layouts against the override list.
            angular.forEach(layout, function(value, key) {
              if (overrides.hasOwnProperty(value.id)) {
                value.title = overrides[value.id];
              }
            });
          }
        },
        // Gets the override mapping for object lists.
        getOverrideMapping: function(objectlist) {
          let overrideName = false;
          let overrides = {
            'statuses': 'statusNames',
          }
          if (overrides.hasOwnProperty(objectlist)) {
            overrideName = overrides[objectlist];
          }
          return overrideName;
        },
        getAccountMember: function () {
          var accountMember;

          if (self && self.vals && self.vals.user) {
            let id = self.vals.user.uid;
            accountMember = this.getAccountMemberById(id);
          }

          if (typeof accountMember == 'undefined' || accountMember === false) {
            if (self.memberErrorOpen === false) {
              self.memberErrorOpen = true;
              refreshService.setRefreshState(false);
              var confirm = $mdDialog.confirm()
                .multiple(true)
                .title('Oops, something went wrong!')
                .textContent("Click Refresh to log back into the app")
                .ariaLabel('Refresh App')
                .ok('Refresh');

              $mdDialog.show(confirm).then(function () {
                self.memberErrorOpen = false;
                $mdDialog.hide();
                errorHandlerService.appLogin();
              });
            }
          }
          else {
            return accountMember;
          }
        },
        getAccountMemberById: function (id) {
          let accountMember = false;
          if (typeof id != 'undefined') {
            accountMember = this.getObjectById('members', id, 'uid');
          }

          return accountMember;
        },
        getProjectFolderById: function (id) {
          let folder = false;
          if (typeof id != 'undefined') {
            folder = this.getObjectById('folders', id);
          }

          return folder;
        },
        getDefaultValue: function (field) {
          var defaultValue = null;

          if (self.vals.defaults && self.vals.defaults.hasOwnProperty(field)) {
            defaultValue = self.vals.defaults[field];
          }
          return defaultValue;
        },
        // Allows the new values to be reflected in the current object.
        // Allows for a subset to be merged in, taking precendence, without
        // deleting any values which were not sent.
        processNewValues: function (objectList, valueList, sort = false, idKey = false) {
          let arrayType = false,
            modified = false;
          if (!self.vals[objectList]) {
            if (valueList instanceof Array) {
              self.vals[objectList] = [];
            }
            else {
              self.vals[objectList] = {};
            }
          }
          // Array's need to be set differently.
          if (self.vals[objectList] instanceof Array) {
            arrayType = true;
          }
          self.vals[objectList] = self.vals[objectList] || {};

          angular.forEach(valueList, function (value, key) {
            // Replace array items by an idKey on the value object rather than
            // merely swapping index.
            if (arrayType) {
              if (idKey === false) {
                idKey = (value.hasOwnProperty('id')) ? 'id' : 'uid';
              }
              functions.setObjectById(objectList, value[idKey], idKey, value);
            }
            else {
              self.vals[objectList][key] = value;
            }
            // If the values array isn't empty, something is getting modified.
            modified = true;
          });

          // Sort some lists, but only if there are changes. (Only arrays work.)
          if (sort && modified && arrayType) {
            self.vals[objectList].sort(functions.alphaSortByTitle);
          }
        },
        // Wrapper function to allow filtering out and removing invalid values
        // before processing them.
        filterNewValues: function (objectList, valueList, sort = false, idKey = false) {
          // Iterate through a copy so deletions don't cause the loop to skip.
          let copyList = angular.copy(valueList);
          // Filter out deleted items from the passed values.
          angular.forEach(copyList, function(value, key) {
            if (idKey === false) {
              idKey = (value.hasOwnProperty('id')) ? 'id' : 'uid';
            }
            if (value.hasOwnProperty('field_entity_state')) {
              if (value.field_entity_state == 'deleted') {
                let deleteIndex,
                    idVal = value[idKey];
                // Delete from current self values (for updates).
                if (typeof self.vals[objectList] !== 'undefined') {
                  deleteIndex = self.vals[objectList].findIndex(function (item) {
                    return item[idKey] == idVal;
                  });
                  if (deleteIndex != -1) {
                    self.vals[objectList].splice(deleteIndex, 1);
                  }
                }

                // Delete on index (hit moving targets) on new/force refresh.
                deleteIndex = valueList.findIndex(function (item) {
                  return item[idKey] == idVal;
                });
                valueList.splice(deleteIndex, 1);

                // Folders also exist in the project_groups list.
                if (objectList == 'folders') {
                  if (self.vals.project_groups[idVal] !== undefined) {
                    delete self.vals.project_groups[idVal];
                  }
                }
              }
            }
          });

          functions.processNewValues(objectList, valueList, sort, idKey);
        },
        // Callback function compatible with Array.sort() to alphabetize.
        alphaSortByTitle: function (a, b) {
          let comparison = 0;
          if (a.hasOwnProperty('title') && b.hasOwnProperty('title')) {
            // Use toUpperCase() to ignore character casing
            const titleA = a.title.toUpperCase();
            const titleB = b.title.toUpperCase();

            if (titleA > titleB) {
              comparison = 1;
            }
            else if (titleA < titleB) {
              comparison = -1;
            }
          }
          return comparison;
        },
        getLastRefresh: function () {
          return self.lastRefresh ? self.lastRefresh : 0;
        },
        forceRefresh: function () {
          commonService.clearAppStorage();
          $window.location.href = '/';
        },
        getActiveAccount: function() {
          var activeAccount = false;
          if ($routeParams.accountId && $routeParams.accountId != 0) {
            if (typeof $rootScope.account == 'undefined') {
              $rootScope.account = {};
            }
            $rootScope.account.activeAccount = $routeParams.accountId;
            activeAccount = $rootScope.account.activeAccount;
          }
          else if ($rootScope.account) {
            if ($rootScope.account.activeAccount) {
              activeAccount = $rootScope.account.activeAccount;
            }
            else if ($rootScope.account.entity) {
              activeAccount = $rootScope.account.entity.id;
            }
          }

          if ($routeParams.accountId != activeAccount && activeAccount) {
             var currentParams = $route.current.params;
            currentParams.accountId = activeAccount;
            $route.updateParams(currentParams);
          }

          return activeAccount;
        },
        initStorage: function() {
          self.vals = $rootScope.account = {};
          self.vals.activeAccount = functions.getActiveAccount();
        }
      };

      $rootScope.$on('appRefresh', function (event, args) {
        functions.init();
      });

      return functions;
    }
  ]);

})();
