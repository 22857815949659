
(function () {
  'use strict';

  var app = angular.module('messages');

  app.directive('message', function () {
    return {
      scope: {
        mid: '=',
        context: '=',
        listService: '='
      },
      controller: [
        '$scope',
        '$rootScope',
        'accountService',
        'taskListService',
        'layoutService',
        function ($scope, $rootScope, accountService, taskListService, layoutService) {

          // Define the mapping for rendering messages by type.
          $scope.messageMap = {
            'comment_notification': [
              {callback: 'getAuthorText'},
              {
                class: 'action',
                wrap: 'posted a new comment',
                suffix: 'on'
              },
              {callback: 'getTaskLink'}
            ],
            'project_notification': [
              {callback: 'getAuthorText'},
              'created project',
              {callback: 'getProjectText'}
            ],
            'task_attachment_update': [
              {callback: 'getAuthorText'},
              {
                class: 'action',
                wrap: 'added or removed a file',
                suffix: 'on'
              },
              {callback: 'getTaskLink'}
            ],
            'task_description_create': [
              {callback: 'getAuthorText'},
              {
                class: 'action',
                wrap: 'added a description',
                suffix: 'to'
              },
              {callback: 'getTaskLink'}
            ],
            'task_description_remove': [
              {callback: 'getAuthorText'},
              {
                class: 'action',
                wrap: 'removed the description',
                suffix: 'from'
              },
              {callback: 'getTaskLink'}
            ],
            'task_description_update': [
              {callback: 'getAuthorText'},
              {
                class: 'action',
                wrap: 'updated the description',
                suffix: 'of'
              },
              {callback: 'getTaskLink'}
            ],

            'task_list_update': [
              {callback: 'getAuthorText'},
              'moved',
              {callback: 'getTaskLink'},
              'from',
              {
                callback: 'getStatusText',
                field: 'field_original_list'
              },
              'to',
              {
                callback: 'getStatusText',
                field: 'field_updated_list'
              }
            ],
            'task_notification': [
              {callback: 'getAuthorText'},
              'created task',
              {callback: 'getTaskLink'},
              'in',
              {callback: 'getProjectText'}
            ],
            'task_priority_update': [
              {callback: 'getAuthorText'},
              'changed the priority of ',
              {callback: 'getTaskLink'},
              'from',
              {
                callback: 'getPriorityText',
                field: 'field_original_priority'
              },
              'to',
              {
                callback: 'getPriorityText',
                field: 'field_updated_priority'
              }
            ],
            'task_project_change': [
              {callback: 'getAuthorText'},
              'moved task',
              {callback: 'getTaskLink'},
              'from',
              {
                callback: 'getProjectText',
                field: 'field_projects_removed'
              },
              'to',
              {
                callback: 'getProjectText',
                field: 'field_projects_added'
              }
            ],
            'task_tags_update': [
              {callback: 'getAuthorText'},
              {
                class: 'action',
                wrap: 'updated the tags',
                suffix: 'of'
              },
              {callback: 'getTaskLink'}
            ],
          }

          // Callback to generate a task link.
          $scope.getTaskLink = function(mapping) {
            let nid = $scope.msg.field_task;
            // Set the default task text.
            let taskLink = '<span class="missing missing-task">Deleted</span>'

            // Grab the task from the task list.
            $scope.task = taskListService.getTaskById(nid);
            let title = '';
            let trigger = ' task-trigger nid="msg.field_task"';

            // Default to the task title provided by the search.
            if ($scope.msg.hasOwnProperty('task_title')) {
              title = $scope.msg.task_title;
            }

            // If this message is NOT being generated within a task modal.
            if ($scope.context !== 'task') {
              if ($scope.task && $scope.task.hasOwnProperty('title')) {

                // All messages for a task will link to that task. Don't allow
                // users to accidentally re-open the task.
                title = $scope.task.title;
              }
            }
            // Be more concise on task modal messages.
            else {
              title = 'this task';
              trigger = '';
            }

            if (title) {
              // Create the task link.
              taskLink = '<span class="task-name"' + trigger + '>' + title + '</span>';
            }

            return taskLink;
          }

          // Callback to generate the author text.
          $scope.getAuthorText = function(mapping) {
            let uid = $scope.msg.uid;

            // Define the default.
            let username = '<span class="missing missing-user">Missing</span>';
            let member = false;
            if (uid) {
              // Load them ember and re-set the username.
              member = accountService.getAccountMemberById(uid);
              if (member && member.display_name) {
                username = member.display_name;
              }
            }

            // Build out the full username text.
            return '<span class="username">' + username + '</span>';
          }

          // Callback for building the project text.
          $scope.getProjectText = function(mapping) {
            // Get the project from the field defined in the mapping.
            let field = 'field_project';
            if (mapping.hasOwnProperty('field')) {
              field = mapping.field;
            }

            // Set the default project name and color.
            let projectName = '<span class="missing missing-project">Deleted</span>';
            let projectColor = '';

            // Get the appropriate project from the account.
            if ($scope.msg.hasOwnProperty(field)) {
              let projectId = $scope.msg[field];
              let project = accountService.getObjectById('projects', projectId);

              // Set the project name and color.
              if (project) {
                projectName = project.title;
                projectColor = project.field_project_color;
              }
            }

            var projectClass = 'color-' + projectColor;

            // Build the project token value.
            return '<div class="task-project"><span class="' + projectClass + '">' + projectName + '</span></div>';
          }

          // Callback for genearting priority text.
          $scope.getPriorityText = function(mapping) {
            // default the priority text.
            let priorityName = 'Normal';
            let lowercase = 'normal';

            // Get the appropriate priority value based on the mapping.
            if (mapping.hasOwnProperty('field') && mapping.field) {
              let priorityId = $scope.msg[mapping.field];
              let priority = accountService.getObjectById('priorities', priorityId);
              // Get the priority title and class from the loaded priority.
              priorityName = priority.title;
              lowercase = priority._lowercase;
            }
            // And build the priority token value.
            return '<span class="priority ' + lowercase + '">' + priorityName + '</span>';
          }

          // Callback for the status text token.
          $scope.getStatusText = function(mapping) {
            // Default to Not Started and then try to load the status.
            let statusName = 'Not Started';

              // Loading lists in this way will preserve the status overrides.

              if (mapping.hasOwnProperty('field') && mapping.field) {
                let statusId = $scope.msg[mapping.field];

                let lists = $scope.account.statuses;
                accountService.loadTitleOverrides(lists);

                // Iterate over all of the lists to get the correct title.
                angular.forEach(lists, function(value) {
                  if (value.id == statusId) {
                    var status = value;
                    if (typeof status != 'undefined') {
                      statusName = status.title;
                    }
                  }
                });
              }
              return '<span class="status">' + statusName + '</span>';

            // Build the status token value.
          }

          $scope.init = function() {
            $scope.listService.fetchEntityById($scope.mid, 'mid').then(function(message) {
              $scope.msg = message;

              let renderPieces = [];
              accountService.getAccount().then(function(account) {
                $scope.account = account;
                // Get the template and use that to fetch the mapping.
                if (message.template && $scope.messageMap.hasOwnProperty(message.template)) {
                  let itemMap = $scope.messageMap[message.template];
                  angular.forEach(itemMap, function(mapping) {

                    // Strings just get added to the render array.
                    if (typeof mapping == 'string') {
                      renderPieces.push(mapping);
                    }
                    // Objects can optionally specify an wrapper class.
                    else if (typeof mapping == 'object' && mapping.class && mapping.wrap) {
                      // Wrap the .wrap property in span with the .class.
                      let value = '<span class="' + mapping.class + '">' + mapping.wrap + '</span>';

                      // Allow for optional prefixes (unwrapped).
                      if (mapping.prefix) {
                        renderPieces.push(mapping.prefix);
                      }

                      // Main render push.
                      renderPieces.push(value);

                      // Allow for optional suffixes (unwrapped).
                      if (mapping.suffix) {
                        renderPieces.push(mapping.suffix);
                      }
                    }
                    // Objects with a a callback.
                    else if (typeof mapping == 'object' && mapping.callback) {
                      let callback = $scope[mapping.callback];
                      let value = callback(mapping);
                      // Make sure the value was successfully returned.
                      if (typeof value != 'undefined') {
                        renderPieces.push(value);
                      }
                    }
                  });
                }

                // Combine the renderPieces.
                if (renderPieces.length > 0) {
                  $scope.messageValue = renderPieces.join(' ');
                }
              });

              // Manually add the timezone as UTC.
              $scope.messageDate = moment($scope.msg.created + '-0000').format($rootScope.defaultDateFormat);
            });
          }

          // Kick off the init.
          $scope.init();
        }
      ],
      templateUrl: 'app/src/components/message/message.html'
    };
  });

})();
