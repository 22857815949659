(function() {
  'use strict';

  var app = angular.module('inlineEdit');

  app.controller('inlineWidgetDateRangeBase', [
    '$scope',
    '$rootScope',
    '$controller',
    '$element',
    '$attrs',
    '$timeout',
    'commonService',
    function($scope, $rootScope, $controller, $element, $attrs, $timeout, commonService) {
      $scope.closeOnUpdate = false;

      $scope.selectedRange = {
        showTemplate: true,
        fullscreen: false
      };

      let templateDefaults = commonService.getDateRangeDefaults();
      $scope.selectedRange.customTemplates = templateDefaults.customTemplates;
      $scope.disabledTemplates = templateDefaults.disabledTemplates;

      $scope.dateClear = function() {
        $scope.selectedRange = {
          showTemplate: false,
          fullscreen: false
        };
        $scope.node.field_date = {};
        $scope.selectedValue[$scope.modelKey] = $scope.node.field_date;
        $scope.onUpdate();
      };

      // Select callback for md-date-range directive.
      $scope.onSelect = function() {
        // Only fire if there is a value set for dateStart (initially set to
        // node.start from node load request).
        if ($scope.node && $scope.selectedRange.dateStart) {
          var format = 'YYYY-MM-DD\THH:mm:ss';
          if (typeof $scope.node.field_date !== 'object') {
            $scope.node.field_date = {
              value: null,
              end_value: null
            };
          }

          // Save initial values of date fields as moment()s.
          var before = [
            moment($scope.node.field_date.value).startOf('day').format(format),
            moment($scope.node.field_date.end_value).endOf('day').format(format)
          ];

          // Update the date values based on the settings.
          $scope.node.field_date.value = moment($scope.selectedRange.dateStart).startOf('day').format();
          $scope.node.field_date.end_value = moment($scope.selectedRange.dateEnd).endOf('day').format();

          // Grab new values as moment()s.
          var after = [
            moment($scope.node.field_date.value).startOf('day').format(format),
            moment($scope.node.field_date.end_value).endOf('day').format(format)
          ];

          // Only push update if the values have changed.
          if (before[0] != after[0] || before[1] != after[1]) {
            // Update the field.
            $scope.selectedValue[$scope.modelKey] = $scope.node.field_date;
            $scope.onUpdate();
          }
        }

        // Always tell mdDateRange to accept the changes.
        return true;
      };

      $scope.immediateOpen = function($element) {
        // The daterange picker isn't immediately available and there isn't
        // an event for when it's open. Create a listener to watch the
        // contents of the panel for when the panel has been initialized.
        var openListener = $scope.$watch(function() {
          return $element.find('.md-select-value').length > 0
        },
        function() {
          // Again, no event is fired on close. Create a listener forwhen it
          // closes.
          $timeout(function() {
            $element.find('.md-select-value').triggerHandler('click');
            var closeListener = $scope.$watch(function() {
              // When the dateRange picker closes (hides) it adds the
              // md-leave class. Watch for that class as a signal that the
              // date picker has closed and the edit panel should close.
              return angular.element('.md-open-menu-container').hasClass('md-leave');
            },
            function(newValue, oldValue) {
              // Watch is triggered several times during the init process of
              // the date picker. Only trigger a close of the container has
              // the md-leave class (newValue == true) and the value changed.
              if (newValue && newValue != oldValue) {
                // Trigger a close, but no save.
                $scope.onClose(false);
              }
            });
            $rootScope.addListener(closeListener, $scope);
          });
        });
        $rootScope.addListener(openListener, $scope);
      }

      // Watch selectedRange model separately from mdDateRangeSelect since it calls
      // the update callback like 4x as much as needs to.
      let dateRangeListener = $scope.$watch('selectedRange', $scope.onSelect, true);
      $rootScope.addListener(dateRangeListener, $scope);

      angular.extend(this, $controller('inlineWidgetCtrl', { $scope: $scope, $element: $element, $attrs: $attrs }));
    }
  ]);

  app.directive('inlineWidgetDateRange', function() {
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&?',
        modelVal: '=?',
        modelKey: '=?',
        inputType: '@',
        immediate: '=?',
        context: '@?'
      },
      templateUrl: 'app/src/components/inline-edit/widgets/date/templates/dateRange.html',
      controller: 'inlineWidgetDateRangeBase',
    };
  });

  app.directive('inlineWidgetMatDateRange', function() {
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&?',
        modelVal: '=?',
        modelKey: '=?',
        inputType: '@',
        immediate: '=?',
        context: '@?'
      },
      templateUrl: 'app/src/components/inline-edit/widgets/date/templates/dateRange-mat.html',
      controller: 'inlineWidgetDateRangeBase',
    };
  });

})();
