(function () {
  'use strict';

  var app = angular.module('misc', []);

  app.directive('ngEnter', function () {
    return function (scope, element, attrs) {
      element.bind("keydown keypress", function (event) {
        if (event.which === 13) {
          scope.$apply(function () {
            scope.$eval(attrs.ngEnter);
          });

          event.preventDefault();
        }
      });
    };
  });

  app.directive('ngEsc', function () {
    return function (scope, element, attrs) {
      element.bind("keydown keypress", function (event) {
        if (event.which === 27) {
          scope.$apply(function () {
            scope.$eval(attrs.ngEsc);
          });

          event.preventDefault();
        }
      });
    };
  });


  app.directive('focusMe', ['$timeout', '$parse', function ($timeout, $parse) {
    return {
      link: function (scope, element, attrs) {
        var model = $parse(attrs.focusMe);
        scope.$watch(model, function (value) {
          if (value === true) {
            $timeout(function () {
              element[0].focus();
            });
          }
        });
      }
    };
  }]);

  app.directive('elemReady', ['$timeout', '$parse', function ($timeout, $parse) {
    return {
      restrict: 'A',
      link: function ($scope, elem, attrs) {
        $timeout(function () {
          elem.ready(function () {
            $scope.$apply(function () {
              var func = $parse(attrs.elemReady);
              func($scope);
            });
          });
        });
      }
    };
  }]);

  app.directive('ignoreMouseWheel', ['$timeout', function ($timeout) {
    return {
      link: function (scope, element, attrs) {
        element.bind('mousewheel', function (event) {
          if (element[0] == document.activeElement) {
            event.preventDefault();
          }
        });
      }
    };
  }]);

  app.directive('autocompleteFocus', ["$mdUtil", "$timeout", function ($mdUtil, $timeout) {
    return {
      require: "^mdAutocomplete",
      link: function ($scope, $element, $attributes, $mdAutocompleteCtrl) {
        $timeout(function () {
          var nativeFocus = $mdAutocompleteCtrl.focus;
          $mdAutocompleteCtrl.focus = function () {
            nativeFocus.call($mdAutocompleteCtrl);
            $mdUtil.nextTick(function () {
              $scope.$eval($attributes.autocompleteFocus, {
                "$mdAutocomplete": $mdAutocompleteCtrl
              });
            });
          };
        });
      }
    };
  }]);

  app.directive('autocompleteBlur', ["$mdUtil", "$timeout", function ($mdUtil, $timeout) {
    return {
      require: "^mdAutocomplete",
      link: function ($scope, $element, $attributes, $mdAutocompleteCtrl) {
        $timeout(function () {
          var nativeBlur = $mdAutocompleteCtrl.blur;
          $mdAutocompleteCtrl.blur = function () {
            nativeBlur.call($mdAutocompleteCtrl);
            $mdUtil.nextTick(function () {
              $scope.$eval($attributes.autocompleteBlur, {
                "$mdAutocomplete": $mdAutocompleteCtrl
              });
            });
          };
        });
      }
    };
  }]);

  // Similar to core bindHTML, but it supports directive interpolation.
  app.directive('bindHtmlCompile', ['$compile', function ($compile) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        scope.$watch(function () {
          return scope.$eval(attrs.bindHtmlCompile);
        }, function (value) {
          // In case value is a TrustedValueHolderType, sometimes it
          // needs to be explicitly called into a string in order to
          // get the HTML string.
          element.html(value && value.toString());

          // If scope is provided use it, otherwise use parent scope
          var compileScope = scope;
          if (attrs.bindHtmlScope) {
            compileScope = scope.$eval(attrs.bindHtmlScope);
          }
          $compile(element.contents())(compileScope);
        });
      }
    };
  }]);

  // Similar to bindHtmlCompile that appends, and accepts input via attributes.
  // Used on table cell content.
  app.directive('bindCompile', ['$compile', function($compile) {
    return {
      scope: {
        compile: '=',
        args: '=',
        args2: '='
      },
      link: function(scope, element, attrs) {
        // Keep digesting changes to args.
        scope.$watch('args', function() {
          // Add the args to this scope for execution.
          scope = Object.assign(scope, scope.args, scope.args2);

          // Add the compiled version inside the directive parent.
          element.append($compile(scope.compile)(scope));
        });
      }
    };
  }]);

  // Found at: https://stackoverflow.com/a/25303686/1631443
  app.filter('addTargetBlank', function(){
    return function(text) {
      // Defensively wrap in a div to avoid 'invalid html' exception.
      var tree = angular.element('<div>' + text + '</div>');
      // Manipulate the parse tree.
      tree.find('a').attr('target', '_blank');
       // Trick to have a string representation.
      return angular.element('<div>').append(tree).html();
    }
  });

})();
