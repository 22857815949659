(function () {
  'use strict';

  angular.module('refresh-service', [])
    .service('refreshService', [
      '$rootScope',
      function ($rootScope) {

        var appRefreshState = true;
        var app;
        var functions = {
          appRefresh: function () {
            if (appRefreshState === false) {
              return;
            }

            // Get the current time to compare against the last refresh.
            let now = new Date().getTime();

            // Fetch the app settings from localStorage.
            if (!app) {
              app = {
                lastRefresh: 0,
                fetching: false
              };
            }

            var refresh = false;
            var interval = AppConfig.appRefreshInterval;

            // If the app is already refreshing then don't double up.
            if (app.fetching === false || now - app.fetching > interval) {
              refresh = true;
            }

            if (refresh) {

              // Place a hold on all other refreshes.

              // Reset the refresh time.
              let refreshTime = new Date().getTime();
              app.fetching = refreshTime;
              app.lastRefresh = refreshTime;

              // Actually do the app refresh.
              $rootScope.$emit('appRefresh');

              // AccountLoaded is one of the primary listners for AppRefresh. Once
              // AccountLoaded has completed, then we can consider the appRefresh
              // complete.
              let listener = $rootScope.$on('accountLoaded', function () {
                listener();

                // Get and reset the app LocalStorage.

                let appFetching = app;
                if (!appFetching) {
                  appFetching = {
                    lastRefresh: 0,
                    fetching: false
                  };
                }
                else {
                  appFetching.fetching = false;
                }
              });
            }
          },
          setRefreshState: function (val) {
            appRefreshState = val;
          }
        };

        return functions;

      }
    ]);
})();
