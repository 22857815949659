(function() {
'use strict';

angular.module('user', [
  'ngCookies',
  'angular-drupal'
])

.service('userService', ['$cookies', function($cookies) {
  return {
    login: function(username) {

      // TODO: I think we used this for Oauth..

      var token;
      if (username == 'test user') {
        token = 'WMRxzOvo1dBpzTO_TEWoBP1fg4IvJucqXHYDA7jrE24';
      }
      else {
        token = 'mmoPPYNY10dd8b4oZAA2zZQahSZFjw1Jb1CFF6YNEBY';
      }

      $cookies.put('app_oauth_token', token);
    },
    logout: function() {
      window.location.href = AppConfig.endpointUrl + '/user/logout';
    }
  };
}]);
})();