(function () {
  'use strict';

  angular.module('gridmenu', ['angular-drupal', 'user'])
    .directive('gridmenu', function () {
      return {
        scope: {},
        controller: [
          '$scope',
          function ($scope) {

            $scope.openMenu = function ($mdMenu, ev) {
              $mdMenu.open(ev);
            };

            $scope.init = function () {
              $scope.path = AppConfig.externalEndpointUrl;
              $scope.ready = true;
            };

            $scope.init();
          }
        ],
        templateUrl: 'app/src/components/gridmenu/gridmenu.html'
      };
    });

})();