(function () {
  'use strict';

  let app = angular.module('repeatable-panel', []);

  app.controller('RepeatablePanelController', [
    '$scope',
    'task',
    'repeatable',
    'parentScope',
    'RestResource',
    'repeatableService',
    function ($scope, task, repeatable, parentScope, RestResource, repeatableService) {
      $scope.periodList = repeatableService.getPeriodList();
      $scope.dayList = repeatableService.getDayList();
      $scope.dayNumList = repeatableService.getDayNumList();

      // Initialize scope based on repeatable, if available; or default, if not.
      $scope.init = function() {
        if (repeatable) {
          $scope.values = {
            quantity: parseInt(repeatable.field_quantity, 10),
            period: repeatable.field_period,
            offset: repeatable.field_offset || null
          };
        }
        else {
          repeatable = {};
          let repeatDay = task.end || undefined;
          $scope.values = {
            quantity: 1,
            period: 'week',
            offset: moment(repeatDay).format('d')
          };
          $scope.onUpdate();
        }
      };

      // Get's the list of days for months or weeks (or not).
      $scope.getDayList = function() {
        let list = false;

        switch ($scope.values.period) {
          case 'month':
            list = $scope.dayNumList;
            break;

          case 'week':
            list = $scope.dayList;
            break;
        };

        return list;
      };

      // Only fires on actual changes, so original values are not checked.
      $scope.onUpdate = function() {
        repeatable.field_quantity = $scope.values.quantity;
        repeatable.field_period = $scope.values.period;
        // Offset should be unset for some repeat periods, updated for others.
        if (['week', 'month'].indexOf($scope.values.period) !== -1) {
          if ($scope.values.offset) {
            if ($scope.values.period == 'week' && $scope.values.offset > 6) {
              // Set this to recalcuate.
              $scope.values.offset = null;
            }
            else {
              repeatable.field_offset = $scope.values.offset;
            }
          }
          // Since this value can be updated by above, else is not appropriate.
          if (!$scope.values.offset) {
            // Get integer for day of week or day of month.
            let format = ($scope.values.period == 'week') ? 'd' : 'D';
            repeatable.field_offset = $scope.values.offset = moment(task.end).format(format);
          }
        }
        // Nuke unless period is weekly or monthly.
        else {
          repeatable.field_offset = $scope.values.offset = null;
        }

        // Save the repeatable then tell the parent.
        repeatableService.upsertRepeatable($scope.values, repeatable).then(function(res) {
            repeatable.id = res.id[0].value;
            parentScope.setRepeatableValues(repeatable);
        });
      };

      $scope.init();
    }
  ]);
})();