(function () {
  'use strict';

  angular.module('common', [])
    .service('commonService', [
      '$mdToast',
      '$route',
      '$window',
      'drupal',
      function ($mdToast, $route, $window, drupal) {

        this.getDateRangeDefaults = function () {
          let now = new Date();
          let quarter = Math.floor((now.getMonth() / 3));
          let startDate = new Date(now.getFullYear(), quarter * 3, 1);
          let endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
          return {
            customTemplates: [{
              name: 'This Quarter',
              dateStart: startDate,
              dateEnd: endDate
            }],
            disabledTemplates: "YD, LW, LM, TY, LY"
          };
        };
        this.createDateRange = function(start, end) {
          var ret = {};
          var startDate = start ? moment(start) : false;
          var endDate = end ? moment(end) : false;

          if (startDate && startDate.isValid()) {
            var sameYear = (startDate.year() == endDate.year());
            var startFormatted = this.formatDate(startDate, !sameYear);
            ret.dateRange = startFormatted;
            ret.sortDate = startDate;

            // Only examine end dates when there's a start date (should always be
            // the case for good data anyway.)
            if (endDate && endDate.isValid() && startDate.diff(endDate, 'days') < 0) {
              var addYear = !sameYear || moment().year() != endDate.year();
              var sameMonth = (sameYear) ? (startDate.month() == endDate.month()) : false;
              var endFormatted = this.formatDate(endDate, addYear, !sameMonth);

              ret.endDate = endDate;
              ret.dateRange += ' - ' + endFormatted;
            }
          }

          return ret;
        };
        this.getDateStatus = function(date) {
          var today = moment();
          status = (date.diff(today) > 0) ? 'on-time' : 'late';
          return status;
        };
        this.formatDate = function (date, year = false, month = true) {
          var format = 'D';
          if (month) {
            format = 'MMM ' + format;
          }
          if (year) {
            format += ' YYYY';
          }

          return moment(date).format(format);
        };

        // Helper function that determines if a value is falsy or not.
        this.empty = function (value) {
          let undef;
          // let emptyValues = [undef, null, false, 0, '', '0'];
          let emptyValues = [undef, null, false, ''];
          let len = emptyValues.length;

          for (let i = 0; i < len; i++) {
            if (value === emptyValues[i]) {
              return true;
            }
          }
          if (typeof value === 'object') {
            for (let key in value) {
              if (value.hasOwnProperty(key)) {
                return false;
              }
            }
            return true;
          }

          return false;
        };

        /**
         * Helper function to set or flip a value.
         * Doesn't allow actually setting to -1 since that's the flip value.
         */
        this.setOrFlip = function(obj, prop, value = -1, defaultVal = true) {
          let setValue;

          // If a value was provided, then just use that.
          if (value != -1) {
            setValue = value;
          }
          // If the toggle value was provided, then flip the current value.
          else if (value == -1 && obj.hasOwnProperty(prop)) {
            setValue = !(obj[prop]);
          }
          // If the flip value was provided, but there isn't a value to flip.
          else {
            // Assume that the list was open and it should be collapsed.
            setValue = defaultVal;
          }
          obj[prop] = setValue;
        };

        this.sdbmHash = function (str) {
          var hash = 0;
          for (let i = 0; i < str.length; i++) {
            let char = str.charCodeAt(i);
            hash = char + (hash << 6) + (hash << 16) - hash;
          }
          return hash;
        };
        this.clearAppStorage = function () {
          let removeKeys = [];
          let key = '';
          for (let index = 0; index < localStorage.length; index++) {
            key = localStorage.key(index);
            if (key.indexOf('dm.') === 0) {
              removeKeys.push(key);
            }
          }

          for (let keyIndex = 0; keyIndex < removeKeys.length; keyIndex++) {
            localStorage.removeItem(removeKeys[keyIndex]);
          }
        };

        // Copies a string to the user's clipboard and shows a toast about it.
        this.copyToClipboard = function (string) {
          // TODO: This feels like a kinda bad way to do this.
          const el = document.createElement('textarea');
          el.value = string;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);

          let toast = $mdToast.simple()
            .textContent('Successfully copied to clipboard.')
            .action('Ok')
            .highlightAction(true)
            .position('bottom right')
            .hideDelay(3000);
          $mdToast.show(toast);
        };

        this.getObjectById = function (objectList, id, key = 'id') {
          for (let index in objectList) {
            let obj = objectList[index];
            if (obj[key] == id) {
              return obj;
            }
          }
        };

        this.getObjectByIds = function (objectList, ids, key = 'id') {
          key = (key) ? key : 'id';
          var list = [];

          for (var index in ids) {
            var id = ids[index];
            var obj = this.getObjectById(objectList, id, key);

            if (typeof obj == 'object') {
              list.push(obj);
            }
          }
          return list;
        };

        // Converts a number of bytes to a human readable string.
        this.bytesToHumanSize = function (bytes) {
          var i = Math.floor(Math.log(bytes) / Math.log(1024));
          let postfix = ['B', 'KB', 'MB', 'GB', 'TB'][i];
          return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + postfix;
        };

        // Returns the name of the controller for the current display.
        this.getActiveController = function(controller = 'BoardCtrl') {
          if ($route.current.$$route.controller) {
            controller = $route.current.$$route.controller;
          }
          return controller;
        };

        // Builds a URL pointing back to the API.
        this.buildUrl = function(path = '') {
          let urlParts = [drupal.settings.sitePath, drupal.settings.basePath];
          if (typeof path == 'string') {
            urlParts.push(path);
            return urlParts.join('');
          }
        };

        // Redirects the window to the provided URL.
        this.redirect = function(url, target = '_self') {
          $window.open(url, target);
        };

        // Runs the callback only if a digest is not in process.
        // Copied from mdDateRangePicker. Pseudocode, not in use.
        // Generally this use cases is covered by $timeout().
        this.runIfNotInDigest = function(callback) {
          // Check if digest already in progress.
          if (scope.$root != null && !scope.$root.$$phase) {
            // Trigger digest then.
            scope.$apply();
            if (callback && typeof callback === 'function') {
              callback();
            }
          }
        };

        this.decodeHtml = function(html) {
          var txt = document.createElement("textarea");
          txt.innerHTML = html;
          return txt.value;
        };
      }
    ]);
})();
