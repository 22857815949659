(function() {
  'use strict';

  var app = angular.module('chart-service', ['angular-drupal', 'account', 'rest']);

  app.factory('chartService', [
    '$rootScope',
    '$compile',
    '$timeout',
    'commonService',
    'filterService',
    'accountService',
    'taskService',
    'taskListService',
    'layoutService',
    'RestResource',
    function($rootScope, $compile, $timeout, commonService, filterService, accountService, taskService, taskListService, layoutService, RestResource) {

      function ChartService(start, end, type, scope, selector = 'timeline-view') {
        // The default date range for the chart.
        this.start = start;
        this.end = end;

        // ID of the chart div.
        this.selector = selector;

        // The type of chart. Currently 'categories' is the only real type.
        // Any other value falls back on the default behavior.
        this.type = type;

        // The scope that initialized this chart.
        // Used for rendering angular directives.
        this.scope = scope;

        this.chart = null;
        this.defaultButton = 1;
        this.lists = [];
        this.parentMap = {};

        // Constant for the number of seconds in a day.
        this.day = 1000 * 60 * 60 * 24;

        // Dragging state for the chart.
        this.dragging = false;

        let self = this;

        let filterOpenListener = $rootScope.$on('filteringToggled', function() {
          // The animation for collapsing/expanding the filterpane takes 200ms.
          // Wait until it's done before attempting to reflow.
          $timeout(function() {
            self.chart.reflow();
          }, 300);
        });
        $rootScope.addListener(filterOpenListener, this);
      }

      // TODO: Kinda hate this... Need to figure out a better way to expose this
      // function to both highcharts and ChartService.
      function updateHeightCallback(chart) {
        if (!commonService.empty(chart) && !commonService.empty(chart.series)) {
          let visibleData = 0;
          let data = [];

          if (!commonService.empty(chart.series[0])) {
            let series = chart.series[0];
            data = series.data;
          }
          if (chart.hasOwnProperty('yAxis') && !commonService.empty(chart.yAxis[0])) {
            let axis = chart.yAxis[0];
            if (axis.hasOwnProperty('categories') && !commonService.empty(axis.categories)) {
              if (axis.categories.length < data.length || data.length == 0) {

                data = axis.categories;
              }
            }
          }

          if (!commonService.empty(data)) {
            angular.forEach(data, function(item) {
              if (item.hasOwnProperty('visible') && item.visible) {
                visibleData++;
              }
              else if (typeof item === 'string') {
                visibleData++;
              }
            });
            // let chartHeight = 50 * series.data.length + 1;
            let chartHeight =  ((30) * visibleData) + 142 + 80 + 20;
            // console.log(chart, series);
            chart.update({
              chart: {
                height: chartHeight
              }
            });
          }
        }
      }

      // Override the Highcharts toggleCollapse method.
      // Allows recalculating the chart height whenever tasks are collapsed.
      (function (H) {
        H.wrap(H.Tick.prototype, 'toggleCollapse', function (proceed) {

          // Apply the original toggleCollapse method.
          proceed.apply(this, Array.prototype.slice.call(arguments, 1));

          // Update the chart height. Depends on Tick.toggleCollapse having run.
          updateHeightCallback(this.axis.chart);
        });
      }(Highcharts));

      // Initialize the rootScope storage of the values for this entity list.
      ChartService.prototype = {

        /***************************
         * Initialization and Setup
         ***************************/

        /**
         * Perform the actual initialization of the gantt chart.
         */
        initChart: function(defaultOptions = true) {
          // Clear out all the mappings, categories and actual data.
          this.parentMap = {};
          this.categories = [];
          this.seriesData = [];
          var self = this;

          // Totally empty charts can't be initialized. Add an empty series to
          // allow pre-creating the chart.
          let data = {
            series: [{
              data: []
            }],
            backgroundColor: 'none',
            // Disable the highcharts link in the footer.
            credits: {
              enabled: false
            },
            chart: {
              styledMode: true,
              // Prep for congrolling height.
              height: 3,

              events: {
                redraw: function() {
                  self.postRender(this)
                },
              }
            },
            tooltip: {
              outside: true,
            },
            // Prevent growing animation on load.
            animation: false,
            gantt: {
              animation: 0,
            },
          };

          // Actually initialize the chart.
          this.chart = Highcharts.ganttChart(this.selector, data);

          // Set all the default options.
          if (defaultOptions) {
            this.setDefaultOptions();
          }
        },

        postRender: function(chart) {
          // `this` is Highcharts
          let xAxis = chart.xAxis[0],
            yAxis = chart.yAxis[0],
            // Top gray box coords.
            height = 94,
            position = {
              x: chart.plotLeft,
              y: chart.plotTop - height,
              width: xAxis.len,
              height: height
            },
            // Left white box coords.
            positionY = {
              x: chart.spacingBox.x,
              y: chart.plotTop,
              width: chart.plotLeft,
              height: yAxis.len
            };

          // Gray box around top date headers.
          if (!xAxis.background) {
            xAxis.background = chart.renderer.rect().attr({
              fill: '#E6E7E8',
              zIndex: 0
            }).attr(position).add();
          }
          else {
            xAxis.background.animate(position);
          }

          // White box around left labels.
          if (!yAxis.background) {
            yAxis.background = chart.renderer.rect().attr({
              fill: '#FFFFFF',
              zIndex: 0
            }).attr(positionY).add();
          }
          else {
            yAxis.background.animate(positionY);
          }

          // Mark the chart as fully built.
          angular.element(chart.renderTo).addClass('render-complete');
        },

        /**
         * Wrapper function to set all default options for timeline charts.
         */
        updateHeight: updateHeightCallback,


        /**
         * Wrapper function to set all default options for timeline charts.
         */
        setDefaultOptions: function() {
          // Turns on the range selector buttons and sets default config.
          this.enableRangeSelector();

          // Set up styled mode to use CSS vs JS API for (most) styling.
          // this.enableStyledMode();

          // Set initial chart height.
          // this.updateHeight();

          // Turns on the navigator (zoomable scrollbar at the bottom).
          this.enableNavigator();

          // Define events and config for the xAxis.
          this.initRangeConfiguration();

          // Set default config for the labels displayed on points.
          this.enableDataLabels();

          // Enable drag and drop and set callbacks.
          this.enableDragDrop();

          // Custom config for tooltip display.
          this.formatTooltips();

          // Disable the initial chart animation.
          this.disableAnimation();

          // Define default config for the display of the yAxis.
          this.configureYAxisDisplay();
        },

        /**
         * Enable the range selector for this chart using default options.
         */
        enableNavigator: function(redraw = false) {
          // @TODO: Display up to 40 items (but don't leave a bunch of empty space).
          let maxCount = 20;// Math.min(this.chart.series[0].data.length, 40);
          let option = {
            navigator: {
              // enabled: true,
              height: 80,
              margin: 0,
              // series: {
              //   // type: 'gantt',
              //   type: 'line',
              //   // lineWidth: 1,
              // }
              enabled: true,
              liveRedraw: true,
              series: {
                type: 'gantt',
                pointPlacement: 0.1,
                pointPadding: 0.1,
                // lineWidth: 1,
              },
              yAxis: {
                min: 0,
                max: maxCount,
                reversed: true,
                categories: []
              }
            }
          };
          this.chart.update(option, redraw);
        },

        // /**
        //  * Enable styling with CSS using classes rather than just JS themes.
        //  */
        // enableStyledMode: function(redraw = false) {
        //   let option = {
        //     chart: {
        //       styledMode: true
        //     }
        //   };
        //   this.chart.update(option, redraw);
        // },

        /**
         * Enable the range selector for this chart using default options.
         */
        enableRangeSelector: function(redraw = false) {
          let option = {
            rangeSelector: {
              allButtonsEnabled: true,
              inputEnabled: false,
              enabled: true,
              selected: this.defaultButton
            }
          };
          this.chart.update(option, redraw);
        },

        /**
         * Defines the default event listener for the x-axis shifting.
         */
        initRangeConfiguration: function(redraw = false) {

          // The setExtremes event is fired whenever the x-axis shifts.
          // This includes paging or zooming (in or out).
          let option = {
            xAxis: {
              events: {
                // Callback for when the displayed range is updated (zooming).
                setExtremes: this.updateRange
              },
              labels: {
                align: 'center',
              },
              // Add the current date indicator.
              currentDateIndicator: true,
              // Force the xAxis to snap to whole days.
              endOnTick: true,
              // Set the minimum tick to a day.
              minTickInterval: this.day,
              // Don't allow users to zoom in past a single week.
              minRange: this.day * 7
            }
          }

          this.chart.update(option, redraw);
        },

         /**
         * Defines the default event listener for the x-axis shifting.
         */
        configureYAxisDisplay: function(redraw = false) {
          let option = {
            yAxis: {
              // gridLineWidth: 0,
              // breaks: [{
              //   breakSize: .2,
              //   from: 0,
              //   to: 0
              // },
              // {
              //   breakSize: .2,
              //   from: 6,
              //   to: 6
              // }],
              // Set basic style settings for yAxis labels.
              labels: {
                // reserveSpace: true,
                useHTML: true,
                symbol: {
                  // height: 0,
                  y: -8,
                }
                // style: {
                //   fontSize: '15px',
                //   minWidth: '32vw',
                //   wordBreak: 'break-all'
                // },
                // levels: [
                //   {
                //     level: 1,
                //     style: {
                //       height: '20px'
                //     },
                //   },
                //   {
                //     level: 2,
                //     style: {
                //       height: '10px'
                //     }
                //   }
                // ],
                // formatter: function() {
                //   // console.log(this);
                //   let itemClass = 'child';
                //   return '<div class="' + itemClass + '">Test Label</div>'
                // }
              }
            }
          }
          this.chart.update(option, redraw);
        },

        /**
         * Defines the default event listener for the x-axis shifting.
         */
        enableDragDrop: function(redraw = false) {
          let self = this;

          // Enable drag and drop as well as define the events needed.
          let option = {
            plotOptions: {
              series: {
                dragDrop: {
                  draggableX: true,
                  draggableY: false, // TODO: Allow this.
                  dragPrecisionX: this.day
                },
                point: {
                  // This method of adding events allows for a distinction
                  // between `this` (the event) and `this` (ChartService).
                  // Below, `this` refers to the event. Inside the callbacks
                  // `this` refers to ChartService.
                  events: {
                    // drag: function(event) {
                    //   self.dragEvent(this, event);
                    // },
                    drop: function(event) {
                      self.dropEvent(this, event);
                    },
                    dragStart: function(event) {
                      self.dragStartEvent(this, event);
                    },
                  }
                }
              }
            }
          }
          this.chart.update(option, redraw);
        },

        /**
         * Defines the default event listener for the x-axis shifting.
         */
        enableDataLabels: function(redraw = false) {
          var self = this;

          // Set configuration for the labels of each data point.
          let option = {
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  align: 'left',
                }
              }
            }
          };

          if (this.type != 'categories') {
            option.plotOptions.series.dataLabels.formatter = function() {
              // Generate and render a memberpic element for the owner.
              let format = '';
              if (!commonService.empty(this.point.owner)){
                let template = '<div><memberpic uid="' + this.point.owner + '" tooltip="0"></memberpic></div>';
                let memberpic = $compile(template)(self.scope);

                // Trigger a digest which actually renders the memberpic.
                self.scope.$digest();

                // Pass the HTML to the chart.
                format = memberpic.html();
              }
              return format;
            };
          }


          this.chart.update(option, redraw);
        },

        /**
         * Defines the default event listener for the x-axis shifting.
         */
        formatTooltips: function(redraw = false) {

          // Set configuration for the tooltip of each data point.
          let option = {
            plotOptions: {
              gantt: {
                tooltip: {
                  // Remove the default series title header.
                  headerFormat: '',
                  pointFormat: '<b>{point.name}</b><br><span class="date start-date">Start: {point.start:%m/%d/%y}</span><br><br><span class="date end-date">End: {point.end:%m/%d/%y}</span>',
                }
              }
            }
          }
          this.chart.update(option, redraw);
        },

        /**
         * Defines the default event listener for the x-axis shifting.
         */
        disableAnimation: function(redraw = false) {

          // Turn off the animation for when the series is added to the chart.
          let option = {
            plotOptions: {
              series: {
                animation: false
              }
            },

                animation: false
          }
          this.chart.update(option, redraw);
        },

        /**
         * Builds a series object and attaches it to the chart.
         */
        buildSeries: function(seriesData, redraw = false) {
          let self = this;
          let series = {
            data: seriesData,
            events: {
              // Add a click event for the task.
              click: function(event) {
                // Bail on the event if there isn't a parent.
                // Parents define their own click events.
                if (event.point.hasOwnProperty('parent')) {
                  taskService.openTaskModal(event.point.id);
                }
                // Highcharts fires a dragging
                self.dragging = false;
              }
            },
          };

          // Set lower column tallness.
          this.chart.update({series: [series]}, redraw);
          this.updateHeight(this.chart, series);
          return series;
        },

        /*******************
         * Event Callbacks
         *******************/

        /**
         * Event callback for when the chart range is triggered.
         */
        updateRange: function(event) {
          // Determine the source of the event.
          // if (event.trigger && event.trigger == 'rangeSelectorButton') {
          //   if (event.hasOwnProperty('rangeSelectorButton')) {
          //     let button = event.rangeSelectorButton;
          //     let start = moment().utc();
          //     let end = moment().utc();
          //     switch (button.text) {
          //       case '1m':
          //         start.subtract(1, 'week');
          //         end.add(1, 'month');
          //         break;
          //       case '3m':
          //         start.subtract(1, 'month');
          //         end.add(3, 'months');
          //         break;
          //       case '6m':
          //         start.subtract(2, 'months');
          //         end.add(6, 'months');
          //         break;
          //       case 'YTD':
          //         start.startOf('year');
          //         end.add(1, 'week');
          //         break;
          //       case '1y':
          //         start.subtract(2, 'months');
          //         end.add(1, 'year');
          //         break;
          //       case 'All':
          //         start.subtract(1, 'year');
          //         end.add(1, 'year');
          //         break;
          //     }

          //     if (start && end) {
          //       console.log([start.toDate(), end.toDate()]);
          //       console.log(event);

          //       let filtering = filterService.getFiltering();
          //       filtering.selection.start = start.toDate();
          //       filtering.selection.end = end.toDate();

          //       $rootScope.$emit('filterUpdate');

          //       console.log(this);
          //       this.setExtremes(start.unix(), end.unix(), true);
          //     }
          //   }
          // }
        },

        /**
         * Event handler for 'dragEvent' event.
         *
         * @TODO: Remove this. Not in use.
         */
        dragEvent: function(point, event) {
          // Called continuously during the drag.
        },

        /**
         * Event handler for 'dropEvent' event.
         *
         * Called when the drag stops.
         *
         * @TODO: This is also called when dragging projects, but doesn't work.
         */
        dropEvent: function(point, event) {

          // Get the ID of the point that was dragged (the task ID).
          let taskId = event.target.id;

          // Get the original values. In cases where only one end of the task
          // was dragged, only the changed value is set in newPoints.
          let origVals = event.origin.points[taskId];

          // Get the new/changed values.
          let newVals = event.newPoints[taskId].newValues;

          // TODO: This assumes that origVals is always set.
          // Get a value for both the start and end (using the original value as
          // necessary).
          let start = (newVals.hasOwnProperty('start')) ? newVals.start : origVals.start;
          let end = (newVals.hasOwnProperty('end')) ? newVals.end : origVals.end;

          // Build the fields object to be passed to taskUpdate.
          var format = 'YYYY-MM-DD\THH:mm:ss';
          let fieldVal = {
            value: moment.utc(start).format(format),
            end_value: moment.utc(end).format(format)
          };
          // console.log(event);
          // console.log(fieldVal);
          let fields = {
            field_date: fieldVal
          };

          // TODO: This is a hack to update the task start/end dates.
          let task = taskListService.getTaskById(taskId);
          task.start = fieldVal.value;
          task.end = fieldVal.end_value;

          // TODO: This method of updating the task value updates field_date,
          // not start and end. This results in the task reverting to the
          // original values before the task refresh updates the start/end.
          // Save the update, this updates the layout directly.
          taskService.updateTask(taskId, fields, null, true);


          // Indicate that the drag is over.
          this.dragging = false;
        },

        /**
         * Event handler for 'dragStartEvent' event.
         *
         * Called when the drag event starts.
         */
        dragStartEvent: function(point, event) {
          // Indicate that a drag is in progress.
          // This prevents the chart from reloading mid-drag.
          this.dragging = true;
        },

         /********************************
         * Rendering and Preprocessing
         *********************************/

        /**
         *
         */
        loadData: function(lists, field) {
          // Reset the mapping if this is a categories chart. Categories
          // are re-generated on each reload.
          if (this.type != 'categories') {
            this.parentMap = {};
            this.parents = [];
          }

          // Start from a clean slate of task data.
          this.seriesData = [];

          let self = this;

          this.lists = lists;

          // Iterate over all of the lists to add their tasks to the series.
          angular.forEach(lists, function(list) {
            // If there's only one list assume the chart is grouped by field.
            // @TODO: Not a great assumption.
            if (lists.length == 1) {
              self.groupByField(list.tasks, list, field);
            }
            else {
              // @TODO: This doesn't actually do anything.
              self.groupByList(list.tasks, list);
            }
          });

          // Set the categories for the chart.
          if (this.type == 'categories') {
            let options = {
              yAxis: {
                categories: this.categories,
                type: 'categories' // TODO: Should this be set to categories?
              }
            };
            this.chart.update(options, true);
          }
          // All other chart types get the same treatment.
          else {
            // Sort parents by their scheduled start date.
            // @TODO: I belive this is needed by highcharts to not throw an error.
            //  Needs confirmation.
            this.parents.sort(function(a, b) {
              if (a.hasOwnProperty('start')) {
                if (b.hasOwnProperty('start')) {
                  if (a.start > b.start) {
                    return 1;
                  }
                }
                return -1
              }
              return 1;
            });

            // Parents are actually part of the series itself. Add them.
            this.seriesData = this.parents.concat(this.seriesData);
          }
          this.buildSeries(this.seriesData, true);
        },

        /**
         * @TODO: This is the callback for grouping based off of the layout.
         *   Possibly not necessary?
         */
        groupByList: function(tasks, list) {

        },

        /**
         *
         */
        groupByField: function(tasks, list, field = 'project') {
          let self = this;
          // @TODO: This should probably change it's behavior based on how many
          // projects are currently filtered. E.g. Only one project.

          // @TODO: This is currently not implemented and totally untested.
          // Set the field based on the layout list field.
          if (!commonService.empty(list.field)) {
            field = list.field;
          }

          // Load each task and create a data point.
          angular.forEach(tasks, function(nid) {
            let task = taskListService.getTaskById(nid);

            // Assume no grouping until known otherwise.
            let grouping = 0;

            // If the task doesn't have the field, it goes into a default group.
            if (!commonService.empty(task[field])) {
              grouping = task[field];
            }

            // Attempt to get/create the parent grouping.
            let parentVal = self.getSeriesGroup(grouping, field);

            // Add the task to the series. Parents are added separately.
            self.seriesData.push(self.createDataPoint(task, parentVal));

          });
        },

        /**
         * Returns the parent for this task.
         * Creates the parent if it doesn't exist.
         */
        getSeriesGroup: function(grouping, field) {
          // Handling for default grouping.
          if (commonService.empty(grouping) || grouping == 0) {
            grouping = 'none';
          }

          // Intialize all the settings for the parent.
          let type = null;
          let color = null;
          let start = null;
          let end = null;

          // Attempt to look up the parent. If none is found, create one.
          let parentMapVal = null;
          if (!this.parentMap.hasOwnProperty(grouping)) {
            // Set the default grouping name.
            let name = 'Not Grouped';

            // Special handling for grouping by project.
            if (field == 'project') {

              var project = accountService.getObjectById('projects', grouping);
              if (project) {

                // Get the title and other values from the project.
                name = project.title;
                // if (project.hasOwnProperty('field_date_auto_adjust')
                //   && project.field_date_auto_adjust == '0') {

                  if (project.hasOwnProperty('start')) {
                    start = project.start;
                  }
                  if (project.hasOwnProperty('end')) {
                    end = project.end;
                  }
                // }

                type = 'project';
                color = project.field_project_color;
              }
            }
            // Alternate handling for task owner grouping.
            else if (field == 'field_task_owner') {
              // @TODO: Currently task owners are always an array (if not empty).
              if (typeof grouping == 'object') {
                // Assume that the first owner (only) is correct.
                grouping = grouping[0];

                // Load the user and set grab usable info.
                let member = accountService.getObjectById('members', grouping, 'uid');

                if (member) {
                  name = member.display_name;
                }
                else {
                  grouping = 'none';
                }
                type = 'user';
              }
            }

            // Add the parent to the categories if this is a categories chart.
            if (this.type == 'categories') {
              this.categories.push(name);

              // Get the index of the parent we just made to make lookups easier.
              // Since categories are 'pushed' the index is length - 1.
              this.parentMap[grouping] = this.categories.length - 1;

            }
            else {
              // Create a new parent point.
              let parent = {
                id: grouping,
                name: name,
                // Set the height of the parent line (in pixels).
                // pointWidth: 20,
                // @TODO: This doesn't work.
                collapsed: true,
                dragDrop: {
                  // Don't allow re-categorizing parents. (Doesn't make sense.)
                  draggableY: false
                }
              };

              // If there's a start and end date, then set them.
              if (start && end) {
                parent.start = moment.utc(start).valueOf()
                parent.end = moment.utc(end).valueOf();
              }
              // If there isn't an explicit start/end date, don't allow dragging
              // the parent.
              // TODO: Only disable dragging on the xAxis if the project is auto
              // scheduled.
              // else {
                parent.dragDrop.draggableX = false;
              // }

              // Set the color if one was specified.
              if (color) {
                parent.className = 'color-' + color;
              }

              // Projects get their own click handler.
              if (type == 'project') {
                parent.events = {
                  click: function(event) {
                    $rootScope.openProjectModal(event.point.id);
                  }
                };
              }

              // Add the parent to parents and add it to the mapping.
              this.parents.push(parent);
              this.parentMap[grouping] = grouping;
            }
          }

          // Return the key to be used for nesting the child values.
          if (this.type == 'categories') {
            parentMapVal = this.parentMap[grouping];
          }
          else {
             parentMapVal = grouping;
          }

          return parentMapVal;
        },

        /**
         * Callback to create a data point from a task.
         */
        createDataPoint: function(task, parent) {
          // Load the project from the task. (Even if not grouped by project).
          let project = accountService.getObjectById('projects', task.project);
          let point = {
            id: Number(task.nid),
            name: task.title,
            start: moment.utc(task.start).valueOf(),
            end: moment.utc(task.end).valueOf(),
            // @TODO: Should be used for tooltip generation.
            projectName: project.title,
            // Set the height of the task line (in pixels).
            // pointWidth: 10,
            // Add the project color as a class.
            className: 'color-' + project.field_project_color,
            owner: task.field_task_owner,
          }

          // Set the parent based on the chart type.
          if (this.type == 'categories') {
            // Categories have a soft relation to the parent. Uses the category
            // index rather than a direct reference.
            point.y = parent;
          }
          else {
            // Directly relate the task to it's parent.
            point.parent = parent;
          }
          return point;
        }
      };

      return (ChartService);
    }
  ]);
})();

