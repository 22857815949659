(function () {
  'use strict';

  var app = angular.module('calendar', [
    'ngRoute',
    'angular-drupal',
    'ui.calendar',
    'filterpane'
  ]);

  app.constant("moment", moment);

  app.config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/:accountId/projects/:projectId/calendar/:taskId?', {
      templateUrl: 'app/src/calendar/calendar.html',
      controller: 'CalendarCtrl',
      activetab: 'calendar',
      reloadOnSearch: false
    });
  }]);

  app.controller('CalendarCtrl', [
    '$scope',
    '$rootScope',
    'RestResource',
    'accountService',
    'routingService',
    'filterService',
    'refreshService',
    'uiCalendarConfig',
    '$timeout',
    'taskListService',
    'taskService',
    'layoutService',
    'searchService',
    'commonService',
    'moment',
    '$compile',
    '$mdPanel',
    'featureService',
    function ($scope, $rootScope, RestResource, accountService, routingService, filterService, refreshService, uiCalendarConfig, $timeout, taskListService, taskService, layoutService, searchService, commonService, moment, $compile, $mdPanel, featureService) {
      $scope.filtering = filterService.getFiltering();
      $scope.eventSources = [];
      $scope.allEvents = [];
      $scope.filteredEvents = {
        events: []
      };
      $scope.eventsIdList = [];
      $scope.eventArray = [];
      $scope.eventsList = [];
      $scope.rendered = false;
      $scope.skipRefetch = false;

      $scope.$on('$destroy', function () {
        $rootScope.destroyListeners($scope);
      });

      routingService.viewInit();

      // No grouping here.
      // layoutService.setLayout(0);
      // Show 100 rows, hopefully getting everything in since there's no
      // concept of calendar scrolling.
      // @TODO: add calendar loadmore capability.
      searchService.setDefaultLimit(100);

      // Called
      $scope.init = function () {
        layoutService.loadLayout().then(function(lists) {

          $scope.uiConfig = {
            calendar: {
              contentHeight: $scope.getCalHeight,
              editable: true,
              eventStartEditable: true,
              eventDurationEditable: featureService.featureStatus('calendar_drag_to_resize'),
              header: {
                left: 'month,basicWeek',
                center: 'title',
                right: 'today prev,next'
              },
              displayEventTime: false,
              navLinks: featureService.featureStatus('calendar_add_task_from_day'),
              navLinkDayClick: $scope.navLinkDayClick,
              eventLimit: true,
              eventClick: $scope.openTaskModal,
              eventRender: $scope.eventRender,
              eventDrop: $scope.updateTaskDates,
              eventResize: $scope.updateTaskDates,
              views: {
                basic: {
                  eventLimit: 20
                },
                week: {
                  eventLimit: 30
                }
              },
              dragScroll: false,
              eventDragStart: $scope.dragStart,
              eventRenderWait: 100,
              windowResize: $scope.updateCalendarHeight,
              viewRender: $scope.viewRender,
              eventAfterAllRender: $scope.eventAfterAllRender
            }
          };

          // Ensure calendar is loaded correctly on init.
          uiCalendarConfig.calendars.calendar1.fullCalendar('render');

          // And then force a height recalculation.
          $scope.updateCalendarHeight();

          $rootScope.$emit('viewReady');
        });
      };

      // Open the task modal on event click.
      $scope.openTaskModal = function (event, jsEvent, view) {
        taskService.openTaskModal(event.nid);
      };

      // Adds project class to events as they are rendered.
      $scope.eventRender = function (event, element) {
        var className = '';
        var eventProject = '';
        if (event.project) {
          var projectId = (event.project.id) ? event.project.id : event.project;
          eventProject = accountService.getObjectById('projects', projectId);
          if (eventProject && eventProject.field_project_color) {
            className = 'project-task color-' + eventProject.field_project_color;
          }
        }
        element.addClass(className);
      };

      // Handle event dragging to update dates.
      $scope.updateTaskDates = function (event, delta, revertFunc, jsEvent, ui, view) {
        var start = event.start.clone().startOf('day').format();
        var end;
        var task = taskListService.getTaskById(event.nid);

        var new_date = {
          "value": start
        };
        task.start = start;

        // Should always be true.
        if (event.end) {
          // Reconcile FCs tomorrow at 00:00:00 with other's today at 23:59:59.
          // See: https://fullcalendar.io/docs/v3/event-object
          end = event.end.clone().subtract(1,'d').endOf('day').format();
          new_date.end_value = end;
          task.end = end;
        }
        taskListService.updateTaskVals(task.nid, task);
        refreshService.appRefresh();

        // Push the updated task.
        RestResource.entityFieldUpdate([{
          "entity_type": "node",
          "entity_bundle": "task",
          "entity_id": event.nid,
          "fields": {
            "field_date": new_date
          }
        }]).then(function () {
          // Only after a response should dragging be considered complete.
          $scope.isDragging = false;

          // If a refetch miss occured while dragging, refetch now.
          if ($scope.dragMiss) {
            $scope.dragMiss = false;
          }
        });
      };

      // For padding.
      $scope.calHeightOffset = 86;
      $scope.calHeightMin = 570;
      $scope.getCalHeight = function (events) {
        var mainContent = angular.element(document.getElementById('mainView')),
          parentHeight = mainContent[0].clientHeight,
          returnHeight = 0;

        if (parentHeight < $scope.calHeightMin) {
          returnHeight = $scope.calHeightMin;
        }
        else {
          returnHeight = parentHeight;
        }

        return returnHeight - $scope.calHeightOffset;
      };

      $scope.updateCalendarHeight = function () {
        if (uiCalendarConfig.calendars.calendar1) {
          uiCalendarConfig.calendars.calendar1.fullCalendar('option', 'height', $scope.getCalHeight());
        }
      };

      // Reload Calendar Height once calendar is rendered.
      $scope.viewRender = function (view, element) {

        $scope.updateCalendarHeight();
      };

      $scope.eventAfterAllRender = function (view) {
        $scope.rendered = true;
        $scope.updateCalendarHeight();
      };

      $scope.dragStart = function (task) {
        $scope.isDragging = true;
      };

      $scope.navLinkDayClick = function(date, jsEvent) {
        // Get a date "within the currently displayed month".
        let calDate = uiCalendarConfig.calendars.calendar1.fullCalendar('getDate');
        // Only trigger add popup for same month.
        if (date.isSame(calDate, 'month')) {
          console.log(jsEvent);
          $scope.showAddPanel(jsEvent.target, date);
        }
      };

      // Creates a panel for adding a new task.
      $scope.showAddPanel = function(el, date) {
        console.log(el);
        let position = $mdPanel.newPanelPosition().relativeTo(el).addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.ALIGN_TOPS);
        let startDate = date.clone().startOf('day').format();
        let endDate = date.clone().endOf('day').format();
        let presets = {
          field_date: {
            "value": startDate,
            "end_value": endDate
          }
        };

        layoutService.loadLayout().then(function(lists) {
          // Grab the first list in the default layout.
          // @TODO take advantage of upcoming default status.
          let listsKeys = Object.getOwnPropertyNames(lists);
          // 0 is the length property, so 1 is the first.
          let list = lists[listsKeys[1]];

          // Appending dialog to document.body to float above full application.
          $mdPanel.open({
            attachTo: angular.element(document.body),
            controller: [
              '$scope', 'presets', 'list', 'mdPanelRef',
              function($scope, presets, list, mdPanelRef) {
                $scope.presets = presets;
                $scope.list = list;
                // Close on save (taskForm consumes this).
                $scope.onSave = function() {
                  mdPanelRef.close();
                };
              }],
            panelClass: 'add-task-dialog',
            position: position,
            locals: {
              presets: presets,
              list: list
            },
            template: '<task-form form-only="true" presets="presets" list="list" on-save="onSave"></task-form>',
            trapFocus: true,
            zIndex: 150,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true
          });
        });
      };

      // Convert an id to a full blow task.
      // This basically takes the place of the minicards on the board.
      $scope.eventDataTransform = function (eventData) {
        let task = taskListService.getTaskById(eventData);
        // Note that FullCalendar v3 has no concept of what to do if this isn't
        // a parsable event and will throw an error (v4 accepts false to skip).
        // Given that, fake away the errors for now. @todo: V4!
        if (!task.start) {
          task = {
            title: task.title ? task.title : '',
            start: '2000-01-01'
          }
        }

        // Ensure start and end are Moments in the correct day.
        if (typeof task.end == 'string'){
          task.start = moment(task.start).startOf('day').add(1, 'seconds');
          task.end = moment(task.end).endOf('day').add(1, 'seconds');
        }

        // Required for resizing.
        task.allDay = true;

        return task;
      };

      // Callback function to fetch events for the calendar.
      $scope.getEvents = function (start, end, timezone, callback) {
        // Force set filtration variables for date, so this mimics the rest of
        // the filtration system.
        $scope.filtering.selection.start = start.toDate();
        $scope.filtering.selection.end = end.toDate();
        // @todo: Prevent double fetches.
        $scope.skipRefetch = true;
        taskListService.getTasks({
          start: start.toDate(),
          end: end.toDate()
        }).then(function(tasks) {
          $scope.skipRefetch = false;
          // Simply grab the list of task ids from the taskListService based on
          // the date-restricted search just performed.
          callback(taskListService.getCurrentTaskList(true));
        });
      };

      // Sets the main event source. This is called on every view update.
      $scope.eventSources.push({
        // Function call to fetch the event nids.
        events: $scope.getEvents,
        // Lookup function to get full (parsable Event) task objects from ids.
        eventDataTransform: $scope.eventDataTransform
      });

      let filterListener = $rootScope.$on('filterUpdate', function () {
        if ($scope.rendered) {
          // Allow skipping once if currently fetching.
          if ($scope.skipRefetch) {
            $scope.skipRefetch = false;
            return;
          }
          // Skip fetching when dragging.
          if ($scope.isDragging) {
            $scope.dragMiss = true;
            return;
          }

          uiCalendarConfig.calendars.calendar1.fullCalendar('refetchEvents');
        }
      });
      $rootScope.addListener(filterListener, $scope);

      // Refetch Events as long as that won't cause issues.
      let refreshHandler = function () {
        // Allow skipping up to twice if currently fetching.
        if ($scope.skipRefetch) {
          // Allow refetch next time if 2 have been skipped.
          if ($scope.skipRefetch == 2) {
            $scope.skipRefetch = false;
          }
          else {
            $scope.skipRefetch = 2;
          }
          return;
        }
        // Skip fetching when dragging.
        if ($scope.isDragging) {
          $scope.dragMiss = true;
          return;
        }

        uiCalendarConfig.calendars.calendar1.fullCalendar('refetchEvents');
      };

      // Refetch events on taskList updates and appRefreshes.
      let tasklistListener = $rootScope.$on('taskListLoaded', refreshHandler);
      $rootScope.addListener(tasklistListener, $scope);
      let refreshListener = $rootScope.$on('appRefresh', refreshHandler);
      $rootScope.addListener(refreshListener, $scope);
    }
  ]);

})();
