(function () {
  'use strict';

  var app = angular.module('tracking');

  app.service('trackingService', [
    '$rootScope',
    function ($rootScope) {
      this.registerEvent = function (eventName, eventData) {
        // Send page changes to AppCues.
        if (AppConfig.trackOn) {
          Appcues.track(eventName, eventData);
        }
      };
      this.eventTaskOpened = function (task) {
        this.registerEvent("Opened Task", {
          taskID: task.nid,
          taskName: task.title
        });
      };
    }
  ]);
})();