(function () {
  'use strict';

  let app = angular.module('folders', []);

  app.directive('folderForm', function () {
    return {
      scope: {
        onSave: '=?',
        onCancel: '=?',
        folder: '=?'
      },
      controller: [
      '$scope',
      '$element',
      '$timeout',
      'RestResource',
      'accountService',
      'refreshService',
      function ($scope, $element, $timeout, RestResource, accountService, refreshService) {
        $scope.folderName = '';
        $scope.isSaving = false;
        $scope.folderInvalid = false;

        // Default form to folder title if one exists.
        if ($scope.folder && $scope.folder.title) {
          $scope.folderName = $scope.folder.title;
        }

        $scope.afterSave = function () {
          // Run callback if applicable.
          if (typeof $scope.onSave == 'function') {
            $scope.onSave($scope.folder);
          }

          // Blank out the form.
          $scope.folderName = '';
          $scope.isSaving = false;
          refreshService.appRefresh();
        };

        $scope.saveFolder = function () {
          // Validate.
          if ($scope.folderName.length === 0) {
            $scope.folderInvalid = true;
            return;
          }
          else {
            $scope.folderInvalid = false;
          }

          // Only allow one folder to be saved at a time.
          if ($scope.isSaving) {
            return;
          }

          $scope.isSaving = true;
          var request = {
            title: $scope.folderName
          };

          accountService.getAccount().then(function(account) {
            $scope.account = account;
            var accountId = account.entity.id;
            request.__attach = {
              "entity_id": accountId
            };

            // Update an existing label.
            if ($scope.folder && $scope.folder.id) {
              // Fake save the title
              $timeout(function () {
                $scope.$parent.projectGroups[$scope.folder.id].title = $scope.folderName;
                if ($scope.account.project_groups[$scope.folder.id]) {
                  $scope.account.project_groups[$scope.folder.id].title = $scope.folderName;
                }
              });

              request.id = $scope.folder.id;
              RestResource.eckEntityUpdate('tag', 'folder', request.id, request).then(function () {
                $scope.afterSave();
              });
            }
            // Instantiate a new folder label with the info provided.
            else {
              RestResource.eckEntityCreate('tag', 'folder', request).then(function (response) {
                // Add new item to folders list immediately.
                var newFolder = response;
                newFolder._lowercase = response.title.toLowerCase();
                $scope.account.folders.push(newFolder);

                $scope.afterSave();
              });
            }
          });
        };

      }],
      templateUrl: 'app/src/components/folders/folders-form.html'
    };
  });
})();
