(function () {
  'use strict';

  var app = angular.module('tasklist-service', ['angular-drupal', 'account', 'rest']);

  app.service('taskListService', [
    'RestResource',
    '$rootScope',
    '$route',
    'entityListService',
    'commonService',
    function (RestResource, $rootScope, $route, entityListService, commonService) {

      // Initialize the entityList service and overwrite any functions needed.
      var entityService = new entityListService('tasklist', 'task', 'nid', '', 'taskListLoaded');

      // Initialize the service with the values for this instance.
      entityService.serviceInit();

      // Provide a wrapper around digestEntities that provides the proper
      // entity list.
      entityService.digestTasks = function(changes) {
        return entityService.digestEntities(changes);
      };

      // Provide function aliases for ease of use.
      entityService.getTasksByIds = entityService.getEntitiesByIds;
      entityService.getTaskById = entityService.getEntityById;
      entityService.fetchTaskById = entityService.fetchEntityById;
      entityService.getTaskList = entityService.getEntityList;
      entityService.getCurrentTaskList = entityService.getCurrentEntityList;
      entityService.removeTask = entityService.removeEntity;
      // entityService.addToCurrentTaskList = entityService.addtoCurrentList;
      entityService.getTasks = entityService.getEntities;
      entityService.updateTaskVals = entityService.updateEntityVals;

      let updateNested = function(entity, fieldId, value, key = 'cid') {
        let basic = true;
        if (entity[fieldId] && Array.isArray(entity[fieldId])) {
          if (value && typeof value == 'object') {
            basic = false;
            let itemMap = entity[fieldId].map(entityItem => entityItem[key]);

            angular.forEach(value, function(item, itemIndex) {
              let index = itemMap.indexOf(item[key]);
              if (index !== -1) {
                angular.forEach(item, function(itemVal, property) {
                  entity[fieldId][index][property] = itemVal;
                });
              }
              else {
                entity[fieldId].push(item);
              }
            });
          }
        }
        if (basic) {
          entity[fieldId] = value;
        }
      }

      entityService.updateMapping = {
        task_custom_value: updateNested
      }

      $rootScope.$on('appRefresh', function(event, args) {
        // Don't try to refresh an un-initted tasklist, and tasks aren't shown
        // on the dashboard.
        if (entityService.isInitted() && $route.current.activetab != 'dashboard') {
          entityService.refresh();
        }
      });

      // On filter update, trigger a new search.
      $rootScope.$on('filterUpdate', function(event, args) {
        entityService.reinit();
      });

      // Listen for a task update where a new task is created.
      $rootScope.$on('taskUpdate', function(event, task, unchanged, updates, hash) {
        // If unchanged is empty but updates isn't, then this is a new task.
        if (commonService.empty(unchanged) && !commonService.empty(updates)) {
          // Increment the total task count to reflect the new task.
          entityService.incrementCount(1);
        }
      });

      return entityService;
    }
  ]);
})();
