(function () {
  'use strict';

  angular.module('memberpic', ['angular-drupal', 'account'])
    .directive('memberpic', function () {
      return {
        restrict: 'E',
        scope: {
          uid: "=",
          tooltip: "=?",
          owner: "=?"
        },
        controller: [
          '$scope',
          'accountService',
          function ($scope, accountService) {
            $scope.$watch('uid', function () {

              $scope.user = accountService.getObjectById('members', $scope.uid, 'uid');

              if (typeof $scope.user == 'undefined') {
                $scope.user = accountService.getUnassignedUser();
              }

              if (!angular.isDefined($scope.tooltip)) {
                if (!angular.isDefined($scope.owner)) {
                  $scope.tooltip = $scope.user.display_name;
                }
                else {
                  $scope.tooltip = $scope.user.display_name + ' (Project Owner)';
                }
              }

            });
          }
        ],
        templateUrl: 'app/src/components/memberpic/memberpic.html'
      };
    });
})();
