(function () {
  'use strict';
  var app = angular.module('customfield', ['angular-drupal', 'user', 'angular.filter', 'labelpanel', 'colorbox', 'xeditable']);
  app.config(['$mdDialogProvider', function ($mdDialogProvider) {
    $mdDialogProvider.addPreset('customFieldFormModal', {
      options: function () {
        return {
          controller: 'CustomFieldFormController',
          templateUrl: 'app/src/components/customfield/customFieldForm.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          escapeToClose: true,
          multiple: true,
          hasBackdrop: false,
        };
      }
    });
  }]);

  app.controller('CustomFieldFormController', [
    '$scope',
    '$mdDialog',
    '$mdToast',
    'edit_data',
    'customFieldService',
    'accountService',
    '$timeout',
    'listLocalService',
    'commonService',
    function ($scope, $mdDialog, $mdToast, edit_data, customFieldService, accountService, $timeout, listLocalService, commonService) {
      $scope.customField = {};

      // This flag is for buttons display (Create/Update).
      $scope.is_edit = false;

      // This is for storing current selected value.
      // $scope.customField.selectedValue = {
      //   id: null
      // };
      // Bundle of the edited field.
      $scope.customField = {
        type: '',
        subType: {
          id: 'default'
        },
        title: ''
      };

      // $scope.customField.newFieldType = '';
      // // Current field's sub-type selected (like email or select-list).
      // $scope.customField.subType = 'default';
      // // Title for custom field.
      // $scope.customField.newCustomFieldName = ' ';

      $scope.subTypeList = [];
      // List of options for bundle.
      $scope.optionsList = [];
      $scope.optionsLabel = 'Selected Options';
      // List of reference item (users and tags).
      $scope.customField.optionslist = [];
      $scope.optionCreateCount = 0;
      // Placehoder for reference select list.
      $scope.placeholder = "+ Add option";

      // NOTE: changes to any label below needs to be synced with backend
      // (in "/admin/structure/views/view/account_fields" view).
      const fieldTypeMap = {
        textfield: [
          {
            label: "Plain Text",
            id: "plain_text"
          },
          {
            label:  "URL",
            id: "url"
          },
          {
            label: "Email Address",
            id: "email_address"
          },
          {
            label: "Phone Number",
            id: "phone_number"
          },
          {
            label: 'Select List',
            id: 'select_list'
          }
        ],
        number: [
          {
            label: "Number",
            id: "number"
          },
          {
            label: "Money",
            id: "money"
          },
          {
            label: 'Select List',
            id: 'select_list'
          }
        ]
      };

      // Set CustomFieldTypes.
      $scope.customFieldTypes = {
        textfield: {
          title: "Textfield",
          validationField: 'field_text_validation',
          selectListField: 'field_text_select_list_options',
          bundle: "textfield"
        },
        number: {
          title: "Number",
          validationField: 'field_number_validation',
          selectListField: 'field_number_select_list_options',
          bundle: "number"
        },
        boolean: {
          title: 'Checkbox',
          bundle: 'boolean',
          field_name: 'field_default_value',
        },
        users: {
          title: "User",
          field_name: "field_user",
          bundle: "users"
        },
        tag: {
          title: "Tag",
          field_name: "field_tag",
          bundle: "tag"
        },
        colored_value: {
          title: 'Colored value',
          field_name: 'field_tag',
          bundle: 'colored_value',
        },
      };

      // Load account service.
      accountService.getAccount().then(function (account) {
        $scope.account = account;
        if (edit_data) {
          // This flag is for buttons display (Create/Update).
          $scope.is_edit = true;
          // Options are clear in edit.
          $scope.customField.options = true;
          // This is for field popuplation.
          $scope.customField.title = edit_data.title;
          // Title of the modal.
          $scope.ariaLabel = 'Edit Custom Field';
          // Id of the edited field.
          $scope.customField.edit_id = edit_data.id;
          // Bundle of the edited field.
          $scope.customField.type = edit_data.bundle;
          // $scope.customField.subType = $scope.customFieldTypes[edit_data.bundle].title;

          // Load options for edit.
          let type = $scope.customField.type;
          $scope.updateOptions(type);
          $scope.subTypeList = fieldTypeMap[type] || [];
          // $scope.customField.aggregationtypes = getAggregationType[type];
          $scope.loadStoredOptions(type, edit_data.id);

          if ($scope.subTypeList.length) {
            // Get selected sub-type.
            let subType = edit_data.field_type;
            let typeList = $scope.subTypeList;
            for (let i = 0; i < typeList.length; ++i) {
              let current = typeList[i];
              if (current.id === subType) {
                $scope.customField.subType = current;
                break;
              }
            }
          }
        }
        else {
          //Add Modal Title.
          $scope.ariaLabel = 'New Custom Field';
        }

      });

      // Mark as read for modal.
      $scope.dialogReady = true;

      // Custom field save.
      $scope.onFormSubmit = function () {

        // Assign general values.
        let type = $scope.customField.type;
        const subType = $scope.customField.subType.id;
        let data = {
          title: $scope.getValidTitle(),
        };

        // Ensure that all required fields have an input.
        let msg = '';
        if (!data.title) {
          msg = 'Field Name';
        }
        // If there's a subType, make sure they made a selection.
        else if (subType === 'default' && $scope.subTypeList.length > 0) {
          msg = 'Sub-Type';
        }
        // Show a message to the user informing them of the error and bail.
        if (msg) {
          $scope.toast(`Warning: ${msg} is required!`);
          return null;
        }

        customFieldService.updateOptionEntities($scope.customField).then(function() {

          // Assign values of the field based on type and sub-type
          // (first for Select-list sub-type).
          if (subType === 'select_list') {
            let fieldName = $scope.customFieldTypes[type].selectListField;
            if (fieldName) {
              let list = $scope.customField.optionslist;
              let mapped = [];
              for (let i = 0; i < list.length; ++i) {
                mapped.push(list[i].title);
              }
              data[fieldName] = mapped;
            }
          }
          // Reference fields are treated differently.
          let fieldName = $scope.customFieldTypes[type].field_name;
          if (fieldName) {
            if (type === 'users' || type === 'tag' || type === 'colored_value') {
              let list = $scope.customField.optionslist;

              data[fieldName] = $scope.getReferenceList(list);
            }
            // else if (type === 'boolean') {
            //   const {id} = $scope.customField.selectedValue;
            //   data[fieldName] = id;
            // }
            // else {
            //   data[fieldName] = $scope.customField.selectedValue;
            // }
          }

          // Store sub-type id for later validation (like text and
          // number fields for example).
          const {validationField} = $scope.customFieldTypes[type];
          if (validationField) {
            data[validationField] = $scope.customField.subType.id;
          }

          // Request changes on backend.
          let result;
          if ($scope.is_edit) {
            result = customFieldService.updateCustomField(type, data, $scope.customField.edit_id);
          }
          else {
            // Set "__attach" optionally (Backend is able to detect account,
            // but maybe user has multiple accounts).
            data.__attach = {
              entity_id: $scope.account.entity.id
            };

            // This label is used for listLocalService.
            let label = $scope.customField.subType.label;
            if (!label) {
              label = type;
            }
            // let label = getFieldLabel($scope.customField.subTypeList, $scope.customField.subType.id);

            // Create the custom field.
            result = customFieldService.createCustomField(type, data, label);
          }
          $scope.afterResponse();
          return result;
        });
      };

      // Shows warning messages
      $scope.toastDialog = null;
      $scope.toast = (msg) => {
        const options = $mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(2000);
        $scope.toastDialog = $mdToast.show(options);
        return $scope.toastDialog;
      };

      // Returns the title (only if it's valid).
      $scope.getValidTitle = function () {
        const title = $scope.customField.title;
        return typeof title === 'string' ? title.trim() : null;
      };

      // View settings.
      $scope.isListable = () => {
        if ($scope.customField.subType.id === 'select_list') {
          return true;
        }
        const type = $scope.customField.type;
        return type === 'tag' ||
          type === 'users'
          || type === 'colored_value';
      };
       $scope.isEditable = () => {
        const type = $scope.customField.type;
        return type == 'colored_value';
      };
      $scope.isDeletable = () => {
        const type = $scope.customField.type;
        return type !== 'boolean';
      };

      // Load options when user select a custom field.
      $scope.loadOption = function (field_type) {
        $scope.subTypeList = fieldTypeMap[field_type] || [];

        $scope.updateOptions(field_type);
        $scope.customField.options = true;
        $scope.customField.type = field_type;
      };

      $scope.updateOptions = function (type) {
        $scope.optionsList = [];
        switch (type) {
          case 'users': {
            const memberList = $scope.account.members;
            // Prepare list of users added for the field.
            Object.keys(memberList).forEach(function (index) {
              const user = {
                id: memberList[index].uid,
                title: memberList[index].display_name,
              };
              $scope.optionsList.push(user);
            });
            break;
          }
          case 'tag':
            if (Array.isArray($scope.account.tags)) {
              $scope.optionsList = $scope.account.tags;
            }
            else {
              // Ensures "optionsList" is always an array.
              for (const prop in $scope.account.tags) {
                if ($scope.account.tags.hasOwnProperty(prop)) {
                  $scope.optionsList.push($scope.account.tags[prop]);
                }
              }
            }
            break;
          case 'colored_value':
            if (Array.isArray($scope.account.colored_vals)) {
              $scope.optionsList = $scope.account.colored_vals;
            }
            else {
              // Ensures "optionsList" is always an array.
              for (const prop in $scope.account.colored_vals) {
                if ($scope.account.colored_vals.hasOwnProperty(prop)) {
                  $scope.optionsList.push($scope.account.colored_vals[prop]);
                }
              }
            }
            break;

        }
      };

      // Store the selected option of the field.
      $scope.onOptionSelected = function (value) {
        // $scope.customField.selectedValue = value;
        for (let i = 0; i < $scope.customField.optionslist.length; ++i) {
          const current = $scope.customField.optionslist[i];
          if (current.id === value.id) {
            return;
          }
        }
        $scope.customField.optionslist.push(value);
      };

      $scope.newValue = {
        value: '',
        color: false
      };

      // Handles Select-list option addition.
      $scope.onOptionEnter = function () {
        let value = $scope.newValue.value;
        let color = $scope.newValue.field_label_color;
        $scope.newValue.value = '';
        $scope.newValue.field_label_color = false;

        if (typeof value === 'string') {
          value = value.trim();
        }

        if (value) {
          $scope.optionCreateCount += 1;
          const entry = {
            title: value,
            field_label_color: color,
            // Negative value means the option needs to be created:
            id: 0 - $scope.optionCreateCount,
          };

          // Prevents duplicate entries
          // (we don't simply call "onOptionSelected(...)" as
          // that checks for duplicate Ids).
          const options = $scope.customField.optionslist;
          for (let i = 0; i < options.length; ++i) {
            if (options[i].title === value) {
              return;
            }
          }
          // $scope.customField.selectedValue = entry;
          $scope.customField.optionslist.push(entry);
        }
      };

      // Delete selected item of the reference list.
      $scope.deleteOption = function (id) {
        // Loop through item and remove the matching id.
        const options = $scope.customField.optionslist;
        for (let i = 0; i < options.length; ++i) {
          if (options[i].id === id) {
            $scope.customField.optionslist.splice(i, 1);
            return;
          }
        }
      };

      $scope.afterResponse = function () {
        // Close the panel.
        $mdDialog.cancel();
      };
      // Get reference list for users.
      $scope.getReferenceList = function (obj) {
        var referenceList = [];
        // Prepare list of users added for the field.
        Object.keys(obj).forEach(function (index) {
          var referenceItem = {};
          referenceItem.target_id = obj[index].id;
          referenceList.push(referenceItem);
        });
        return referenceList;
      }
      // This load the stored list of items for reference field.
      $scope.loadStoredOptions = function (bundle, id) {
        // Get/ load selected items from custom-field.
        const field = accountService.getObjectById('custom_fields', id);
        let selections = field ? field.options || [] : [];
        if (typeof selections === 'string') {
          selections = selections.split('\0');
          if (typeof selections === 'string') {
            selections = selections.split(', ');
          }
        }

        // Update display based on loaded field.
        switch (bundle) {
          case 'users':
          case 'tag':
          case 'colored_value':
            const options = $scope.optionsList;
            for (let i = 0; i < options.length; ++i) {
              const current = options[i];
              if (selections.indexOf(current.id) >= 0) {
                $scope.customField.optionslist.push(current);
              }
            }
            break;
          case 'boolean':
            // $scope.customField.selectedValue = {
            //   id: selections.length > 0 ? selections[0] : 0
            // };
            break;
          case 'textfield':
          case 'number':
            // Handles custom options (like Select-list sub-type).
            if (Array.isArray(selections)) {
              for (let i = 0; i < selections.length; ++i) {
                $scope.customField.optionslist.push({
                  id: i,
                  title: selections[i],
                });
              }
            }
            break;

          default:
            break;
        }
      };
    }
  ]);
})();
