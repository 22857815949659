(function () {
  'use strict';

  angular.module('tracking', ['angular-drupal', 'angular-google-analytics'])
    .directive('fbPixel', function() {
      return {
        templateUrl: 'app/src/components/tracking/fb-pixel.html'
      };
    })
    .controller('TrackingCtl', [
      'Analytics',
      '$rootScope',
      '$scope',
      '$location',
      'accountService',
      function (Analytics, $rootScope, $scope, $location, accountService) {

        if (typeof AppcuesWidget === 'function') {
          let widget = new AppcuesWidget(Appcues.user());
          widget.init("#appcues-widget", {
            position: "left",
            header: "<h1>Tutorials</h1>"
          });
        }

        // Grab account object and add it to tracking data.
        accountService.getAccount().then(function(account) {
          // Track current users.
          if (AppConfig.trackOn && account.user.uid) {
            FS.identify(account.user.uid, {
              displayName: account.user.displayName,
              email: account.user.mail,
              accountID_str: account.entity.id,
              accountName_str: account.entity.title
            });
            Appcues.identify($scope.account.user.uid, {
              name: account.user.name,
              email: account.user.mail,
              accountID: account.entity.id,
              accountName: account.entity.title
            });
          }
        });

        // Track all URL changes.
        if (AppConfig.trackOn) {
          $scope.$on('$routeChangeSuccess', function () {
            trackURL($location.absUrl());
          });
          $scope.$on('$routeUpdate', function () {
            trackURL($location.absUrl());
          });
        }

        function trackURL(url) {
          if (window['piTracker']) {
            window['piTracker'](url);
          }
          if (Analytics) {
            Analytics.trackPage(url);
          }
        }
      }
    ])
    .config(['AnalyticsProvider', function (AnalyticsProvider) {
      // Add configuration code if tracking enabled.
      if (AppConfig.trackOn) {
        AnalyticsProvider
          .setAccount('UA-3322603-4')
          .logAllCalls(true)
          // .trackEvent(true)
          .trackPages(true);
      }
      else {
        AnalyticsProvider
          .setAccount('UA-XXXXXXX-0');
      }
    }])
    // .run() call required to instantiate tracking.
    .run(['Analytics', function (Analytics) {}])
    .run(function () {

      if (AppConfig.trackOn && !window['_trackingInitted']) {
        window['_trackingInitted'] = true;

        // FullStory tracking code.
        window['_fs_debug'] = false;
        window['_fs_host'] = 'fullstory.com';
        window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
        window['_fs_org'] = 'AYCQ4';
        window['_fs_namespace'] = 'FS';
        (function(m,n,e,t,l,o,g,y){
          if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
          g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
          o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
          y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
          g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
          g.anonymize=function(){g.identify(!!0)};
          g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
          g.log = function(a,b){g("log",[a,b])};
          g.consent=function(a){g("consent",!arguments.length||a)};
          g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
          g.clearUserCookie=function(){};
          g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
          if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
          g._v="1.2.0";
        })(window,document,window['_fs_namespace'],'script','user');

        // Pardot tracking code.
        window['piAId'] = '1030';
        window['piHostname'] = 'pi.pardot.com';
        (function () {
          function async_load() {
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
            var c = document.getElementsByTagName('script')[0];
            c.parentNode.insertBefore(s, c);
          }
          if (window.attachEvent) {
            window.attachEvent('onload', async_load);
          }
          else {
            window.addEventListener('load', async_load, false);
          }
        })();

        // Facebook Pixel tracking code
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1983786971927240');
        fbq('track', 'PageView');

      }
    }).service('tracking', [function() {
      // Allow logging events in a centralized system.
      return {
        logEvent: function (type, message) {
          // If FullStory is around, sent that data on.
          if (AppConfig.trackOn && FS) {
            FS.log(type, message);
          }
        }
      };
    }]);
})();
