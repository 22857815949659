(function () {
  'use strict';
  angular.module('file', [
      'angular-drupal',
      'ngFileSaver'
    ])

    .service('fileService', [
      'RestResource',
      'FileSaver',
      'Blob',
      '$http',
      function (RestResource, FileSaver, Blob, $http) {
        var self = this;
        self.loadCount = 1;
        self.loaded = 0;

        var functions = {
          fileEntityDelete: function (fid, data) {
            var path = ['entity', 'file', fid].join('/');
            return RestResource.RestRequest(path, data, 'DELETE');
          },
          fileEntityCreate: function (file, progressListener = false) {
            var path = ['entity', 'file'].join('/');
            let listeners = false;

            if (progressListener && typeof progressListener == 'function') {
              listeners = {
                'progress': function(event) {
                  let percent = functions.progressCalc(event);
                  progressListener(percent);
                }
              };
            }

            let format = 'json';
            let errorMsg = 'Your file could not be uploaded. If the problem continues, please refresh.';
            return RestResource.RestRequest(path, file, 'POST', errorMsg, format, listeners);
          },
          fileEntityUpdate: function (fid, data) {
            var path = ['entity', 'file', fid].join('/');
            return RestResource.RestRequest(path, data, 'PATCH');
          },
          taskGetArchive: function (nid, data) {
            var path = ['task', 'attachments', nid].join('/');
            return RestResource.RestRequest(path, data, 'GET');
          },
          downloadFile: function(url, filename, callback = false) {
            // Build request to load the file.
            var req = {
              url: url,
              method: 'GET',
              responseType: 'arraybuffer',
              withCredentials: true
            };
            $http(req).then(function(resp) {
              // Create a blob out of the file data.
              var serverData = new Blob([resp.data], {type: resp.headers()['content-type']});
              // Perform a saveAs with the displayed filename.
              FileSaver.saveAs(serverData, filename);
              if (callback) {
                callback();
              }
            });
          },
          progressCalc: function(event) {
            if (event.lengthComputable) {
              return Math.round(event.loaded / event.total * 100);
            }
            return null;
          }

        };

        return functions;
      }
    ]);
})();
