(function() {
  'use strict';

  angular.module('inlineEdit', [])

    .controller('inlineWidgetCtrl', [
      '$scope',
      '$element',
      'accountService',
      'taskListService',
      'taskService',
      'customFieldService',
      '$rootScope',
      'commonService',
      '$attrs',
      '$timeout',
      function($scope, $element, accountService, taskListService, taskService, customFieldService, $rootScope, commonService, $attrs, $timeout) {
        if (!$scope.hasOwnProperty('closeOnUpdate')) {
          $scope.closeOnUpdate = true;
        }
        $scope.menuList = {};
        $scope.selectedList = {};
        $scope.selectedValue = {};

        // $scope.selectedValue[$scope.modelKey] = $scope.model;
        // if input type was not set by it's parent, set to normal text.
        if (typeof $scope.inputType === 'undefined') {
          $scope.inputType = 'text'
        }
        if (typeof $scope.defaultValue === 'undefined' || $scope.defaultValue == 'undefined') {
          $scope.defaultValue = '';
        }
        $scope.customFiledValueNew = false;

        if (!$scope.hasOwnProperty('emptyVal')) {
          $scope.emptyVal = null;
        }

        let fieldMapping = {
          'field_task_owner': {
            list: 'members',
            options: $scope.memberSelectOptions,
            key: 'uid',
            label: 'display_name',
          },
          'field_label': {
            list: 'tags',
            options: $scope.applyChipSelect,
            key: 'id',
            label: 'title'
          },
          'field_priority': {
            list: 'priorities',
            key: 'id',
            label: 'title',
            classCallback: $scope.priorityClasses
          },
          'field_list': {
            list: 'statuses',
            key: 'id',
            label: 'title'
          },
          'field_followers': {
            list: 'members',
            options: $scope.applyChipSelect,
            key: 'uid',
            label: 'display_name'
          },
          'project': {
            list: 'projects',
            key: 'id',
            label: 'title'
          },
        };

        $scope.defaultClassCallback = function(item) {
          return [item[$scope.selectDisplay].toLowerCase()];
        }

        // TODO: this function is massive. It should be split out into
        //   multiple functions. Should probably be provided by the individual
        //   widget.
        // Moved to a function for setting default value.
        $scope.loadFieldValues = function(defaultValue = '', node = false) {
          // Attempt to load the account
          let value = {};
          // Skip this if the account has already been loaded.
          if (fieldMapping.hasOwnProperty($scope.fieldName)) {
            let mapping = fieldMapping[$scope.fieldName];
            $scope.loadFieldOptions(mapping);

            // Start off with the default key.
            let valueKey = defaultValue;

            // if the node has a value, use that instead.
            if (node !== false) {
              valueKey = node[$scope.fieldName];
            }

            // If the value is empty, then use the emptyVal key.
            if (commonService.empty(valueKey)) {
              valueKey = $scope.emptyVal;
            }

            // Get the value from the list of options.
            value = $scope.populateValue($scope.options, valueKey, mapping.key);
          }

          // Check if it's  custom field.
          else if (!commonService.empty($scope.cid)) {
            let fieldId = $scope.cid;
            let customFieldMapping = $scope.getFieldMap();

            let valueKey = defaultValue;
            if (node != false) {
              let customValue = taskService.getCustomValue($scope.taskId, fieldId);
              if (customValue && customValue.hasOwnProperty('value')) {
                valueKey = customValue.value;
              }
            }

            // Load options based on the type of field.
            let selectTypes = ['user', 'tag', 'select'];

            $scope.isSelect = (selectTypes.indexOf($scope.inputType) !== -1);

            if ($scope.isSelect) {
              // $scope.selectKey is set inside customFieldService.
              $scope.options = customFieldService.loadFieldOptions(fieldId, $scope.inputType, $scope);

              // If the value is empty, then use the emptyVal key.
              if (commonService.empty(valueKey)) {
                if ($scope.selectKey) {
                  // If key and a modelval, likely needs to be converted
                  if ($scope.modelKey && !commonService.empty($scope.modelVal)) {
                    valueKey = $scope.modelVal;
                  }
                  else {
                    valueKey = $scope.emptyVal;
                  }
                }
                else {
                  valueKey = 'none';
                }
              }
              if ($scope.selectKey) {
                // Null is an allowed empty value that should still perform a lookup.
                if ((!commonService.empty(valueKey) || valueKey === null) && (!Array.isArray(valueKey) || typeof valueKey[0] !== 'object')) {
                  if (Array.isArray(valueKey) && typeof valueKey[0] !== 'object') {
                    value = commonService.getObjectByIds($scope.options, valueKey, $scope.selectKey);
                  }
                  else if (typeof valueKey !== 'object') {
                    // Get the value from the list of options.
                    value = commonService.getObjectById($scope.options, valueKey, $scope.selectKey);
                  }
                  else {
                    value = valueKey;
                  }
                }
                else {
                  value = valueKey;
                }
              }
              else {
                if ($scope.options.indexOf(valueKey) !== -1) {
                  value = valueKey;
                }
                else {
                  value = $scope.options[0];
                }
              }
            }
            else if (customFieldMapping['type'] == 'number-range') {
              value = $scope.modelVal;
            }
            else {
              value = valueKey;
            }
          }
          else if ($scope.fieldName == 'field_date') {
            // Process dates for calendar selector widget.
            $scope.node.field_date = {};
            $scope.selectedRange.dateStart = node.start ? moment(node.start).startOf('day').toDate() : false;
            $scope.selectedRange.dateEnd = node.end ? moment(node.end).endOf('day').toDate() : false;
            $scope.node.field_date.value = moment($scope.selectedRange.dateStart).startOf('day').format();
            $scope.node.field_date.end_value = moment($scope.selectedRange.dateEnd).endOf('day').format();
          }
          else {
            if (node == false) {
              value = defaultValue;
            }
            else {
              value = node[$scope.fieldName];
            }
          }

          // Choose where the final value should be pulled from, generally
          // preferring values that are passed in.
          // TODO: This logic looks redundant.
          if (typeof $scope.modelKey !== 'undefined' && !$scope.isSelect) {
            if (typeof $scope.modelVal === 'undefined') {
              $scope.modelVal = value;
            }
            $scope.selectedValue[$scope.modelKey] = $scope.modelVal;
          }
          else {
            $scope.selectedValue[$scope.modelKey] = value;
          }

          if ($scope.hasOwnProperty('preProcessValue')) {
            $scope.selectedValue[$scope.modelKey] = $scope.preProcessValue($scope.selectedValue[$scope.modelKey]);
          }

          $scope.prevValue = $scope.selectedValue[$scope.modelKey];

          $scope.initState();

          $scope.ready = true;

          if ($scope.immediate) {
            $timeout(function() {
              $scope.immediateOpen($element);
            });
          }
        };


        // Sets up focus/blur state of fields depending on present value.
        $scope.initState = function() {
          if (typeof $scope.acFocus == 'function' && typeof $scope.acBlur == 'function') {
            if (commonService.empty($scope.selectedValue[$scope.modelKey])) {
              $scope.acBlur($scope.modelKey);
            }
            else {
              $scope.acFocus($scope.modelKey);
            }
          }
        };

        // Grap field info for this type and context of field.
        $scope.getFieldMap = function() {
          let fieldType = $scope.inputType;

          if ($scope.context == 'filterpane') {
            return customFieldService.getFieldTypeFilterMapping(fieldType, true);
          }
          else {
            return customFieldService.getFieldTypeMapping(fieldType, false);
          }
        }

        $scope.loadFieldOptions = function(mapping) {
          $scope.selectDisplay = mapping.label;
          $scope.selectKey = mapping.key;

          let objectList = mapping.list;

          if (mapping.hasOwnProperty('options')) {
            $scope.options = mapping.options(mapping);
          }
          else {
            $scope.options = $scope.account[objectList];
            accountService.loadTitleOverrides($scope.options, objectList);
          }

          if (mapping.hasOwnProperty('classCallback')) {
            $scope.classCallback = mapping.classCallback;
          }
          else {
            $scope.classCallback = $scope.defaultClassCallback;
          }
        };

        if (!$scope.populateValue) {
          $scope.populateValue = function(options, valueKey, key) {
            return commonService.getObjectById(options, valueKey, key);
          };
        }

        if (!$scope.immediateOpen) {
          $scope.immediateOpen = function($element) {
            $element.find('md-select').triggerHandler('click');
          };
        }

        $scope.init = function(retry = true) {
          $scope.ready = false;
          $scope.account = accountService.getAccountValue();

          if (!$scope.account) {
            accountService.getAccount().then(function(account) {
              $scope.account = account;
              $scope.init();
            });
            return;
          }
          else {
            // Set Default Values if there was no task linked.
            if ($scope.taskId == false) {
              $scope.loadFieldValues($scope.defaultValue);
            }
            else {
              // If this is dependent on a task, then fetch it and use it.
              taskService.loadTask($scope.taskId).then(function(node) {
                $scope.node = node;
                if ($scope.node) {
                  $scope.loadFieldValues('', $scope.node);
                }
              });
            }
          }
        };

        // If $scope.onUpdate already exists, then don't overwrite it.
        if (!$scope.onUpdate) {
          // Default update callback. Respects provided callbacks if present.
          $scope.onUpdate = function() {
            // Check if the onChange attribute was set.
            // Using $attrs here because $scope.onChange will still be set
            // even if nothing was provided.
            if (!$attrs.hasOwnProperty('onChange') || typeof $scope.onChange !== 'function') {
              $scope.fieldUpdate();
            }
            else {
              let value = $scope.selectedValue[$scope.modelKey];
              $scope.onChange()($scope.fieldName, $scope.taskId, value, $scope.cid, $scope);
            }
          }
        }

        $scope.$on('filterFieldUpdate', function (event, fields) {
          if ($scope.context == 'filterpane' && $scope.cid) {
            // Allow for null and other falsy values.
            if (typeof fields[$scope.cid] !== 'undefined') {
              $scope.modelVal = angular.copy(fields[$scope.cid]);
              // Re-init with new values (converting key to object).
              $scope.init();
            }
          }
        });

        // Emit the close event. Allows performing a save before closing.
        $scope.onClose = function(update = true) {
          if (update) {
            $scope.onUpdate();
          }
          $scope.$emit('inlineOnClose');
          $rootScope.destroyListeners($scope);
        }

        // The default onUpdate callback.
        $scope.fieldUpdate = function(isReference = false) {
          let value = $scope.selectedValue[$scope.modelKey];

          if (value == null){
            value = undefined;
          }

          // Can't update a task if there isn't a task ID.
          if ($scope.taskId)  {
            // cid means custom field id.
            if ($scope.cid) {
              // Create or update the custom value.
              taskService.addCustomFieldValue($scope.taskId, $scope.cid, value); 
            } 
            // Updating the project for a task gets special handling.
            else if ($scope.fieldName == 'project') {
              taskService.updateTaskProject($scope.taskId, value);
            }
            // Everything else just does a field update.
            else {
              taskService.fieldUpdate($scope.fieldName, $scope.taskId, value);
            }
          }

          if ($scope.closeOnUpdate) {
            // Trigger the onClose logic, but don't allow it to start a save loop.
            $scope.onClose(false);
          }

          // Update the previous value for cancel operations.
          $scope.prevValue = value;
        };

        $scope.cancel = function() {
          $scope.selectedValue[$scope.modelKey] = $scope.prevValue;
          $scope.onClose(false);
        };

        $scope.init();
        if ($scope.taskId) {

          let taskUpdateListener = $rootScope.$on('taskPostUpdate', function(event, task, updates, hash) {
            if (task.nid == $scope.taskId) {

              $scope.node = task;
              // Prefer whatever value can be pulled from the node.
              $scope.modelVal = undefined;
              // This will only be firing on updates, so don't try to re-open.
              $scope.immediate = false;

              // Reload values/options for this field.
              // TODO: Probably only need to reload value.
              $scope.loadFieldValues('', $scope.node);
            }
          });
          $rootScope.addListener(taskUpdateListener, $scope);
        }
      }
    ]);

})();
