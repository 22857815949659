(function() {
  'use strict';

  var app = angular.module('customFieldWidget', ['ngMaterial', 'angular-drupal', 'user', 'angular.filter', 'labelpanel', 'colorbox', 'xeditable', 'popmenu']);

  app.directive('customFieldWidget', [
    'customFieldService',
    '$compile',
    function(customFieldService, $compile) {
      return {
        restrict: 'E',
        scope: {
          taskId: '=',
          fieldName: '@',
          label: '@',
          defaultValue: '@',
          onChange: '&',
          immediate: '=',
          modelVal: '=?',
          modelKey: '=?',
          strictValues: '=?',
          cid: '=',
          inputStyle: '@',
          context: '@?'
        },
        template: '<span bind-compile compile="template" args="args"></span>',
        controller: [
          '$scope',
          '$rootScope',
          '$attrs',
          function($scope, $rootScope, $attrs) {
            $scope.getMapping = function(fieldType) {
              if ($scope.context == 'filterpane') {
                return customFieldService.getFieldTypeFilterMapping(fieldType, $scope.strictValues);
              }
              else {
                return customFieldService.getFieldTypeMapping(fieldType, $scope.strictValues);
              }
            };

            $scope.init = function() {
              let type = 'input';
              let cssClass = '';
              let inputType = 'text';
              $scope.model = $scope.model || {};
              $scope.modelKey = $scope.modelKey || 'value';

              // Attempt to get real values from the custom field set against
              // the account.
              let customField = customFieldService.loadField($scope.cid);

              if (customField) {
                // Get the type from the custom field set against the account.
                let fieldType = customField.field_type;
                // Now get the actual mapping and set the type and class values.
                let mapping = $scope.getMapping(fieldType);
                if (mapping) {

                  $scope.type = mapping.type;
                  $scope.cssClass = mapping.cssClass;
                  $scope.inputType = mapping.inputType;
                }
                $scope.label = customField.title;
                $scope.buildTemplate();
              }
              else {
                let accountListener = $rootScope.$on('accountLoaded', function() {
                  accountListener();
                  $scope.init();
                });
              }
            };

            $scope.buildTemplate = function() {
              $scope.args = {
                modelVal: $scope.modelVal,
                modelKey: $scope.modelKey,
              };
              let callbackParam = '';
              if ($attrs.hasOwnProperty('onChange')) {
                callbackParam = 'on-change="onChange(fieldName, nid, value, cid)"';
                $scope.args.onChange = $scope.onChange;
              }
              let contextParam = '';
              if ($attrs.hasOwnProperty('context')) {
                contextParam = 'context="{{context}}"';
                $scope.args.context = $scope.context;
              }

              let directive = 'inline-widget-';

              if ($scope.inputStyle) {
                directive += $scope.inputStyle + '-';
              }

              directive += $scope.type;

              // Actually build the template.
              $scope.template = `
                <${directive}
                  class="${$scope.cssClass}"
                  input-type="${$scope.inputType}"
                  task-id="${$scope.taskId}"
                  field-name="${$scope.fieldName}"
                  label="${$scope.label}"
                  immediate="${$scope.immediate}"
                  model-val="modelVal"
                  model-key="modelKey"
                  ${callbackParam}
                  ${contextParam}
                  cid="${$scope.cid}"
                ></${directive}>
              `;
            };

            // Actually initialize the custom field.
            $scope.init();
          }
        ]
      };
    }
  ]);

})();
