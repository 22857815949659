(function () {
  'use strict';

  let app = angular.module('multiedit');

  app.directive('multieditCheckbox', function () {
    return {
      restrict: 'E',
      scope: {
        nid: '=',
      },
      controller: [
        '$scope',
        'multiEditService',
        'taskListService',
        '$rootScope',
        function ($scope, multiEditService, taskListService, $rootScope) {
          // Check with the multiEditService to show if the checkbox display.
          $scope.isCheckboxVisible = function(){
            return multiEditService.isMultiEditEnabled();
          };

          // Fires when a checkbox has been checked.
          $scope.processChange = function () {
            if (multiEditService.isMultiEditEnabled()) {
              // Ensure the same type as the tasklist.
              let nid = Number($scope.nid);

              if (multiEditService.isInTaskList(nid)) {
                $scope.isSelected = true;
              }
              else {
                $scope.isSelected = false;
              }
              // Set a flag on the task itself that it is selected.
              $scope.task = taskListService.getTaskById($scope.nid);
              if ($scope.task) {
                $scope.task.multiedit = $scope.isSelected;
              }

              return $scope.isSelected;
            }
          };

          // Listen for changes to this individual task as well as the list as a whole.
          $scope.$on(multiEditService.ALTERED_MULTI_EDIT_FLAG, $scope.processChange);
          $scope.$on(multiEditService.ALTERED_MULTI_EDIT_TASK_LIST, $scope.processChange);

          $scope.$on('$destroy', function () {
            $rootScope.destroyListeners($scope);
          });

          // When a checkbox value changes, update the multiedit list.
          $scope.onSelect = function () {
            if ($scope.isSelected) {
              multiEditService.addTaskID($scope.nid);
            }
            else {
              multiEditService.removeTaskID($scope.nid);
            }
          };

          // TODO: This can be hit before the tasklist has been fully loaded
          // when reloading the page while in multi-edit mode.
          if ($scope.nid) {
            $scope.processChange();
          }
        }
      ],
      templateUrl: 'app/src/components/multiedit/multiedit-checkbox.html'
    };
  });
})();
