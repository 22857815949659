(function () {
  'use strict';

  let app = angular.module('task');

  app.directive('taskForm', function () {
    return {
      scope: {
        layout: '=?',
        list: '=?',
        presets: '=?',
        formOnly: '=?',
        onSave: '=?'
      },
      controller: [
      '$scope',
      '$element',
      '$timeout',
      'drupal',
      '$rootScope',
      '$mdDialog',
      'RestResource',
      'accountService',
      'taskListService',
      'listLocalService',
      'commonService',
      'refreshService',
      'featureService',
      'layoutService',
      '$mdToast',
      'taskService',
      'filterService',
      function ($scope, $element, $timeout, drupal, $rootScope, $mdDialog, RestResource, accountService, taskListService, listLocalService, commonService, refreshService, featureService, layoutService, $mdToast, taskService, filterService) {
        $scope.addTaskFormShow = true;
        $scope.filtering = filterService.getFiltering();

        accountService.getAccount().then(function(account) {
          $scope.account = account;
        });

        $scope.init = function () {
          if ($scope.formOnly) {
            $scope.showTaskForm();
          }
          else {
            $scope.addTaskFormShow = false;
          }
        };
        $scope.showTaskForm = function () {
          $scope.addTaskFormShow = true;
          // Focus once everything is rendered.
          $timeout(function() {
            angular.element($element).find('input, textarea').focus();
          });
        };
        $scope.hideTaskForm = function () {
          // Blank out the form.
          $scope.newTaskName = '';
          $scope.addTaskFormShow = false;
        };

        // Determine if tasks should actually proceed to be added.
        $scope.addNewTasks = function() {

          let title = $scope.newTaskName;
          // Don't allow creating empty tasks.
          if (title == '' || typeof title == 'undefined') {
            return;
          }
          let taskTitles = title.split(/\r?\n/);
          // If there are mutliple tasks, confirm this is intentional.
          if (taskTitles.length > 1) {
            let list = taskTitles.join("</li><li>");
            let confirm = $mdDialog.confirm()
              .multiple(true)
              .title('Add multiple tasks?')
              .htmlContent("<div>It looks like you're pasting multiple tasks. Do you want to add the following tasks?</div><ul><li>" + list + "</li></ul>")
              .ariaLabel('Add multiple tasks?')
              .ok('Add ' + taskTitles.length + ' Tasks')
              .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
              $scope.createMultipleTasks(taskTitles);
            });
          }
          // Single tasks are created without confirmation.
          else {
            $scope.createMultipleTasks(taskTitles);
          }
        };

        // Actual prep for creation of tasks and hand off to TaskService.
        $scope.createMultipleTasks = function(taskTitles) {
          // Blank out the form.
          $scope.newTaskName = '';

          accountService.getAccount().then(function(account) {

            if (taskTitles.length > 0) {
              let tasks = [], defaultProject;
              let list = $scope.list;
              let weight = layoutService.getWeight(list, -1);

              // Grab correct project ID to attach new task to.
              let projectId = $scope.$parent.projectId;
              if (projectId == 'all' || typeof projectId == 'undefined') {
                if ($scope.filtering.selection &&
                  $scope.filtering.selection.project &&
                  $scope.filtering.selection.project[0]) {
                  projectId = $scope.filtering.selection.project[0];
                }
                else {
                  defaultProject = accountService.getAccountData('defaultProject');
                  if (accountService.getObjectById('projects', defaultProject)) {
                    projectId = defaultProject;
                  }
                  else {
                    // If no default group set then use the first account project.
                    projectId = account.projects[0].id;
                  }
                }
              }

              // Reverse tasks so they end up in the same order they were pasted
              // in after weighting math.
              taskTitles.reverse();
              angular.forEach(taskTitles, function(title) {
                // Keep halving weights for each task being added.
                let taskWeight = weight / 2;
                weight = taskWeight;

                // Base task setup.
                let task = angular.extend({
                  title: title,
                  field_weight: weight,
                  project: projectId.toString()
                }, $scope.presets);

                // Check if the user should be automatically set as the owner.
                let feature = 'auto_assign_task_owner_on_task_creation';
                if (featureService.featureStatus(feature, account)) {

                  // Get the current user from the account and set it.
                  let currentUser = accountService.getAccountMember();
                  if (currentUser) {
                    task.field_task_owner = currentUser.uid;
                  }
                }

                // Support for dynamic fields based on layout info.
                angular.forEach(list.fields, function(fieldValue, fieldName) {
                  task[fieldName] = fieldValue;
                });

                // Add task to the default status list if one isn't specified.
                if (!task['field_list']) {
                  let defaultList = accountService.getAccountData('defaultList');
                  if (defaultList) {
                    task['field_list'] = defaultList;
                  }
                }

                // Add the task to the list of tasks to be created.
                tasks.unshift(task);
              });

              // Send to taskService to create and temporarily "fake."
              taskService.createMultipleTasks(tasks);
            }

            if (typeof $scope.onSave == 'function') {
              $scope.onSave();
            }
          });
        };
      }],
      templateUrl: 'app/src/task/task-form.html'
    };
  });
})();
