(function () {
  'use strict';

  let multiedit = angular.module('multiedit', ['multiedit-service']);

  multiedit.config([
    '$mdDialogProvider',
    function ($mdDialogProvider) {
      $mdDialogProvider.addPreset('MultiEditDialog', {
        options: function () {
          return {
            controller: 'MultiEditDialogController',
            templateUrl: 'app/src/components/multiedit/multiedit-dialog.html',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            escapeToClose: true
          };
        }
      });
    }
  ]);

  multiedit.controller('MultiEditDialogController', [
    '$scope',
    'multiEditService',
    '$mdDialog',
    'taskListService',
    'refreshService',
    '$mdToast',
    'filterService',
    'taskService',
    function ($scope, multiEditService, $mdDialog, taskListService, refreshService, $mdToast, filterService, taskService) {
      let task_ids = multiEditService.getTaskIDs();
      $scope.tasks = taskListService.getTasksByIds(task_ids);

      // TODO: Fix this. A separate promise is not needed for all of these.
      multiEditService.getAccountMembers().then(function(members) {
        $scope.members = members;
      });
      multiEditService.getAccountPriorities().then(function(priorities) {
        $scope.priorities = priorities;
      });
      multiEditService.getAccountStatuses().then(function(statuses) {
        $scope.statuses = statuses;
      });
      multiEditService.getAccountTags().then(function(tags) {
        $scope.tags = tags;
      });
      multiEditService.getAccountProjects().then(function(projects) {
        $scope.projects = projects;
      });
      $scope.customFieldsList = multiEditService.getCustomFields();

      $scope.selectedTags = [];
      $scope.nodeReady = true;

      $scope.states = $scope.states || {
        'tags': {},
        'addTags': {},
        'removeTags': {}
      };



      $scope.models = $scope.models || {};

      $scope.models.set = $scope.models.set || {
        'tags': [],
        'date': {}
      };

      $scope.models.alter = $scope.models.alter || {
        'addTags': [],
        'removeTags': [],
        'dateShift': {},
        'updateCustomValues':{}
      };

      // // Create a separate model for each field value to store against.
      // $scope.customFieldsList.forEach((element) => {
      //   let val = element.defaut_value || '';
      //   $scope.models.alter.updateCustomValues[element.id] = val;
      // });

      $scope.entityStates = {
        active : {
          title: 'Active',
          value: ''
        },
        archived: {
          title: 'Archived',
          value: 'archived'
        },
      };
      $scope.dateShift = {
        direction: {
          forward : {
            title: 'Later',
            value: '+'
          },
          backward: {
            title: 'Earlier',
            value: '-'
          },
        },
        units: {
          days : {
            title: 'Day(s)',
            value: 'days'
          },
          months: {
            title: 'Months(s)',
            value: 'months'
          },
        }
      };

      $scope.fieldClear = function (fieldSet, model, submodel = false) {
        let isArray = false;
        let fieldset = $scope.models[fieldSet];
        if (submodel) {
          let modelVal = fieldset[model]
          if (modelVal && modelVal[submodel] instanceof Array) {
            isArray = true;
          }
          modelVal[submodel] = (isArray) ? [] : '';
        }
        else {
          if (fieldset[model] instanceof Array) {
            isArray = true;
          }
          fieldset[model] = (isArray) ? [] : {};
        }
      };

      $scope.querySearch = function (query, list) {
        var results = query ? list.filter(createFilterFor(query)) : list.filter(createFilterFor(''));
        return results;
      };

      // Filter function for a query string.
      function createFilterFor(query) {
        var lowercaseQuery = angular.lowercase(query);

        return function filterFn(value) {
          return (value._lowercase.indexOf(lowercaseQuery) === 0);
        };
      }

      // Sets state for field to focused.
      $scope.acFocus = function (field) {
        $scope.states[field].focus = true;
      };

      // Defaults to a blank state. Called again /after/ chip removal.
      $scope.acBlur = function (fieldSet, field) {
        if ($scope.states[field]) {
          $scope.states[field].focus = false;
          $scope.states[field].filled = false;
          if ($scope.models[fieldSet][field].length >= 1) {
            $scope.states[field].filled = true;
          }
        }
      };

      /**
       * Delete all tasks without warning!
       */
      $scope.deleteSelected = function () {
        var confirm = $mdDialog.confirm()
          .multiple(true)
          .title('Are you sure you want to delete these tasks?')
          .textContent("This will queue the selected tasks for deletion. After 30 days they will be permantently deleted along with all associated comments, checklists, files and history. Consider archiving them instead to keep all this.")
          .ariaLabel('Delete task')
          .ok('Delete Task')
          .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
          multiEditService.deleteTasks(task_ids);
          // Close the modal.
          $mdDialog.hide();
          // Finished with multi-edit.
          multiEditService.unsetMultiEdit();
        });
      };

      // Accepts a list of form values and converts them into a format that
      // can easily be consumed and processed. Used as a precursor to building
      // the multi-edit request and local update values.
      $scope.processModelValues = function (list) {
        var excluded = ['project'];
        var processedVals = {};
        // Iterate through all in scope models to add any with values to
        // the request.
        angular.forEach(list, function(fieldVal, fieldId){
          // Don't proceed unless a value is present.
          if (Object.keys(fieldVal).length > 0 || fieldVal === '') {

            // Convert the date range object into an array of the start and
            // end date.
            if (fieldId == 'date') {
              if (typeof(fieldVal.dateEnd) !== "undefined"
                && typeof(fieldVal.dateStart) !== "undefined") {
                let format = 'YYYY-MM-DD\THH:mm:ss',
                  // Make sure these changes don't break widget.
                  dateStart = fieldVal.dateStart,
                  dateEnd = fieldVal.dateEnd;
                // Set array of start and end for processing.
                // Formatting and BOF/EOF date set here.
                fieldVal = [
                  moment(dateStart).startOf('day').format(format),
                  moment(dateEnd).endOf('day').format(format)
                ];
              }
              // If the date is invalid, then don't add it to the request.
              else {
                fieldId = false;
              }
            }
            // Objects need some preprocessing.
            else if (typeof fieldVal == 'object') {
              if (fieldId == 'updateCustomValues') {
                angular.forEach(fieldVal, function(value, cid) {
                  if (value) {
                    fieldVal[cid] = $scope.collapseValues(value);
                  }
                });
              }
              else {
                fieldVal = $scope.collapseValues(fieldVal);
              }
            }

            if (fieldId && excluded.indexOf(fieldId) === -1 && fieldVal != null) {
              processedVals[fieldId] = fieldVal;
            }
          }
        });
        return processedVals;
      };

      $scope.collapseValues = function(fieldVal) {
        // Determine the key based on what is present.
        let key = 'id';
        if (!(fieldVal instanceof Array)) {
          key = fieldVal.hasOwnProperty('uid') ? 'uid' : key;

          // Don't try to access an undefined property. This is important
          // for dateShift which is an object but shouldn't be reduced.
          if (fieldVal.hasOwnProperty(key)) {
            fieldVal = fieldVal[key];
          }
        }
        // Add extra handling for array values.
        else {
          let tempVal = [];
          // Check each item to see if it needs to be reduced.
          angular.forEach(fieldVal, function (item) {
            if (typeof item == 'object' && item.hasOwnProperty(key)) {
              item = item[key];
            }
            tempVal.push(item);
          });
          fieldVal = tempVal;
        }
        return fieldVal;
      };

      $scope.onCustomFieldChange = function(fieldName, nid, value, cid) {
        $scope.models.alter.updateCustomValues[cid] = value;
      };

      // Preprocessor function to format custom values before further processing.
      $scope.preprocessCustomValues = function(customVals) {
        let customObjects = [];
        if (customVals) {
          angular.forEach(customVals, function(val, key){
            if (val !== '') {
              customObjects.push({
                cid: key,
                value: val
              });
            }
          });
        }
        return customObjects;
      };

      /**
       * Handles the update for task data by doing the following:
       * 1. For every task object we have selected...
       * 2. Get the value of the field we have updated.
       * 3. use the generators to construct proper objects to update value both locally and remote.
       */
      $scope.update = function () {
        let refresh = false;

        // Define all of the request components ahead of time.
        let fields = {};
        let attach = {};
        let alter = {};
        let request = [];

        // Also define the core pieces for handling local updates and
        // processing model values.
        let localData = [];
        let localFields = {};
        let alterFields = {};
        let value = {};
        let localValue = {};

        // Process and iterate through the 'set' values.
        let setVals = $scope.processModelValues($scope.models.set);
        angular.forEach(setVals, function(fieldVal, fieldId) {
          // Fetch both the remote and local versions of these changes
          value = multiEditService.genTaskFieldData(fieldId, fieldVal);
          localValue = multiEditService.genLocalTaskFieldData(fieldId, fieldVal);

          // Combine this field with the other fields already present.
          fields = Object.assign(fields, value);
          localFields = Object.assign(localFields, localValue);
        });

        // Build the alter values.
        alter = $scope.processModelValues($scope.models.alter);
        if (alter.hasOwnProperty('updateCustomValues')) {

            alter.updateCustomValues = $scope.preprocessCustomValues(alter.updateCustomValues);
        }
        angular.forEach(alter, function(fieldVal, fieldId) {
          alterFields[fieldId] = fieldVal;
        });

        // Project reassignment isn't a field change but a task re-attachment
        // operation.
        // @TODO: Consolidate into TaskService. No reason to differentiate here.
        if ($scope.models.set.project) {
          attach = {
            "entity_id": $scope.models.set.project.id,
            "plugin": 'group_node:task',
            "reattach": true
          };
          localFields.project = $scope.models.set.project;
        }

        // Only proceed if there is actually a change being made.
        let fieldsEmpty = Object.keys(fields).length == 0;
        let attachEmpty = Object.keys(attach).length == 0;
        let alterEmpty = Object.keys(alter).length == 0;

        if (!fieldsEmpty || !attachEmpty || !alterEmpty) {

          // Build the base request for the field updates.
          request = {
            "entity_type": "node",
            "entity_bundle": "task",
            "entity_id": task_ids,
          };

          // Add the attachment action if there is one.
          if (!attachEmpty) {
            request.__attach = attach;
          }

          // Add the attachment action if there is one.
          if (!alterEmpty) {
            request.__alter = alter;
          }

          // Add the fields and prepare the changes for local application.
          if (!fieldsEmpty) {
            request.fields = fields;
          }

          if (Object.keys(localFields).length > 0 || Object.keys(alterFields).length > 0) {
            task_ids.forEach(taskId => {
              // Compile a list of changes for the task.
              let taskUpdates = angular.copy(localFields);
              let updateTags = false;


              // Emit an event for the task being updated.
              let task = taskListService.getTaskById(taskId);

              // Use current tag lists (use multi-edit vals if reset).
              let taskTags = task.field_label;
              if (localFields.field_label && localFields.field_label.length > 0) {
                taskTags = localFields.field_label
              }
              angular.forEach(alterFields, function(val, key) {
                switch (key) {
                  case 'addTags':
                    // Reduce to non-duplicates (prevent render errors).
                    let add = val.filter(x => !taskTags.includes(x));
                    // Append to other tag changes (if any).
                    taskTags = taskTags.concat(add);
                    updateTags = true;
                    break;

                  case 'removeTags':
                    // Reduce to non-duplicates.
                    taskTags = taskTags.filter(x => !val.includes(x));
                    updateTags = true;
                    break;

                  case 'dateShift':
                    // TODO: implement this faking. :)
                    break;

                  case 'updateCustomValues':
                    // Do a deep copy of the value and pass it to the task.
                    // Deep copy is necessary to make sure that each task has a
                    // separate copy of the custom values.
                    taskUpdates.task_custom_value = angular.copy(val);
                    break;
                }
              });

              // Reset tags to new values if set to update.
              if (updateTags) {
                taskUpdates.field_label = taskTags;
              }

              taskService.emitTaskUpdate(task, taskUpdates, 'updated');
              localData.push(Object.assign({nid: taskId}, taskUpdates));
            });
          }

        }

        // Verify that there actually is a request to be made.
        if (Object.keys(request).length > 0) {
           // Let the user know the task is still going, could take a bit.
          var toast = $mdToast.show(
            $mdToast.simple()
              .textContent('Updating ' + task_ids.length + ' tasks. Still working...')
              .position('bottom right')
              .hideDelay(0)
          );

          // Apply the updates locally.
          multiEditService.updateLocalTasks(localData);

          task_ids.forEach(taskId => {
            // Emit an event for the task being updated.
            let task = taskListService.getTaskById(taskId);
            let taskUpdates = angular.copy(localFields);
            taskService.emitTaskPostUpdate(task, taskUpdates, 'updated');
          });

          // Send the actual field update request.
          // Hide the toast when the response comes back.
          multiEditService.updateRemoteTasks([request]).then(function() {
            $mdToast.hide();
          });

          // Hide the dialog and unset the tasks from multiedit.
          $mdDialog.hide();
          multiEditService.unsetMultiEdit();
        }
      };

      $scope.closeDialog = function () {
        $mdDialog.hide();
        multiEditService.unsetMultiEdit();
      };
    }
  ]);
})();
