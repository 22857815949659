(function () {
  'use strict';

  var app = angular.module('task');

  app.directive('taskWidget', function () {
    return {
      scope: {
        task: '=',
        link: '=?'
      },
      controller: function ($scope, $rootScope, accountService) {
        var task = $scope.task;
        var link = $scope.link;
        $scope.status = accountService.getObjectById('statuses', task.field_list);
        if (typeof task.project == 'object' && !(task.project instanceof Array)) {
          $scope.project = task.project;
        }
        else {
          $scope.project = accountService.getObjectById('projects', task.project);
        }
      },
      templateUrl: 'app/src/task/task-widget.html'
    };
  });
})();
