(function () {
  'use strict';

  angular.module('error-handler', [])
    .service('errorHandlerService', [
      '$window',
      '$rootScope',
      'commonService',
      function ($window, $rootScope, commonService) {
        // Redirect the user to the app login url for this environment.
        this.appLogin = function (accountId = false) {
          commonService.clearAppStorage();
          let path = AppConfig.loginPath;
          let query = [];

          // If an account ID was passed, then the redirect may not be valid.
          if (accountId === 0) {

            let destination = window.location.hash;
            destination = destination.split('/');
            destination.splice(0,2);
            destination = destination.join('/');
            query.push('redirect=' + window.encodeURIComponent(destination));

            // If another account ID wasn't provided and an active account
            // can be determined, then use that.
            if ($rootScope.account && $rootScope.account.activeAccount) {
              accountId = $rootScope.account.activeAccount;
            }
          }

          // If an environment is set, then indicate that to D7.
          if (AppConfig.envName) {
            query.push('env=' + AppConfig.envName);
          }

          if (accountId) {
            // Set the accountID being used.
            query.push('account=' + accountId);
          }

          // Add all the query param to the URL.
          if (query.length > 0) {
            path += '?' + query.join('&');
          }

          $window.location = AppConfig.externalEndpointUrl + path;
        };
      }
    ]);
})();
