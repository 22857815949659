(function () {
  'use strict';

  var app = angular.module('comment', ['angular-drupal', 'user']);

  app.directive('commentList', function () {
    return {
      scope: {
        node: '='
      },
      controller: [
        '$scope',
        '$rootScope',
        'accountService',
        'taskService',
        function ($scope, $rootScope, accountService, taskService) {

          var $modal = $scope.$parent.$parent;
          if ($scope.node) {

            taskService.loadComments($modal, $scope.node);
            $scope.comments = $modal.comments;

            // TODO: Add allowances for appDigest.
            let refreshListener = $rootScope.$on('appRefresh', function (event, args) {
              taskService.loadComments($modal, $scope.node);
              $scope.comments = $modal.comments;
            });

            $rootScope.addListener(refreshListener, $scope.$parent);
          }

        }
      ],
      templateUrl: 'app/src/comment/comment-list.html'
    };
  });

  app.directive('commentForm', function () {
    return {
      scope: {
        nid: '='
      },
      controller: [
        '$scope',
        'RestResource',
        'accountService',
        'commonService',
        'refreshService',
        'taskService',
        'taskListService',
        'listLocalService',
        '$timeout',
        function ($scope, RestResource, accountService, commonService, refreshService, taskService, taskListService, listLocalService, $timeout) {

          $scope.formDisabled = function () {
           if ($scope.commentText.length < 1) {
              return true;
            }
          };

          $scope.addNewComment = function () {

            let user = accountService.getAccountMember();

            // Instantiate a new comment with all the info available.
            var comment = {
              entity_id: $scope.nid,
              entity_type: 'node',
              field_name: 'field_comments',
              comment_type: 'comment',
              uid: user.uid,
              comment_body: {
                value: $scope.commentText,
                format: 'html'
              },
              name: user.name,
              __attach: {
                entity_id: $scope.nid
              }
            };
            var hash = commonService.sdbmHash(JSON.stringify(comment));
            comment.hash = hash;

            $timeout(function () {
              $scope.$parent.comments.unshift(comment);
              $scope.$parent.comments = taskService.processComments($scope.$parent.comments);

              // Add the comment to the changelist.
              listLocalService.addChanges('comments', 'created', comment, 'cid', $scope.$parent);

              // And nuke the form.
              $scope.commentText = '';

              // Save and then reload all tasks.
              let task = taskListService.getTaskById($scope.nid);
              task.field_comments++;
              taskListService.updateTaskVals($scope.nid, task);
            });

            refreshService.appRefresh();

            RestResource.eckEntityCreate('comment', 'comment', comment).then(function (res) {
              res.created = new Date(res.created * 1000);
              res.hash = hash;
              listLocalService.addChanges('comments', 'created', res, 'cid', $scope.$parent);
            });
          };

          // Set the CKE options here, all set in separate JS file for now.
          $scope.commentText = '';
          $scope.options = {
            customConfig: AppConfig.basePath + '/cke/ckeditor-config.js'
          };

          // Mark preceding div as unclean on focus.
          CKEDITOR.on('instanceReady', function(evt) {
            var editor = evt.editor;

            editor.on('focus', function(e) {
              $timeout(function() {
                if (!commonService.empty(e.editor.element.$)) {
                  e.editor.element.$.parentElement.classList.remove('form-clean');
                }
              }, 500);
            });
          });

        }
      ],
      templateUrl: 'app/src/comment/comment-form.html'
    };
  });
})();
