(function () {
  'use strict';

  var app = angular.module('task-service', ['angular-drupal', 'ngMaterial', 'xeditable', 'ckeditor', 'misc', 'popmenu', 'attachments', 'resource']);

  app.service('taskService', [
    'accountService',
    'RestResource',
    'refreshService',
    'commonService',
    'taskListService',
    'listLocalService',
    'filterService',
    'customFieldService',
    '$rootScope',
    '$mdToast',
    '$mdDialog',
    '$routeParams',
    '$route',
    '$location',
    'layoutService',
    'tracking',
    function (accountService, RestResource, refreshService, commonService, taskListService, listLocalService, filterService, customFieldService, $rootScope, $mdToast, $mdDialog, $routeParams, $route, $location, layoutService, tracking) {

      var functions = {
        taskPromises: [],
        shortHandMap: {
          'owner': 'field_task_owner',
          'tags': 'field_label',
          'priority': 'field_priority',
          'status': 'field_list',
          'followers': 'field_followers',
          'date': 'field_date',
          'state': 'field_entity_state',
          'custom_value': 'field_custom_values'
        },
        openTaskModal: function (id = false) {
          /* TODO: Rework this so that the task ID is part of the URL instead of a
           query param. See question below on how to do this without reloading the
           controller on url change.

           https://stackoverflow.com/questions/14974271/can-you-change-a-path-without-reloading-the-controller-in-angularjs
          // if (!id && typeof $route.current.pathParams.taskId !== 'undefined') {
          //   id = $route.current.pathParams.taskId;
          // }
          */

          if (!id && typeof $route.current.params.task !== 'undefined') {
            id = $route.current.params.task;
          } else if (id) {
            $routeParams.task = id;
          }

          var modalListeners = [];

          if (id) {
            $location.search('task', id);
            $mdDialog.show(
              $mdDialog.openTaskModal({
                locals: {
                  node_id: id,
                  listeners: modalListeners,
                },
                onRemoving: function (element, promise) {
                  $rootScope.destroyListeners({
                    'listeners': modalListeners
                  });
                  // $route.updateParams({'taskId': null});
                  $location.search('task', null);


                  // Clean up CKEs on Dialog close.
                  // Destroy all, since Dialogs are the only place they can exist.
                  if (typeof CKEDITOR.instances == 'object') {
                    for (let i in CKEDITOR.instances) {
                      let item = CKEDITOR.instances[i];
                      item.destroy();
                    }
                  }
                }
              })
            );
            return true;
          }
          return false;
        },
        // Wrapper around multi-create function.
        createTask: function (task) {
          return this.createMultipleTasks([task]);
        },
        // Add one or more tasks on the backend and performs some local faking.
        createMultipleTasks: function (tasks) {
          let hashes = [];
          let request = [];

          angular.forEach(tasks, function (task) {
            // Prep hashes for keeping track of local version.
            let hash = commonService.sdbmHash(JSON.stringify(task));
            hash = 'hash:' + hash.toString();
            task.hash = hash;
            task.nid = hash;
            hashes.push(hash);

            // Setup up the request objects
            let requestItem = {};
            angular.forEach(task, function (val, key) {
              // Any title or field.
              if (key == 'title' || key.indexOf('field_') === 0) {
                requestItem[key] = val;
              }
            });
            // Add the project attachment separately.
            if (task.hasOwnProperty('project')) {
              requestItem["__attach"] = {
                "entity_id": Number(task.project)
              };
            }

            request.push(requestItem);
            functions.emitTaskUpdate(task, {}, 'created');
            taskListService.digestChanges();
          });

          // refreshService.appRefresh();
          let errorMsg = 'There was a problem adding your task. If the problem continues, please refresh.';

          // Send the new tasks on the server.
          RestResource.eckEntityCreateMultiple('node', 'task', request, errorMsg).then(function (res) {
            // Grab all the prepped hashes
            for (let index = 0; index < hashes.length; index++) {
              let createdTask = res[index];
              createdTask.hash = hashes[index];
              listLocalService.addChanges('tasks', 'created', createdTask, 'nid');
            }

            if (typeof res == 'object' && res) {
              let newTasks = [];
              // Key list by nid so it can be merged in tasklist.
              angular.forEach(res, function(value, id) {
                newTasks[value.nid] = value;
              });
              taskListService.processNewValues('tasks', newTasks);
            }
            refreshService.appRefresh();
            // Catch errors and tell the user what's up.
          }).catch(function (err) {
            // Loop through tasks and find the recently 'faked' ones.
            for (let i = 0; i < tasks.length; i++) {
              let taskItem = tasks[i];
              if (hashes.indexOf(taskItem.hash) >= 0) {
                // Remove failed 'faked' tasks from display.
                listLocalService.addChanges('tasks', 'deleted', tasks[i], 'nid');
              }
              // Tell the view to digest immediately.
              $rootScope.$emit('appDigest');
            }
          });
        },

        // Create a copy of at task and create temporary local version of it.
        replicateTask: function (task) {
          var taskDupe = angular.copy(task);
          delete taskDupe.nid;
          delete taskDupe.$$hashKey;
          taskDupe.field_entity_state = 'cloning';

          let hash = commonService.sdbmHash(JSON.stringify(taskDupe));
          hash = 'hash:' + hash.toString();
          taskDupe.hash = hash;
          taskDupe.originNid = task.nid;
          taskDupe.title = 'Copy of ' + taskDupe.title;

          // TODO: This isn't passed to backend.
          // This essentially acts like the duplicated task was just dragged
          // below the origin task.
          taskDupe.field_weight = layoutService.getListIndexWeightById(task.nid, true);
          taskDupe.changed = "0";

          functions.emitTaskUpdate(taskDupe, {}, 'created');

          RestResource.eckEntityReplicate('node', 'task', task.nid).then(function (cloning) {
            cloning.hash = hash;
            cloning.originNid = task.nid;
            // Assists in ensuring that this change will be overwritten.
            cloning.changed = "0";
            listLocalService.addChanges('tasks', 'created', cloning, 'nid');

            taskListService.updateTaskVals(cloning.nid, cloning);

            // This tells the backend this is an operation check.
            var data = {
              '__processing': true
            };
            RestResource.requestRetry('eckEntityGet',
              // GET args.
              ['node', 'task', cloning.nid, data],
              // Success function.
              (res) => {
                // If the state isn't cloning, all is well.
                return (res.field_entity_state != 'cloning');
              }).then(
              // Success callback.
              (cloned) => {
                if (cloned.nid) {
                  listLocalService.addChanges('tasks', 'replicated', cloned, 'nid');
                  taskListService.updateTaskVals(cloned.nid, cloned);
                  let changes = {
                    field_entity_state: cloned.field_entity_state
                  };

                  // Emit an post update event with the changes.
                  functions.emitTaskPostUpdate(cloned, changes);
                }
              },
              // Failure callback.
              (failed) => {
                let errorMsg = "Your duplication of '" + task.title + "' failed. Please try again.";
                let toast = $mdToast.simple()
                  .textContent(errorMsg)
                  .action('Ok')
                  .highlightAction(true)
                  .toastClass('warning')
                  .position('bottom right')
                  .hideDelay(60000);
                $mdToast.show(toast);

                // Tell tracking services.
                tracking.logEvent('error', 'Replication Error: [' + failed.nid + '] Reported a completed clone operation but is still in cloning state.');

                // Hide faked task from user.
                taskListService.removeTask(cloning.nid);
              }
            );
          });
        },
        archiveTask: function (task, cancelCallback) {
          if (functions.archivedStatus(task)) {
            task.field_entity_state = '';
          }
          else {
            task.field_entity_state = 'archived';
            // Remove from view until it comes back in another search.
            taskListService.removeTask(task.nid);
            // Close modal.
            cancelCallback();
          }
          functions.fieldUpdate('field_entity_state', task.nid, task.field_entity_state);
        },
        deleteTask: function (task, cancelCallback) {
          // If in trash, undelete task.
          if (functions.deletedStatus(task)) {
            task.field_entity_state = '';
            // Remove from view until it comes back in another search.
            taskListService.removeTask(task.nid);
            refreshService.appRefresh();

            // Make the actual update request.
            functions.fieldUpdate('field_entity_state', task.nid, task.field_entity_state);

            if (typeof cancelCallback == 'function') {
              // Close modal.
              cancelCallback();
            }
          }
          else {
            let confirm = $mdDialog.confirm()
              .multiple(true)
              .title('Are you sure you want to delete this task?')
              .textContent("This will queue the task for deletion. After 30 days it will be permantently deleted along with all associated comments, checklists, files and history. Consider archiving it instead to keep all this.")
              .ariaLabel('Delete task')
              .ok('Delete Task')
              .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
              taskListService.removeTask(task.nid);
              $mdDialog.hide();
              refreshService.appRefresh();
              taskListService.removeTask(task.nid);

              RestResource.eckEntityDelete('node', 'task', task.nid);
            });
          }
        },
        hasValidId: function (task) {
          return (task.nid && functions.isValidId(task.nid));
        },
        isValidId: function (nid) {
          return !isNaN(nid);
        },

        // Determine if the task is currently pending an operation.
        //  @param {bool} strict
        //   Strict is used to indicate that not only is the task pending an
        //   operation, but it is unusable in it's current state.
        taskIsPending: function (nid, strict = true) {

          // Grab the task from taskList. This includes results from listlocal.
          let task = taskListService.getTaskById(nid);

          // Default to the task in a pending state.
          let pending = true;

          // Unless the task is present AND has a valid ID, no checks needed.
          if (task && functions.hasValidId(task)) {

            // Check the field_entity_state for a cloning status.
            if (task.hasOwnProperty('field_entity_state')) {
              if (task.field_entity_state !== 'cloning') {
                pending = false;
              }
            }

            // This check can't override a pending item back to false.
            if (strict && !pending) {

              // Since this is the final check, just set pending to task.hiding.
              if (!task.hasOwnProperty('hiding')) {
                pending = (task.hiding);
              }
            }
          }

          return pending;
        },

        /**
         * This method generates the data we need to send back to drupal
         * whenever a task field value has changed.
         *
         * @param {int} taskId - The ID of the task being modified.
         * @param {string} fieldName - The name if the field being modified in human readable terms.
         * @param {array} fieldValues - The new values for the fieldname.
         *
         * @returns {object} - The properly formated object which becomes the POST data for the RestResource service.
         */
        generateTaskFieldUpdateValues: function (fieldName, fieldValues) {

          let request = {};
          let fieldVal = [];
          let field = this.fieldMap(fieldName);

          if (field) {
            switch (fieldName) {
              case 'owner':
              case 'tags':
              case 'priority':
              case 'status':
                fieldVal = this.buildField(fieldName, fieldValues);
                break;

              case 'owner':
              case 'followers':
                fieldVal = this.buildField(fieldName, fieldValues, 'user');
                break;

              case 'date':
                var format = 'YYYY-MM-DD\THH:mm:ss';
                fieldVal = {
                  value: moment(fieldValues[0]).format(format),
                  end_value: moment(fieldValues[1]).format(format)
                };
                break;

              case 'state':
                fieldVal = fieldValues;
                break;
            }
          } else {
            // This is anything which maps 1-to-1
            // For consistency, fieldValues remains an array.
            field = fieldName;
            fieldVal = fieldValues[0];
          }

          request[field] = fieldVal;
          return request;
        },

        // Builds out the field values for the type being referenced.
        buildField: function (fieldName, fieldValues, type = 'tag') {
          let fieldVal = [];
          fieldValues.forEach(function (value) {
            fieldVal.push({
              target_id: value,
              target_type: type
            });
          });
          return fieldVal;
        },

        /**
         * This method generates the data we pass to taskListService.
         * taskListService is responsible for maintaining task state in ng-app
         * @param {int} taskId
         * @param {string} fieldName
         * @param {Array} fieldValues
         *
         * @returns {object} - Returns a properly formed object TaskListService can use to change task state.
         */
        generateLocalTaskFieldUpdateValue: function (fieldName, fieldValues) {
          let changes = {
            // nid: taskId,
          };

          let field = this.fieldMap(fieldName);

          switch (fieldName) {
            case 'owner':
            case 'priority':
            case 'status':
              changes[field] = fieldValues[0];
              break;

            case 'state':
            case 'tags':
            case 'followers':
              changes[field] = fieldValues;
              break;

            case 'date':
              changes['start'] = fieldValues[0];
              changes['end'] = fieldValues[1];
              break;

            default:
              changes[fieldName] = fieldValues[0];
              break;
          }
          return changes;

        },

        // Returns the full Drupal field_name based on the short hand name.
        fieldMap: function (fieldName) {
          let field = this.shortHandMap[fieldName];
          return (typeof field !== 'undefined') ? field : false;
        },

        // Helper function to build a request object.
        buildRequest: function (nid) {
          return {
            "entity_type": "node",
            "entity_bundle": "task",
            "entity_id": nid,
            "fields": {}
          };
        },

        // Prepares a request and changes for a task field update.
        prepareFieldRequest: function (nid, field, value, request = false, changes = false) {
          let callback = false;
          let fieldVal = [];
          let ids = [];

          // If there isn't already a request, then build it.
          if (!request) {
            request = functions.buildRequest(nid);
          }

          // If changes is empty, then initialize it.
          if (!changes) {
            changes = {
              nid: nid
            };
          }

          let node = taskListService.getTaskById(nid);

          // Prepare the field value according to the mapping.
          switch (field) {
            case 'field_task_owner':
              changes[field] = value.uid;
              fieldVal.push({
                // target_id: val.uid,
                target_id: changes[field],
                target_type: 'user'
              });
              request.fields[field] = fieldVal;
              break;

            case 'field_label':
              angular.forEach(value, function (val) {
                ids.push(val.id);
                fieldVal.push({
                  target_id: val.id,
                  target_type: 'tag'
                });
              });
              changes[field] = ids;
              request.fields[field] = fieldVal;
              break;


            // TODONEW: Exactly the same as field_list;
            case 'field_priority':
              // Emulate a reload with new info before save.
              // TODO: This may need to be re-enabled.
              //$scope.mapToAccount([$scope.priority.id], 'field_priority', 'priorities');

              // angular.forEach($scope.priority, function(val) {
              fieldVal.push({
                target_id: value.id,
                target_type: 'tag'
              });
              // });
              changes[field] = value.id;
              request.fields[field] = fieldVal;
              break;

            case 'field_list':
              // Emulate a reload with new info before save.
              // $scope.mapToAccount([$scope.status.id], 'field_list', 'statuses');

              // angular.forEach($scope.status, function(val) {
              fieldVal.push({
                target_id: value.id,
                target_type: 'tag'
              });
              // });
              changes[field] = value.id;
              request.fields[field] = fieldVal;
              break;

            case 'field_followers':
              angular.forEach(value, function (val) {
                ids.push(val.uid);
                fieldVal.push({
                  target_id: val.uid,
                  target_type: 'user'
                });
              });
              changes[field] = ids;
              request.fields[field] = fieldVal;
              break;

            case 'field_date':
              if (value.value) {
                var format = 'YYYY-MM-DD\THH:mm:ss';
                fieldVal = {
                  value: moment(value.value).format(format),
                  end_value: moment(value.end_value).format(format)
                };
                changes[field] = {
                  value: value.value,
                  end_value: value.end_value
                };
                changes['start'] = value.value;
                changes['end'] = value.end_value;
              }
              // No value? Consider this a removal of scheduling.
              else {
                changes[field] = [];
                changes['start'] = null;
                changes['end'] = null;
              }
              request.fields[field] = fieldVal;
              break;

            default:
              changes[field] = value;
              request.fields[field] = value;
              break;
          }
          return {
            changes: changes,
            request: request,
            callback: callback
          };
        },

        // Processed a field update and tell the rest of the app.
        fieldUpdate: function (field, nid, value) {
          // Prepare the request itself.
          let updateVals = functions.prepareFieldRequest(nid, field, value);

          // Ensure a task is grabbed, even if not present currently.
          this.loadTask(nid).then(function(node) {
            // Emit an update event with the changes.
            functions.emitTaskUpdate(node, updateVals.changes);

            // Apply the changes after changes have been broadcast.
            taskListService.updateTaskVals(nid, updateVals.changes);

            // Emit an post update event with the changes.
            functions.emitTaskPostUpdate(node, updateVals.changes);

            // Process actual field changes server-side.
            functions.fieldUpdateRequest(nid, updateVals.request, updateVals.callback);
          });
        },

        // Update a task with an array of fields.
        // @TODO: This seems like it is strictly inferior to fieldUpdate, with
        //   the exception that it can handle multiple fields at a time.
        //   These should either be combined, or one should extend the other.
        updateTask: function (nid, fields, callback = null, update = false) {
          // Emit an update event with the changes (old vs. new).
          let origTask = taskListService.getTaskById(nid);
          functions.emitTaskUpdate(origTask, fields);

          // Apply the changes after changes have been broadcast.
          taskListService.updateTaskVals(nid, fields, update, update);

          // Emit an post update event with the changes.
          functions.emitTaskPostUpdate(origTask, fields);

          // Prepare the request itself.
          let request = functions.buildRequest(nid);

          // Build out request with changes.
          angular.forEach(fields, function (value, key) {
            request.fields[key] = value;
          });
          functions.fieldUpdateRequest(nid, request, callback);
        },

        // Performs the field update request with the provided request value.
        fieldUpdateRequest: function (nid, request, callback = false, afterRequest = false, ) {
          // If no afterRequest was provided then use the default.
          if (!afterRequest) {
            afterRequest = functions.fieldUpdateAfterRequest;
          }

          // Perform the actual request with the provided request body.
          RestResource.entityFieldUpdate([request]).then(function(res) {
            // Do whatever cleanup or post-processing is needed.
            afterRequest(nid, res, callback);
          });
        },

        // Default after-request for task field updates.
        fieldUpdateAfterRequest: function (nid, res, callback = false) {
          // Currently this function can only update a single task.
          taskListService.updateTaskVals(nid, res[0]);

          // Add the latest task data to the local store.
          functions.loadTask(nid, true).then(function(task) {
            // Apply the changes after changes have been broadcast.
            taskListService.updateTaskVals(nid, res);

            // Emit an post update event with the changes.
            functions.emitTaskPostUpdate(task, res);
          });

          // If there was a callback, then execute it.
          if (callback) {
            callback();
          }

          refreshService.appRefresh();
        },

        /**
         * Re-attaches a task to a new project.
         *
         * @param {int} nid - the node ID of the task being updated.
         * @param {object} project - the full project object.
         */
        updateTaskProject: function (nid, project) {
          let request = {
            "__attach": {
              "entity_id": project.id,
              "plugin": 'group_node:task',
              "reattach": true
            }
          };

          // Emit an event for the task being updated.
          let changes = {
            project: project.id
          };
          
          // Ensure the task is loaded prior to continuing.
          this.loadTask(nid, true).then(function(task) {
            functions.emitTaskUpdate(task, changes, 'updated');
            // Apply the changes after changes have been broadcast.
            taskListService.updateTaskVals(nid, changes);

            // Emit an post update event with the changes.
            functions.emitTaskPostUpdate(task, changes);

            RestResource.eckEntityUpdate('node', 'task', nid, request).then(function (res) {
              refreshService.appRefresh();
            });
          });
        },

        addCustomFieldValue: function(nid, cid, value) {
          // Prepare the request itself.
          let updateVals = functions.prepareCustomRequest(nid, cid, value);

          let node = taskListService.getTaskById(nid);

          // Emit an update event with the changes.
          functions.emitTaskUpdate(node, updateVals.changes);

          // Apply the changes after changes have been broadcast.
          taskListService.updateTaskVals(nid, updateVals.changes);

          // Emit an post update event with the changes.
          functions.emitTaskPostUpdate(node, updateVals.changes);

          // Process actual field changes server-side.
          functions.fieldUpdateRequest(nid, updateVals.request, updateVals.callback);
        },

        // Callback to prepare the update requests for a custom field.
        prepareCustomRequest: function(nid, cid, value) {
          cid = cid.toString();
          let node = taskListService.getTaskById(nid);

          if (typeof value == 'object' && Object.keys(value).length > 0) {
            let mapping = customFieldService.getFieldMappingById(cid);

            if (mapping && mapping.key) {
              if (value.hasOwnProperty(mapping.key)) {
                value = value[mapping.key];
              }
            }
          }
          // Drupal handles the upsert based on the cid for this task.
          // Update and create requests are identical.
          let fieldValue = {
            cid: cid,
            value: value
          };

          let request = functions.buildRequest(nid);
          request.__alter = {
            updateCustomValues: [fieldValue]
          };

          var customVals = false;

          if (node && node.hasOwnProperty('task_custom_value')) {
            // Create an index map of all existing cids.
            let cidMap = [];
            customVals = node.task_custom_value
            if (!commonService.empty(customVals)) {
              cidMap = customVals.map(fieldVal => fieldVal.cid);
            }
            else {
              customVals = [];
            }

            // Update the value of the custom field if it was found.
            let index = cidMap.indexOf(cid);
            if (index !== -1) {
              customVals[index].value = value;
            }

            // otherwise create a new one.
            else {
              customVals.push(fieldValue);
            }

          }

          return {
            changes: {task_custom_value: customVals},
            request: request,
            callback: false
          }
        },

        // Wrapper function to load a node and lookup a custom field value.
        getCustomValue: function(nid,  cid) {

          let node = taskListService.getTaskById(nid);

          return functions.getTaskCustomValue(node, cid);
        },

        // Performs a lookup for a specific CID on the given node.
        getTaskCustomValue: function(node,  cid) {

          // Cids are strings against nodes.
          cid = cid.toString();

          // Return a value of false if no custom value is found.
          let customValue = false;
          if (node && node.hasOwnProperty('task_custom_value')) {
            if (!commonService.empty(node.task_custom_value)) {

              // Create an index map of all the existing cids for the node.
              let customVals = node.task_custom_value;
              let cidMap = customVals.map(fieldVal => fieldVal.cid);

              // Provide the actual custom value object if a match is found.
              let index = cidMap.indexOf(cid);
              if (index !== -1) {
                customValue = customVals[index];
              }
            }
          }
          return customValue;
        },

        // Generate the original values of a task from a changes object.
        getUnchangedValues: function (node, changes) {
          let orig = {};

          // Created tasks won't have this yet.
          if (node && node.nid) {
            orig.nid = node.nid;

            // Iterate over all of the changes and copy the original value.
            angular.forEach(changes, function (value, key) {
              if (node.hasOwnProperty(key)) {
                orig[key] = node[key];
              }
            });
          }

          return orig;
        },

        // Returns a boolean for whether a task is archived or not.
        archivedStatus: function (task) {
          return (task.field_entity_state == 'archived');
        },

        // Returns a boolean for whether a task is archived or not.
        deletedStatus: function (task) {
          return (task.field_entity_state == 'trashed');
        },

        // Wrapper around commonService createDateRange.
        createDateRange: function (start, end) {
          return commonService.createDateRange(start, end);
        },

        // Returns the status string for a date based on the provided list.
        scheduledStatus: function (date, listId = false) {
          var completedId = accountService.getDefaultValue('completed_list');
          var status = 'none';
          if (!listId || listId != completedId) {
            if (date) {
              status = commonService.getDateStatus(date);
            }
          } else {
            status = 'completed';
          }

          return status;
        },

        // Loads the comments for a provided node and attaches them to scope.
        loadComments: function ($scope, node) {
          if (typeof node != 'undefined' && node.task_details) {
            var results = node.task_details.comments;
            listLocalService.getChangeList('comments', results, false, 'cid', $scope, false);

            var comments = functions.processComments(results);

            $scope.comments = comments;
          }
        },

        // Helper function for pre-processing comments before display.
        processComments: function (comments) {
          var comment_list = [];
          if (comments.length > 0) {

            // Parse details from comments.
            for (var i = 0; i < comments.length; i++) {
              var comment_item = comments[i];
              comment_item.author = accountService.getObjectByIds('members', [comment_item.uid], 'uid')[0];
              comment_item.posted = moment(comment_item.created).format($rootScope.defaultDateFormat);
              if (typeof comment_item.comment_body == 'object') {
                if (comment_item.comment_body.hasOwnProperty('value')) {
                  comment_item.comment_body_val = comment_item.comment_body.value;
                }
              } else {
                comment_item.comment_body_val = comment_item.comment_body;
              }

              comment_list.push(comment_item);
            }
          }
          return comment_list;
        },

        // Helper function to load the attachments of a task.
        loadAttachments: function ($scope, node) {
          if (typeof node != 'undefined' && node.task_details) {
            var results = node.task_details.attachments;
            listLocalService.getChangeList('attachments', results, false, 'fid', $scope);

            var attachments = functions.processAttachments(results);

            $scope.attachments = attachments;
          }
        },

        // Processor function to prepare attachments for display.
        processAttachments: function (files) {
          let attachments = [];
          if (files.length > 0) {
            // Parse details from attachments.
            for (let i = 0; i < files.length; i++) {
              let attachment = files[i];
              let filename = attachment.filename;
              // Strip out extensions from filenames.
              let extPos = filename.lastIndexOf('.' + attachment.extension);
              attachment.bareName = filename.substring(0, extPos);

              attachment.author = accountService.getObjectByIds('members', [attachment.uid], 'uid')[0];
              attachment.added = moment(attachment.created).format($rootScope.defaultDateFormat);

              attachments.push(attachment);
            }
          }

          return attachments;
        },

        // Loads a resouce entity and attaches it to scope.
        resourceLoad: function ($scope) {
          return new Promise(function (resolve, reject) {
            var resource_id = $scope.resourceId;
            RestResource.eckEntityGet('resource', 'resource', resource_id).then(function (resource) {
              $scope.resource = resource;
              resolve(resource);
            });
          });
        },

        // Emit a task update event.
        emitTaskUpdate: function (task, updates, op) {
          let unchanged = functions.getUnchangedValues(task, updates);

          // Pass created/deleted events to list local.
          if (['created', 'deleted'].indexOf(op) >= 0) {
            listLocalService.addChanges('tasks', op, task, 'nid');
          }

          // Update total count. Subcount increments after response.
          if (op == 'created') {
            taskListService.vals.count++;
          }

          // Emit the event to update the task app-wide.
          $rootScope.$emit('taskUpdate', task, unchanged, updates, op);
        },

        // Emit the task post-update event to update the task app-wide.
        emitTaskPostUpdate: function (task, updates, op) {
          $rootScope.$emit('taskPostUpdate', task, updates, op);
        },

        // Loads a task. Tries from taskList unless forceFresh is false.
        // Ensures repeated requests aren't made to the server.
        loadTask: function (nid, forceFresh = false) {
          if (!forceFresh) {
            let node = taskListService.getTaskById(nid);

            if (node) {
              return new Promise(function(resolve, reject) {
                resolve(node);
              });
            }
          }

          if (forceFresh || !this.taskPromises[nid]) {
            this.taskPromises[nid] = this.loadTaskNow(nid);
          }

          return this.taskPromises[nid];
        },
        // Loads a fresh copy of a task and updates the local copy.
        loadTaskNow: function (nid) {
          return new Promise(function (resolve, reject) {

            // Load a fresh copy of the node, even if one exists already.
            RestResource.eckEntityGet('node', 'task', nid).then(function (nodeResponse) {
              // Take the response and use that to update the tasklist.
              taskListService.updateTaskVals(nid, nodeResponse);

              // Grab the newly updated task from the tasklist.
              let node = taskListService.getTaskById(nid);

              // Check if the node was found in the tasklist.
              if (commonService.empty(node)) {

                // Add the task to the loaded changelist so that it doesn't get
                // dropped later.
                listLocalService.addChanges('tasks', 'loaded', nodeResponse, 'nid');

                // Have to separately tell the taskList service to digest the
                // loaded task.
                taskListService.digestChanges();

                // Now do a fetch of the task. If, for some reason, the task
                // still isn't in the tasklist, wait for one more taskLoad.
                taskListService.fetchEntityById(nid).then(function (fetchedNode) {
                  resolve(fetchedNode);
                });
              }
              // If the node was found, then just pass it back.
              else {
                resolve(node);
              }
            }).catch(function(error) {
              reject(error)
            });
          });
        }
      };

      return functions;
    }
  ]);
})();
