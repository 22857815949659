(function () {
  'use strict';

  angular.module('dashboard', [
      'ngRoute',
      'angular-drupal',
      'project',
      'popmenu',
      'memberpic',
      'projectstats',
      'folders'
    ])

    .config(['$routeProvider', function ($routeProvider) {
      $routeProvider.when('/dashboard', {
        redirectTo: '/0/dashboard'
      });
    }])
    .config(['$routeProvider', function($routeProvider) {
      $routeProvider.when('/:accountId/dashboard', {
        templateUrl: 'app/src/dashboard/dashboard.html',
        controller: 'DashboardCtrl',
        activetab: 'dashboard',
        reloadOnSearch: false
      });
    }])

    .controller('DashboardCtrl', [
      '$scope',
      '$rootScope',
      'drupal',
      'accountService',
      'refreshService',
      'commonService',
      'routingService',
      'RestResource',
      '$mdDialog',
      '$timeout',
      'listLocalService',
      'featureService',
      'userDataService',
      'filterService',
      '$route',
      function ($scope, $rootScope, drupal, accountService, refreshService, commonService, routingService, RestResource, $mdDialog, $timeout, listLocalService, featureService, userDataService, filterService, $route) {
        $scope.account = {
          members: []
        };
        $scope.root = $rootScope.root;
        $scope.showNewFolderForm = false;
        $scope.folderEdit = {};
        $scope.filtering = filterService.getFiltering();
        $scope.sel = $scope.filtering.selection;
        $scope.lastResult = 0;
        $scope.projectResult = {};

        $scope.initProjectGroups = function () {
          $scope.projectGroups = {};
        };

        $scope.initProjectGroups();

        // Initialize settings immediately with the defaults.
        // This is updated once the account has initialized.
        $scope.settings = userDataService.getDashboardSettings(true);

        $scope.archiveSettings = {
          'none': 'Active',
          'all': 'All',
          'only': 'Archived',
          'trash': 'Deleted'
        };


        $scope.showNewFolder = function() {
          $scope.showNewFolderForm = true;
        };
        $scope.hideNewFolder = function(folder) {
          if (folder) {
            $scope.folderEdit[folder.id] = false;
          }
          else {
            $scope.showNewFolderForm = false;
          }
        };
        $scope.folderEmpty = function(folder) {
          return folder.projects.length < 1;
        };
        $scope.showFolder = function(folder) {
          let recentlyCreated = moment().subtract(2, 'minutes').isBefore(folder.changed * 1000);
          return !$scope.folderEmpty(folder) || recentlyCreated;
        };
        $scope.folderEditMode = function(folderId) {
          return $scope.folderEdit[folderId];
        };
        $scope.editFolder = function(folder) {
          return $scope.folderEdit[folder.id] = true;
        };

        // Getter/Setter for the showArchived setting.
        $scope.showArchived = function (val) {
          if (typeof val != 'undefined') {
            userDataService.setDashboardSetting('showArchived', val);
          }

          return $scope.settings.showArchived;
        };

        $scope.projectFilterSave = function() {
          $scope.projectFilterFocused = false;
          userDataService.setDashboardSetting('projectFilter', $scope.settings.projectFilter);
        };

        // This expands/collapses the project filter box.
        $scope.toggleFolderCollapse = function (folderId) {
          // Toggle individual folders in sidebar.
          userDataService.setDashboardSetting('filterFolders-' + folderId);
        };

        $scope.addFolderFilter = function(group) {
          if (group.projects.length < 1) {
            // Can't view 0 projects because that would actually be `all`.
            return;
          }
          // Single projects are viewed as a primary subhead project.
          // else if (group.projects.length == 1) {
          //   $scope.openProject(group.projects[0].id);
          // }
          // Multiple projects are shown as a multi-project folders.
          else {
            let proj = [];
            group.projects.forEach(function(project) {
              proj.push(project.id);
            });

            filterService.setFilterItem('project', proj);

            // Redirect to board.
            if ($route.current && $route.current.activetab == 'dashboard') {
              routingService.changeDisplay('board');
            }
          }
        }

        $scope.state = {
          submitting: false
        };

        // Initialize and define all the settings for the dashboard.
        $scope.buildSettings = function() {
          // Get the dashboard settings from the
          $scope.settings = userDataService.getDashboardSettings();

          // Provide a default for the expanded setting if there is none.
          if (!$scope.settings.hasOwnProperty('expanded')) {
            $scope.settings.expanded = false;
          }

          // Initialize the showArchived Setting.
          let defaultKey = 'none';
          if (!$scope.settings.hasOwnProperty('showArchived')) {
            $scope.settings.showArchived = defaultKey;
          }

          // Provide the default sorting if there is none set.
          if (!$scope.settings.hasOwnProperty('sorting')) {
            $scope.settings.sorting = {
              mode: 'id',
              dir: 'asc',
            };
          }
        };

        // Called after the directive can actually init (has account info).
        $scope.init = function () {
          $scope.memberUser = accountService.getAccountMember();
          accountService.getAccount().then(function(account) {
            $scope.account = account;
            $scope.buildSettings();

            // Build member options list for project filtering by owner. (TODO)
            let unassigned = {uid: "", display_name:"All"};
            $scope.memberOptions = [unassigned].concat($scope.account.members);

            if (typeof $scope.memberUser != 'undefined' &&
              typeof $scope.memberUser.group_roles != 'undefined') {
              $scope.isAdmin = $scope.memberUser.group_roles == 'account-admin';
            }
            else {
              $scope.isAdmin = false;
            }

            // Check if the project title filter feature is enabled.
            $scope.titleFilteringEnabled = featureService.featureStatus('project_dashboard_enable_project_title_filter');

            // If filtering is enabled, the initialize the filter.
            if ($scope.titleFilteringEnabled) {

              // Start off with the default value.
              let filterDefault = {
                title: '',
              };

              // Check if filer remembering is turned on.
              let rememberFilter = featureService.featureStatus('project_dashboard_remember_project_title_filter');

              // If filtering is turned on, then add the filter value to the
              // dashboard settings object to be tracked aginast local storage.
              if (rememberFilter) {
                if (!$scope.settings.hasOwnProperty('projectFilter')) {
                  $scope.settings.projectFilter = filterDefault;
                }

                // Save this filter straight against scope. This allows the filter
                // to work regardless of whether filter remember is enabled.
                $scope.projectFilter = $scope.settings.projectFilter;
              }
              // Otherwise just set the filtes to the defaults.
              else {
                $scope.projectFilter = filterDefault;
              }
            }
            loadView();
          });
        };

        // Handler to check if a project modal should be opened.
        $rootScope.openProjectModal();

        // Once the account has loaded, initialize the dashboard.
        accountService.getAccount().then(function(account) {
          $scope.account = account;
          $scope.init();
        });

        $scope.loadProjects = function () {
          accountService.getAccount().then(function(account) {
            // $scope.initProjectGroups();
            loadView();
          });

        };

        function loadView() {
          if (typeof $scope.memberUser == 'undefined') {
            return;
          }
          let includeArchived = false,
              showArchived = $scope.showArchived();

          if (showArchived && showArchived != 'none') {
            includeArchived = showArchived;
          }

          RestResource.loadProjectList(includeArchived, $scope.lastResult).then(function (result) {
            // If this batch of projects doesn't match our archived key, then
            // this must be an old request. Bail and let the next one handle it.
            if ($scope.showArchived() != showArchived) {
              return;
            }

            // If the server sent info that's already loaded, bail.
            if ($scope.lastResult >= result._server_time) {
              return;
            }
            // Otherwise, continue to load it and update time locally.
            else {
              $scope.lastResult = result._server_time;
            }

            // Don't keep this, and remove prior to comparison.
            delete result._server_time;

            // JSON.stringify replacement function to eliminate some keys.
            function replacer(key, value) {
              // Filtering out properties
              if (key == '$$hashKey') {
                return undefined;
              }
              return value;
            }

            // If all project stats are the same, don't nuke the project list.
            if (commonService.sdbmHash(JSON.stringify(result, replacer)) == commonService.sdbmHash(JSON.stringify($scope.projectResult, replacer))) {
              return;
            }

            // Save this for comparison against future responses.
            $scope.projectResult = result;

            // Clone the object so that it can be updated (sorted) without
            // affect the copy saved for future change comparisons.
            $scope.projectGroups = angular.copy(result);

            // Initialize the sorting for all projects based on active settings.
            $scope.sortProjects();

            // Emit an event to allow other app components to respond to the
            // project list completing load.
            $rootScope.$emit('projectListLoaded');
            $rootScope.$emit('viewReady');

            $scope.$apply();
          });
        }

        $scope.updateAccountProjects = function () {
          let allProjects = [];
          let projectGroups = {};
          angular.forEach($scope.projectGroups, function (groupList, type) {
            projectGroups[type] = {};
            if (typeof groupList.projects != 'undefined' && groupList.projects.length > 0) {
              angular.forEach(groupList.projects, function (group) {
                if (group.isMember || type == 'personal') {
                  allProjects.push(group);
                  projectGroups[type][group.id] = group;
                }
              });

            }
          });
          projectGroups.all = allProjects;
          accountService.getAccount().then(function(account) {
            account.projects = allProjects;
            account.project_groups = projectGroups;
          });

        };

        $scope.openProject = function (project) {
          let projectId = project;
          if (project != 'all' && typeof project == 'object') {
            projectId = project.id;
            if ((project.cloning || project.field_entity_state == 'archived')
              || (project.type != 'personal' && !project.isMember)) {
              return false;
            }
          }
          $rootScope.$emit('resetFilters');
          return routingService.changeDisplay('board', projectId);
        };

        $scope.getFolderMenu = function (folder) {
          return $scope.folderAdminMenu;
        };

        $scope.getProjectMenu = function (projectType, group) {
          if (group.field_entity_state == 'archived') {
            return $scope.projectAdminMenuArchived;
          }
          else {
            return $scope.projectAdminMenu;
          }
        };

        $scope.editProject = function (project) {
          var list = $scope.projectGroups[project.field_project_folder];
          $rootScope.openProjectModal(project.id, project.type, list, $scope.state, project.field_project_folder);
        };

        $scope.hideProject = function (project) {
          let folder = project.field_project_folder;
          if (project.type == 'personal') {
            folder = 'personal';
          }
          else if (!project.field_project_folder || !$scope.projectGroups[folder]) {
            folder = 'no_folder';
          }

          let projectGroup = $scope.projectGroups[folder];
          let removeIndex = projectGroup.projects.findIndex(function (element) {
            return element.id == project.id;
          });

          if (removeIndex > -1) {
            projectGroup.projects.splice(removeIndex, 1);
          }

          delete $scope.account.project_groups[folder.id];
        };

        /**
         * @TODO: There isn't really any rhyme or reason to how these callbacks
         *  are defined. (Sometimes tied to scope, sometimes just defined.)
         */
        function replicateProject(project) {
          // Create a duplicate and clean it up so that it's considered fully unique.
          var projectVals = Object.assign({}, project);
          delete projectVals.id;
          delete projectVals.$$hashKey;
          projectVals.cloning = true;
          projectVals.title = 'Copy of ' + projectVals.title;

          // Generate a hash so that we can properly update it once it's created.
          var hash = commonService.sdbmHash(JSON.stringify(projectVals));
          projectVals.hash = hash;

          // Add it directly to scope so it shows up immediately.
          if (projectVals.type && $scope.projectGroups[projectVals.type]) {
            $scope.projectGroups[projectVals.type].projects.push(projectVals);
          }

          RestResource.eckEntityReplicate('group', 'project', project.id).then(function (res) {
            // Clone our original and give it the new values from the created project.
            var newProject = Object.assign({}, projectVals);
            newProject.id = res.id[0].value.toString();
            newProject.title = res.label[0].value;
            newProject.field_project_folder = res.field_project_folder[0].target_id;
            newProject.hash = hash;

            // Update our pending creations list with our new values.
            listLocalService.addChanges('projects', 'created', newProject);
            // $rootScope.addProjectChanges('created', newProject);

            // Open the modal for the new project.
            $scope.editProject(newProject);

            // And run our long polling to finish replicating the project.
            var data = {
              '__processing': true
            };
            RestResource.eckEntityGet('group', 'project', newProject.id, data).then(function (task) {
              refreshService.appRefresh();
            });
          });
        }

        function deleteProject(project) {
          var confirm = $mdDialog.confirm()
            .multiple(true)
            .title('Are you sure you want to delete this project?')
            .textContent("This will queue the project for deletion. After 30 days the project will be permantently deleted along with all associated tasks, comments, checklists, files and history. Consider archiving it instead to keep all this.")
            .ariaLabel('Delete Project')
            .ok('Delete Project')
            .cancel('Cancel');

          $mdDialog.show(confirm).then(function () {

            $mdDialog.hide();
            listLocalService.addChanges('projects', 'deleted', project);

            // Immediately remove the project from scope.
            $scope.hideProject(project);
            accountService.deleteObjectById('projects', project.id);
            RestResource.eckEntityDelete('group', 'project', project.id);
          });
        }

        // Archival/unarchival function for projects.
        $scope.archiveProject = function (project) {
          // Toggle the value of the archived state.
          let archiveState = (project.field_entity_state == 'archived') ? '' : 'archived';
          let request = {
            entity_type: "group",
            entity_bundle: "project",
            entity_id: project.id,
            fields: {
              field_entity_state: archiveState
            }
          };

          // Check if the project's status equals the showArchived setting.
          if ($scope.settings.showArchived.key != 'all') {
            if ((archiveState == '' && $scope.settings.showArchived == 'only') ||
                (archiveState == 'archived' && $scope.settings.showArchived == 'none')) {
              // If not, hide the project.
              $scope.hideProject(project);
            }
          }

          RestResource.entityFieldUpdate([request]).then(function (res) {
            refreshService.appRefresh();
          });
        };

        function deleteFolder(folder) {
          var confirm = $mdDialog.confirm()
            .multiple(true)
            .title('Are you sure you want to delete this folder?')
            .textContent("This will move all projects inside to the 'Uncategorized Projects' folder. There is no undo.")
            .ariaLabel('Delete Folder')
            .ok('Delete Folder')
            .cancel('Cancel');

          $mdDialog.show(confirm).then(function () {

            $mdDialog.hide();

            // Log pending changes locally.
            listLocalService.addChanges('folders', 'deleted', folder);
            accountService.deleteObjectById('folders', folder.id);

            // Trigger the actual deletion.
            RestResource.eckEntityDelete('tag', 'folder', folder.id);

            // Move projects into the uncategorized folder.
            if ($scope.projectGroups[folder.id]) {
              $scope.projectGroups['no_folder'].projects = $scope.projectGroups['no_folder'].projects.concat($scope.projectGroups[folder.id].projects);
            }
            if ($scope.account.project_groups[folder.id]) {
              $scope.account.project_groups['no_folder'].projects = $scope.account.project_groups['no_folder'].projects.concat($scope.account.project_groups[folder.id].projects);
            }

            // Immediately remove the project from scope.
            delete $scope.projectGroups[folder.id];
            delete $scope.account.project_groups[folder.id];
          });
        }

        $scope.folderMenu = {
          edit: {
            title: 'Edit Folder',
            callback: $scope.editFolder
          },
        };
        $scope.folderAdminMenu = Object.assign({}, $scope.folderMenu);
        $scope.folderAdminMenu.delete = {
          title: 'Delete Folder',
          callback: deleteFolder
        };

        $scope.projectMenu = {
          edit: {
            title: 'Edit Project',
            callback: $scope.editProject
          },
          replicate: {
            title: 'Duplicate Project',
            callback: replicateProject
          },
          archive: {
            title: 'Archive Project',
            callback: $scope.archiveProject
          }
        };
        $scope.projectAdminMenu = Object.assign({}, $scope.projectMenu);
        $scope.projectAdminMenu.delete = {
          title: 'Delete Project',
          callback: deleteProject
        };
        $scope.projectAdminMenuArchived = Object.assign({}, $scope.projectAdminMenu);
        $scope.projectAdminMenuArchived.archive = {
          title: 'Unarchive Project',
          callback: $scope.archiveProject
        };

        // Callback to indicate whether a project is being cloned.
        $scope.projectCloning = function (project) {
          return (!project.id || project.field_entity_state == 'cloning');
        };

        // Sort handler for project lists.
        $scope.sortProjects = function(update = false) {
          if (update) {
            // Save the new sorting method.
            userDataService.setDashboardSetting('sorting', $scope.settings.sorting);
          }

          // Get the field to sort off of, and the comparator to be used.
          let field = $scope.settings.sorting.mode;
          let direction = $scope.settings.sorting.dir;
          let comparator = (direction === 'asc') ? 1 : -1;

          angular.forEach($scope.projectGroups, function (projectList, type) {

            // Don't try to sort an empty list.
            if (typeof $scope.projectGroups[type].projects != 'undefined') {

              $scope.projectGroups[type].projects.sort(function(a, b) {
                if (field === 'end') {
                  // Convert date values to actual dates or false if none is set.
                  a = (a.end) ? new Date(a.end) : false;
                  b = (b.end) ? new Date(b.end) : false;
                }
                else if (field === 'field_owner') {
                  // Grab the names of the users for sorting.
                  a = a[field] ? $scope.projectOwnerName(a[field]) : false;
                  b = b[field] ? $scope.projectOwnerName(b[field]) : false;
                }
                else {
                  // Upper and lowercase strings are evaluated differently
                  a = a[field] ? a[field].toLowerCase() : false;
                  b = b[field] ? b[field].toLowerCase() : false;
                }

                // When filtering on a value, results without that value should
                // always appear after those that do.
                if (a === false) {
                  return 1;
                }
                else if (b === false) {
                  return -1;
                }

                // Otherwise, just do a regular sort based on the comparator.
                if (a > b) {
                  return comparator * -1;
                }
                else if (a < b) {
                  return comparator * 1;
                }
                else {
                  return 0;
                }
              });
            }
          });
        };

        // Return the owner display name for a given project ID.
        $scope.projectOwnerName = function(id) {
          let owner = accountService.getAccountMemberById(id);

          if (owner && typeof owner.display_name === 'string') {
            return owner.display_name.toLowerCase();
          }
          return false;
        };

        // Define the various fields that can be sorted off of.
        $scope.sortOptions = {
          id: 'Created',
          title: 'Title',
          end: 'Scheduled',
          field_owner: 'Project Owner',
        };

        // Define the various sort directions,  keyed by sort mode.
        $scope.sortDirections = {
          title: {
            asc: 'Z-A',
            desc: 'A-Z',
          },
          id: {
            asc: 'Newest',
            desc: 'Oldest',
          },
          end: {
            desc: 'Due First',
            asc: 'Due Last',
          },
          field_owner: {
            asc: 'Z-A',
            desc: 'A-Z',
          },
        };

        $scope.getSortDirections = function() {
          return $scope.sortDirections[$scope.settings.sorting.mode];
        }

        // Determines the current expansion button based on the expanded setting.
        $scope.getExpandedText = function() {
          $scope.expandText = ($scope.settings.expanded) ? 'Collapse' : 'Expand';
        };

        // Update the current expanded text based on the settings.
        $scope.getExpandedText();

        // Callback to flip expanded, and to updathe the expanded text.
        $scope.toggleExpand = function($event) {
          if ($event) {
            $event.stopPropagation();
          }
          userDataService.setDashboardSetting('expanded');
          $scope.getExpandedText();
        };

        // Add a listener to reload the view when accountLoaded is emitted.
        let refreshListener = $rootScope.$on('accountLoaded', function () {
          if (!$scope.state.submitting) {
            loadView();
          }
        });
        $rootScope.addListener(refreshListener, $scope);

        // Add a destroy listener to remove all listeners attached to scope.
        $scope.$on('$destroy', function () {
          $rootScope.destroyListeners($scope);
        });

      }
    ]);
})();
