(function () {
  'use strict';

  var app = angular.module('custom-field-service', ['angular-drupal', 'ngMaterial', 'xeditable', 'ckeditor', 'misc', 'popmenu', 'attachments', 'resource']);

  app.service('customFieldService', [
    'RestResource',
    '$mdDialog',
    'refreshService',
    'listLocalService',
    'commonService',
    'accountService',
    '$rootScope',
    function (RestResource, $mdDialog, refreshService, listLocalService,
      commonService, accountService, $rootScope) {

      var functions = {

        // Account service wrapper to load a field.
        loadField: function(id) {
          return accountService.getObjectById('custom_fields', id);
        },

        loadFieldValues: function(id, addLower = false) {
          accountService.getAccount((account) => {
            let field = functions.loadField(id);
            let mapping  = functions.getFieldMappingById(id);
            let list = account[mapping.list];

            return list.map(function(item) {
              if (addLower) {
                let label = item.display_name || item.title || item.name || item.label;
                item['_lowercase'] = label.toLowercase();
              }
              return item;
            });
          });
        },

        // Wrapper function to load a field then return its mapping.
        getFieldMappingById: function(id) {
          let bundle = false;
          let customField = functions.loadField(id);
          if (customField && customField.hasOwnProperty('bundle')) {
            bundle = customField.bundle;
          }
          return functions.getFieldMappingByBundle(bundle);
        },

        // Returns basic display and value information for a given bundle.
        getFieldMappingByBundle: function(bundle) {
          let mapping = {
            list: false,
            key: false,
            label: false
          };
          if (bundle) {

            switch (bundle) {
              case 'users':
                mapping.label = 'display_name';
                mapping.key = 'uid';
                mapping.list = 'members';
                break;
              case 'tag':
                mapping.label = 'title';
                mapping.key = 'id';
                mapping.list = 'tags';
                break;
              case 'colored_value':
                mapping.label = 'title';
                mapping.key = 'id';
                mapping.list = 'colored_vals';
                break;
              case 'boolean':
                mapping.key = 'id';
                mapping.label = 'label';
            }
          }
          return mapping;
        },

        // Wrapper function to load a field and return its field type mapping.
        getFieldTypeMappingById: function(id) {
          let fieldType = false;
          let customField = functions.loadField(id);
          if (customField && customField.hasOwnProperty('field_type')) {
            fieldType = customField.field_type;
          }
          return functions.getFieldTypeMapping(fieldType);
        },

        // Returns the field type mapping for a field type.
        getFieldTypeMapping: function(fieldType, strictValues = false) {
          let mapping = false;

          let fieldMapping = {
            'money': {
              label: 'Money',
              type: 'number',
              inputType: 'money',
              cssClass: 'custom-field-money',
            },
            'users': {
              label: 'Users',
              type: 'select',
              inputType: 'user',
              cssClass: 'custom-field-users',
            },
            'tag': {
              label: 'Tag',
              type: 'select',
              inputType: 'tag',
              cssClass: 'custom-field-tag',
            },
            'colored_value': {
              label: 'Colored Value',
              type: 'select',
              inputType: 'tag',
              cssClass: 'custom-field-tag',
            },
            'plain_text': {
              label: 'Plain Text',
              type: 'input',
              inputType: 'text',
              cssClass: 'custom-field-plain-text',
            },
            'select_list': {
              label: 'Select List',
              type: 'select-flat',
              inputType: 'select',
              cssClass: 'custom-field-select',
            },
            'phone_number': {
              label: 'Phone Number',
              type: 'input',
              inputType: 'text',
              cssClass: 'custom-field-phone',
            },
            // Has an override to provide a widget that has true/false/null vals.
            'boolean': {
              label: 'Checkbox',
              type: (strictValues) ? 'select' : 'boolean',
              inputType: (strictValues) ? 'select' : 'checkbox',
              cssClass: 'custom-field-boolean',
            },
            'email_address': {
              label: 'Email Address',
              type: 'input',
              inputType: 'email',
              cssClass: 'custom-field-email',
            },
            'url': {
              label: 'URL',
              type: 'input',
              inputType: 'url',
              cssClass: 'custom-field-url',
            },
            'number': {
              label: 'Number',
              type: 'number',
              inputType: 'number',
              cssClass: 'custom-field-number',
            },
          };
          if (fieldMapping.hasOwnProperty(fieldType)) {
            mapping = fieldMapping[fieldType];
          }

          return mapping;
        },

        getFieldTypeFilterMapping: function(fieldType, strictValues) {
          let filterMapping = {
            'number': {
              'type': 'number-range'
            },
            'money': {
              'type': 'number-range'
            },
            'select_list': {
              'type': 'chip-select-flat'
            },
            'tag': {
              'type': 'chip-select'
            },
            'colored_value': {
              'type': 'chip-select'
            },
            'users': {
              'type': 'chip-select'
            }
          };
          let mapping = functions.getFieldTypeMapping(fieldType, strictValues);
          if (filterMapping.hasOwnProperty(fieldType)) {
            mapping = Object.assign(mapping, filterMapping[fieldType]);
          }
          return mapping;
        },

        fieldIsAggregatable: function(field) {
          let aggregatable = ['number', 'boolean'];
          return (aggregatable.indexOf(field.bundle) !== -1)
        },

        //Delete Custom field from the list by selected id,
        //field: custom field object
        //$scope: customfield.js file scope to check account custom fields and delete from list
        deleteCustomField: function (id, bundle, $scope) {
          let confirm = $mdDialog.confirm()
            .multiple(true)
            .title('Are you sure you want to delete this field?')
            .textContent("This will also delete from all tasks that reference it.")
            .ariaLabel('Delete field')
            .ok('Delete Field')
            .cancel('Cancel');

          $mdDialog.show(confirm).then(function () {
            // Remove the custom field from the account.
            accountService.deleteObjectById('custom_fields', id);

            // The delete that custom field.
            RestResource.eckEntityDelete('custom_field', bundle, id).then(function () {
              refreshService.appRefresh(true);
            });
          });

        },
        updateCustomField: function (field_type, postData, id) {
          RestResource.eckEntityUpdate('custom_field', field_type, id, postData).then(function () {
            refreshService.appRefresh(true);
          });
        },

        // Wrapper function for updating custom fields. Does nothing unless a
        // colored value needs to be created.
        updateOptionEntities: function(customfield) {
          let bail = false;
          return new Promise(function(resolve, reject) {
            if (customfield.type == 'colored_value' && customfield.optionslist.length > 0) {
              let options = customfield.optionslist;
              let newOptions = [];
              let indexList = [];
              angular.forEach(options, function(value, index){
                // A negative ID is used to indicate that this is a new option.
                if (value.id < 0) {
                  newOptions.push(value);
                  indexList.push(index);
                }
              });

              if (newOptions.length > 0) {

                functions.createColoredFieldValues(newOptions).then(function(res) {
                  // Assumes that items are returned in the same order that they
                  // were sent.
                  angular.forEach(indexList, function(origIndex, index) {
                    options[origIndex] = res[index];
                  });
                  resolve();
                });
              }
              else {
                bail = true;
              }
            }
            else {
              bail = true;
            }

            // Make sure that the promise is always resolved.
            if (bail) {
              resolve();
            }
          });
        },

        // Creates new colored_value entities for use in custom fields.
        createColoredFieldValues: function(items) {
          let request = [];
          angular.forEach(items, function (item) {

            // Setup up the request objects
            let requestItem = {};
            requestItem = {
              title: item.title,
              field_label_color: item.field_label_color,
              __attach: {
                entity_id: $rootScope.account.activeAccount
              }
            }

            request.push(requestItem);
          });
          // Send the new tasks on the server.
          return RestResource.eckEntityCreateMultiple('tag', 'colored_value', request);
        },

        createCustomField: function (bundle, config, label) {
          // Set a static field type for now.
          var newField = {
            title: config.title,
            field_type: label,
            bundle: bundle,
            // field_aggregation_options: config.field_aggregation_options,
          };

          // prepare hash value.
          var hash = commonService.sdbmHash(JSON.stringify(newField));
          newField.hash = hash;
          // Push changes for local service to adjust the display, the fake version.
          listLocalService.addChanges('custom_field', 'created', newField, 'id');
          // $rootScope.$emit('customFieldCreate');

          RestResource.eckEntityCreate('custom_field', bundle, config).then((res) => {
            newField.id = res.id[0].value;
            // Push changes for local serice to adjust the display.
            listLocalService.addChanges('custom_field', 'created', newField, 'id');
            $rootScope.$emit('customFieldCreate');
          });
        },
        OpenCustomFieldForm: function (edit_data = false) {
          $mdDialog.show(
            $mdDialog.customFieldFormModal({
              locals: {
                edit_data: edit_data,
              }
            })
          )
        },
        // This loads options for custom fields of type select.
        // for now this only support tags and users.
        loadFieldOptions: function(fieldId, inputType, $scope) {
          $scope.customField = functions.loadField(fieldId);
          let mapping = false;
          if ($scope.customField.bundle) {
            mapping = functions.getFieldMappingByBundle($scope.customField.bundle);
            if (mapping) {

              if (mapping.label) {
                $scope.selectDisplay = mapping.label;
              }
              if (mapping.key) {
                $scope.selectKey = mapping.key;
              }
            }
          }

          let options = [];
          if ($scope.customField && $scope.customField.hasOwnProperty('options')) {
            options = $scope.customField.options;
          }
          else {
            options = functions.getDefaultOptions($scope.customField, $scope.selectKey, $scope.selectDisplay);
          }

          if (options.length > 0) {
            if (mapping.list) {
              options = accountService.getObjectByIds(mapping.list, options, mapping.key);
            }
          }

          if (!$scope.allowMultiple) {
            let noneOption = {};

            if ($scope.selectDisplay && $scope.selectKey) {
              noneOption[$scope.selectDisplay] = 'None';
              noneOption[$scope.selectKey] = $scope.emptyVal;
              noneOption['_lowercase'] = 'none';
            }
            else {
              noneOption = 'None';
            }
            // This shouldn't happen, but sometimes None can be added twice.
            if (!options.includes(noneOption)) {
              options.unshift(noneOption);
            }
          }
          else if (inputType == 'select') {
            $scope.selectKey = 'id';
            let complexOptions = [];
            angular.forEach(options, function(value) {
              let item = {
                id: value,
                title: value,
                _lowercase: value.toLowerCase()
              };
              complexOptions.push(item);
            });

            options = complexOptions;

            if ($scope.modelVal) {
              $scope.modelVal = commonService.getObjectByIds(options, $scope.modelVal, $scope.selectKey);
            }
          }

          return options;
        },

        // Callback to return default options for custom fields.
        getDefaultOptions: function(field, key, label) {
          let options = [];
          if (field.bundle) {
            switch (field.bundle) {
              case 'boolean':
                options = [
                  {[key]: 1, [label]: 'Yes'},
                  {[key]: 0, [label]: 'No'}
                ];
                break;
            }
          }
          return options;
        }
      };

      return functions;
    }
  ]);
})();
