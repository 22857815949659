(function () {
  'use strict';

  angular.module('rest', [
      'ngRoute',
      'ngResource',
      'angular-drupal',
      'ngCookies'
    ])

    .config(['drupalProvider', function (drupalProvider) {
      var $cookies;
      angular.injector(['ngCookies']).invoke(['$cookies', function (_$cookies_) {
        $cookies = _$cookies_;
      }]);

      drupalProvider.setConfigs({
        sitePath: AppConfig.endpointUrl,
        cdnUrl: AppConfig.cdnUrl
      });
    }])

    .factory('RestResource', [
      'drupal',
      '$rootScope',
      '$mdToast',
      'tracking',
      function (drupal, $rootScope, $mdToast, tracking) {
      return {
        pending: 0,
        RestRequest: function (dest, data, method = 'GET', errorMsg = false, format = 'json', eventListeners = false, successStatus = [200, 201, 204]) {
          var self = this;

          // Set up the error message if applicable.
          if (errorMsg === true) {
            errorMsg = 'Your last saved failed. If the problem continues, please refresh.';
          }

          self.pending++;

          return new Promise(function (resolve, reject) {
            drupal.token().then(function (token) {

              var path;

              if (typeof dest == 'object') {
                var query_array = [];
                if (typeof dest.query == 'object') {
                  for (var key in dest.query) {
                    if (dest.query.hasOwnProperty(key)) {
                      query_array.push(key + '=' + dest.query[key]);
                    }
                  }
                }
                if (query_array.length > 0) {
                  path = dest.path + '?_format=' + format + '&' + query_array.join('&');
                }
                else {
                  path = dest.path + '?_format=' + format;
                }
              }
              else {
                path = dest + '?_format=' + format;
              }

              if ($rootScope.account && $rootScope.account.activeAccount) {
                path = path + '&account=' + $rootScope.account.activeAccount;
              }

              var req = new XMLHttpRequest();
              if (eventListeners) {
                angular.forEach(eventListeners, function(listener, eventName) {
                  req.upload.addEventListener(eventName, listener, false);
                });
              }

              req.open(method, drupal.restPath() + path);
              req.setRequestHeader('Content-type', 'application/json');
              if (method != 'GET' && token) {
                req.setRequestHeader('X-CSRF-Token', token);
              }
              req.withCredentials = true;

              req.onload = function () {
                self.completeRequest();
                if (successStatus.indexOf(req.status) > -1) {
                  if (req.response.length > 0) {
                    resolve(JSON.parse(req.response));
                  }
                  else {
                    resolve();
                  }
                }
                else {
                  // Failure? Send a message if applicable.
                  if (errorMsg) {
                    // Tell the user what happened.
                    let toast = $mdToast.simple()
                      .textContent(errorMsg)
                      .action('Ok')
                      .highlightAction(true)
                      .toastClass('warning')
                      .position('bottom right')
                      .hideDelay(60000);
                    $mdToast.show(toast);
                  }

                  // Tell tracking services.
                  tracking.logEvent('error', 'Request Error: [' + req.status + '] ' + req.responseText + '(' + req.responseURL + ')');

                  // Send the rejection notice and complete either way.
                  reject(Error(req.status));
                  self.completeRequest();
                }
              };

              req.onerror = function () {
                reject(Error("Network Error"));
                self.completeRequest();
              };
              req.send(JSON.stringify(data));

            });
          });
        },
        completeRequest() {
          var self = this;
          self.pending -= self.pending;
          if (self.pending < 1) {
            $rootScope.root.loading = false;
          }
        },
        /**
         * Calls a request function with a retry option.
         *
         * This function accepts another function in this resource and calls it
         * in an attempt to get a success for background processes (cloning).
         *
         * Be careful about using on a call that could produce duplication!
         *
         * @param  callback  A function to call in this object.
         * @param  args      An Array of args to pass to callback.
         * @param  checker   A function to determine if request was successful.
         * @param  [retries] The max number of attempts to make.
         * @param  [backoff] The compounding time (ms) to wait before retry.
         * @return           A Promise to tie a resolve/reject callback to.
         */
        requestRetry: function(callback, args, checker, retries = 2, backoff = 300) {
          return new Promise((resolve, reject) => {
            // Callback must be a property of RestRequest. This also assumes it
            // will return a Promise or this will fail.
            this[callback].apply(this, args).then((result) => {
              // Note that this result (generally an object) will only be the
              // object, not the information about the request.
              if (checker(result)) {
                resolve(result);
              }
              else {
                if (retries > 0) {
                  // Increases the delay and decreases the retry count.
                  setTimeout(() => {
                    // Recursion. Bubbles up resolve/reject based on result.
                    return this.requestRetry(callback, args, checker, retries - 1, backoff * 2).then(resolve, reject);
                  }, backoff);
                }
                else {
                  reject(result);
                }
              }
            }, reject);
          });
        },
        entityFieldUpdate: function (data, errorMsg = true) {
          return this.RestRequest('entity/field-update', data, 'PATCH', errorMsg);
        },
        eckEntityCreate: function (type, bundle, data, errorMsg = true) {
          var path = ['eck', type, bundle].join('/');
          return this.RestRequest(path, data, 'POST', errorMsg);
        },
        eckEntityCreateMultiple: function (type, bundle, data, errorMsg = true) {
          var path = ['eck-multiple', type, bundle].join('/');
          return this.RestRequest(path, data, 'POST', errorMsg);
        },
        eckEntityUpdate: function (type, bundle, id, data, errorMsg = true) {
          var path = ['eck', type, bundle, id].join('/');
          return this.RestRequest(path, data, 'PATCH', errorMsg);
        },
        eckEntityReplicate: function (type, bundle, id, data = {}, errorMsg = true) {
          data.__action = 'replicate';
          var path = ['eck', type, bundle, id].join('/');
          return this.RestRequest(path, data, 'PATCH', errorMsg);
        },
        eckEntityGet: function (type, bundle, id, query) {
          if (type == 'node') {
            return this.loadTask(id, query);
          }
          let path = ['eck', type, bundle, id].join('/');
          if (typeof query != 'undefined') {
            path = {
              path: path,
              query: query
            };
          }

          return this.RestRequest(path, false, 'GET');
        },
        eckEntityDelete: function (type, bundle, id, data) {
          let path = ['eck', type, bundle, id].join('/');
          let errorMsg = 'Your deletion failed.  If the problem continues, please refresh.'
          return this.RestRequest(path, data, 'DELETE', errorMsg);
        },
        loadTask: function (id, query) {
          let path = 'task/' + id;
          if (typeof query != 'undefined') {
            path = {
              path: path,
              query: query
            };
          }

          return this.RestRequest(path, false, 'GET');
        },
        loadAccountInfo: function (lastFetch = 0, includeArchived = false) {
          let path = {
            path: 'rest/account-info',
            query: {
              last_fetch: lastFetch
            }
          };
          if (includeArchived) {
            path.query.archived = includeArchived;
          }
          return this.RestRequest(path);
        },
        loadAccountInfoData: function (data) {
          return this.RestRequest('rest/account-info', data, 'PATCH');
        },
        loadMemberAccounts: function() {
          return new Promise(function(resolve, reject) {
            drupal.viewsLoad('rest/accounts').then(function(view) {
              var results = view.getResults();
              resolve(results);
            });
          });
        },
        loadTags: function () {
          return new Promise(function (resolve, reject) {
            drupal.viewsLoad('rest/account-tags').then(function (view) {
              var results = view.getResults();
              resolve(results);
            });
          });
        },
        loadMembers: function () {
          return new Promise(function (resolve, reject) {
            drupal.viewsLoad('rest/account-members').then(function (view) {
              var results = view.getResults();
              resolve(results);
            });
          });
        },
        loadPriorities: function () {
          return new Promise(function (resolve, reject) {
            drupal.viewsLoad('rest/account-priorities').then(function (view) {
              var results = view.getResults();
              resolve(results);
            });
          });
        },
        loadStatuses: function () {
          return new Promise(function (resolve, reject) {
            drupal.viewsLoad('rest/account-lists').then(function (view) {
              var results = view.getResults();
              resolve(results);
            });
          });
        },
        loadResources: function () {
          return new Promise(function (resolve, reject) {
            drupal.viewsLoad('rest/resource-list').then(function (view) {
              var results = view.getResults();
              resolve(results);
            });
          });
        },
        loadCalendar: function (start, end) {
          return this.loadTaskList({
            'filters': {
              'start': start,
              'end': end
            }
          });
        },
        loadMessages: function (data) {
          var path = 'rest/message-search';
          return this.RestRequest(path, data, 'POST');
        },
        loadProjectList: function (includeArchived = false, lastLoad = 0) {
          var path = {
            path: 'rest/project-list'
          };

          if (includeArchived) {
            path.query = {
              archived: includeArchived
            };
          }
          return this.RestRequest(path);
        },
        loadTaskList: function (data, type = 'update') {
          var path = {
            path: 'rest/task-search'
          };
          if (type == 'update') {
            path.query = {
              refresh: true
            };
          }
          return this.RestRequest(path, data, 'POST');
        },
        loadTaskExport: function (id, format, query) {
          let path = 'rest/task-export/' + id;
          if (typeof query != 'undefined') {
            path = {
              path: path,
              query: query
            };
          }
          return this.RestRequest(path, false, 'GET', false, format);
        },
        loadFileList: function () {
          return this.RestRequest('rest/files');
        },
        loadCustomValues: function (taskId) {
          return this.RestRequest('rest/custom-values/' + taskId);
        },

        loadTemplateList: function (type, all = false) {
          var path = {
            'path': 'rest/templates/' + type
          };
          if (all) {
            path.query = {
              'all': all
            };
          }
          return this.RestRequest(path);
        }
      };
    }]);
})();
