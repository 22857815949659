(function () {
  'use strict';

  var app = angular.module('message-service');

  app.service('entityMessageService', [
    '$rootScope',
    'RestResource',
    'entityListService',
    'searchService',
    function ($rootScope, RestResource, entityListService, searchService) {
      // Initialize the entityList service and overwrite any functions needed.
      let entityService = new entityListService('entitymessagelist', 'entitymessage', 'mid', 'messages', 'entitymessageListLoaded');

      // Override the entityListService getRequestBase function to provide the
      // correct default request for this message list type.
      entityService.getRequestBase = function(update) {
        var request = {};

        // Provide the default request body for listing task activity.
        if (entityService.entity && entityService.entity.type == 'task') {

          request = {
            filters: {
              field_task: [entityService.entity.id],
              template: {
                not: ['comment_notification']
              }
            }
          };
        }
        return request;
      };

      // Override the entityListService processResults function to ignore
      // deleted messages, since messages can't be deleted.
      // this.processResults = function(result) {
      //   self.processNewValues('entities', result.messages);
      // };

      // Override the entityListService request function to point to the
      // messages endpoint.
      entityService.performRequest = function(request, update) {
        searchService.messageSearch(request, update).then(function(result) {
          entityService.afterRequest(request, result, update);
        });
      };

      // // Load all messages pertaining to this project.
      // this.loadProjectMessages = function(pid) {
      //   let filters = {
      //     project: [pid]
      //   };
      //   this.loadMessages(filters);
      // }

      // Initialize the service with the values for this instance.
      entityService.serviceInit();


      // Define the setEntity function, which is used when performing a request
      // to limit results to only those pertaining to this entity.
      entityService.setEntity = function(type, id) {
        // Don't bother doing anything with the entity list unless all the
        // relevant values are present.
        if (entityService.entity && entityService.entity.type && entityService.entity.id) {

          // If the entity isn't the same, then nuke the list and start over.
          // Only one entity can be listed by this service at a time.
          if (entityService.entity.type !== type || entityService.entity.id !== id) {
            entityService.vals.entities = {};
            entityService.vals.entityList = [];
          }
        }
        entityService.entity = {
          type: type,
          id: id
        };
      };


      // Provide a wrapper around digestEntities.
      this.digestMessages = function(changes) {
        return self.digestEntities(changes);
      };

      // Set up all the entity-type specific function aliases for easier use.
      entityService.getMessagesByIds = entityService.getEntitiesByIds;
      entityService.getMessageById = entityService.getEntityById;
      entityService.getMessageList = entityService.getEntityList;
      entityService.getCurrentMessageList = entityService.getCurrentEntityList;
      entityService.removeMessage = entityService.removeEntity;
      // entityService.addToCurrentMessageList = entityService.addtoCurrentList;
      entityService.getMessages = entityService.getEntities;


      return entityService;
    }
  ]);
})();
