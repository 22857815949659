(function () {
  'use strict';

  var app = angular.module('task');

  app.directive('taskMini', function () {
    return {
      scope: {
        nid: '=',
      },
      controller: [
        '$scope',
        '$rootScope',
        '$routeParams',
        'accountService',
        'multiEditService',
        'taskListService',
        'taskService',
        function ($scope, $rootScope, $routeParams, accountService, multiEditService, taskListService, taskService) {
          $scope.task = false;

          $scope.getState = function () {
            $scope.pending = taskService.taskIsPending($scope.task.nid);
          };

          $scope.preventDrag = function(task) {
            if (task.field_entity_state == "cloning") {
              return true;
            }
          };

          $scope.getImageUrl = function () {
            // TODO: POC only. This would need to verify it's an image, have a
            // smaller version, crop correctly, and only show up on large mode.
            $scope.imageUrl = AppConfig.endpointUrl + $scope.task.file_url[0];
          };

          $scope.getProjectClass = function () {
            $scope.projectClass = 'no-project';

            // Handle project IDs.
            if (typeof $scope.task.project == 'string') {
              $scope.primaryProject = accountService.getObjectById('projects', $scope.task.project)
            }
            // Or project objects.
            else if (typeof $scope.task.project == 'object') {
              if ($scope.task.project instanceof Array) {
                $scope.primaryProject = accountService.getObjectById('projects', $scope.task.project[0]);
              }
              else {
                $scope.primaryProject = $scope.task.project
              }
            }

            if ($routeParams['projectId'] == 'all' && $scope.primaryProject) {
              $scope.projectClass = 'border-color-' + $scope.primaryProject.field_project_color;
            }
          };

          $scope.getTaskOwner = function () {
            let taskOwner = accountService.getObjectById('members', $scope.task.field_task_owner, 'uid');
            if (typeof taskOwner == 'undefined') {
              $scope.taskOwner = 'Unassigned';
            }
            else {
              $scope.taskOwner = taskOwner.display_name;
            }
          };

          $scope.getPriorityClass = function () {
            if ($scope.task.field_priority) {
              $scope.priority_class = 'priority-' + accountService.getObjectById('priorities', $scope.task.field_priority)._lowercase;
            }
          };

          $scope.init = function (digest = true) {
            // Potentially wait for task to be ready.
            taskListService.fetchTaskById($scope.nid).then(function(task) {
              $scope.task = task;

              // $scope.getImageUrl();
              $scope.getState();
              $scope.getProjectClass();
              $scope.getTaskOwner();
              $scope.getPriorityClass();
              if (digest) {
                $scope.$digest();
              }
            });
          };
          $scope.init();

          let taskUpdateListener = $rootScope.$on('taskPostUpdate', function(event, task, updates, hash) {
            if (task.nid == $scope.nid) {
              $scope.init(false);
            }
          });

          let hideTaskListener = $rootScope.$on('hideTask', function(event, id, task) {
            if (id == $scope.task.nid) {
              $scope.getState();
            }
          });

          $rootScope.addListener(taskUpdateListener, $scope);
          $rootScope.addListener(hideTaskListener, $scope);

          $scope.$on('$destroy', function () {
            $rootScope.destroyListeners($scope);
          });
        }
      ],
      templateUrl: 'app/src/task/task-mini.html'
    };
  });
})();
