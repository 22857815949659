(function() {
'use strict';

angular.module('addusers', ['angular-drupal', 'user'])
.directive('addusers', function() {
  return {
    scope: {},
    controller: ['$scope', '$timeout', 'drupal', '$rootScope','$window', 'accountService', function($scope, $timeout, drupal, $rootScope, $window, accountService) {

      $scope.init = function() {
        $scope.path = AppConfig.externalEndpointUrl;
      };

      // Wait for a connection to Drupal.
      if (!drupal.connected || !accountService.loaded()) {
        // Because account loaded fires after drupal.connected.
        let accountListener = $rootScope.$on('accountLoaded', function() {
          $scope.init();
          accountListener();
        });
      }
      else {
        $scope.init();
      }
    }],
    templateUrl: 'app/src/components/addusers/addusers.html'
  };
});

})();
