(function() {
  'use strict';

  var app = angular.module('inlineEdit');

  app.controller('inlineWidgetInputBase', [
    '$scope',
    '$controller',
    '$element',
    '$attrs',
    function($scope, $controller, $element, $attrs) {
      $scope.getPlaceholder = function() {
        if ($scope.fieldName == 'title') {
          return 'Loading...';
        }
        else {
          return 'Add...';
        }
      }

      $scope.immediateOpen = function($element) {
        $element.find('input').focus();
      };

      angular.extend(this, $controller('inlineWidgetCtrl', { $scope: $scope, $element: $element, $attrs: $attrs }));
    }
  ]);

  app.directive('inlineWidgetInput', function() {
    let directiveImage = this;
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&',
        modelVal: '=?',
        modelKey: '=?',
        immediate: '=?',
        context: '@?'
      },
      templateUrl: 'app/src/components/inline-edit/widgets/input/templates/input.html',
      controller: 'inlineWidgetInputBase',
    };
  });

  app.directive('inlineWidgetMatInput', function() {
    let directiveImage = this;
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&',
        modelVal: '=?',
        modelKey: '=?',
        immediate: '=?',
        context: '@?'
      },
      templateUrl: 'app/src/components/inline-edit/widgets/input/templates/input-mat.html',
      controller: 'inlineWidgetInputBase',
    };
  });

  app.directive('inlineWidgetTextareaInput', function() {
    let directiveImage = this;
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&',
        modelVal: '=?',
        modelKey: '=?',
        immediate: '=?',
        context: '@?'
      },
      templateUrl: 'app/src/components/inline-edit/widgets/input/templates/input-textarea.html',

      controller: 'inlineWidgetInputBase',
    };
  });

})();
