(function () {
  'use strict';

  angular.module('tableview', [
    'ngRoute',
    'angular-drupal',
    'infinite-scroll',
    'md.data.table',
    'table',
    'aggregation'
  ])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/:accountId/projects/:projectId/table/:taskId?', {
      templateUrl: 'app/src/tableview/tableview.html',
      controller: 'TableviewCtrl',
      activetab: 'table',
      reloadOnSearch: false
    });
  }])

  .controller('TableviewCtrl', [
    '$scope',
    '$rootScope',
    '$controller',
    'accountService',
    function ($scope, $rootScope, $controller, accountService) {
      // Could ideally be sent from the server at some point. This has the
      // configurations for the order and lookup info for each cell.
      $scope.account = {};

      $scope.hideProjectColumn = function() {
        return !$scope.noProject;
      };

      $scope.tableColumns = [
        {
          title: 'Task',
          fieldName: 'title',
          editPanel: true,
          sortable: true,
          useButton: true,
          inputStyle: 'textarea'
        },
        {
          title: 'Project',
          fieldName: 'project',
          editPanel: true,
          sortable: true,
          hide: $scope.hideProjectColumn,
          preventTaskTrigger: true
        },
        {
          title: 'Status',
          fieldName: 'field_list',
          editPanel: true,
          sortable: true
        },
        {
          title: 'Scheduled',
          fieldName: 'field_date',
          editPanel: true,
          sortable: true,
          compile: '<task-schedule nid="nid" no-schedule-text="true"></task-schedule>'
        },
        {
          title: 'Priority',
          fieldName: 'field_priority',
          editPanel: true,
          sortable: true
        },
        {
          title: 'Owner',
          fieldName: 'field_task_owner',
          editPanel: true,
          sortable: true,
          compile: '<memberpic uid="itemProp(fieldName, \'uid\', cid)"></memberpic> <span class="name-wrapper">{{ itemProp(fieldName, false, cid) }}</span> <outsider project="task.project" task="nid" uid="itemProp(fieldName, \'uid\', cid)" tooltip="true"></outsider>',
        },
      ];

      // Add these columns based on account info.
      accountService.getAccount().then(function (account) {
        $scope.account = account;
        // Build table column based on user custom fields.
        addCustomFieldToTable();

        // Make sure these columns are always last.
        $scope.tableColumns.push({
          title: 'Updated',
          fieldName: 'changed',
          sortable: true
        });
        $scope.tableColumns.push({
          title: 'Details',
          fieldName: 'details',
          compile: '<task-details nid="nid"></task-details>'
        });
      });

      // Add custom fields and their values as columns for the table.
      function addCustomFieldToTable() {
        let customFields = $scope.account.custom_fields;
        angular.forEach(customFields, function(value) {
          let newCol = {
            title: value.title,
            fieldName: 'task_custom_value',
            cid: value.id,
            editPanel: true,
            sortable: true,
            type: value.field_type
          };

          // Boolean fields should an actual input instead of a popup.
          if (value.bundle == 'boolean') {
            newCol.compile = `
              <custom-field-widget
                task-id="nid"
                field-name="task_custom_value"
                cid="${value.id}"
              ></custom-field-widget>
            `;
            // Don't add the edit panel for inline edits.
            newCol.editPanel = false;
          }
          // Show memberpics on custom user-fields.
          if (value.bundle == 'users') {
            newCol.compile = '<memberpic uid="itemProp(fieldName, \'uid\', cid)"></memberpic> <span class="name-wrapper">{{ itemProp(fieldName, false, cid) }}</span> ';
          }

          $scope.tableColumns.push(newCol);
        });
      }

      angular.extend(this, $controller('tableCtrl', { $scope: $scope }));
    }
  ]);
})();
