(function () {
  'use strict';

  var app = angular.module('account-settings', [
    'ngRoute',
    'angular-drupal',
    'misc',
    'rest',
    'labelmanage',
    'customfield'
  ]);

  app.config(['$mdDialogProvider', function ($mdDialogProvider) {
    $mdDialogProvider.addPreset('accountSettingsModal', {
      options: function () {
        return {
          controller: 'AccountDialogController',
          templateUrl: 'app/src/account/account-settings.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          escapeToClose: true,
          hasBackdrop: true,
        };
      }
    });
  }]);

  app.controller('AccountDialogController', [
    '$scope',
    '$mdDialog',
    'RestResource',
    'accountService',
    'refreshService',
    'featureService',
    function ($scope, $mdDialog, RestResource, accountService, refreshService, featureService) {
      $scope.ariaLabel = 'Account Settings';

      $scope.dialogReady = true;

      accountService.getAccount().then(function(account) {
        $scope.account = account;
        $scope.settings = $scope.account.settings;
        if (typeof $scope.settings.statusNames == 'undefined') {
          $scope.settings.statusNames = {};
        }

        $scope.statuses = $scope.account.statuses;
        $scope.statusNames = $scope.settings.statusNames;

        $scope.humanKeys = {};

        for (let i = 0; i < $scope.account.statuses.length; i++) {
          let item = $scope.statuses[i];
          if (!$scope.statusNames.hasOwnProperty(item.id) ||
            $scope.statusNames[item.id].length < 1) {
            $scope.statusNames[item.id] = item.title;
          }
          $scope.humanKeys[item.id] = i + 1;
        }

        // Check toe enable the Tag management tab.
        let tagManagementFeature = 'account_settings_tag_management_interface';
        if (featureService.featureStatus(tagManagementFeature)) {
          $scope.tabs.tagManager = {
            label: 'Manage Tags',
          };
        }

        // Check toe enable the Tag management tab.
        let customFieldManagementFeature = 'account_settings_custom_field_interface';
        if (featureService.featureStatus(customFieldManagementFeature)) {
          $scope.tabs.customfield = {
            label: 'Custom Fields'
          }
        }
      });
      $scope.activeTab = 'accountSettings';

      $scope.tabs = {
        'accountSettings': {
          label: 'Account Settings',
        }
      };


      $scope.changeTab = function(tab) {
        if (tab !== $scope.activeTab) {
          $scope.activeTab = tab;
        }
      };

      $scope.showTabs = function() {
        return ($scope.tabs && Object.keys($scope.tabs).length > 1);
      };

      $scope.cancel = function () {
        $mdDialog.cancel();
      };

      $scope.saveAccountSettings = function () {
        // Reassign the status names back to account settings.
        $scope.settings.statusNames = $scope.statusNames;
        accountService.processNewValues('settings', $scope.settings);

        var request = {
          field_data: JSON.stringify($scope.settings)
        };

        var afterResponse = function (res) {
          // Close the panel.
          $mdDialog.cancel();
          refreshService.appRefresh(true);
        };

        RestResource.eckEntityUpdate('group', 'account', $scope.account.entity.id, request).then(afterResponse);
      };
    }
  ]);
})();
