(function () {
  let app = angular.module('repeatable', []);

  app.directive('repeatable',
    function () {
      return {
        scope: {
          taskId: '='
        },
        controller: [
          '$scope',
          'taskService',
          '$mdPanel',
          'RestResource',
          'repeatableService',
          'featureService',
          function ($scope, taskService, $mdPanel, RestResource, repeatableService, featureService) {

            $scope.enable = false;
            featureService.featureEnabled('repeatable_tasks').then(function(enabled) {
              $scope.enable = enabled;
            });

            taskService.loadTask($scope.taskId).then(function(task) {
              $scope.task = task;
              if ($scope.task) {
                $scope.repeatable = $scope.task.repeatable;
                $scope.updateValues();
              }
            });

            $scope.updateValues = function() {
              $scope.period_text = repeatableService.getRepeatText($scope.task, $scope.repeatable);
            };

            $scope.removeRepeatable = function() {
              // Delete the repeatable object itself.
              let repeatableId = $scope.repeatable.id || $scope.task.field_repeatable;
              repeatableService.removeRepeatable(repeatableId, $scope.task.nid);

              // Unset and hide locally.
              $scope.repeatable = null;
              $scope.updateValues();
            };

            $scope.setRepeatableValues = function(values) {
              $scope.repeatable = values;
              $scope.updateValues();

              repeatableService.setRepeatable($scope.repeatable.id, $scope.task.nid);
            };

            $scope.openPanel = function(ev) {
              let position = $mdPanel.newPanelPosition()
              .relativeTo(ev.currentTarget)
              .addPanelPosition(
                $mdPanel.xPosition.ALIGN_START,
                $mdPanel.yPosition.ALIGN_TOPS
              );

              let config = {
                attachTo: angular.element(document.body),
                controller: 'RepeatablePanelController',
                templateUrl: 'app/src/components/repeatable/repeatable-panel.html',
                position: position,
                openFrom: ev,
                clickOutsideToClose: true,
                focusOnOpen: true,
                locals: {
                  task: $scope.task,
                  repeatable: $scope.repeatable,
                  parentScope: $scope
                },
                zIndex: 82,
                width: 300
              };

              $mdPanel.open(config);
            };
          }
        ],
        templateUrl: 'app/src/components/repeatable/repeatable.html'
      };
    });
})();
