(function() {
  'use strict';

  var app = angular.module('inlineEdit');

  app.directive('inlineWidgetSelectFlat', function($compile) {
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&?',
        inputType: '@',
        modelVal: '=?',
        modelKey: '=?',
        immediate: '=?',
        context: '@?'
      },
      templateUrl: 'app/src/components/inline-edit/widgets/select/templates/select-flat.html',
      controller: 'inlineWidgetSelectBase'
    };
  });

  app.directive('inlineWidgetMatSelectFlat', function($compile) {
    return {
      restrict: 'E',
      scope: {
        taskId: '=taskId',
        cid: '=?',
        fieldName: '@',
        label: '@',
        defaultValue: '@',
        onChange: '&?',
        inputType: '@',
        modelVal: '=?',
        modelKey: '=?',
        immediate: '=?',
        context: '@?'
      },
      templateUrl: 'app/src/components/inline-edit/widgets/select/templates/select-flat-mat.html',
      controller: 'inlineWidgetSelectBase'
    };
  });
})();
