(function () {
  'use strict';

  angular.module('projectstats', ['angular-drupal'])
    .directive('projectstats', function () {
      return {
        scope: {
          project: "=",
          expanded: "=?"
        },
        controller: ['$scope', '$rootScope', 'commonService', 'featureService', function ($scope, $rootScope, commonService, featureService) {
          $scope.init = function() {
            $scope.dateRange = false;

            $scope.projectStatsCalc();

            $scope.dateInit();
          }

          $scope.projectStatsCalc = function() {
            let project = $scope.project;
            $scope.total = project.total;
            $scope.complete = project.completed ? project.completed : false;
            $scope.overdue = project.overdue ? project.overdue : false;
            $scope.duesoon = project.duesoon ? project.duesoon : false;
            let percentComplete = 0;
            $scope.progressClass = 'empty';

            if ($scope.total) {
              if ($scope.total && $scope.total > 0) {
                percentComplete = ($scope.complete / $scope.total) * 100;
                $scope.progressClass = 'empty';
              }
              $scope.tasksLeft = $scope.total - $scope.complete;
              $scope.progressClass = ($scope.tasksLeft === 0) ? 'complete' : 'in-progress';
            }
            else {
              $scope.tasksLeft = false;
            }

            $scope.tasksPending = !($scope.tasksLeft && $scope.tasksLeft == 0);

            $scope.percentComplete = percentComplete;

            $scope.statistics = [
              {
                type: 'left',
                value: ($scope.tasksLeft === false) ? 0 : $scope.tasksLeft,
                label: 'To Do',
                class: $scope.tasksPending ? 'remaining' : ''
              },
              {
                type: 'done',
                value: ($scope.complete === false) ? 0 : $scope.complete,
                label: 'Done',
                class: ($scope.complete && $scope.complete > 0) ? 'complete' : ''
              },
              {
                type: 'duesoon',
                value: ($scope.duesoon === false) ? 0 : $scope.duesoon,
                label: 'Due Soon',
                class: ($scope.duesoon && $scope.duesoon > 0) ? 'duesoon' : ''
              },
              {
                type: 'overdue',
                value: ($scope.overdue === false) ? 0 : $scope.overdue,
                label: 'Overdue',
                class: ($scope.overdue && $scope.overdue > 0) ? 'overdue' : ''
              }
            ];

            // Check if the display member count feature is enabled.
            featureService.featureEnabled('project_statistics_display_member_count').then(function(displayMembers) {
              if (project.type != 'personal' && displayMembers) {
                if (typeof project.members == 'undefined') {
                  project.members = [];
                }
                $scope.statistics.push({
                  type: 'members',
                  value: project.members.length,
                  label: 'Members',
                  class: (project.members.length == 0) ? 'empty' : ''
                });
              }
            });
          }

          $scope.dateInit = function() {
            let dateStatus = 'none';
            let dateObj = commonService.createDateRange($scope.project.start, $scope.project.end);
            if (dateObj.hasOwnProperty('dateRange')) {
              $scope.dateRange = dateObj.dateRange;
            }
            if (dateObj.hasOwnProperty('endDate')) {
              dateStatus = commonService.getDateStatus(dateObj.endDate);
            }

            dateStatus = $scope.tasksPending ? dateStatus : 'complete';
            $scope.dateStatus = dateStatus;
          }

          // Kick off init.
          $scope.init();

          // Watch selectedRange model separately from mdDateRangeSelect since
          // it calls the update callback like 4x as much as needs to.
          let dateStartListener = $scope.$watch('project.start', $scope.dateInit);
          $rootScope.addListener(dateStartListener, $scope);
          let dateEndListener = $scope.$watch('project.end', $scope.dateInit);
          $rootScope.addListener(dateEndListener, $scope);

          // Add the projectlist listener to catch updates to the project.
          let projectListener = $rootScope.$on('projectListLoaded', $scope.init);
          $rootScope.addListener(projectListener, $scope);

        }],
        templateUrl: 'app/src/components/projectstats/projectstats.html'
      };
    });
})();
