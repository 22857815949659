(function () {
  'use strict';

  angular.module('aggregation', ['angular-drupal', 'account',])
    .directive('aggregationViewer', function () {
      return {
        restrict: 'E',
        scope: {
          fieldName: "=",
          cid: "=?",
        },
        controller: [
          '$scope',
          '$rootScope',
          '$filter',
          'accountService',
          'taskListService',
          'customFieldService',
          function ($scope, $rootScope, $filter, accountService, taskListService, customFieldService) {
            // Default to viewing the count of items.
            $scope.active = 'sum';


            // Initialize the agg viewer.
            $scope.init = function() {
              // Get all of the aggregations from the task list.
              $scope.taskList = taskListService.getTaskList();
              $scope.aggregations = $scope.taskList.aggregations;

              // Check if this field has a set of aggregations.
              if ($scope.aggregations && $scope.aggregations.hasOwnProperty($scope.fieldName)) {
                let fieldAgg = $scope.aggregations[$scope.fieldName];
                //Special handling for custom fields.
                if ($scope.hasOwnProperty('cid')) {
                  if (fieldAgg.hasOwnProperty($scope.cid)) {
                    // Do a lookup to handle the structure of the agg response.
                    $scope.aggOptions = $scope.getCustomFieldAgg(fieldAgg);

                  }
                }
                else {
                  $scope.aggOptions = fieldAgg;
                }

                // Build the options into an object that is displayable.
                $scope.buildOptions();

                // Get the aggregation onption that the user was looking at last.
                $scope.getAggView();
              }
            }

            // Check to see if a value has been placed in local storage for
            // the current agg view.
            $scope.getAggView = function() {

              // TODO: This really should be stored on either the user or layout.
              $scope.account = accountService.getAccountValue();
              if ($scope.account && !$scope.account.aggs) {
                $scope.account.aggs = {};
              }
              if ($scope.account.aggs.hasOwnProperty($scope.fieldName)) {
                let aggs = $scope.account.aggs[$scope.fieldName];
                if ($scope.cid) {
                  if (aggs.hasOwnProperty($scope.cid)) {
                    $scope.active = aggs[$scope.cid];
                  }
                }
                else {
                  $scope.active = aggs;
                }
              }
            }

            // Respond to changes from the user to allow updates to persist.
            $scope.setAggView = function() {
              if ($scope.cid) {
                if (!$scope.account.aggs.hasOwnProperty($scope.fieldName)) {
                  $scope.account.aggs[$scope.fieldName] = {};
                }
                $scope.account.aggs[$scope.fieldName][$scope.cid] = $scope.active;
              }
              else {
                $scope.account.aggs[$scope.fieldName] = $scope.active;
              }
            }

            // Agg for custom fields is formatted strangely. Handles fetching
            // agg values based on the custom field bundle.
            $scope.getCustomFieldAgg = function(fieldAgg) {
              let customFieldAgg = fieldAgg[$scope.cid];
              let customField = customFieldService.loadField($scope.cid);

              $scope.fieldType = customField.field_type;
              if (customField && customField.bundle){
                switch (customField.bundle) {
                  case 'number':

                    return customFieldAgg['task_custom_value.number'];
                  // case 'boolean':
                  //   return customFieldAgg['task_custom_value.bool'];
                }
              }
            }

            // Build the agg types into a select list option set.
            $scope.buildOptions = function() {
              if ($scope.aggOptions) {
                $scope.options = {};
                angular.forEach($scope.aggOptions, function(value, key) {
                  if (value !== null) {

                    if ($scope.fieldType == 'money') {
                      // Round currency to nearest dollar.
                      value = $filter('currency')(value, '$', 0);
                    }
                    else {
                      // Round other numbers to 0-3 decimal places.
                      value = $filter('number')(value);
                    }
                    let label = $scope.getLabel(key) + ': ' + value;
                    $scope.options[key] = label;
                  }
                });
              }
            }

            // Convert labels into human readable versions.
            $scope.getLabel = function(key) {
              let label = key;
              switch (key) {
                case 'count':
                  label = 'Count'
                  break;
                case 'min':
                  label = 'Min'
                  break;
                case 'max':
                  label = 'Max'
                  break;
                case 'avg':
                  label = 'Avg'
                  break;
                case 'sum':
                  label = 'Sum'
                  break;
              }
              return label;
            }


            let digestListener = $rootScope.$on('appDigest', function () {
              $scope.init();
            });
            $rootScope.addListener(digestListener, $scope);

            let listener = $rootScope.$on('taskListLoaded', function () {
              $scope.init();
            });
            $rootScope.addListener(listener, $scope);
          }
        ],
        templateUrl: 'app/src/components/aggregation/aggregation-viewer.html'
      };
    });
})();
