(function () {
  'use strict';

  var app = angular.module('colorbox', ['angular-drupal', 'angular.filter']);

  app.directive('colorbox',
    function() {
      return {
        scope: {
          entity: '=',
          callback: '&?',
          field: '='
        },
        controller: 'colorboxCtrl',
        templateUrl: 'app/src/components/colorbox/colorbox.html'
      };
    });

  app.controller('colorboxCtrl', [
    '$scope',
    function ($scope) {
      $scope.colors = [
        'purple',
        'purplelight',
        'graylight',
        'gray',
        'greenblack',
        'teal',
        'skyblue',
        'bluedark',
        'green',
        'greendark',
        'yellow',
        'orange',
        'rust',
        'red',
        'pink',
        'maroon'
      ];

      // Called when a new color is selected.
      $scope.submit = function(entity) {
        $scope.callback(entity);
      }

      // Returns a random color from the list of colors.
      $scope.randomize = function() {
        let colors = $scope.colors;
        return colors[Math.floor(Math.random() * colors.length)];
      }

      // A value of exactly false means that there is no color and
      // one should be chosen randomly.
      if ($scope.entity[$scope.field] === false) {
        $scope.entity[$scope.field] = $scope.randomize();
      }

      $scope.onSelect = function (entity) {
        // Default callback. Currently doesn't do anything.
      };

      // Assign a default callback if none was provided.
      if (typeof $scope.callback !== 'function') {
        $scope.callback = $scope.onSelect;
      }
      $scope.$watch('entity.' + $scope.field, function() {
        if ($scope.entity[$scope.field] === false) {
          $scope.entity[$scope.field] = $scope.randomize();
        }
      });
    }
  ]);
})();
