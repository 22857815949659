(function () {
  'use strict';

  var app = angular.module('tableview');

  app.directive('tablerow', function () {
    return {
      scope: {
        nid: '=',
        fid: '=?',
        cols: '=',
        isCheckboxVisible: '='
      },
      controller: [
        '$scope',
        '$rootScope',
        '$routeParams',
        'accountService',
        'commonService',
        'taskListService',
        'taskService',
        'filterService',
        'customFieldService',
        '$filter',
        '$timeout',
        function ($scope, $rootScope, $routeParams, accountService, commonService, taskListService, taskService, filterService, customFieldService, $filter, $timeout) {
          $scope.task = false;
          $scope.row = {};
          $scope.rowscope = $scope;

          // Grab the current user id.
          accountService.getAccount().then(function (account) {
            let current_user = account.user;
            $scope.account = account;
            $scope.current_uid = (current_user.uid) ? current_user.uid : 0;
          });

          $scope.loadTask = function(reinit = true) {
            // Potentially wait for task to be ready.
            taskListService.fetchTaskById($scope.nid).then(function (task) {
              $scope.task = task;
              if (reinit) {
                $scope.init();
              }
            });
          }
          $scope.loadTask();

          // Only called with a task.
          $scope.init = function () {
            if ($scope.task) {
              // Once the task is set up, build the row.
              $scope.row = $scope.getRow($scope.task);
              $scope.$digest();
            }
          };

          // Compares the task and the FID to determine which file is desired.
          $scope.getFileIndex = function () {
            if (!$scope.hasOwnProperty('fileIndex')) {
              $scope.fid = Number($scope.fid);
              $scope.fileIndex = $scope.task.file_fid.indexOf($scope.fid);
            }
            return $scope.fileIndex;
          };

          // Wrapper to reliably fetch a file value for the current row.
          $scope.getFileValue = function (field, value = '') {
            let index = $scope.getFileIndex();
            if (index >= 0) {

              if ($scope.task.hasOwnProperty(field)) {
                if (!commonService.empty($scope.task[field][index])) {
                  return $scope.task[field][index];
                }
              }
            }
            return value;
          };

          // Get the values for each field.
          var itemProp = function (name, prop = 'title', cid = false) {
            let val = '';
            let obj, objName;
            let objKey = 'id';
            let defaultVal = 'None';
            let allowFalse = false;

            switch (name) {
              case 'title':
                if ($scope.task.field_dm_resource) {
                  val += '<span class="has-resource"></span>';
                }
                val += $scope.task[name];
                break;
              case 'field_list':
                objName = 'statuses';
                break;
              case 'projectObj':
                objName = 'projects';
                break;
              case 'project':
                // Grab the object and extract the values here.
                let project = $scope.task[name];
                let projectId = (isNaN(project) ? project.id : Number(project));
                let projectObj = accountService.getObjectById('projects', projectId);
                let color = accountService.joinByProp([projectObj], 'field_project_color');
                let projectName = accountService.joinByProp([projectObj], 'title');
                let classes = 'project-background color-' + color;

                val = '<span class="' + classes + '"></span>';
                val += '<span class="project-name-wrapper">' + projectName + '</span>';
                break;
              case 'field_priority':
                objName = 'priorities';
                break;
              case 'field_task_owner':
                defaultVal = 'Unassigned';
                objName = 'members';
                objKey = 'uid';
                // If prop was excluded or defaulted, provide an alternative.
                if (!prop || prop == 'title') {
                  prop = 'display_name';
                }
                break;
              case 'changed':
                val = this.updatedDate();
                break;
              case 'file_created':
                val = $scope.fileDate();
                break;
              case 'file_filesize':
                let fileValue = $scope.getFileValue(name, val);
                if (fileValue) {
                  val = commonService.bytesToHumanSize(fileValue);
                }
                break;
              case 'file_uid':
                objName = 'members';
                objKey = 'uid';
                prop = 'display_name';
                val = $scope.getFileValue(name, val);
                break;
              case 'file_url':
              case 'file_filename':
                val = $scope.getFileValue(name, val);
                break;
              case 'task_custom_value':
                let customValue = taskService.getTaskCustomValue($scope.task, cid);
                if (customValue && customValue.hasOwnProperty('value')) {
                  val = customValue.value;
                  if (!commonService.empty(val)) {
                    let mapping = customFieldService.getFieldMappingById(cid);

                    if (mapping) {
                      if (mapping.list) {
                        objName = mapping.list;
                      }
                      if (mapping.key) {
                        objKey = mapping.key;
                      }
                      if (mapping.label && !prop) {
                        prop = mapping.label;
                      }
                    }
                  }

                  // Allow different handling based on the type of field.
                  let typeMapping = customFieldService.getFieldTypeMappingById(cid);

                  // Number fields consider 0 to be a non-empty value.
                  if (typeMapping.type == 'number') {

                    // Make sure val isn't null or undefined.
                    if (val != null) {
                      allowFalse = true;

                      if (val) {
                        if (typeMapping.inputType == 'money') {
                          // Round currency to nearest dollar.
                          val = $filter('currency')(val, '$', 0);
                        }
                        else if (typeMapping.inputType == 'number') {
                          // Round other numbers to 2 decimal places.
                          val = $filter('number')(val);
                        }
                      }
                    }
                  }
                }
                break;
              default:
                val = $scope.task[name];
                break;
            }

            if (objName) {
              if (!val) {
                val = $scope.task[name];
              }
              // Look up object from accountService based on info above.
              if (obj = accountService.getObjectById(objName, val, objKey)) {
                accountService.loadTitleOverrides([obj], objName);
                // Allow returning the full object.
                if (prop == 'obj') {
                  val = obj;
                }
                // Usually pull the individual value for the user.
                else {
                  val = accountService.joinByProp([obj], prop);
                }
              }
            }

            // Use the default if the value is falsey, unless allowFalse is true.
            if (!allowFalse && (!val || val.length === 0)) {
              val = defaultVal;
            }

            return val;
          };

          // Builds classes for the various row columns/cells.
          var itemClass = function (name, cid = false) {
            let classes = [];

            switch (name) {
              case 'field_priority':
              case 'field_list':
                // Add a key/value class for static fields.
                let shorthand = name.replace('field_', '');
                let value = this.itemProp(name);
                value = value.replace(/[^0-9a-zA-Z]+/, '-').toLowerCase();
                let className = shorthand + '-' + value;
                classes.push(className);
                break;

              case 'project':
                let color = this.itemProp('projectObj', 'field_project_color');
                classes.push('border-color-' + color);
                classes.push('color-' + color);
                break;

              case 'field_task_owner':
                let assign = commonService.empty($scope.task.field_task_owner) ? 'not-assigned' : 'assigned';
                classes.push(assign);
                // Add special class for self.
                if ($scope.current_uid == $scope.task.field_task_owner) {
                  classes.push('assigned-to-current');
                }
                break;
              case 'file_filename':
                let mimetype = $scope.getFileValue('file_mime');
                if (mimetype) {
                  classes.push('file-icon')
                  // Get the first part of the mimetype.
                  let type = mimetype.split('/')[0];
                  classes.push('file-icon-' + type);
                }
                break;
              case 'task_custom_value':
                if (cid) {
                  let typeMapping = customFieldService.getFieldTypeMappingById(cid);
                  if (typeMapping) {
                    if (typeMapping.cssClass) {
                      classes.push(typeMapping.cssClass);
                    }
                    if (typeMapping.type) {
                      classes.push('widget-' + typeMapping.type);
                    }
                  }
                  // Allow mappings to override the property to search for.
                  let prop = false;
                  let classPrefix = '';
                  // Tags get a class for the label color.
                  if (typeMapping.inputType == 'tag') {
                    // Set the property, a prefix for the class and a base class.
                    prop = 'field_label_color';
                    classPrefix = 'color-';
                    classes.push('label-color');
                  }

                  let value = this.itemProp(name, prop, cid);
                  if (typeMapping.type == 'boolean') {
                    value = Number(value);
                  }

                  if ((value && value == 'None') || !value) {
                    classes.push('empty-value');
                  }
                  // If the value isn't the default "none" and there was a prop.
                  else if (prop) {
                    // Add the custom class including the prefix.
                    classes.push(classPrefix + value);
                  }

                }
                break;
            }

            if ($scope.task.field_entity_state == 'cloning') {
              classes.push('cloning');
            }

            return classes.join(' ');
          };

          // Basic function to display the updated date for tasks.
          var updatedDate = function (value = false) {
            if (!value) {
              value = $scope.task.changed;
            }
            let today = new Date();
            let date = (value) ? new Date(value) : today;
            return $filter('date')(date, "M/d/yy");
          };

          // Wrapper around upload date to provide a human readable upload date.
          $scope.fileDate = function (field = 'file_created') {
            let date = $scope.getFileValue(field);
            if (date) {
              date *= 1000;
            }
            return updatedDate(date)
          }

          // Determines if this task should show each column.
          var hideRowColumn = function (col) {
            if (col.hasOwnProperty('hide')) {
              if (typeof col.hide === 'function') {
                return col.hide();
              }
              else {
                return col.hide;
              }
            }
            return false;
          };

          // Optionally fire an additional click event.
          // When using this, prevent the click fire in task-trigger directive.
          var onClick = function ($event, col) {
            if ($scope.task.field_entity_state == 'cloning') {
              return;
            }

            switch (col.fieldName) {
              case 'title':
                taskService.openTaskModal(this.nid);
                break;

              case 'project':
                $event.preventDefault();
                return $scope.setProject($scope.task['project']);
              case 'file_filename':
                $event.preventDefault();
                let uri = $scope.getFileValue('file_url');
                if (uri) {
                  let url = commonService.buildUrl(uri);
                  commonService.redirect(url, '_blank');
                }
            }

            return true;
          };

          // 
          var editDisabled = function (col) {
            // Fake using the button (but provide no button) for cloning tasks.
            if ($scope.task.field_entity_state == 'cloning') {
              return true;
            }
          };

          // Implement any layout changes from app digest.
          var taskListListener = $rootScope.$on('filterUpdate', function (event, args) {
            $timeout(function() {
              $scope.loadTask(false);
              $scope.row.task = $scope.task;
            });
          });
          $rootScope.addListener(taskListListener, $scope);

          // Process task rows ad add them to the list.
          $scope.getRow = function (task) {
            let row = {
              nid: task.nid,
              task: task,
              itemProp: itemProp,
              hide: hideRowColumn,
              itemClass: itemClass,
              updatedDate: updatedDate,
              editDisabled: editDisabled,
              onClick: onClick
            };
            return row;
          };

          $scope.setProject = function (project) {
            let projectId = (isNaN(project) ? project.id : Number(project));
            filterService.setFilterItem('project', [projectId]);
          };

        }
      ],
      templateUrl: 'app/src/tableview/tablerow.html'
    };
  });

})();
