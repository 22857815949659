(function () {
  'use strict';

  let multiedit = angular.module('multiedit');

  multiedit.directive('multiedit', function () {
    return {
      restrict: 'E',
      scope: {},
      controller: [
        '$scope',
        'routingService',
        'multiEditService',
        'taskListService',
        function ($scope, routingService, multiEditService, taskListService) {
          $scope.taskCount = 0;
          $scope.visible = false;

          function updateVisible() {
            // Only allow the multi-edit bar to be shown on board an table.
            if (routingService.displayIsActive(['board', 'table'])) {
              $scope.visible = multiEditService.isMultiEditEnabled();
            }
            else {
              $scope.visible = false;
            }
          }
          function updateTaskCount() {
            let taskIDs = multiEditService.getTaskIDs();
            $scope.taskCount = taskIDs.length;
          }

          // Run initially.
          updateVisible();
          updateTaskCount();

          // And update values on events.
          $scope.$on(multiEditService.ALTERED_MULTI_EDIT_FLAG, updateVisible);
          $scope.$on('$routeChangeSuccess', updateVisible);
          $scope.$on(multiEditService.ALTERED_MULTI_EDIT_TASK_LIST, updateTaskCount);

          $scope.showMultiEditDialog = function () {
            multiEditService.openMultiEditModal();
          };

          $scope.showUnselect = function () {
            let taskListTasks = taskListService.getCurrentTaskList();
            let multiEditTasks = multiEditService.getTaskIDs();

            return taskListTasks.length == multiEditTasks.length;
          };

          $scope.selectAll = function () {
            multiEditService.addAllTaskIDs();
          };

          $scope.unselectAll = function () {
            multiEditService.clearTaskIDs();
          };

          $scope.cancel = function () {
            multiEditService.unsetMultiEdit();
          };
        }
      ],
      templateUrl: 'app/src/components/multiedit/multiedit-banner.html'
    };
  });

})();
