(function () {
  'use strict';

  angular.module('userchip', ['angular-drupal', 'account'])
    .directive('userchip', function () {
      return {
        scope: {
          user: "=",
        },
        // controller: [
        //   '$scope', '$timeout', 'drupal', '$rootScope', '$window', 'accountService',
        //   function ($scope, $timeout, drupal, $rootScope, $window, accountService) {

        // }],
        templateUrl: 'app/src/components/userchip/userchip.html'
      };
    });
})();