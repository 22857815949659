(function () {
  'use strict';

  // Configure infinite scroll not to fire too fast.
  angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 250);

  var app = angular.module('board', [
    'ngRoute',
    'angular-drupal',
    'as.sortable',
    'infinite-scroll',
    'rest',
    'task',
    'misc',
    'layout'
  ]);

  app.config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/:accountId/projects/board', {
      redirectTo: '/:accountId/projects/all/board'
    });
  }]);
  app.config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/:accountId/projects/:projectId/board/:taskId?', {
      templateUrl: 'app/src/board/board.html',
      controller: 'BoardCtrl',
      activetab: 'board',
      reloadOnSearch: false
    });
  }]);

  app.controller('BoardCtrl', [
    '$scope',
    '$rootScope',
    'drupal',
    '$routeParams',
    'RestResource',
    'accountService',
    'userDataService',
    'refreshService',
    'routingService',
    'filterService',
    'taskListService',
    'searchService',
    'layoutService',
    'taskService',
    '$timeout',
    function ($scope, $rootScope, drupal, $routeParams, RestResource, accountService, userDataService, refreshService, routingService, filterService, taskListService, searchService, layoutService, taskService, $timeout) {
      $scope.projectId = $routeParams.projectId;
      $scope.accountId = $routeParams.accountId;
      $scope.filtering = filterService.getFiltering();
      $scope.ready = false;
      $scope.collapseSettings = {};

      routingService.viewInit();

      $scope.$on('$destroy', function () {
        $rootScope.destroyListeners($scope);
      });


      var noProject = false;

      if ($routeParams.projectId == 'all' ||
        typeof $routeParams.projectId == 'undefined') {
        noProject = true;
      }

      $scope.boardClass = {
        'all-projects': noProject,
        'project-active': !noProject
      };

      $scope.pageReady = function () {
        $scope.ready = true;
        // $rootScope.$emit('viewReady');
      };

      // Toggle the collapsed status of a list.
      $scope.toggleListCollapse = function (listId) {
        userDataService.setCollapseStatus(listId);
      };

      // List of situations where a scroll should be avoided.
      $scope.preventScroll = function(list) {
        let prevent = false;
        // If everything has already been shown, don't keep trying to load more.
        // If there are 0 tasks, that indicates a load is in progress.
        // Or if the list column already contains the full number of tasks.
        if (list.tasks.length < 1 || list.count <= list.tasks.length) {
          prevent = true;
        }

        // If tasklist isn't ready, or if dragging or updating is in progress.
        if (!taskListService.isInitted() || $scope.isDragging || $scope.pauseScroll) {
          prevent = true;
        }
        return prevent;
      };

      // Trigger a load for more tasks on scroll events.
      $scope.infiniScroll = function(list) {
        // One last check (shouldn't happen).
        if (!$scope.preventScroll(list)) {
          // Show loader for up to 5 seconds.
          list.showLoader = true;
          $timeout(function() {
            list.showLoader = false;
          }, 5000);

          let options = {
            key: list.key
          };
          taskListService.refresh('loadmore', options);
        }
      };

      // Both order and list changes require the same processing.
      var processKanbanChange = function (event) {
        // Counts are changing as a result of this, that shouldn't trigger a
        // scroll event until the dust has settled.
        $scope.pauseScroll = true;
        $scope.kanbanChange = true;
        layoutService.setPreventUpdate(true);

        // The two lists in their post-change state.
        // let sourceList = event.source.sortableScope.$parent.list;
        let destinationList = event.dest.sortableScope.$parent.list;

        // Support for dynamic fields based on layout info.
        let nid = event.source.itemScope.nid;
        let newIndex = event.dest.index;
        let fields = destinationList.fields;

        // @TODO: only when sorting by weight.
        fields.field_weight = layoutService.getWeight(destinationList, newIndex);

        // Save the update, this updates the layout directly.
        taskService.updateTask(nid, fields);
        $timeout(function() {
          layoutService.setPreventUpdate(false);
          $scope.kanbanChange = false;
        }, 1000);
      };

      function dragStart() {
        $scope.isDragging = true;
        $scope.pauseScroll = true;
        layoutService.setPreventUpdate(true);
      }

      function dragEnd() {
        $scope.isDragging = false;
        // If a change is not in progress, allow updates again.
        if (!$scope.kanbanChange) {
          layoutService.setPreventUpdate(false);
        }
        // If anything happened to the board that hasn't been digested, do that
        // now. Note: layoutService doesn't currently have a concept of this.
        if ($scope.dragMiss) {
          $scope.dragMiss = false;
          $scope.processTasks();
        }
      }

      $scope.kanbanSortOptions = {
        itemMoved: processKanbanChange,
        orderChanged: processKanbanChange,
        containment: '#columns',
        containerPositioning: 'relative',
        longTouch: true,
        dragStart: dragStart,
        dragEnd: dragEnd
      };

      // Initial setup of lists once loaded from account.
      $scope.init = function () {
        layoutService.loadLayout().then(function(lists) {
          // On initial load this generally won't include tasks in the lists.
          $scope.processTasks(lists);
        });

        // Get the account and fetch the collapsed lists from userData.
        accountService.getAccount().then(function(account) {
          $scope.collapseSettings = userDataService.getCollapseSettings();
        });
      };

      // Account for any changes to layouts throughtout the app.
      $scope.processTasks = function (lists = false) {
        // Sometimes used to set task lists as well.
        if (lists) {
          $scope.lists = lists;
        }

        if ($scope.isDragging) {
          $scope.dragMiss = true;
          return;
        }

        $timeout(function() {
          $scope.pageReady();
          $rootScope.$emit('viewReady');
        });
      };

      // Updates to the layout go through the layout service.
      var layoutListener = $rootScope.$on('layoutUpdate', function (event, lists) {
        $timeout(function() {
          $scope.pauseScroll = false;
        });

        // This adds tasks and/or task updates to the board.
        $scope.processTasks(lists);
      });
      $rootScope.addListener(layoutListener, $scope);

      // Implement any layout changes from app digest.
      var digestListener = $rootScope.$on('appDigest', function (event, args) {
        $scope.processTasks();
      });
      $rootScope.addListener(digestListener, $scope);
    }
  ]);
})();
