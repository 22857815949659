(function () {
  'use strict';

  var app = angular.module('editPanel');

  app.directive('editPanel', [
    'taskService',
    'taskListService',
    'multiEditService',
    function () {
      return {
        transclude: true,
        scope: {
          taskId: '=?',
          fieldName: '=?',
          cid: '=?',
          editPanel: '=?',
          useButton: '=?',
          inputStyle: '=',
          editDisabled: '=?'
        },
        controller: [
          '$scope',
          'taskService',
          'taskListService',
          'multiEditService',
          '$mdPanel',
          '$mdDialog',
          '$element',
          function ($scope, taskService, taskListService, multiEditService, $mdPanel, $mdDialog, $element) {

            $scope.openPanel = function ($event) {
              // If editing isn't enabled, don't open the panel.
              if (!$scope.editPanel || $scope.editDisabled) {
                return;
              }

              $event.stopPropagation();
              var position = $mdPanel.newPanelPosition().relativeTo($element).addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.ALIGN_TOPS);

              var config = {
                position: position,
                openFrom: $event,
                locals: {
                  taskId: $scope.taskId,
                  fieldName: $scope.fieldName,
                  editEnabled: $scope.editPanel,
                  inputStyle: $scope.inputStyle,
                  cid: $scope.cid,
                  event: $event,
                  element: $element
                },
              };

              $mdPanel.open('openEditPanel', config);
            }
          }
        ],
        templateUrl: 'app/src/components/edit-panel/edit-panel-trigger.html'
      };
    }
  ]);
})();
