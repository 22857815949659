(function () {
  'use strict';

  var app = angular.module('task');

  // Xeditable CKEditor integration.
  app.directive('editableCkeditor', ['editableDirectiveFactory',
    function (editableDirectiveFactory) {
      return editableDirectiveFactory({
        directiveName: 'editableCkeditor',
        inputTpl: '<div></div>',
        render: function () {

          this.parent.render.call(this);
          this.scope.options = {
            customConfig: AppConfig.basePath + '/cke/ckeditor-config.js',
            placeholder: 'Add a description...'
          };
          this.inputEl.attr('ckeditor', 'options');

          // Default links to target=_blank.
          CKEDITOR.on('dialogDefinition', function (e) {
            if (e.data.name === 'link') {
              var target = e.data.definition.getContents('target');
              var options = target.get('linkTargetType').items;
              for (var i = options.length - 1; i >= 0; i--) {
                var label = options[i][0];
                if (!label.match(/new window/i)) {
                  options.splice(i, 1);
                }
              }
              var targetField = target.get('linkTargetType');
              targetField['default'] = '_blank';
            }
          });
        },
        addListeners: function () {
          var self = this;
          self.parent.addListeners.call(self);
          // Submit textarea by ctrl+enter when blur is set to submit.
          if (self.single && self.blur == 'submit') {
            self.autosubmit();
          }

          // Attach editor destroy on removal to prevent memory leak issues.
          self.onbeforesave =
            self.oncancel = function () {
              var id = angular.element(self.editorEl).find('.cke').attr('id').substr(4);
              CKEDITOR.instances[id].destroy();
            };
        },
        autosubmit: function () {
          var self = this;
          self.inputEl.bind('keydown', function (e) {
            if (self.attrs.submitOnEnter) {
              if (e.keyCode === 13 && !e.shiftKey) {
                self.scope.$apply(function () {
                  self.scope.$form.$submit();
                });
              }
            }
            else if ((e.ctrlKey || e.metaKey) && (e.keyCode === 13) ||
              (e.keyCode === 9 && self.editorEl.attr('blur') === 'submit')) {
              self.scope.$apply(function () {
                self.scope.$form.$submit();
              });
            }
          });
        }
      });
    }
  ]);

  // TODO: Is this still in use?
  // Xeditable date integration.
  app.directive('editableMdDate', ['editableDirectiveFactory',
    function (editableDirectiveFactory) {
      return editableDirectiveFactory({
        directiveName: 'editableMdDate',
        inputTpl: '<div></div>',
        render: function () {
          this.parent.render.call(this);
          var inputDatePicker = angular.element('<md-input-container class="md-block" flex-gt-sm><label>Start date</label><md-datepicker ng-model="$parent.$data" md-open-on-focus></md-datepicker></md-input-container>');

          this.inputEl.prepend(inputDatePicker);
        }
      });
    }
  ]);
})();
