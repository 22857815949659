(function () {
  'use strict';


  var app = angular.module('editPanel', ['ngMaterial', 'angular-drupal', 'user', 'angular.filter', 'labelpanel', 'colorbox', 'xeditable', 'popmenu']);

  app.config(['$mdPanelProvider', function ($mdPanelProvider) {

    $mdPanelProvider.definePreset('openEditPanel', {
      attachTo: angular.element(document.body),
      controller: 'editPanelController',
      templateUrl: 'app/src/components/edit-panel/edit-panel.html',
      panelClass: 'edit-panel-wrapper',
      clickOutsideToClose: true,
      escapeToClose: false,
      focusOnOpen: true
    });
  }]);

  app.controller('editPanelController', [
    '$scope',
    '$mdPanel',
    'mdPanelRef',
    'taskId',
    'fieldName',
    'editEnabled',
    'inputStyle',
    'cid',
    'event',
    'element',
    function ($scope, $mdPanel, mdPanelRef, taskId, fieldName, editEnabled, inputStyle, cid, event, element) {
      $scope.taskId = taskId;
      $scope.fieldName = fieldName;
      $scope.editEnabled = editEnabled;
      $scope.cid = cid;

      if ($scope.editEnabled) {

        $scope.editing = false;

        $scope.init = function() {

          $scope.getFieldType();
          let directive = 'inline-widget-';

          if (inputStyle) {
            directive += inputStyle + '-';
          }
          directive += $scope.fieldType;
          let cidParam = '';
          let inputStyleParam = ''

          if($scope.fieldName == 'task_custom_value' && $scope.cid) {
            // $scope.newfieldName = 'customfield:' + $scope.cid;
            cidParam = 'cid="' + $scope.cid + '"';
            directive = 'custom-field-widget';
            if (inputStyle) {
              inputStyleParam = 'input-style="' + inputStyle + '"'
            }
          }

          // Actually build the template.
          $scope.template = `
            <${directive}
              task-id="${$scope.taskId}"
              field-name="${$scope.fieldName}"
              immediate="1"
              ${cidParam}
              ${inputStyleParam}
            ></${directive}>
          `;

          // Set width of text fields based on cell.
          if ($scope.fieldType == 'input' || $scope.fieldType == 'select') {
            $scope.width = element.outerWidth();
            $scope.height = element.outerHeight();
          }
        };

        $scope.getFieldType = function() {
          $scope.fieldType = 'input';
          if ($scope.fieldName) {

            let mapping = {
              'title': 'input',
              'project': 'select',
              'field_list': 'select',
              'field_date': 'date-range',
              'field_priority': 'select',
              'field_task_owner': 'select',
              'field_followers': 'select',
              'field_labels': 'chiptagging',
            };

            if (mapping.hasOwnProperty($scope.fieldName)) {
              $scope.fieldType = mapping[$scope.fieldName];
            }
            // else if ($scope.fieldName == 'task_custom_value' && $scope.cid) {
            //   $scope.fieldType = 'input';
            // }
          }
        };

        $scope.$on('inlineOnClose', function() {
          mdPanelRef.close();
        });

        $scope.init();
      }
    }
  ]);
})();

