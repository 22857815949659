(function () {
  'use strict';

  var app = angular.module('task');

  app.directive('taskSchedule', function () {
    return {
      scope: {
        nid: '=',
        noScheduleText: '='
      },
      controller: [
        '$scope', 
        '$rootScope', 
        'taskService', 
        'taskListService', 
        'repeatableService',
        function ($scope, $rootScope, taskService, taskListService, repeatableService) {
          var emptyText = ($scope.noScheduleText) ? 'None' : '';

          $scope.getSchedule = function () {
            var scheduled_obj = taskService.createDateRange($scope.task.start, $scope.task.end);
            $scope.schedule = scheduled_obj.dateRange ? scheduled_obj.dateRange : emptyText;
            $scope.scheduled_sort = scheduled_obj.sortDate ? scheduled_obj.sortDate : false;

            var statusDate = scheduled_obj.endDate || scheduled_obj.sortDate;
            $scope.status = taskService.scheduledStatus(statusDate, $scope.task.field_list);
            
            // Fetch repeatable info if this is repeatable.
            if ($scope.task.repeatable) {
              $scope.repeatsTooltip = repeatableService.getRepeatText($scope.task);
            }
          };

          $scope.init = function () {
            $scope.task = taskListService.getTaskById($scope.nid);
            $scope.getSchedule();
          };

          let taskUpdateListener = $rootScope.$on('taskPostUpdate', function(event, task, updates, hash) {
            if (task.nid == $scope.nid) {
              $scope.init();
            }
          });
          $rootScope.addListener(taskUpdateListener, $scope);

          $scope.$on('$destroy', function () {
            $rootScope.destroyListeners($scope);
          });

          $scope.init();
        }],
      templateUrl: 'app/src/task/task-schedule.html'
    };
  });
})();
