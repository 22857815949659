(function () {
  'use strict';

  angular.module('layout', ['userdata'])
    .service('layoutService', [
      '$rootScope',
      'commonService',
      'taskListService',
      'searchService',
      'accountService',
      'userDataService',
      'listLocalService',
      function ($rootScope, commonService, taskListService, searchService, accountService, userDataService, listLocalService) {
        var self = this;
        this.layouts = [];
        this.activeLayout = {};
        this.initted = false;
        this.preventUpdate = false;
        this.lastChange = 0;
        this.movedTasks = {};
        this.baseLayout = {
          'id': 0,
          'title': 'No Grouping',
          'dimensions': [{
            'field_grouping_field': '',
            'field_grouping_values': [{
              'field': '',
              'id': '',
              'key': '0',
              'title': 'No Grouping'
            }]
          }]
        };

        this.init = function() {
          // To initialize Layout, account must also be initialized.
          accountService.getAccount().then(function(account) {
            // Create a copy of the account layout.
            // @TODO: Revisit this decision. Account object is stored in
            // localStorage and this can cause unexpected behavior between
            // pageloads/tabs. It is very nice to maintain a local copy which
            // can be depended upon to match the server. Init is called anytime
            // that the application needs a clean copy of the layouts
            // (and those situations could be revisited as well).
            self.layouts = angular.copy(account.layouts);


            let lid = userDataService.getLayoutId();

            // Init default layout if this is a first load.
            if (lid == -1) {
              // Default to the user's chosen layout.
              if (account.user.field_data.defaultLayout) {
                lid = account.user.field_data.defaultLayout;
              }
              // Or pull the default for the account.
              else if (account.settings.defaultLayout) {
                lid = account.settings.defaultLayout;
              }
              // Or just pull whatever's first.
              else {
                lid = account.layouts[0].id;
              }
            }

            // Prepend base layout.
            let baseLayout = self.getBaseLayout();
            self.layouts.unshift(baseLayout);
            self.setLayout(lid);
            self.initted = true;
          });
        };

        // Reinitialize should only init after primary init.
        this.reinit = function() {
          if (this.initted) {
            this.layouts = [];
            this.init();
          }
        };

        // Return the default "No Grouping" Layout.
        this.getBaseLayout = function() {
          return this.baseLayout;
        };

        // Allow flagging the layout system not to update.
        this.setPreventUpdate = function(value) {
          this.preventUpdate = value;
        };

        // Getter/pseudo-constructor of the layout object.
        this.getLayouts = function() {
          return new Promise(function(resolve, reject) {
            if (!commonService.empty(self.layouts)) {
              resolve(self.layouts);
            }
            else {
              accountService.getAccount().then(function(account) {
                resolve(self.layouts);
              });
            }
          });
        };

        // Get the active layout object.
        this.getActiveLayout = function(justLists = true) {
          return new Promise(function(resolve, reject) {
            self.getLayouts().then(function(layouts) {
              let layoutId = userDataService.getLayoutId();
              let layout = commonService.getObjectById(layouts, layoutId);

              // Save this against object for quick lookups.
              self.activeLayout = layout;

              // @TODO: Support multiple dimensions.
              let dimension = 0;
              if (justLists) {
                resolve(layout.dimensions[dimension].field_grouping_values);
              }
              else {
                resolve(layout);
              }
            });
          });
        };

        // Wrapper around userData.getLayoutId().
        this.getActiveLayoutId = function() {
          return userDataService.getLayoutId();
        };

        // Getter for the searches of this layout.
        this.getSearches = function() {
          return searchService.getSearches();
        };

        // Sets and initializes the layout.
        this.setLayout = function(layoutId) {

          // @TODO: Is there a better way to do this than always nuking?
          searchService.resetSearches();

          userDataService.setLayoutId(layoutId);
          this.initLayout();
        };

        // Initialize the layout variable and define the current searches.
        this.initLayout = function() {
          this.buildLayout();
          this.getLayoutFacets();
          this.loadLists(taskListService.isInitted());
        };

        // Builds the searches based on the layout properties.
        this.buildLayout = function() {
          // TODO: Build once that grabs the last, not first result.
          // self.buildOnce = true;
          // Ensure the account is loaded.
          self.getActiveLayout(false).then(function(layout) {
            // if (!self.buildOnce) {
            //   return;
            // }
            // self.buildOnce = false;
            // @TODO: support multiple dimensions.
            let dimension = 0;
            // Iterate through each layout list and create a new search item
            // for that list.
            angular.forEach(layout.dimensions[dimension].field_grouping_values, function(layoutItem) {
              searchService.createNewSearch(layoutItem);
            });

            // Kick off task load request based on new searches.
            taskListService.refresh('init');
          });
        };

        // Fetches the current layout and associates facet values with it.
        this.getLayoutFacets = function() {
          this.getActiveLayout(false).then(function(activeLayout) {
            // @TODO: support multiple dimensions.
            let dimension = 0;
            if (activeLayout && activeLayout.dimensions[dimension]) {
              let layout = activeLayout.dimensions[dimension];

              // Attempt to load the facets from the task list service.
              let facets = taskListService.getFacets();
              let tasklist = taskListService.getTaskList();

              // Proceed if they were loaded, otherwise try again later.
              if (facets) {
                // Iterate over each layout to set it's facet values individually.
                angular.forEach(layout.field_grouping_values, function(layoutItem) {
                  // Default count of 0.
                  layoutItem.count = 0;
                  // Don't bother trying to get facet information if the layout
                  // doesn't know what facet it's looking for.
                  if (facets.hasOwnProperty(layout.field_grouping_field)) {
                    // Get the right facet for the layout.
                    let layoutFacet = facets[layout.field_grouping_field];

                    // And make sure that the layout has a value for this item.
                    if (layoutItem.id && layoutFacet.hasOwnProperty(layoutItem.id)) {
                      layoutItem.count = layoutFacet[layoutItem.id];
                    }
                  }
                  // Otherwise assume a value of 0.
                  else if (layoutItem.id === '') {
                    layoutItem.count = tasklist.count;
                  }
                });
                self.initted = true;
              }
            }
          });
        };

        // Loads the layout lists and attaches them directly to the provided
        // scope variable.
        this.loadLists = function(tasksReady = true) {
          return new Promise(function(resolve, reject) {
            // Get and/or initialize the layout.
            self.getActiveLayout(false).then(function(layout) {
              if (!layout) {
                return;
              }
              // @TODO: support multiple dimensions.
              let dimension = 0;
              let primary = layout.dimensions[dimension].field_grouping_values,
                  fieldName = layout.dimensions[dimension].field_grouping_field;

              // Get any layout overrides that are set in the admin interface.
              accountService.loadTitleOverrides(primary);
              // Initialize the lists from scratch.
              let lists = [];

              // Loop through all the layout lists and initialize them.
              if (primary && Object.keys(primary).length > 0) {
                angular.forEach(primary, function(list, index) {
                  list.add_task_form_show = false;
                  list.add_task_button_show = true;
                  list.fields = {
                    [fieldName]: list.id
                    // @TODO: add multiple dimensions here.
                  };
                  if (!tasksReady) {
                    list.tasks = self.getListTasks(fieldName, list.id);
                  }
                  else {
                    list.tasks = [];
                  }
                  list.count = list.count || 0;
                  lists[list.key] = list;
                });
              }
              // Assign new list value to layout object.
              layout.dimensions[dimension].lists = lists;

              // Push new values to the app as a whole.
              $rootScope.$emit('layoutUpdate', layout.dimensions[dimension].field_grouping_values);

              // And the requesting function.
              resolve(layout.dimensions[dimension].lists);
            });
          });
        };

        // Update tasks in lists
        this.clearListTasks = function() {
          // Get and/or initialize the layout.
          self.getActiveLayout(false).then(function(layout) {
            angular.forEach(layout, function(dimension) {
              angular.forEach(dimension.lists, function(list) {
                list.count = 0;
                list.tasks = [];
              });
            });
          });
        };

        // Update tasks in lists
        this.updateListTasks = function() {
          return new Promise(function(resolve, reject) {
            // @TODO: Support multiple dimensions.
            let dimension = 0;
            // Get and/or initialize the layout.
            self.getActiveLayout(false).then(function(layout) {
              // Grab the tasks for the layouts.
              angular.forEach(layout.dimensions[dimension].field_grouping_values, function(list, index) {
                self.processMovedTasks(list);
                list.tasks = self.getListTasks(list.key);
              });

              $rootScope.$emit('layoutUpdate', layout.dimensions[dimension].field_grouping_values);
            });
          });
        };

        this.processMovedTasks = function(list) {
          if (Object.keys(self.movedTasks).length >= 1) {
            angular.forEach(self.movedTasks, function(moved, nid) {
              // Delete local version in favor of updated version.
              if (list.tasks.indexOf(parseInt(nid)) !== -1 && list.key == moved.addedListKey) {
                delete self.movedTasks[nid].addedListKey;
              }
              else if (list.tasks.indexOf(parseInt(nid)) === -1 && list.key == moved.removedListKey) {
                delete self.movedTasks[nid].removedListKey;
              }
            });
          }
        };

        // Wrapper around searchService to get the tasks by list key.
        this.getListTasks = function(key) {
          let listTasks = searchService.getTaskGroupById(key);
          let movedTasks = Object.keys(this.movedTasks);

          // Filter out tasks that don't reflect recent moves.
          movedTasks.forEach(id => {
            let nid = Number(id);
            let index = listTasks.indexOf(nid);
            let taskInfo = self.movedTasks[id];

            // If this list has a task that was recently moved, make sure it
            // should still be here.
            if (index !== -1) {
              if (taskInfo.removedListKey == key) {
                listTasks.splice(index, 1);
              }
            }
            // @TODO: Keeping this out for now as it causes dupes when making
            // fast changes because of latency between server/client systems.
            // // If this list is missing the task and is the destination of a
            // // moved task, add it back to the top of the list.
            // else if (taskInfo.addedListKey == key) {
            //   // @TODO insert at addedAt.
            //   listTasks.unshift(nid);
            // }
          });

          return listTasks;
        };

        // Given a node_id this fetches a weight
        this.getListIndexWeightById = function(id, next = false) {
          let layout = self.activeLayout;
          // @TODO: Support multiple dimensions.
          let dimension = 0;
          let lists = layout.dimensions[dimension].field_grouping_values;
          let index = -1;
          let theList;

          // Grab the tasks for the layouts.
          angular.forEach(lists, function(list, listIndex) {
            id = Number(id);
            index = list.tasks.indexOf(id);
            if (index > -1) {
              theList = list;
              return;
            }
          });
          if (next) {
            index += 1;
          }

          // Uses the function for dragging to calculate weight.
          let weight = this.getWeight(theList, index);
          return weight;
        };

        // Uniform weight calculation function for all displays.
        // An index of -1 will insert first.
        // Bottom isn't supported, but would need to be list.task.length + 1.
        this.getWeight = function(list, index) {
          let prevWeight = 0,
              nextWeight = 10000,
              weight = 1;

          let prevIndex = index - 1;
          let nextIndex = index + 1;
          let taskList = list.tasks;

          // Fetch the tasks from the list (if they exist)
          let prevTask = taskListService.getTaskById(taskList[prevIndex]);
          let nextTask = taskListService.getTaskById(taskList[nextIndex]);

          if (typeof prevTask != 'undefined' &&
            typeof prevTask.field_weight != 'undefined') {
            prevWeight = Number(prevTask.field_weight);
          }

          if (typeof nextTask != 'undefined' &&
            typeof nextTask.field_weight != 'undefined') {
            nextWeight = Number(nextTask.field_weight);
          }

          // Set the weight to halfway between the 2 adjacent tasks.
          // Same logic for both present or neither.
          if ((prevTask && nextTask) || (!prevTask && !nextTask)) {
            weight = (prevWeight + nextWeight) / 2;
          }
          // If there's only a previous, just put it after.
          else if (prevTask) {
            weight = prevWeight + 1;
          }
          // If there's only a next, put it just before.
          else if (nextTask) {
            weight = nextWeight - 1;
          }

          // Move within bounds if nextWeight is < 1;
          if (weight <= 0) {
            weight = nextWeight / 2;
          }
          // Move within bounds if weight is > 10k (assumes nextWeight is <10k).
          else if (weight >= 10000) {
            weight = nextWeight + (10000 - nextWeight) / 2;
          }

          return weight;
        };

        // Look up the task just above the weight specified in the task list.
        // @TODO: in order to sort by other fields, a more complex comparator
        // function should be added here.
        this.getIdByWeight = function(tasks, weight) {
          // Return task id 0 by default.
          let task_id = 0;
          for (let nid of tasks) {
            // This is assuming that the task is available already (which
            // should be true based on how these task lists are loaded.)
            let task = taskListService.getTaskById(nid);

            // If the this task has a larger weight than the given weight,
            // this task is below the given weight.
            if (!task || !task.hasOwnProperty('field_weight') || task.field_weight > weight) {
              // Don't update task_id.
              break;
            }
            // If not, then update the task id and keep going.
            else {
              task_id = nid;
            }
          }
          return task_id;
        };

        // Loads a new layout. Layouts depends on taskLists.
        this.loadLayout = function (wait = false) {
          return new Promise(function(resolve, reject) {
            let tasksReady = taskListService.isInitted();

            if (!tasksReady && wait) {
              taskListService.getTasks().then(function(tasks) {
                // console.log(tasks);
                tasksReady = taskListService.isInitted();
                self.loadLists(tasksReady).then(function (lists) {
                  // console.log(tasksReady);
                  // console.log(lists[0].tasks);
                  resolve(lists);
                });
              });
            }
            else {
              // If tasks aren't initted yet, wait for that.
              if (!tasksReady && self.initted) {
                taskListService.getTasks().then(function() {
                  self.updateListTasks();
                });
              }

              // Kick off the load either way.
              let lists = self.loadLists(tasksReady).then(function (lists) {
                resolve(lists);
              });
            }
          });
        };

        // Adds the given item to the corresponding list (just locally).
        this.addToList = function (key, id, afterId) {
          let list = this.getListTasks(key);
          let addToTop = true;

          // Prevent duplication (can be caused by dragging on board).
          if (list.indexOf(id) === -1) {
            // Attempt to insert it after another item, if provided and present.
            if (afterId) {
              afterId = Number(afterId);
              let index = list.indexOf(afterId);
              if (index !== -1) {
                list.splice(index + 1, 0, id);
                // This worked, don't add it to the top too.
                addToTop = false;
              }
            }

            // If the ID hasn't already been inserted, just add it to the top.
            if (addToTop) {
              list.unshift(id);
            }
          }

          // Update the corresponding search query.
          let request = searchService.getSearchByKey(key);
          request.limit += 1;
        };

        // Removes the given item from the corresponding list (just locally).
        this.removeFromList = function (key, id) {
          let list = this.getListTasks(key);
          let index = list.indexOf(id);

          // Don't remove from the list if it's not there.
          if (index >= 0) {
            list.splice(index, 1);
          }

          // Update the corresponding search query.
          let request = searchService.getSearchByKey(key);
          request.limit -= 1;
        };

        this.processListMove = function (fromList = {}, toList = {}, task) {

          let updateLists = [];
          // @TODO: Support multiple dimensions.
          let dimension = 0;
          // Get the facet list from the task list service.
          let facets = taskListService.getFacets();
          let id = self.getItemId(task);

          if (fromList.key != toList.key) {
            let date = new Date();

            this.movedTasks[id] = {
              removedListKey: fromList.key,
              addedListKey: toList.key,
              addedAt: (toList.tasks) ? toList.tasks.indexOf(id) : 0,
              changed: date
            };
          }

          // Get and/or initialize the layout.
          self.getActiveLayout(false).then(function(activeLayout) {
            let layout = activeLayout.dimensions[dimension];
            // Update the lists for the layouts.
            angular.forEach(layout.field_grouping_values, function(list, index) {
              let count = -1;

              // From list should be decremented.
              if (list.key == fromList.key) {
                fromList.count--;
                list.tasks = fromList.tasks;
                count = fromList.count;

                // Remove the task from the source list.
                self.removeFromList(fromList.key, id);
              }
              // To list should be incremented.
              if (list.key == toList.key) {
                toList.count++;
                list.tasks = toList.tasks;
                count = toList.count;

                // Get the ID of the task above this task based on weight.
                let afterId = self.getIdByWeight(toList.tasks, task.field_weight);
                // Add the task to the destination list.
                self.addToList(toList.key, id, afterId);
              }

              // If a change is called for, make the updates.
              if (count >= 0) {
                list.count = count;
                updateLists.push(list);
                layout.field_grouping_values[index] = list;

                // Update facets for all values that changed.
                // (This prevents the layout from being changed back without
                // new info from the server, and makes sure layout and facet
                // counts are uniform.)
                let fieldName = layout.field_grouping_field;
                if (facets.hasOwnProperty(fieldName)) {
                  // Get the right facet for the layout.
                  let layoutFacet = facets[fieldName];

                  // And make sure that the layout has a value for this item.
                  if (list.id && layoutFacet.hasOwnProperty(list.id)) {
                    layoutFacet[list.id] = count;
                  }
                }
              }
            });

            // Push the changes out.
            $rootScope.$emit('layoutUpdate', layout.field_grouping_values);

            // Tell the search service about the local changes.
            searchService.updateTaskGroups(updateLists);
          });
        };



        // Finds and removes a task from the layout.
        this.removeTask = function(task) {
          self.processTaskUpdates(task, task, {});
        };

        // Set the listener to process the facets once tasks are loaded.
        $rootScope.$on('taskListLoaded', function(event, type, tasklist) {
          // Old data? Don't update the layout yet.
          if (type != 'init' && tasklist.lastFetch < tasklist.lastChanged) {
            return;
          }
          // Don't update the layout if the view is mid-change.
          if (self.preventUpdate) {
            return;
          }

          self.getLayoutFacets();
          self.updateListTasks(tasklist);
        });

        // Retrieve the primary id for the item, either nid or hash (or 0).
        this.getItemId = function(item) {
          let id = (item.nid) ? Number(item.nid) : 0;
          // NIDs should be numbers, if not attempt to set as a hash.
          if (item.hash) {
            if (!id || id == 'NaN') {
              id = item.hash;
            }
          }
          return id;
        };

        // Determines and returns the list that a task is in.
        this.getListByTask = function(layout, task, initKey = 'nid') {
          let dimension = 0;
          // Determine where this should go in the layout.
          let layoutGroup = layout.dimensions[dimension];
          let keyField = layoutGroup.field_grouping_field;
          let lists = layoutGroup.field_grouping_values;

          // @TODO: Code below is functionally similar to getListByKeyField()
          //   with the exception of defaulting to list index 0 instead of list
          //   ID 0. Combine?
          let id = task[initKey] ? Number(task[initKey]) : task.hash;

          // Default to first list.
          let list = lists[0];

          if (task[keyField]) {
            let taskVal = task[keyField];
            list = commonService.getObjectById(lists, taskVal);
          }

          return list;
        };

        // Retrieves the list for a task based on the keyfield.
        this.getListByKeyField = function(keyField, lists, item) {
          let list;
          if (item.hasOwnProperty(keyField)) {
            list = commonService.getObjectById(lists, item[keyField]);
          }
          else if (keyField === '') {
            // Get the No Grouping list if the keyfield is an empty string.
            list = commonService.getObjectById(lists, 0);
          }
          return list;
        };

        this.processTaskUpdates = function(task, unchanged, updates, op) {
          self.getActiveLayout(false).then(function(layout) {
            // If this is called before layouts are loaded, this will fail.
            if (!layout) {
              return;
            }

            // @TODO: Support multiple dimensions.
            let dimension = 0;
            // Determine where this should go in the layout.
            let layoutGroup = layout.dimensions[dimension];
            let keyField = layoutGroup.field_grouping_field;
            let lists = layoutGroup.field_grouping_values;
            let id = self.getItemId(task);
            let sourceList;
            let destList;

            // Only try to remove from the source if there's an unchanged val.
            // This should happen for non-created.
            if (!commonService.empty(unchanged)) {
              // Remove the task from the source list.
              sourceList = self.getListByKeyField(keyField, lists, unchanged);
            }

            if (op == 'created' && task.hasOwnProperty(keyField)) {
              destList = commonService.getObjectById(lists, task[keyField]);
            }
            // Only try to add the task if there's a current/updated version.
            // Created/deleted ops won't have this.
            else if (!commonService.empty(updates)) {
              // Add the task to the destlist.
              destList = self.getListByKeyField(keyField, lists, updates);
            }

            // If this was a move: remove from old list and add to new list.
            if (sourceList || destList) {
              // Only proces the move if the two lists aren't the same.
              if (sourceList && destList && (sourceList.key == destList.key)) {
                return;
              }
              // Do general cleanup of counts, etc. without waiting for server.
              self.processListMove(sourceList, destList, task);
            }
          });
        };

        // Reintialize layouts on new routes to prevent showing old data.
        $rootScope.$on('$routeChangeStart', function() {
          self.reinit();
        });

        // Make adjustments to layout based to account for local changes.
        $rootScope.$on('localItemUpdate', function (event, objectList, op, item, initKey, info) {
          // Only fire on swap events, all else goes through taskUpdate event.
          if (objectList == 'tasks' && op == 'created' && info.addItem && info.remove) {
            // Add the new task (now with nid).
            self.processTaskUpdates(item, {}, item, 'swap');

            let clone = angular.copy(item);
            clone.nid = clone.hash;
            // Remove the temp cloned one.
            self.processTaskUpdates(clone, clone, {}, 'swap');
          }
        });

        // TaskService fires this on just about every update to a task.
        $rootScope.$on('taskUpdate', function(event, task, unchanged, updates, op) {
          // Digest those changes and update the layout where it makes sense.
          self.processTaskUpdates(task, unchanged, updates, op);
        });

        // Respond to perform the proper layout/search updates.
        $rootScope.$on('hideTask', function(event, nid, task) {
          self.removeTask(task);
        });

        // Kick off initial setup.
        this.init();
      }
    ]);
})();
