(function () {
  'use strict';

  angular.module('fileview', [
    'ngRoute',
    'angular-drupal',
    'infinite-scroll',
    'md.data.table',
    'table'
  ])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/:accountId/projects/:projectId/files/:taskId?', {
      templateUrl: 'app/src/fileview/fileview.html',
      controller: 'FileviewCtrl',
      activetab: 'files',
      reloadOnSearch: false
    });
  }])

  .controller('FileviewCtrl', [
    '$scope',
    '$rootScope',
    '$controller',
    '$timeout',
    'layoutService',
    'taskListService',
    'filterService',
    'searchService',
    function ($scope, $rootScope, $controller, $timeout, layoutService, taskListService, filterService, searchService) {
      $scope.sel = filterService.getFiltering().selection;
      $scope.sel.field_attachment = true;

      $scope.initted = false;

      $scope.hideProjectColumn = function() {
        return !$scope.noProject;
      };

      // Could ideally be sent from the server at some point. This has the
      // configurations for the order and lookup info for each cell.
      $scope.tableColumns = [
        {
          title: 'File Name',
          fieldName: 'file_filename',
          sortable: true,
          preventTaskTrigger: true

        },
        {
          title: 'Uploaded',
          fieldName: 'file_created',
          sortable: true
        },
        {
          title: 'Uploaded By',
          fieldName: 'file_uid',
          sortable: true,
        },
        {
          title: 'Size',
          fieldName: 'file_filesize',
          sortable: true
        },
        // {
        //   title: 'Owner',
        //   fieldName: 'field_task_owner',
        //   sortable: true
        // },
        {
          title: 'Task Name',
          fieldName: 'title',
          sortable: true
        },
        {
          title: 'Project',
          fieldName: 'project',
          sortable: true,
          hide: $scope.hideProjectColumn,
          preventTaskTrigger: true
        }
      ];

      angular.extend(this, $controller('tableCtrl', {$scope: $scope}));

      searchService.setDefaultLimit(3);

      $scope.processTasks = function(lists) {
        if (lists) {
          $scope.lists = lists;
          $scope.fileLists = {};
          angular.forEach(lists, function(list) {
            if (list.tasks) {

              // Each task can have multiple files.
              $scope.fileLists[list.key] = {};
              angular.forEach(list.tasks, function(nid) {

                // Load the task and grab all of the files for that task.
                let task = taskListService.getTaskById(nid);
                if (task && task.file_fid) {

                  // Create a new list keyed by FID not NID.
                  angular.forEach(task.file_fid, function(fid) {
                    $scope.fileLists[list.key][fid] = nid;
                  });
                }
              });
            }
          });

        }

        $timeout(function() {
          $scope.pageReady();
          $scope.$digest();
        });
      }

      $scope.reinit = function() {
        if ($scope.initted) {
          $scope.init();
        }
      }

      // Updates to the layout go through the layout service.
        var layoutListener = $rootScope.$on('layoutUpdate', function (event, lists) {
          $scope.pauseScroll = false;
          // This adds tasks and/or task updates to the board.
          $scope.processTasks(lists);
        });
        $rootScope.addListener(layoutListener, $scope);


    }
  ]);
})();
