(function () {
  'use strict';

  var app = angular.module('task');

  app.directive('taskTrigger', [
    'taskService',
    'taskListService',
    'multiEditService',
    function () {
      return {
        transclude: true,
        scope: {
          task: '=?',
          nid: '=?',
          column: '='
        },
        controller: function ($scope, taskService, taskListService, multiEditService) {
          $scope.taskModal = function (ev) {
            if (typeof $scope.nid == 'undefined') {
              if ($scope.hasOwnProperty('task') && $scope.task.hasOwnProperty('nid')) {
                $scope.nid = $scope.task.nid;
              }
            }

            let task = taskListService.getTaskById($scope.nid);
            let taskCreated = true;

            if (typeof task != 'undefined') {

              // Skip if the task hasn't been created, or doesn't have an ID.
              if (task.field_entity_state == 'cloning') {
                taskCreated = false;
              }
              else {
                taskCreated = taskService.hasValidId(task);
              }
            }
            else if (!taskService.isValidId($scope.nid)) {
              taskCreated = false;
            }

            // Respect column settings on if this task trigger should fire.
            let prevented = false;
            if (typeof $scope.column != 'undefined'
              && typeof $scope.column.preventTaskTrigger != 'undefined') {
              prevented = $scope.column.preventTaskTrigger;
            }

            // Make sure that multi-edit isn't on and the task has been created.
            let multiEditEnabled = multiEditService.isMultiEditEnabled();

            if (!prevented && !multiEditEnabled && taskCreated) {
              taskService.openTaskModal($scope.nid);
            }
          }
        },
        templateUrl: 'app/src/task/task-trigger.html'
      };
    }
  ]);
})();
